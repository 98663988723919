import AppInfo from '../../httpClient/AppInfo';

//Action Types
export const types = {
  FETCHING_TERMS_DATA: 'FETCHING_TERMS_DATA',
  FETCHING_TERMS_DATA_SUCCESS: 'FETCHING_TERMS_DATA_SUCCESS',
  FETCHING_TERMS_DATA_FAILURE: 'FETCHING_TERMS_DATA_FAILURE'
};

//Initial States
export const initialState = {
  terms: null,
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_TERMS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_TERMS_DATA_SUCCESS:
      return { ...state, isLoading: false, terms: action.terms, error: null };

    case types.FETCHING_TERMS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

//Actions
export function fetchTermsAndConditions() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_TERMS_DATA });
    return AppInfo.getTermsAndConditions()
      .then(data => {
        dispatch({
          type: types.FETCHING_TERMS_DATA_SUCCESS,
          terms: data.terms.caption
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_TERMS_DATA_FAILURE, error: err });
      });
  };
}
