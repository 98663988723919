//react
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import axios from 'axios';

//App Settings
import { config } from '../../utils/AppSettings';
import { apiBaseEndpointUrl } from '../../utils/NetworkSettings'

//css
import './LogIn.css';
import 'react-toastify/dist/ReactToastify.css';

//Redux
import { connect } from 'react-redux';
import { loginAdmin, logOutAdmin } from './../../redux/modules/Admin';

//components
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';

//okta
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
//import '@okta/okta-auth-js';
import { OktaAuth } from '@okta/okta-auth-js';

class LogIn extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.props.logOutAdmin();

    this.state = {
      loggedIn: false
    };

    this.signIn = new OktaSignIn(config);


  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.AdminReducer.error,
      loading: nextProps.AdminReducer.isLoading,
      loggedIn: nextProps.AdminReducer.loggedIn,
      redirect: nextProps.AdminReducer.loggedIn
    });

    if (nextProps.AdminReducer.error) {
      if (nextProps.AdminReducer.error.response) {
        const { data } = nextProps.AdminReducer.error.response
        let message = data.error && data.error.message || 'Error';
        toast.error(message, { position: 'bottom-center' });
        this.signIn.remove();
        this.renderWidget();
      } else {
        toast.error('Unable to Connect to Server', {
          position: 'bottom-center'
        });
      }
    }
  }

  componentDidMount = async () => {
    this.signIn.remove();
    this.renderWidget();
  };

  onSuccess = (res) => {
    if (res.status === 'SUCCESS') {
      this.props.loginAdmin(res.claims.sub);
    }
  };

  renderWidget = () => {
    this.signIn.renderEl(
      // Assumes there is an empty element on the page with an id of 'osw-container'
      { el: '#widget-container' },
      (res) => this.onSuccess(res)
    );
  };

  /**
   * Get okta login url
   */
  handleLogin = async () => {
    let samlCallbackUrl = window.location.origin + "/samlCallback";
    const endpoint = apiBaseEndpointUrl + "/api2/admin/saml/Login?returnUrl=" + samlCallbackUrl;

    try {
      const response = await axios.get(endpoint)
      if (response.data)
        window.location.href = response.data
    } catch (e) {
      toast.error("An Error Occurred", { position: 'bottom-center' });
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect push to="/Dashboard" />;
    }

    return (
      <div className="center">
        <h2 className="fade-in-text" style={{ marginBottom: '5%' }}>Welcome to the GMR Connect Admin Portal</h2>
        <button className="login-button" onClick={this.handleLogin}>Login with Okta</button>
        <ToastContainerComponent />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAdmin: (sub) => dispatch(loginAdmin(sub)),
    logOutAdmin: () => dispatch(logOutAdmin())
  };
};

export default LogIn = connect(mapStateToProps, mapDispatchToProps)(LogIn);