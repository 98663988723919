import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './components/PrivateRoute/PrivateRoute.js';
import { PublicRoute } from './components/PublicRoute/PublicRoute.js';

import Dashboard from './pods/Dashboard/Dashboard';
import LogIn from './pods/LogIn/LogIn';
import GeofencedNotifications from './pods/GeofencedNotifications/GeofencedNotifications';
import PushNotifications from './pods/PushNotifications/PushNotifications';
import Notifications from './pods/Notifications/Notifications';
import Protocols from './pods/Protocols/Protocols';
import ContinuingEducation from './pods/ContinuingEducation/ContinuingEducation';
import Forms from './pods/Forms/Forms';
import Documents from './pods/Documents/Documents';
import Terms from './pods/Terms/Terms';
import Search from './pods/CDM/Search/Search';
import EmployeeDetail from './pods/CDM/EmployeeDetail/EmployeeDetail';
import Contacts from './pods/Contacts/Contacts';
import SelfService from './pods/SelfService/SelfService';
import SamlCallback from './pods/SamlCallback/SamlCallback';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /login
// and /dashboard routes will match any pathname that starts
// with /login or /dashboard. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <div>
    <Switch>
      <PublicRoute path="/LogIn" component={LogIn} />
      <PublicRoute path="/samlCallback" component={SamlCallback} />
      <Route path="/Terms" component={Terms} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute path="/Dashboard" component={Dashboard} />
      <PrivateRoute path="/GeofencedNotifications" component={GeofencedNotifications} />
      <PrivateRoute path="/PushNotifications" component={PushNotifications} />
      <PrivateRoute path="/Notifications" component={Notifications} />
      <PrivateRoute path="/Protocols" component={Protocols} />
      <PrivateRoute path="/ContinuingEducation" component={ContinuingEducation} />
      {/* <PrivateRoute path="/Forms" component={Forms} /> */}
      <PrivateRoute path="/Documents" component={Documents} />
      <PrivateRoute path="/CDM/Search" component={Search} />
      <PrivateRoute path="/CDM/EmployeeDetail" component={EmployeeDetail} />
      <PrivateRoute path="/Contacts" component={Contacts} />
      <PrivateRoute path="/SelfService" component={SelfService} />
    </Switch>
  </div>
);

export default Main;
