import RestApi from './RestApi';

import { cdmApiUrl } from '../utils/NetworkSettings';

class CDM {
  static getFilterValues() {
    const api = new RestApi();
    // return api.GMRGet('certificates/documentTypes').then((response) => {
    return api.GET('/cdm?p1=GF').then((response) => {
      return response;
    });
  }

  static auditVerification(OracleEmpNum) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GM&p2=' + encodeURIComponent(OracleEmpNum)).then((response) => {
      return response;
    });
  }

  static regulatoryApproval(OracleEmpNum) {
    const api = new RestApi();
    return api.GET('/cdm?p1=RA&p2=' + encodeURIComponent(OracleEmpNum)).then((response) => {
      return response;
    });
  }

  static getEmployee(OracleEmpNum) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GE&p2=' + encodeURIComponent(OracleEmpNum)).then((response) => {
      return response;
    });
  }

  static getTerminatedEmployee(OracleEmpNum) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GT&p2=' + encodeURIComponent(OracleEmpNum)).then((response) => {
      return response;
    });
  }

  static searchEmployees(searchText, adminID) {
    const api = new RestApi();
    return api.POST('/cdm/listOfEmployees?p1=GE&p2=' + encodeURIComponent(searchText) + '&p3=' + encodeURIComponent(adminID)).then((response) => {
      return response;
    });
  }

  static searchTerminatedEmployees(searchText, adminID) {
    const api = new RestApi();
    return api
      .POST('/cdm/listOfTerminatedEmployees?p1=GT&p2=' + encodeURIComponent(searchText) + '&p3=' + encodeURIComponent(adminID))
      .then((response) => {
        return response;
      });
  }
  // static searchEmployees(searchText, adminID) {
  //   const api = new RestApi();
  //   return api
  //     .CONNECT_GET('/v1/Employees/listOfEmployees?searchText=' + encodeURIComponent(searchText) + '&adminID=' + encodeURIComponent(adminID))
  //     .then((response) => {
  //       return response;
  //     });
  // }

  // static searchEmployees(firstName, lastName, employeeNumber, buIDs) {
  //   const api = new RestApi();
  //   firstName = encodeURIComponent(firstName);
  //   lastName = encodeURIComponent(lastName);
  //   employeeNumber = encodeURIComponent(employeeNumber);
  //   return api
  //     .GMRPost('employees/searchAdminTool?firstName=' + firstName + '&lastName=' + lastName + '&employeeId=' + employeeNumber, buIDs)
  //     .then((response) => {
  //       return response;
  //     });
  // }
  // static employeeCerts(oracleID) {
  //   const api = new RestApi();
  //   return api.GMRGet('employees/certificationstatus?employeeId=' + encodeURIComponent(oracleID)).then((response) => {
  //     return response;
  //   });
  // }

  static employeeCerts(OracleEmpNum) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GC&p2=' + encodeURIComponent(OracleEmpNum) + '&p3=' + encodeURIComponent('Active')).then((response) => {
      return response;
    });
  }

  static historicalEmployeeCerts(OracleEmpNum, certStatus) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GC&p2=' + encodeURIComponent(OracleEmpNum) + '&p3=' + encodeURIComponent(certStatus)).then((response) => {
      return response;
    });
  }

  static terminatedEmployeeCerts(OracleEmpNum, certStatus) {
    const api = new RestApi();
    return api.GET('/cdm?p1=TC&p2=' + encodeURIComponent(OracleEmpNum) + '&p3=' + encodeURIComponent(certStatus)).then((response) => {
      return response;
    });
  }

  static postNewCert(params) {
    const api = new RestApi();
    // return api.GMRPost('employees/submitToWorkDay', params).then((response) => {
    return api.POST('/cdm/addCert', params).then((response) => {
      return response;
    });
  }

  static addNewUpdateLog(adminID, payload) {
    const api = new RestApi();
    return api.POST('/cdm?p1=ML', payload).then((response) => {
      // return api.POST('/cdm?p1=GE&p2=' + encodeURIComponent('yes'), payload).then(response => {
      return response;
    });
  }

  static updateCert(adminID, params) {
    const api = new RestApi();
    return api.PUT('/cdm/saveCert?adminID=' + encodeURIComponent(adminID), params).then((response) => {
      return response;
    });
  }

  static resetEmployeePassword(adminID, params) {
    const api = new RestApi();
    return api.POST('/cdm?adminID=' + encodeURIComponent(adminID), params).then((response) => {
      return response;
    });
  }

  static getDocumentTypes() {
    const api = new RestApi();
    return api.EXTERNALGET(encodeURIComponent(cdmApiUrl) + 'certificates/GetDocumentTypes').then((response) => {
      return response;
    });
  }

  static updateEmployee(params) {
    const api = new RestApi();
    return api.PUT('/cdm', params).then((response) => {
      return response;
    });
  }

  static getStateOrCounties(county) {
    const api = new RestApi();
    return api.EXTERNALGET('/cdm?p1=SC&p2=' + encodeURIComponent(county)).then((response) => {
      return response;
    });
  }

  static getEmployeeTitles(OracleEmpNum) {
    const api = new RestApi();
    return api.GMRGet('employees/positions?employeeId=' + encodeURIComponent(OracleEmpNum)).then((response) => {
      return response;
    });
  }

  static getCertsStatus() {
    const api = new RestApi();
    return api.GET('/cdm/GetCertStatus').then((response) => {
      return response;
    });
  }

  static eVerifyStatus(OracleEmpNum, CertNumber) {
    const api = new RestApi();
    return api.GET('/cdm?p1=EV&p2=' + encodeURIComponent(OracleEmpNum) + '&p3=' + encodeURIComponent(CertNumber)).then((response) => {
      return response;
    });
  }

  static getBase64Image(params) {
    const api = new RestApi();
    return api.CONNECT_POST('/Certificate/getImage', params).then((response) => {
      return response;
    });
  }

  static getImageFromOracle(base64Image) {
    const api = new RestApi();
    return api.GET('/cdm?p1=GI&p2=' + encodeURIComponent(base64Image)).then((response) => {
      return response;
    });
  }

  static getAllCounties(stateCode) {
    const api = new RestApi();
    return api.GET('/cdm?state=' + encodeURIComponent(stateCode)).then((response) => {
      return response;
    });
  }

  static eVerifyCert(params) {
    const api = new RestApi();
    return api.POST('/cdm?eVerify=eVerify', params).then((response) => {
      return response;
    });
  }

  static getEverifyStates() {
    const api = new RestApi();
    return api.GET('/cdm?p1=VS').then((response) => {
      return response;
    });
  }

  static GetEcard(eCardNumber, eCardType, OracleEmpNum) {
    const api = new RestApi();
    return api
      .GET(
        '/cdm/GetECard?eCardNumber=' +
          encodeURIComponent(eCardNumber) +
          '&eCardType=' +
          encodeURIComponent(eCardType) +
          '&user=' +
          encodeURIComponent(OracleEmpNum)
      )
      .then((response) => {
        return response;
      });
  }

  static getEcardMapping(codeType, agency) {
    const api = new RestApi();
    return api.GET('/cdm?p1=EM&p2=' + encodeURIComponent(codeType) + '&p3=' + encodeURIComponent(agency)).then((response) => {
      return response;
    });
  }

  static getValidDLFormat(stateCode) {
    const api = new RestApi();
    return api.GET('/cdm?p1=DL&p2=' + encodeURIComponent(stateCode)).then((response) => {
      return response;
    });
  }

  static getAgencies() {
    const api = new RestApi();
    return api.GET('/cdm?p1=GA').then((response) => {
      return response;
    });
  }

  // static setCertificationStatus(adminID, payload) {
  //   const api = new RestApi();
  //   return api.GMRPost('employees/setCertificationStatus?adminID=' + encodeURIComponent(adminID), payload).then((response) => {
  //     return response;
  //   });
  // }
  static setCertificationStatus(adminID, payload) {
    const api = new RestApi();
    return api.POST('/cdm/CertStatus?adminID=' + encodeURIComponent(adminID), payload).then((response) => {
      return response;
    });
  }

  static setManuallyVerified(adminID, params) {
    const api = new RestApi();
    return api.POST('/cdm/manuallyVerifiedCert?adminID=' + encodeURIComponent(adminID), params).then((response) => {
      return response;
    });
  }

  static getWebAppVersion(platform) {
    return new Promise((resolve, reject) => {
      const api = new RestApi();
      api
        .AMRV1GET('/appUpdate?p1=' + encodeURIComponent(platform))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  }

  static CertificationAudit(payload) {
    const api = new RestApi();
    return api.POST('/cdm?p1=AV', payload).then((response) => {
      return response;
    });
  }
}
export default CDM;
