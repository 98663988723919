//react
import React, { Component } from 'react';
import { withRouter } from 'react-router';

//css
import './Header.css';

//images
import amrLogo from '../../assets/images/AMR-Logo-white.png';

//Redux
import { connect } from 'react-redux';
import { logOutAdmin } from '../../redux/modules/Admin';

//HttpsClient
import httpDocument from '../../httpClient/Documents';

//Components
import CountiesComponent from '../CountiesComponent/CountiesComponent';
import { history } from '../../utils/AppSettings.js';

//Libraries
import { toast } from 'react-toastify';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: 'Select a Home County',
      currentPDF: null,
      urlPDF: '\\AppDocuments\\Help.pdf',
      pdfLink: null
    };
    this.loadDocument();
  }
  logoutAdmin = () => {
    sessionStorage.removeItem('admin');
    localStorage.removeItem('businessUnits');
    localStorage.removeItem('securityRoles');
    this.props.history.push('/login');
  };

  loadDocument = async () => {
    try {
      const rawURL = await httpDocument.fetchHelpPDF(this.state.urlPDF);
      this.setState({
        pdfLink: rawURL
      });
    } catch (e) {
      toast.dismiss();
      toast.error('Failed to load PDF, please try again');
    }
  };

  pdfBlobConvesion = (b64Data, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    let byteCharacters = window.atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, { type: 'application/pdf' });
    return blob;
  };

  render() {
    const { loggedIn } = this.props.AdminReducer;
    const { appVersion } = this.props;
    const versionComp = <p className="appVersion">{appVersion}</p>;
    return (
      <div className={`navbar ${process.env.REACT_APP_ENV}`}>
        <div>
          <img
            src={amrLogo}
            alt="logo"
            className="headerLogo"
            onClick={() => {
              loggedIn && this.props.history.push('/Dashboard');
            }}
          />
        </div>

        {/* { loggedIn && admin !== null ? <span className="User">{ admin.FirstName } { admin.LastName }</span> : null} */}
        {loggedIn && <CountiesComponent />}
        {loggedIn ? (
          <div>
            {versionComp}
            {/* {/uat/i.test(process.env.REACT_APP_ENV) ? versionComp : /qa/i.test(process.env.REACT_APP_ENV) ? versionComp : null} */}
            <a href={this.state.pdfLink} target="_blank" className="Help">
              Help
            </a>
            <button className="Signout" href="#" onClick={this.logoutAdmin}>
              Sign Out
            </button>
          </div>
        ) : (
          versionComp
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOutAdmin: () => dispatch(logOutAdmin())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));