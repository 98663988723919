import React, { Component } from 'react';
import "./SessionTimeoutDialog.css"

export default class SessionTimeoutDialog extends Component {
  render() {
    console.log('modal')
    return (
      <div className="modal custom-dialog-body">
        <div className="modal-content">
          <p className="custom-dialog-text"> Your session will logout in <span className="custom-dialog-time"> {this.props.countDown}</span> seconds.</p>
          <div className='custom-Buttons'>
            <button className="custom-button close" onClick={this.props.onContinue}>Continue</button>
            <button className="custom-button close" onClick={this.props.onLogout}>Logout</button>

          </div>

        </div>

      </div>
    )
  }
}
