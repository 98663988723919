import GeofencedNotifications from '../../httpClient/GeofencedNotifications';

//Action Types
export const types = {
  FETCHING_GEOFENCEDNOTIFICATIONS_DATA: 'FETCHING_GEOFENCEDNOTIFICATIONS_DATA',
  FETCHING_GEOFENCEDNOTIFICATIONS_DATA_SUCCESS: 'FETCHING_GEOFENCEDNOTIFICATIONS_DATA_SUCCESS',
  FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE: 'FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE',
  NEW_GEOFENCEDNOTIFICATION_DATA_SUCCESS: 'NEW_GEOFENCEDNOTIFICATION_DATA_SUCCESS',
  UPDATE_GEOFENCEDNOTIFICATION_DATA_SUCCESS: 'UPDATE_GEOFENCEDNOTIFICATION_DATA_SUCCESS',
  DELETE_GEOFENCEDNOTIFICATION_DATA_SUCCESS: 'DELETE_GEOFENCEDNOTIFICATION_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  geofencedNotifications: [],
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geofencedNotifications: action.geofencedNotifications,
        error: null
      };

    case types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_GEOFENCEDNOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: true,
        error: null
      };

    case types.UPDATE_GEOFENCEDNOTIFICATION_DATA_SUCCESS:
      return { ...state, isLoading: false, error: null };

    case types.DELETE_GEOFENCEDNOTIFICATION_DATA_SUCCESS:
      return { ...state, isLoading: false, error: null };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveGeofencedNotifications(businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA });
    return GeofencedNotifications.allActiveGeofencedNotifications(businessUnitID)
      .then(data => {
        dispatch({
          type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_SUCCESS,
          geofencedNotifications: data.geofencedNotifications
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function postNewGeofencedNotification(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA });
    return GeofencedNotifications.postNewGeofencedNotification(params)
      .then(data => {
        dispatch({ type: types.NEW_GEOFENCEDNOTIFICATION_DATA_SUCCESS });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function updateGeofencedNotification(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA });
    return GeofencedNotifications.updateGeofencedNotification(params)
      .then(data => {
        dispatch({ type: types.UPDATE_GEOFENCEDNOTIFICATION_DATA_SUCCESS });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function deleteGeofencedNotification(notificationID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA });
    return GeofencedNotifications.deleteGeofencedNotification(notificationID)
      .then(data => {
        dispatch({ type: types.DELETE_GEOFENCEDNOTIFICATION_DATA_SUCCESS });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_GEOFENCEDNOTIFICATIONS_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
