//react
import React, { Component } from 'react';

//css
import './EmptyState.css';

//images
import emptyStateIcon from '../../assets/images/ambulance.png';

class EmptyState extends Component {
  render() {
    return (
      <div className="empty">
        <div className="empty__icon">
          <img src={emptyStateIcon} alt="emptystate" />
        </div>
        <p className="empty__icontitle">{this.props.title}</p>
        {/* <p className="empty__topline">{this.props.subTitle} <span>&rarr;</span></p>                           */}
      </div>
    );
  }
}

export default EmptyState;
