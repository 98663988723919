import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FaCaretDown, FaCaretRight } from 'react-icons/lib/fa';
//css
import './Accordian.css';

class Accordian extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    onClickHandler = () => {
        this.setState(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));
    }

    render() {
        const { title, children, className, ...rest } = this.props;
        const { isOpen } = this.state;
        return (
            <div>
                <buttton className={`accordian-header ${className} ${isOpen ? 'active' : ''}`} onClick={this.onClickHandler} {...rest}>{isOpen ? <FaCaretDown className="icon" /> : <FaCaretRight className="icon" />}{title}</buttton>
                <div className={`accordian-panel ${isOpen ? 'active' : ''}`}>{children}</div>
            </div>
        );
    }
}

Accordian.propTypes = {
    title: PropTypes.string.isRequired,
};

Accordian.defaultProps = {
    className: "",
}

export default Accordian;