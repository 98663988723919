import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Main from './Main';
// css
import './App.css';
// App Settings
import { config } from './utils/AppSettings';

// components
import Header from './components/Header/Header';
import SessionTimeout from './components/SessionTimeout/SessionTimeout';
import { OktaAuth } from '@okta/okta-auth-js';
import httpCDM from './httpClient/CDM.js';
import { appVersion } from './utils/AppSettings';

class App extends Component {
  constructor(props) {
    super(props);
    const self = this;
    // this.state = {
    //   appVersion
    // };
    const authClient = new OktaAuth(config);
    // authClient.session
    //   .get()
    //   .then(function(session) {
    //     if (session.status.toLowerCase() === 'inactive') {
    //       sessionStorage.removeItem('admin');
    //       localStorage.removeItem('businessUnits');
    //       localStorage.removeItem('securityRoles');
    //       self.props.history.push('/login');
    //     }
    //     // logged in
    //   })
    //   .catch(function(err) {
    //     sessionStorage.removeItem('admin');
    //     localStorage.removeItem('businessUnits');
    //     localStorage.removeItem('securityRoles');
    //     self.props.history.push('/login');

    //     // not logged in
    //   });
    // httpCDM
    //   .getWebAppVersion('AdminTool')
    //   .then((response) => {
    //     console.log('response v', response);
    //     const { appVersion: latestAppVersion } = response.appVersion;
    //     if (latestAppVersion && latestAppVersion != this.state.appVersion) this.setState({ appVersion: latestAppVersion });
    //   })
    //   .catch((err) => console.log(err));
    // this.environment =  process.env.REACT_APP_ENV;
  }

  componentDidMount() {
    httpCDM
      .getWebAppVersion('AdminTool')
      .then((response) => {
        const { appVersion: latestAppVersion } = response.appVersion;
        if (latestAppVersion && latestAppVersion !== appVersion) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          localStorage.clear();
          window.location.reload();
        };
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      // <div>
      <div className="App">
        <Header {...{ environment: this.environment, appVersion }} />
        <div className="main">
          {this.props.location.pathname === '/login/' ? '' : <SessionTimeout />}

          <Main />
        </div>
      </div>
      // </div>
    );
  }
}

export default withRouter(App);
