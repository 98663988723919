import CDM from '../../httpClient/CDM';

//Action Types
export const types = {
  FETCHING_CDM_DATA: 'FETCHING_CDM_DATA',
  CLEAR_CDM_ERROR: 'CLEAR_CDM_ERROR',
  FETCHING_CDM_LOOKUP_VALUES_SUCCESS: 'FETCHING_CDM_LOOKUP_VALUES_SUCCESS',
  FETCHING_CDM_DATA_SUCCESS: 'FETCHING_CDM_DATA_SUCCESS',
  FETCHING_CDM_DATA_FAILURE: 'FETCHING_CDM_DATA_FAILURE',
  NEW_CDM_DATA_SUCCESS: 'NEW_CDM_DATA_SUCCESS',
  UPDATE_CDM_DATA_SUCCESS: 'UPDATE_CDM_DATA_SUCCESS',
  UPDATE_EMPLOYEE_PASSWORD_DATA_SUCCESS: 'UPDATE_EMPLOYEE_PASSWORD_DATA_SUCCESS',
  CLEAR_CDM_DATA_SUCCESS: 'CLEAR_CDM_DATA_SUCCESS',
  SET_EMPLOYEE_DATA_SUCCESS: 'SET_EMPLOYEE_DATA_SUCCESS',
  SET_EMPLOYEE_CERTS_SUCCESS: 'SET_EMPLOYEE_CERTS_SUCCESS',
  UPDATE_EMPLOYEE_DATA_SUCCESS: 'UPDATE_EMPLOYEE_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES',
  UNSET_EMPLOYEE: 'UNSET_EMPLOYEE',
  FETCHING_CDM_COUNTY_LIST_SUCCESS: 'FETCHING_CDM_COUNTY_LIST_SUCCESS',
  FETCHING_CDM_COUNTY_LIST_FAILURE: 'FETCHING_CDM_COUNTY_LIST_FAILURE',
  FETCHING_CDM_COUNTY_LIST: 'FETCHING_CDM_COUNTY_LIST',
  SET_AUDIT_VERIFICATION_SUCCESS: 'SET_AUDIT_VERIFICATION_SUCCESS',
  SET_REGULATORY_APPROVED_SUCCESS: 'SET_REGULATORY_APPROVED_SUCCESS',
  SET_EMPLOYEE_INFO_SUCCESS: 'SET_EMPLOYEE_INFO_SUCCESS',
  NEW_LOG_DATA_SUCCESS: 'NEW_LOG_DATA_SUCCESS',
  FETCHING_EMPLOYEE_CERTS: 'FETCHING_EMPLOYEE_CERTS',
  SET_CERT_INACTIVE_SUCCESS: 'SET_CERT_INACTIVE_SUCCESS',
  SET_MANUAL_VERIFICATION_SUCCESS: 'SET_MANUAL_VERIFICATION_SUCCESS'
};

//Initial States
export const initialState = {
  lookupValues: [],
  employees: [],
  selectedEmployee: {},
  selectedEmployeeCerts: [],
  documentTypes: [],
  isLoading: false,
  error: null,
  success: false,
  countyList: [],
  isCertsLoading: true
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_CDM_DATA:
      return { ...state, isLoading: true, success: false, error: null };

    case types.CLEAR_CDM_ERROR:
      return { ...state, isLoading: false, error: null };

    case types.FETCHING_CDM_LOOKUP_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lookupValues: action.lookupValues,
        documentTypes: action.documentTypes,
        error: null
      };

    case types.FETCHING_CDM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.employees,
        error: null
      };

    case types.FETCHING_CDM_DATA_FAILURE:
      return { ...state, isLoading: false, isCertsLoading: false, error: action.error };

    case types.NEW_CDM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.NEW_LOG_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.UPDATE_CDM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.UPDATE_EMPLOYEE_PASSWORD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.CLEAR_CDM_DATA_SUCCESS:
      return { ...state, isLoading: false, employees: [], error: null };

    case types.SET_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedEmployee: action.selectedEmployee,
        selectedEmployeeCerts: [],
        error: null
      };
    case types.FETCHING_EMPLOYEE_CERTS:
      return {
        ...state,
        isLoading: true,
        isCertsLoading: true,
        error: null
      };
    case types.SET_EMPLOYEE_CERTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCertsLoading: false,
        selectedEmployeeCerts: action.selectedEmployeeCerts,
        error: null
      };

    case types.SET_AUDIT_VERIFICATION_SUCCESS:
      return {
        ...state,
        //isLoading: true,
        selectedEmployeeAudit: action.selectedEmployeeAudit,
        error: null
      };

    case types.SET_REGULATORY_APPROVED_SUCCESS:
      return {
        ...state,
        //isLoading: true,
        selectedEmployeeRegulatory: action.selectedEmployeeRegulatory,
        error: null
      };

    case types.SET_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedEmployeeInfo: action.selectedEmployeeInfo,
        error: null
      };

    case types.UPDATE_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        selectedEmployee: action.selectedEmployee,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return { ...state, isLoading: false, success: false, error: null };

    case types.FETCHING_CDM_COUNTY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countyList: action.countyList,
        error: null
      };

    case types.FETCHING_CDM_COUNTY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        countyList: [],
        error: action.error
      };

    case types.EVERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.SET_CERT_INACTIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload
      };

    case types.SET_MANUAL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        selectedEmployee: action.selectedEmployee,
        error: null
      };

    case types.FETCHING_CDM_COUNTY_LIST:
      return { ...state, isLoading: true, countyList: [], error: null };

    default:
      return state;
  }
};

//Actions
export function getFilterValues() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.getFilterValues()
      .then((data) => {
        localStorage.setItem('tokenCDM', JSON.stringify(data));
        dispatch({
          type: types.FETCHING_CDM_LOOKUP_VALUES_SUCCESS,
          lookupValues: data.filterValues.lookupValues,
          documentTypes: data.filterValues.documentTypes
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function getStateOrCounties() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_COUNTY_LIST });
    return CDM.getStateOrCounties('county')
      .then((data) => {
        localStorage.setItem('countyToken', JSON.stringify(data));
        dispatch({
          type: types.FETCHING_CDM_COUNTY_LIST_SUCCESS,
          countyList: data
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_COUNTY_LIST_FAILURE, error: err });
      });
  };
}

//export function searchEmployees(firstName, lastName, employeeNumber, buIDs) {
export function searchEmployees(searchText, adminID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    //return CDM.searchEmployees(firstName, lastName, employeeNumber, buIDs)
    return CDM.searchEmployees(searchText, adminID)
      .then((data) => {
        dispatch({
          type: types.FETCHING_CDM_DATA_SUCCESS,
          employees: data.employees
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function searchTerminatedEmployees(searchText, adminID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    //return CDM.searchEmployees(firstName, lastName, employeeNumber, buIDs)
    return CDM.searchTerminatedEmployees(searchText, adminID)
      .then((data) => {
        dispatch({
          type: types.FETCHING_CDM_DATA_SUCCESS,
          employees: data.employees
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function clearSearchResults() {
  return function(dispatch) {
    dispatch({ type: types.CLEAR_CDM_DATA_SUCCESS });
  };
}

export function setSelectedEmployee(employee) {
  return function(dispatch) {
    localStorage.setItem('tokenEmployee', JSON.stringify(employee));
    dispatch({
      type: types.SET_EMPLOYEE_DATA_SUCCESS,
      selectedEmployee: employee
    });
    // history.push('/CDM/EmployeeDetail');
  };
}

export function employeeCerts(oracleID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_EMPLOYEE_CERTS });
    return CDM.employeeCerts(oracleID)
      .then((data) => {
        dispatch({
          type: types.SET_EMPLOYEE_CERTS_SUCCESS,
          selectedEmployeeCerts: data.employeeCerts
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function historicalEmployeeCerts(oracleID, certStatus) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_EMPLOYEE_CERTS });
    return CDM.historicalEmployeeCerts(oracleID, certStatus)
      .then((data) => {
        dispatch({
          type: types.SET_EMPLOYEE_CERTS_SUCCESS,
          selectedEmployeeCerts: data.employeeCerts
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function terminatedEmployeeCerts(oracleID, certStatus) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_EMPLOYEE_CERTS });
    return CDM.terminatedEmployeeCerts(oracleID, certStatus)
      .then((data) => {
        dispatch({
          type: types.SET_EMPLOYEE_CERTS_SUCCESS,
          selectedEmployeeCerts: data.employeeCerts
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function postNewCert(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.postNewCert(params)
      .then((data) => {
        dispatch({ type: types.NEW_CDM_DATA_SUCCESS, payload: true });
        window.location.reload();
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function addNewUpdateLog(adminID, payload) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.addNewUpdateLog(adminID, payload)
      .then((data) => {
        dispatch({ type: types.NEW_LOG_DATA_SUCCESS, payload: true });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: 'er' });
      });
  };
}

export function updateCert(adminID, params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.updateCert(adminID, params)
      .then((data) => {
        dispatch({ type: types.UPDATE_CDM_DATA_SUCCESS, payload: true });
        window.location.reload();
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function resetEmployeePassword(adminID, params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.resetEmployeePassword(adminID, params)
      .then((data) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_PASSWORD_DATA_SUCCESS,
          payload: true
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function updateEmployee(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.updateEmployee(params)
      .then((data) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_DATA_SUCCESS,
          selectedEmployee: data.employee
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function clearError() {
  return function(dispatch) {
    dispatch({ type: types.CLEAR_CDM_ERROR });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}

export function eVerifyCert(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.eVerifyCert(params)
      .then((data) => {
        dispatch({ type: types.EVERIFY_SUCCESS, payload: true });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function auditVerification(empNum) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.auditVerification(empNum)
      .then((data) => {
        dispatch({
          type: types.SET_AUDIT_VERIFICATION_SUCCESS,
          selectedEmployeeAudit: data.auditVerification
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function regulatoryApproval(empNum) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.regulatoryApproval(empNum)
      .then((data) => {
        dispatch({
          type: types.SET_REGULATORY_APPROVED_SUCCESS,
          selectedEmployeeRegulatory: data.regulatoryApproval
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function getEmployee(empNum) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.getEmployee(empNum)
      .then((data) => {
        dispatch({
          type: types.SET_EMPLOYEE_INFO_SUCCESS,
          selectedEmployeeInfo: data.employee
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function getTerminatedEmployee(empNum) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.getTerminatedEmployee(empNum)
      .then((data) => {
        dispatch({
          type: types.SET_EMPLOYEE_INFO_SUCCESS,
          selectedEmployeeInfo: data.employee
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}

export function setCertificationStatus(adminID, payload) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.setCertificationStatus(adminID, payload)
      .then((data) => {
        dispatch({ type: types.SET_CERT_INACTIVE_SUCCESS, payload: true });
        window.location.reload();
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: 'er' });
      });
  };
}

export function setManualVerificationStatus(adminID, params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CDM_DATA });
    return CDM.setManuallyVerified(adminID, params)
      .then((data) => {
        dispatch({
          type: types.SET_MANUAL_VERIFICATION_SUCCESS,
          selectedEmployee: data.employee
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_CDM_DATA_FAILURE, error: err });
      });
  };
}