import RestApi from './RestApi';

class AppInfo {
  static getTermsAndConditions() {
    const api = new RestApi();
    return api.GET('/terms').then(response => {
      return response;
    });
  }

  static getPrivacyStatements() {
    const api = new RestApi();
    return api
      .GET('/privacy')
      .then(response => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response;
        }
      })
      .catch(err => {
        throw err;
      });
  }
}

export default AppInfo;
