//react
import React, { PureComponent, Fragment } from 'react';
import { find } from 'lodash';
import { MdClose } from 'react-icons/lib/md';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import { downloadBase64File } from '../../utils/helperFunctions';
import { toast } from 'react-toastify';
import Checkbox from '../../components/Checkbox/Checkbox';
import Loadable from 'react-loading-overlay';
import styled from 'styled-components';
//css
import './../Sidepanel/Sidepanel.css';
//components
import ToastContainerComponent from '../ToastContainerComponent/ToastContainerComponent';

import httpCDM from '../../httpClient/CDM.js';
import ImageResize from '../../components/ImageResize/ImageResize';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const INITIAL_STATE = (props) => {
  //const currentDosageInfo = find(props.dummyStubsCovidVaccine, { dosageNo: String(props.dummyStubsCovidVaccine.length) });
  //const nextDosageDiff = find(props.covidManufacturersList, { manufacturer: props.currentCert.manufacturerName }).nextDose;
  return {
    certificateName: (props.currentCert && props.currentCert.CertName) || props.docType || '',
    vaccinationDate: props.addCert ? '' : props.currentCert.CertIssueDate,
    displayVaccinationDate: props.currentCert.CertIssueDate ? moment(props.currentCert.CertIssueDate).format('MM/DD/YYYY') : '',
    rawVaccinationDate: '',
    lotNumber: props.addCert ? '' : props.currentCert.LotNumber,
    isVaccineDeclined: props.addCert ? false : ((props.currentCert !== undefined && props.currentCert.Declined !== null && (props.currentCert.Declined.toLowerCase() === 'yes' || props.currentCert.Declined === '1')) ? true : false),
    frontImage: props.addCert ? '' : props.currentCert.CertImage,
    agency: '',
    certName: props.docType,
    certNumber: 'NA',
    expirationDate: moment('12/31/2099').format('YYYY-MM-DD'),
    stateCode: '',
    docTypeID: props.docTypeID,
    issueDate: props.currentCert.CertIssueDate ? moment(props.currentCert.CertIssueDate).format('MM/DD/YYYY') : '',
    docType: props.docType,
    countyCode: '',
    isExpiring: 'Y',
    backImage: '',
    isStateCertificate: false,
    isCountyCertificate: false,
    isBackImageRequired: false,
    manufacturerName: props.addCert ? '' : props.currentCert.Manufacturer,
    testing: false,
    theInputKey: false,
    activeFlag: props.addCert ? 'A' : props.currentCert.ActiveFlag,
    // nextDosageMsg:
    //   props.addCert || props.dummyStubsCovidVaccine.length > 1
    //     ? ''
    //     : `Your next dosage is on : ${moment(props.currentCert.IssueDate, 'YYYY-MM-DD')
    //         .add(nextDosageDiff, 'days')
    //         .format('DD-MM-YYYY')}`,
    vaccineErrorMsg: '',
    lotNumberErrorMsg: '',
    imageUploaderrorMsg: '',
    manufacturerErrorMsg: '',
    boosterShotChecked: /^Yes$/i.test(props.currentCert.Dose),
    boosterShotsAlertShownOnce: false,
    dose: props.addCert ? 'no' : props.currentCert.Dose
  };
};

const RedPara = styled.div`
  color: red;
  font-weight: bold;
`;
const WarningMsg = styled.div`
  color: grey;
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 0.4rem;
`;
const DosageInfo = styled.div`
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3a9ef0;
  font-size: 1rem;
  background-color: #3a9ef0;
  color: #ffffff;
`;

export default class CovidVaccine extends PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE(props);
    this.onCloseConfirmAlert = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.certName !== this.props.certName) {
      this.setState({ ...INITIAL_STATE(nextProps) });
    }
  }

  componentWillUnmount = () => {
    typeof this.onCloseConfirmAlert === 'function' && this.onCloseConfirmAlert();
  };

  onSetVaccinationDate = (vaccinationDate) => {
    this.setState(
      {
        vaccinationDate,
        displayVaccinationDate: moment(vaccinationDate).format('MM/DD/YYYY'),
        issueDate: moment(vaccinationDate).format('MM/DD/YYYY')
      },
      () => {
        //this.calculateNextDosage();
      }
    );
  };

  handleRawVaccinationDateChange = (vaccinationDate) => this.setState({ vaccinationDate, displayVaccinationDate: '', issueDate: '' });

  onVaccinationDateBlur = () => {
    const { rawVaccinationDate } = this.state;
    if (rawVaccinationDate !== '' && moment(rawVaccinationDate).format('MM/DD/YYYY') === 'Invalid date') {
      this.setState({
        vaccinationDate: '',
        displayIssueDate: '',
        rawVaccinationDate: ''
      });
      toast.dismiss();
      toast.warn('Please enter a valid issue date.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  onManufacturChange = (event) => {
    this.setState({ manufacturerName: event.target.value }, () => {
      //this.calculateNextDosage();
    });
  };

  //   calculateNextDosage = () => {
  //     const { addCert, dummyStubsCovidVaccine = [] } = this.props;
  //     const { vaccinationDate, manufacturerName } = this.state;
  //     let nextDosageMsg = '';
  //     if (((addCert && !dummyStubsCovidVaccine.length) || (!addCert && dummyStubsCovidVaccine.length === 1)) && vaccinationDate && manufacturerName) {
  //       const { covidManufacturersList } = this.props;
  //       const nextDosageDiff = find(covidManufacturersList, { manufacturer: manufacturerName }).nextDose;
  //       const nextDosage = moment(vaccinationDate)
  //         .add(nextDosageDiff, 'days')
  //         .format('dddd, MMMM do YYYY');
  //       nextDosageMsg = `Your next dosage is on : ${nextDosage}`;
  //     }
  //     this.setState({ nextDosageMsg });
  //   };

  handleVaccinedDenyChange = (event) => {
    this.setState({ isVaccineDeclined: event.target.checked });
    if (event.target.checked) {
      if (window.confirm('All fields will be cleared. Do you wish to Continue?'))
        this.setState({
          lotNumber: '',
          issueDate: moment(),
          manufacturerName: '',
          vaccinationDate: moment(),
          displayVaccinationDate: moment(),
          frontImage: '',
          inputKey: false,
          vaccineErrorMsg: '',
          imageUploaderrorMsg: '',
          manufacturerErrorMsg: '',
          boosterShotChecked: false,
          dose: ''
        });
      if (!this.props.addCert) {
        //Setting current cert image to Failed because we should not be sending an image when Vaccine is declined
        //and API will be expecting Failed
        this.props.currentCert.CertImage = 'Failed';
      }
      // this.fileInput.value = '';
    }
  };

  getvaccineCount = () => {
    const { selectedEmployeeCerts } = this.props;
    const vaccineCount = selectedEmployeeCerts.reduce(
      (result, v) => {
        if (Number(v.Document_Type_ID) === 1007) {
          if (/^johnson & johnson$/i.test(v.Manufacturer)) {
            result.johnson++;
          } else if (/^moderna$/i.test(v.Manufacturer)) {
            result.moderna++;
          } else if (/^pfizer$/i.test(v.Manufacturer)) {
            result.pfizer++;
          }
        }
        return result;
      },
      { johnson: 0, moderna: 0, pfizer: 0 }
    );
    return vaccineCount;
  };

  validateAnddisplayVaccineAlertMsg = (vaccineMsg, checkValue, confirmBtnClicked) => {
    const { boosterShotsAlertShownOnce, vaccinationDate } = this.state;
    if (checkValue) {
      if (confirmBtnClicked) {
        if (!boosterShotsAlertShownOnce) {
          confirmAlert({
            overlayClassName: 'booster-overlay',
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
              this.onCloseConfirmAlert = onClose;
              return (
                <div className="custom-ui-alert">
                  <p>{vaccineMsg}</p>
                  <div className="react-confirm-alert-button-group">
                    <button
                      onClick={() => {
                        this.setState({ boosterShotsAlertShownOnce: true, boosterShotChecked: checkValue, dose: checkValue ? 'yes' : 'no' });
                        onClose();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => {
                        this.setState({ boosterShotsAlertShownOnce: true, confirmBtnClicked: false, dose: 'no' });
                        onClose();
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            }
          });
          /* if (window.confirm(vaccineMsg)) {
            this.setState({ boosterShotsAlertShownOnce: true, boosterShotChecked: checkValue, dose: checkValue ? 'yes' : 'no' });
          } else {
            this.setState({ boosterShotsAlertShownOnce: true, confirmBtnClicked: false, dose: 'no' });
          } */
        }
      } else {
        /* confirmAlert({
          title: '',
          message: vaccineMsg,
          buttons: [
            {
              label: 'Yes',
            },
            {
              label: 'No',
              onClick: () => this.setState({ boosterShotChecked: false, dose: 'no' })
            }
          ],
          overlayClassName: 'booster-overlay',
          closeOnClickOutside: false,
        }); */
        confirmAlert({
          overlayClassName: 'booster-overlay',
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            this.onCloseConfirmAlert = onClose;
            return (
              <div className="custom-ui-alert">
                <p>{vaccineMsg}</p>
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      this.setState({ boosterShotChecked: false, dose: 'no' });
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            );
          }
        });
      }
    }
  };

  handleBoosterShootChangeBtn = (event) => {
    this.setState({ boosterShotChecked: event.target.checked, dose: event.target.checked ? 'yes' : 'no' });
  };

  handleBoosterShootChange = (event, confirmBtnClicked) => {
    const checkValue = !confirmBtnClicked ? event.target.checked : confirmBtnClicked;

    const vaccineCount = this.getvaccineCount();
    if (!confirmBtnClicked) this.setState({ boosterShotChecked: checkValue, dose: checkValue ? 'yes' : 'no' });

    const vaccineMsg =
      vaccineCount.johnson >= 1 || vaccineCount.moderna + vaccineCount.pfizer >= 2
        ? 'Based on the COVID Vaccines you have added so far it seems this could be a booster, is that correct?'
        : 'You are adding this vaccine as a COVID booster vaccine, would you like to continue with this selection?';
    this.validateAnddisplayVaccineAlertMsg(vaccineMsg, checkValue, confirmBtnClicked);
  };

  onChangeFrontImage = (event) => {
    // this.setState({ inputKey: true });

    if (event.target.files.length > 0) {
      if (!event.target.files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
        toast.error('Please select valid image (jpg, jpeg, png, pdf).', {
          position: 'bottom-center'
        });
        event.target.value = null;
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          this.setState({ frontImage: e.target.result });
        };
      }
    }
  };

  validateMandatoryFields = () => {
    const { vaccinationDate, lotNumber, frontImage, manufacturerName } = this.state;
    let vaccineErrorMsg = '',
      lotNumberErrorMsg = '',
      imageUploaderrorMsg = '',
      manufacturerErrorMsg = '';
    if (!vaccinationDate) vaccineErrorMsg = 'Please select vaccination date!';
    //if (!lotNumber) lotNumberErrorMsg = 'Please select lot number!';
    if (!frontImage) imageUploaderrorMsg = 'Please upload the image!';
    if (!manufacturerName) manufacturerErrorMsg = 'Please enter manufacturer!';
    this.setState({ vaccineErrorMsg, imageUploaderrorMsg, manufacturerErrorMsg });
    return vaccinationDate !== '' && frontImage !== '' && manufacturerName !== '';
  };

  addUpdateCertificate = (addCert) => {
    const { boosterShotChecked, boosterShotsAlertShownOnce, isVaccineDeclined, activeFlag, vaccinationDate } = this.state;
    const vaccineCount = this.getvaccineCount();
    const isBooster = moment(vaccinationDate).isAfter('2021/07/31');

    if (!boosterShotChecked && !boosterShotsAlertShownOnce && !isVaccineDeclined && (activeFlag == 'Active' || activeFlag == 'A')) {
      if (vaccineCount.johnson >= 1 && isBooster) {
        this.handleBoosterShootChange(null, true);
        return false;
      }
      if (vaccineCount.moderna + vaccineCount.pfizer >= 2 && isBooster) {
        this.handleBoosterShootChange(null, true);
        return false;
      }
    }

    if (!this.state.isVaccineDeclined) {
      if (this.validateMandatoryFields()) {
        if (addCert) {
          this.props.addNewCert(this.state);
        } else {
          this.props.updateExistingCert(this.state);
        }
      }
    } else {
      if (addCert) {
        this.props.addNewCert(this.state);
      } else {
        this.props.updateExistingCert(this.state);
      }
    }

    // const { currentCert, addCert } = this.props;
    // const payload = {};
    // if (!this.validateMandatoryFields()) {
    //   if (currentCert) {
    //     //this.props.updateCert(currentAdmin.ID, payload);
    //   } else if (addCert) {
    //     //this.props.postNewCert(payload);
    //   }
    //   //this.setState({ panelVisible: false, addCert: false });
    // }
  };

  displayImageBasedOnImageType = (image, imageToLoad) => {
    var format = '';
    var string64 = '';
    // const { pageNumber, numPages } = this.state;
    if (image) {
      const words = image.split(',');
      string64 = words[1];
      format = words[0]
        .split('/')
        .pop()
        .split(';')[0];
    }
    return (
      <Fragment>
        <Fragment>
          {image && !this.state.isVaccineDeclined ? (
            <div>
              <div className="cert-title">Current Certificate Photo</div>
              {format.toUpperCase() === 'PDF' ? (
                <div>{this.props.renderSwitch(image, imageToLoad)}</div>
              ) : (
                <div>
                  {format === '' || this.props.wordDocFormats.filter((docFormat) => docFormat.keyFormat === format).length > 0 ? (
                    <div className="cert-title-main">
                      <div className="detail-type">
                        This document type is not supported. Please download and convert to a PDF or supported image type (jpg, png).
                      </div>
                      <button
                        className={'panel-btn-center'}
                        href="#"
                        onClick={() => {
                          {
                            let documentFormat = this.props.wordDocFormats
                              .filter((item) => item.keyFormat == format)
                              .map(({ formatValue }) => ({ formatValue }))
                              .map((a) => a.formatValue);
                            // this.downloadPDF(string64, documentFormat);
                            downloadBase64File(image, 'download', documentFormat.length ? documentFormat[0] : 'docx');
                          }
                        }}
                      >
                        Download Document
                      </button>
                    </div>
                  ) : (
                    <div>
                      {/*<img src={image ? image : Certificate} className="thumbnail" alt="certificate" />*/}
                      <ImageResize src={image} />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="cert-title-main">
              <div className="profile-heading">There is no image available for this Certificate</div>
            </div>
          )}
        </Fragment>
      </Fragment>
    );
  };

  render() {
    const { addCert, currentCert, covidManufacturersList, filteredDocumentTypes, onCertNameSelect } = this.props;
    const {
      // certificateName,
      docTypeID,
      vaccinationDate,
      displayVaccinationDate,
      manufacturerName,
      lotNumber,
      isVaccineDeclined,
      nextDosageMsg,
      frontImage,
      vaccineErrorMsg,
      lotNumberErrorMsg,
      imageUploaderrorMsg,
      manufacturerErrorMsg,
      boosterShotChecked
    } = this.state;

    const vaccineCount = this.getvaccineCount();
    const vaccineCountCondition = !(vaccineCount.johnson >= 1 || vaccineCount.moderna >= 2 || vaccineCount.pfizer >= 2);
    const boosterDisable = isVaccineDeclined || moment(vaccinationDate).isBefore('2021/08/01') || vaccineCountCondition;
    let isViewingTerminatedEmployee = localStorage.getItem('historicalEmployeeData');
    isViewingTerminatedEmployee = isViewingTerminatedEmployee === 'false' ? false : true;
    return (
      <div>
        <div className="side-panel">
          <Loadable active={this.state.isLoadingSidePanel} spinner text="Loading...">
            <div className="panel-btn-group">
              {/* <div className="panel-btn" href="#" disabled={this.props.isLoading} onClick={this.addUpdateCertificate(addCert)}>
                {addCert ? 'Confirm to Add' : 'Save Changes'}
              </div> */}
              {!isViewingTerminatedEmployee ? 
                <div
                  className="panel-btn"
                  href="#"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.addUpdateCertificate(addCert);
                  }}
                >
                  Add/Update
                </div> : <div>Read Only</div>
              }
            </div>
            <div style={{ padding: '0 1.6rem', textAlign: 'center' }}>
              {/* <div className="form-group-half">
                <span className="form-group-stacked-label">Dose {covidDose}</span>
              </div> */}
            </div>

            <MdClose size={25} color="#3a9ef0" className="icon-close" onClick={this.props.closeSidePanel} />
            <div className="cert-info">
              <div className="left-panel border-box">
                <div className="form-group">
                  <div className="form-group-stacked form-group-half">
                    <span className="form-group-stacked-label">Certificate Name</span>
                    {/* <input maxLength={100} className="form-group-text" type="text" value={certificateName} disabled /> */}
                    <select className="form-group-select-options" value={docTypeID} onChange={onCertNameSelect} disabled={currentCert || isViewingTerminatedEmployee}>
                      <option value="">Select Certificate</option>
                      {filteredDocumentTypes.map((option) => (
                        <option key={option.docTypeId} value={option.docTypeId}>
                          {option.docType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked form-group-half">
                    <span className="form-group-stacked-label">Vaccination Date</span>
                    <DatePicker
                      selected={vaccinationDate ? moment(vaccinationDate) : null}
                      onSelect={this.onSetVaccinationDate}
                      onChangeRaw={(event) => this.handleRawVaccinationDateChange(event.target.value)}
                      onFocus={this.onVaccinationDateFocus}
                      onBlur={this.onVaccinationDateBlur}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      className="form-group-text"
                      placeholderText="Enter Issue Date"
                      value={displayVaccinationDate !== '' && displayVaccinationDate !== 'Invalid date' ? displayVaccinationDate : moment()}
                      disabled={isVaccineDeclined || isViewingTerminatedEmployee}
                      maxDate={moment()}
                    />
                    {vaccineErrorMsg && <RedPara>{vaccineErrorMsg}</RedPara>}
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-stacked form-group-half">
                    <span className="form-group-stacked-label">Manufacturer</span>
                    <select
                      className="form-group-select-options"
                      value={manufacturerName}
                      //onChange={e => this.setState({ manufacturerName: e.target.value })}
                      onChange={(e) => this.onManufacturChange(e)}
                      disabled={isVaccineDeclined || isViewingTerminatedEmployee}
                    >
                      <option value="">Select One</option>
                      {covidManufacturersList.map((i) => (
                        <option value={i.manufacturer.toLowerCase()} {...i} key={i.manufacturer}>
                          {i.manufacturer}
                        </option>
                      ))}
                    </select>
                    {manufacturerErrorMsg && <RedPara>{manufacturerErrorMsg}</RedPara>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked form-group-half">
                    <span className="form-group-stacked-label">Lot Number</span>
                    <input
                      maxLength={100}
                      className="form-group-text"
                      type="text"
                      onChange={(event) => this.setState({ lotNumber: event.target.value.toUpperCase() })}
                      value={lotNumber}
                      placeholder={`Enter Lot Number`}
                      disabled={isVaccineDeclined || isViewingTerminatedEmployee}
                    />
                    {lotNumberErrorMsg && <RedPara>{lotNumberErrorMsg}</RedPara>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked">
                    <div className="form-group-stacked-label">
                      <label>
                        <span style={{ marginRight: 8 }}>Vaccine Declined:</span>
                        {/* <Checkbox checked={isVaccinedDeclined} onChange={(event) => this.handleVaccinedDenyChange(event)} /> */}
                        <Checkbox
                          checked={isVaccineDeclined}
                          onChange={(event) => {
                            this.handleVaccinedDenyChange(event);
                            // if (window.confirm('All fields will be cleared. Do you wish to Continue?')) this.handleVaccinedDenyChange(event);
                          }}
                          disabled={boosterShotChecked || isViewingTerminatedEmployee}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked">
                    <div className="form-group-stacked-label">
                      <label style={boosterDisable ? { opacity: 0.4 } : {}}>
                        <span style={{ marginRight: 8 }}>Vaccine Booster:</span>
                        <Checkbox
                          checked={boosterShotChecked}
                          disabled={boosterDisable || isViewingTerminatedEmployee}
                          onChange={(event) => {
                            this.handleBoosterShootChangeBtn(event);
                            // this.handleBoosterShootChange(event);
                          }}
                        />
                      </label>
                      {vaccineCountCondition && <WarningMsg>Booster vaccine is available after initial vaccine entries are entered.</WarningMsg>}
                    </div>
                  </div>
                </div>
                {!addCert && (
                  <div className="form-group">
                    <div className="form-group-stacked">
                      <span className="form-group-stacked-label">Cert Status</span>
                      <select
                        className="form-group-select-options"
                        value={this.state.activeFlag}
                        onChange={(e) => {
                          this.setState({ activeFlag: e.target.value });
                        }}
                        disabled={isViewingTerminatedEmployee}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                )}
                <Fragment>
                  {this.state.frontImage ? (
                    <Fragment>
                      <button
                        className="clear-cert"
                        onClick={() => {
                          this.setState({ frontImage: '' });
                        }}
                        disabled={isViewingTerminatedEmployee}
                      >
                        Clear Front Image
                      </button>
                      <div>
                        <div className="cert-title">Front Image</div>
                        {this.displayImageBasedOnImageType(this.state.frontImage, 'front')}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="cert-title">Upload Front Image</div>
                      <div>
                        <input
                          ref={(ref) => (this.fileInput = ref)}
                          type="file"
                          name="file"
                          // disabled={isVaccineDeclined ? 'disabled' : ''}
                          disabled={isViewingTerminatedEmployee}
                          accept="image/png,image/jpg,image/jpeg,application/pdf"
                          style={{ marginTop: 15 }}
                          onChange={this.onChangeFrontImage}
                        />
                        {imageUploaderrorMsg && <RedPara>{imageUploaderrorMsg}</RedPara>}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
                {/* <div className="form-group">
                  <div className="form-group-stacked form-group-half">
                    <div className="form-group-stacked-label">
                      <label>Upload Image</label>
                    </div>
                    <input
                      ref={(ref) => (this.fileInput = ref)}
                      type="file"
                      name="file"
                      disabled={isVaccineDeclined ? 'disabled' : ''}
                      accept="image/png,image/jpg,image/jpeg,application/pdf"
                      style={{ marginTop: 15 }}
                      onChange={this.onChangeFrontImage}
                    />
                    {imageUploaderrorMsg && <RedPara>{imageUploaderrorMsg}</RedPara>}
                    {frontImage && <ImageResize src={frontImage} alt="Covid Vaccine Image" />}
                  </div>
                </div> */}
                {/* {isVaccinedDeclined && <RedPara>Vaccine Declined on {moment().format('DD/MM/YYYY, h:mm:ss a')}</RedPara>} */}
                {nextDosageMsg && (
                  <div className="form-group">
                    <div className="form-group-stacked">
                      <RedPara className="error">{nextDosageMsg}</RedPara>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Loadable>
        </div>
      </div>
    );
  }
}
