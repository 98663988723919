import Forms from '../../httpClient/Forms';

//Action Types
export const types = {
  FETCHING_FOMRS_DATA: 'FETCHING_FOMRS_DATA',
  FETCHING_FOMRS_DATA_SUCCESS: 'FETCHING_FOMRS_DATA_SUCCESS',
  FETCHING_FOMRS_DATA_FAILURE: 'FETCHING_FOMRS_DATA_FAILURE',
  NEW_FOMRS_DATA_SUCCESS: 'NEW_FOMRS_DATA_SUCCESS',
  UPDATE_FOMRS_DATA_SUCCESS: 'UPDATE_FOMRS_DATA_SUCCESS',
  DELETE_FOMRS_DATA_SUCCESS: 'DELETE_FOMRS_DATA_SUCCESS',
  JOB_TITLES_GET_DATA_SUCCESS: 'JOB_TITLES_GET_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  allForms: [],
  jobTitles: [],
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_FOMRS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_FOMRS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allForms: action.allForms,
        error: null
      };

    case types.FETCHING_FOMRS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.UPDATE_FOMRS_DATA_SUCCESS:
      return { ...state, isLoading: false, error: null };

    case types.JOB_TITLES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobTitles: action.jobTitles,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveForms(businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_FOMRS_DATA });
    return Forms.allActiveForms(businessUnitID)
      .then(data => {
        dispatch({
          type: types.FETCHING_FOMRS_DATA_SUCCESS,
          allForms: data.forms
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_FOMRS_DATA_FAILURE, error: err });
      });
  };
}

export function updateForm(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_FOMRS_DATA });
    return Forms.updateForm(params)
      .then(data => {
        dispatch({ type: types.UPDATE_FOMRS_DATA_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_FOMRS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchAllJobTitles() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_FOMRS_DATA });
    return Forms.fetchAllJobTitles()
      .then(data => {
        dispatch({
          type: types.JOB_TITLES_GET_DATA_SUCCESS,
          jobTitles: data.jobTitles
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_FOMRS_DATA_FAILURE, error: err });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
