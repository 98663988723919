import RestApi from './RestApi';

class Categories {
  static updateCategory(params) {
    const api = new RestApi();
    return api.PUT('/categories', params).then(response => {
      return response;
    });
  }
}

export default Categories;
