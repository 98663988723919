import { createBrowserHistory } from 'history';
import { redirectUri, clientId, baseUrl } from './NetworkSettings';
// images
import amrLogo from '../assets/images/AMR_logo-lg.png';

export const history = createBrowserHistory();
export const appVersion = '2.0.24.1115';
export const config = {
  baseUrl,
  clientId,
  redirectUri,
  // Return an access token from the authorization server
  getAccessToken: true,
  // Return an ID token from the authorization server
  getIdToken: true,
  issuer: `${baseUrl}/oauth2/default`,
  authParams: {
    issuer: `${baseUrl}/oauth2/default`
  },
  logo: amrLogo,
  i18n: {
    // Overriding English properties
    en: {
      'primaryauth.title': 'Sign In With Okta'
    }
  }
};
