import PushNotifications from '../../httpClient/PushNotifications';

//Action Types
export const types = {
  FETCHING_PUSHNOTIFICATION_DATA: 'FETCHING_PUSHNOTIFICATION_DATA',
  FETCHING_PUSHNOTIFICATION_DATA_SUCCESS: 'FETCHING_PUSHNOTIFICATION_DATA_SUCCESS',
  FETCHING_PUSHNOTIFICATION_DATA_FAILURE: 'FETCHING_PUSHNOTIFICATION_DATA_FAILURE',
  NEW_PUSHNOTIFICATION_DATA_SUCCESS: 'NEW_PUSHNOTIFICATION_DATA_SUCCESS',
  UPDATE_PUSHNOTIFICATION_DATA_SUCCESS: 'UPDATE_PUSHNOTIFICATION_DATA_SUCCESS',
  DELETE_PUSHNOTIFICATION_DATA_SUCCESS: 'DELETE_PUSHNOTIFICATION_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  allNotifications: [],
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PUSHNOTIFICATION_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_PUSHNOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allNotifications: action.allNotifications,
        error: null
      };

    case types.FETCHING_PUSHNOTIFICATION_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_PUSHNOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: true,
        allNotifications: action.allNotifications,
        error: null
      };

    case types.UPDATE_PUSHNOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allNotifications: action.allNotifications,
        error: null
      };

    case types.DELETE_PUSHNOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allNotifications: action.allNotifications,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActivePushNotifications(businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PUSHNOTIFICATION_DATA });
    return PushNotifications.allActivePushNotifications(businessUnitID)
      .then(data => {
        dispatch({
          type: types.FETCHING_PUSHNOTIFICATION_DATA_SUCCESS,
          allNotifications: data.notifications
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_PUSHNOTIFICATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function postNewNotification(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PUSHNOTIFICATION_DATA });
    return PushNotifications.postNewNotification(params)
      .then(data => {
        dispatch({
          type: types.NEW_PUSHNOTIFICATION_DATA_SUCCESS,
          allNotifications: data.notifications
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_PUSHNOTIFICATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function deleteNotification(notificationID, mobileSecurityID, businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PUSHNOTIFICATION_DATA });
    return PushNotifications.deleteNotification(notificationID, mobileSecurityID, businessUnitID)
      .then(data => {
        dispatch({
          type: types.DELETE_PUSHNOTIFICATION_DATA_SUCCESS,
          allNotifications: data.notifications
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_PUSHNOTIFICATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
