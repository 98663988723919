//react
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

//css
import 'react-toastify/dist/ReactToastify.css';

class ToastContainerComponent extends Component {
  render() {
    return (
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default ToastContainerComponent;
