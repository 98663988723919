import Contacts from '../../httpClient/Contacts';

//Action Types
export const types = {
  FETCHING_CONTACTS_DATA: 'FETCHING_CONTACTS_DATA',
  FETCHING_CONTACTS_DATA_SUCCESS: 'FETCHING_CONTACTS_DATA_SUCCESS',
  FETCHING_CONTACTS_DATA_FAILURE: 'FETCHING_CONTACTS_DATA_FAILURE',
  NEW_CONTACTS_DATA_SUCCESS: 'NEW_CONTACTS_DATA_SUCCESS',
  UPDATE_CONTACTS_DATA_SUCCESS: 'UPDATE_CONTACTS_DATA_SUCCESS',
  DELETE_CONTACTS_DATA_SUCCESS: 'DELETE_CONTACTS_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES',
  CATEGORIES_GET_DATA_SUCCESS: 'CATEGORIES_GET_DATA_SUCCESS',
  NEW_CATEGORY_DATA_SUCCESS: 'NEW_CATEGORY_DATA_SUCCESS',
  DELETE_CATEGORY_DATA_SUCCESS: 'DELETE_CATEGORY_DATA_SUCCESS'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  isLoading: false,
  error: null,
  categories: [],
  availableContacts: []
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_CONTACTS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_CONTACTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        availableContacts: action.availableContacts,
        error: null
      };

    case types.FETCHING_CONTACTS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_CONTACTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        postedSuccessfully: true,
        availableContacts: action.availableContacts,
        error: null
      };

    case types.UPDATE_CONTACTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableContacts: action.availableContacts,
        error: null
      };

    case types.DELETE_CONTACTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableContacts: action.availableContacts,
        error: null
      };

    case types.CATEGORIES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.NEW_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.DELETE_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveContacts(businessUnitID, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTACTS_DATA });
    return Contacts.allActiveContacts(businessUnitID, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.FETCHING_CONTACTS_DATA_SUCCESS,
          availableContacts: data.contacts
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewContact(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTACTS_DATA });
    return Contacts.postNewContact(params)
      .then(data => {
        dispatch({
          type: types.NEW_CONTACTS_DATA_SUCCESS,
          availableContacts: data.contacts
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function updateContact(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTACTS_DATA });
    return Contacts.updateContact(params)
      .then(data => {
        dispatch({
          type: types.UPDATE_CONTACTS_DATA_SUCCESS,
          availableContacts: data.contacts
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteContact(id, businessUnitID, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTACTS_DATA });
    return Contacts.deleteContact(id, businessUnitID, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_CONTACTS_DATA_SUCCESS,
          availableContacts: data.contacts
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchAllCategories() {
  return function(dispatch) {
    return Contacts.fetchAllCategories()
      .then(data => {
        dispatch({
          type: types.CATEGORIES_GET_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewCategory(params) {
  return function(dispatch) {
    return Contacts.postNewCategory(params)
      .then(data => {
        dispatch({
          type: types.NEW_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteCategory(id, type, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    return Contacts.deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_CONTACTS_DATA_FAILURE, error: err });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
