//react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//css
import './Heading.css';

class Heading extends Component {
  render() {
    if (this.props.title === 'CDM') {
      if (this.props.stage === 'employee-search') {
        return (
          <div className="heading">
            <div className="breadcrumb">
              <Link to="/">
                <span className="breadcrumb__home">Home</span>
              </Link>
              <span className="breadcrumb__subpage">Credential Management</span>
            </div>
          </div>
        );
      }
      if (this.props.stage === 'employee-detail') {
        return (
          <div className="heading">
            <Link to="/CDM/Search">
              <div className="Back">Back</div>
            </Link>
          </div>
        );
      }
    } else {
      return (
        <div className="heading">
          <div className="breadcrumb">
            <Link to="/">
              <span className="breadcrumb__home">Home</span>
            </Link>
            <span className="breadcrumb__subpage">{this.props.title}</span>
          </div>
          {/* <div className="button">
              <div href="#">New {this.props.title}</div>          
            </div> */}
        </div>
      );
    }
  }
}

export default Heading;
