import RestApi from './RestApi';

class GeofencedNotifications {
  static allActiveGeofencedNotifications(businessUnitID) {
    const api = new RestApi();
    return api.GET('/geofencedNotifications?businessUnitID=' + encodeURIComponent(businessUnitID)).then(response => {
      return response;
    });
  }

  static postNewGeofencedNotification(params) {
    const api = new RestApi();
    return api.POST('/geofencedNotifications', params).then(response => {
      return response;
    });
  }

  static updateGeofencedNotification(params) {
    const api = new RestApi();
    return api.PUT('/geofencedNotifications', params).then(response => {
      return response;
    });
  }

  static deleteGeofencedNotification(notificationID) {
    const api = new RestApi();
    return api.DELETE('/geofencedNotifications?notificationID=' + encodeURIComponent(notificationID)).then(response => {
      return response;
    });
  }
}

export default GeofencedNotifications;
