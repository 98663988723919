import Admin from '../../httpClient/Admin';
//Okta
import OktaAuth from '@okta/okta-auth-js';
//App Settings
import { history, config } from '../../utils/AppSettings.js';
import RestApi from '../../httpClient/RestApi';
//Action Types
export const types = {
  FETCHING_ADMIN_DATA: 'FETCHING_ADMIN_DATA',
  FETCHING_ADMIN_DATA_SUCCESS: 'FETCHING_ADMIN_DATA_SUCCESS',
  FETCHING_ADMIN_SETTINGS_SUCCESS: 'FETCHING_ADMIN_SETTINGS_SUCCESS',
  FETCHING_ADMIN_DATA_FAILURE: 'FETCHING_ADMIN_DATA_FAILURE',
  FETCHING_ADMIN_PROFILE: 'FETCHING_ADMIN_PROFILE',
  FETCHING_ADMIN_PROFILE_SUCCESS: 'FETCHING_ADMIN_PROFILE_SUCCESS',
  FETCHING_ADMIN_PROFILE_FAILURE: 'FETCHING_ADMIN_PROFILE_FAILURE',
  ADMIN_LOGOUT: 'ADMIN_LOGOUT',
  ADMIN_SET_COUNTY: 'ADMIN_SET_COUNTY',
  SET_CURRENT_PATH: 'SET_CURRENT_PATH',
  FETCHING_REFRESH_TOKEN: 'FETCHING_REFRESH_TOKEN',
  FETCHING_REFRESH_TOKEN_SUCCESS: 'FETCHING_REFRESH_TOKEN_SUCCESS',
  FETCHING_REFRESH_TOKEN_FAILURE: 'FETCHING_REFRESH_TOKEN_FAILURE'
};

let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));

const api = new RestApi();

//Initial States
export const initialState = {
  admin: currentAdmin ? currentAdmin : null,
  loggedIn: currentAdmin ? true : false,
  isLoading: false,
  error: null,
  businessUnits: [],
  securityRoles: [],
  currentCounty: null,
  stateCode: null,
  countyName: null,
  currentPath: ''
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_ADMIN_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        admin: action.admin,
        loggedIn: true,
        error: null
      };

    case types.FETCHING_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      };

    case types.FETCHING_ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        businessUnits: action.businessUnits,
        securityRoles: action.securityRoles,
        error: null
      };

    case types.FETCHING_ADMIN_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.ADMIN_LOGOUT:
      return {
        ...state,
        isLoading: false,
        admin: null,
        loggedIn: false,
        error: null,
        counties: [],
        currentCounty: null,
        countyName: null
      };

    case types.ADMIN_SET_COUNTY:
      return {
        ...state,
        currentCounty: action.currentCounty,
        stateCode: action.stateCode,
        countyName: action.countyName
      };

    case types.SET_CURRENT_PATH:
      return { ...state, currentPath: action.currentPath };

    default:
      return state;
  }
};

//Actions
export function loginAdmin(sub) {
  return function (dispatch) {
    dispatch({ type: types.FETCHING_ADMIN_DATA });
    return Admin.loginAdmin(sub)
      .then(data => saveAdmin(data))
      .catch(err => {
        dispatch({ type: types.FETCHING_ADMIN_DATA_FAILURE, error: err });
      });
  };
}

export function refreshToken(OracleEmpNum, accessToken, refreshToken, refreshFlag) {
  return (dispatch) => {
    //console('dispatch',dispatch);
    dispatch({ type: types.FETCHING_REFRESH_TOKEN });
    return api
      .refreshToken(OracleEmpNum, accessToken, refreshToken, refreshFlag)
      .then((data) => {
        if (data.admin && data.admin.Token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // sessionStorage.setItem('oats', btoa(JSON.stringify(data.admin))); // encrypting and storing
          sessionStorage.setItem('admin', JSON.stringify(data.admin));

          dispatch({
            type: types.FETCHING_REFRESH_TOKEN_SUCCESS,
            admin: data.admin,
            loggedIn: true
          });
          return data;
        } else {
          dispatch({
            type: types.FETCHING_REFRESH_TOKEN_FAILURE,
            error: 'An Error Occurred'
          });
        }
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_REFRESH_TOKEN_FAILURE, error: err });
      });
  };
}
export function saveAdmin(user) {
  return function (dispatch) {
    if (user.admin && user.admin.Token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      sessionStorage.setItem('admin', JSON.stringify(user.admin));
      dispatch({
        type: types.FETCHING_ADMIN_DATA_SUCCESS,
        admin: user.admin,
        loggedIn: true
      });
    } else {
      dispatch({
        type: types.FETCHING_ADMIN_DATA_FAILURE,
        error: 'An Error Occurred'
      });
    }
  };

}

export function adminSettings(adminID) {
  return function (dispatch) {
    dispatch({ type: types.FETCHING_ADMIN_DATA });
    return Admin.adminSettings(adminID)
      .then((data) => {
        if (data.businessUnits && data.securityRoles) {
          localStorage.setItem('businessUnits', JSON.stringify(data.businessUnits));
          localStorage.setItem('securityRoles', JSON.stringify(data.securityRoles));
        }
        dispatch({
          type: types.FETCHING_ADMIN_SETTINGS_SUCCESS,
          businessUnits: data.businessUnits,
          securityRoles: data.securityRoles
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_ADMIN_DATA_FAILURE, error: err });
      });
  };
}

export function adminProfile(adminID) {
  return function (dispatch) {
    dispatch({ type: types.FETCHING_ADMIN_PROFILE });
    return Admin.adminProfile(adminID)
      .then((data) => {
        if (data) {
          sessionStorage.setItem('adminProfile', JSON.stringify(data))
        }
        dispatch({
          type: types.FETCHING_ADMIN_PROFILE_SUCCESS,
          adminProfile: data
        })
      })
      .catch((err) => {
        dispatch({ type: types.FETCHING_ADMIN_PROFILE_FAILURE, error: err });
      });
  }
}

export function logOutAdmin() {
  sessionStorage.removeItem('admin');
  localStorage.removeItem('businessUnits');
  localStorage.removeItem('securityRoles');
  localStorage.removeItem('token');
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  return function (dispatch) {
    dispatch({ type: types.ADMIN_LOGOUT });
    if (isIE === true) {
      history.push('#' + '/login/');
    } else {
      history.push('/#/login/');
    }
  };
}

export function setCurrentCounty(county) {
  return function (dispatch) {
    let currentCountyName = county.countyName || 'No County';
    dispatch({
      type: types.ADMIN_SET_COUNTY,
      currentCounty: county,
      stateCode: county.state,
      countyName: currentCountyName
    });
  };
}

export function setCurrentPath(currentPath) {
  return function (dispatch) {
    dispatch({ type: types.SET_CURRENT_PATH, currentPath });
  };
}
