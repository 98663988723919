import React, { Component } from 'react';
import Creatable from 'react-select/lib/Creatable';

const customStyles = {
  option: styles => ({
    ...styles,
    display: 'flex',
    justifyContent: 'space-between'
  })
};

class CreateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSelectChange = value => {
    this.props.onSelectChange(value);
  };

  handleCreateOption = value => {
    this.props.onNewOption(value);
  };

  handleDeleteOption = value => {
    this.props.onDeleteOption(value);
  };

  optionRenderer = props => {
    const {
      children,
      getStyles,
      data,
      innerProps: { ref, onClick, ...restInnerProps }
    } = props;
    const color = data.numItems == 0 ? '#ad0d0d' : 'grey';
    let showAddNew = false;
    if (typeof data.id == 'string') {
      showAddNew = this.props.options.filter(x => x.catTitle.toLowerCase().trim() == data.id.toLowerCase().trim()).length > 0;
    }
    return (
      showAddNew || (
        <div {...restInnerProps} className="row row-break" ref={ref} style={getStyles('option', props)}>
          <div onClick={onClick} style={{ width: '95%', height: '100%' }}>
            {children}
          </div>
          <button
            onClick={() => this.handleDeleteOption(data)}
            style={{
              color: color,
              width: '5%',
              backgroundColor: 'transparent',
              borderWidth: 0
            }}
          >
            x
          </button>
        </div>
      )
    );
  };

  render() {
    const { options, isLoading, value } = this.props;
    return (
      <div className="category-select">
        {/* <span className="form-group-stacked-half-label">Category</span> */}
        <Creatable
          isClearable
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={this.handleSelectChange}
          onCreateOption={this.handleCreateOption}
          options={options}
          placeholder="Add/Select Category"
          value={value ? (value.id ? value : '') : ''}
          formatCreateLabel={inputValue => `Add category "${inputValue}"`}
          getOptionLabel={option => option.catTitle}
          getOptionValue={option => option.id}
          styles={customStyles}
          components={{ Option: this.optionRenderer }}
          getNewOptionData={(inputValue, optionLabel) => ({
            id: inputValue,
            catTitle: optionLabel
          })}
        />
      </div>
    );
  }
}

export default CreateSelect;
