import Documents from '../../httpClient/Documents';

//Action Types
export const types = {
  FETCHING_DOCUMENTS_DATA: 'FETCHING_DOCUMENTS_DATA',
  FETCHING_DOCUMENTS_DATA_SUCCESS: 'FETCHING_DOCUMENTS_DATA_SUCCESS',
  FETCHING_DOCUMENTS_DATA_FAILURE: 'FETCHING_DOCUMENTS_DATA_FAILURE',
  NEW_DOCUMENTS_DATA_SUCCESS: 'NEW_DOCUMENTS_DATA_SUCCESS',
  UPDATE_DOCUMENTS_DATA_SUCCESS: 'UPDATE_DOCUMENTS_DATA_SUCCESS',
  DELETE_DOCUMENTS_DATA_SUCCESS: 'DELETE_DOCUMENTS_DATA_SUCCESS',
  PDF_GET_DATA_SUCCESS: 'PDF_GET_DATA_SUCCESS',
  JOB_TITLES_GET_DATA_SUCCESS: 'JOB_TITLES_GET_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES',
  CATEGORIES_GET_DATA_SUCCESS: 'CATEGORIES_GET_DATA_SUCCESS',
  CATEGORIES_PER_BU_GET_DATA_SUCCESS: 'CATEGORIES_PER_BU_GET_DATA_SUCCESS',
  NEW_CATEGORY_DATA_SUCCESS: 'NEW_CATEGORY_DATA_SUCCESS',
  DELETE_CATEGORY_DATA_SUCCESS: 'DELETE_CATEGORY_DATA_SUCCESS'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  availableDocuments: [],
  isLoading: false,
  error: null,
  PDFFile: null,
  jobTitles: [],
  categories: [],
  categoriesPerBU: []
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_DOCUMENTS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_DOCUMENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        availableDocuments: action.availableDocuments,
        error: null
      };

    case types.FETCHING_DOCUMENTS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_DOCUMENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        postedSuccessfully: true,
        availableDocuments: action.availableDocuments,
        error: null
      };

    case types.UPDATE_DOCUMENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableDocuments: action.availableDocuments,
        error: null
      };

    case types.DELETE_DOCUMENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableDocuments: action.availableDocuments,
        error: null
      };

    case types.PDF_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: action.PDFFile,
        error: null
      };

    case types.CATEGORIES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.CATEGORIES_PER_BU_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriesPerBU: action.categoriesPerBU,
        error: null
      };

    case types.NEW_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.DELETE_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.JOB_TITLES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobTitles: action.jobTitles,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveDocuments(businessUnitID, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.allActiveDocuments(businessUnitID, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.FETCHING_DOCUMENTS_DATA_SUCCESS,
          availableDocuments: data.documents
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewDocument(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.postNewDocument(params)
      .then(data => {
        dispatch({
          type: types.NEW_DOCUMENTS_DATA_SUCCESS,
          availableDocuments: data.documents
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function updateDocument(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.updateDocument(params)
      .then(data => {
        dispatch({
          type: types.UPDATE_DOCUMENTS_DATA_SUCCESS,
          availableDocuments: data.documents
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteDocument(fileKey, businessUnitID, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.deleteDocument(fileKey, businessUnitID, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_DOCUMENTS_DATA_SUCCESS,
          availableDocuments: data.documents
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchCurrentPDF(file) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.fetchCurrentPDF(file)
      .then(data => {
        dispatch({ type: types.PDF_GET_DATA_SUCCESS, PDFFile: data });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}


export function fetchHelpPDF(file) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.fetchCurrentPDF(file)
      .then(data => {
        dispatch({ type: types.PDF_GET_DATA_SUCCESS, PDFFile: data });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchAllJobTitles() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_DOCUMENTS_DATA });
    return Documents.fetchAllJobTitles()
      .then(data => {
        dispatch({
          type: types.JOB_TITLES_GET_DATA_SUCCESS,
          jobTitles: data.jobTitles
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchAllCategories() {
  return function(dispatch) {
    return Documents.fetchAllCategories()
      .then(data => {
        dispatch({
          type: types.CATEGORIES_GET_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}
export function fetchCategoriesPerBU(id) {
  return function(dispatch) {
    return Documents.fetchCategoriesPerBU(id)
      .then(data => {
        dispatch({
          type: types.CATEGORIES_PER_BU_GET_DATA_SUCCESS,
          categoriesPerBU: data
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewCategory(params) {
  return function(dispatch) {
    return Documents.postNewCategory(params)
      .then(data => {
        dispatch({
          type: types.NEW_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteCategory(id, type, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    return Documents.deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_DOCUMENTS_DATA_FAILURE, error: err });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
