//react
import React, { Component, Fragment } from 'react';
import { MdAdd } from 'react-icons/lib/md';
import { FaFile } from 'react-icons/lib/fa';
//css
import './SelfService.css';
import httpSelfService from '../../httpClient/SelfService';
import httpCategory from '../../httpClient/Categories';

//components
import Heading from '../../components/Heading/Heading';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import SearchBUTreeFilter from '../../components/SearchFilter/SearchBUTreeFilter';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
import CreateSelect from '../../components/CreateSelect/CreateSelect';
import EmptyState from '../../components/EmptyState/EmptyState';
import Collapsiple from '../../components/Collapsible/Collapsible';
import MultiBUSelect from '../../components/MultiBUSelect/MultiBUSelect';

import { stateCodes } from '../../assets/stateCodesToName';

// libraries
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import Loadable from 'react-loading-overlay';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from 'lodash';

// redux state
import { connect } from 'react-redux';
import {
  allActiveLinks,
  postNewLink,
  updateLink,
  deleteLink,
  resetDefaultValues,
  fetchAllCategories,
  postNewCategory,
  deleteCategory,
  fetchCategoriesPerBU
} from './../../redux/modules/SelfService';
import { setCurrentCounty, setCurrentPath } from './../../redux/modules/Admin';

const INITIAL_CURRENT_ITEM = {
  id: 0,
  linkTitle: '',
  notes: '',
  linkURL: '',
  category: {},
  sharedBU: []
};

const INITIAL_CURRENT_CAT_ITEM = {
  label: '',
  value: ''
};

class SelfService extends Component {
  constructor(props) {
    super(props);
    this.middleFilter = React.createRef();
    this.state = {
      isLoading: false,
      newItem: false,
      allItems: [],
      searchBUTreePlaceholder: 'Filter by State, County or Business Unit',
      searchBUTreeFilters: ['label'],
      searchBUTreeText: '',
      filteredBUTreeResult: [],
      searchPlaceholder: 'Filter Items by Title',
      searchFilters: ['linkTitle'],
      filterResult: [],
      pagedResults: [],
      categories: [],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 3,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      numPages: null,
      pageNumber: 1,
      currentItem: INITIAL_CURRENT_ITEM,
      fileData: null,
      isCategoryLoading: false,
      newCategory: {},
      deleteConfirmation: false,
      categoryDeleteConfirmation: false,
      categoryToDelete: {},
      currentIndex: 0,
      isButtonDisabled: false,
      maxSize: 20000000,
      sortedBUs: [],
      sortedBUCategories: [],
      filterdSortedBUCategories: [],
      catSelected: {},
      catFilteredResults: [],
      editCats: false, // if true, middle column will show cats instead of BUs
      catList: [], // array of cats to display in middle column
      filteredCatList: [],
      currentCatItem: INITIAL_CURRENT_CAT_ITEM, // current category we're editing
      newCatName: '',
      currentBU: '',
      categoriesPerBU: [],
      requiresFetch: true,
      middleTree: [],
      filteredMiddleTree: [],
      filteredSortedBUCategories: []
    };
  }

  componentWillMount = async () => {
    const { ID } = this.props.AdminReducer.admin;
    this.structureBUs(ID);
  };

  componentDidMount() {
    this.props.fetchAllCategories(); // these categories populate the dropdown on the right
    this.props.setCurrentPath('selfservice');
    // delete currentCounty so clicking on any county will fetch
    const county = {
      countyName: 'No County',
      state: 'ZZ',
      id: 'ZZ'
    };
    this.props.setCurrentCounty(county);
  }

  //When results return from server. Update the page UI.
  componentWillReceiveProps(nextProps) {
    const { availableLinks, isLoading, postedSuccessfully, error, categories, categoriesPerBU } = nextProps.SelfServiceReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }

    //put categories into state
    //if new category, find it and set it as currentItem
    const isNewCategory = this.state.categories.length + 1 === categories.length;
    if (categories.length > 0) {
      if (isNewCategory) {
        const newCat = categories.find(c => c.catTitle == this.state.newCategory.catTitle);
        this.setState({
          currentItem: { ...this.state.currentItem, category: newCat },
          isCategoryLoading: false
        });
      }
      if (this.state.categories.length - 1 === categories.length) {
        this.setState({ isCategoryLoading: false });
      }
      this.setState({ categories });
    }

    if (postedSuccessfully) {
      this.setRequiresFetch();
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.fetchAllCategories();
      this.handleMiddleTreeRefresh(this.state.currentBU);
      this.props.resetDefaultValues();
      this.setState({ newItem: false });
    } else if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      this.setState({ isCategoryLoading: false });
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    }

    if (availableLinks && availableLinks.length > 0 && !isNewCategory) {
      this.setState({
        allItems: availableLinks,
        newItem: false,
        deleteConfirmation: false,
        categoryDeleteConfirmation: false
      });
    } else if (!isNewCategory) {
      this.clearCurrentItem();
      this.setState({ allItems: [], newItem: true });
    }
  }
  //Triggered when the user selects a new business Unit.
  componentDidUpdate = async (prevProps, prevState) => {
    const { currentCounty } = prevProps.AdminReducer;
    // Typical usage (don't forget to compare props):
    //reset current index, and page number if switching categories
    if (prevState.catSelected.catID !== this.state.catSelected.catID) {
      this.setState({ currentIndex: 0, activePage: 1, pageNumber: 1 });
    }

    if (!prevState.requiresFetch && this.state.requiresFetch) {
      await httpSelfService.fetchCategoriesPerBU(this.props.AdminReducer.admin.mobileSecurityID).then(data => {
        this.setState({
          categoriesPerBU: data,
          requiresFetch: false
        });
      });
    }

    if (currentCounty && this.props.AdminReducer.currentCounty.businessUnit !== currentCounty.businessUnit) {
      this.setState({ currentIndex: 0 }, () => this.fetchLatestRecords(this.props.AdminReducer.currentCounty.id));
    }
  };

  //Fetch page specific data here from the API.
  fetchLatestRecords = businessUnit => {
    const { ID: mobileSecurityID } = this.props.AdminReducer.admin;
    this.props.allActiveLinks(businessUnit, mobileSecurityID);
  };

  // Structure BUs by state and county
  structureBUs = () => {
    const businessUnits = JSON.parse(localStorage.getItem('businessUnits'));

    const sortedBUs = [];
    const sortedBUCats = [];
    for (let i = 0; i < businessUnits.length; i++) {
      let currentBU = businessUnits[i];
      let currentState = currentBU.state;
      let currentCounty = currentBU.countyName;
      // Populate states
      if (sortedBUs.find(x => x.value == currentState) === undefined) {
        const state = stateCodes[currentState] || currentState;
        sortedBUs.push({ value: currentState, label: state, children: [] });
        sortedBUCats.push({
          value: currentState,
          label: state,
          children: [],
          showCheckbox: false
        });
      }

      // Populate counties
      let stateIndex = sortedBUs.findIndex(x => x.value === currentState);
      if (sortedBUs[stateIndex].children.find(x => x.value == currentState + currentCounty) === undefined) {
        sortedBUs[stateIndex].children.push({
          value: currentState + currentCounty,
          label: currentCounty,
          children: []
        });
        sortedBUCats[stateIndex].children.push({
          value: currentState + currentCounty,
          label: currentCounty,
          children: [],
          showCheckbox: false
        });
      }
      // Insert BUs
      let countyIndex = sortedBUs[stateIndex].children.findIndex(x => x.value === currentState + currentCounty);
      sortedBUs[stateIndex].children[countyIndex].children.push({
        value: JSON.stringify({
          id: currentBU.id,
          state: currentState,
          countyName: currentBU.countyName,
          businessUnit: currentBU.businessUnit
        }),
        label: currentBU.businessUnit + ' - ' + currentBU.businessUnitName
      });

      //if bu is selected, give it a selected className
      //else give it regular bu className
      let buSelected = currentBU.id === this.state.currentBU.id;
      sortedBUCats[stateIndex].children[countyIndex].children.push({
        value: JSON.stringify({
          state: currentState,
          countyName: currentBU.countyName,
          businessUnit: currentBU.businessUnit,
          currentBU
        }),
        label: currentBU.businessUnit + ' - ' + currentBU.businessUnitName,
        children: [],
        showCheckbox: false,
        className: buSelected ? 'buSelected' : 'buNode'
      });
    }
    // Sort alphabetically by state, then county
    sortedBUs.sort((a, b) => (a.value > b.value ? 1 : a.value === b.value ? (a.children.value > b.children.value ? 1 : -1) : -1));
    sortedBUCats.sort((a, b) => (a.value > b.value ? 1 : a.value === b.value ? (a.children.value > b.children.value ? 1 : -1) : -1));
    this.setState({ sortedBUs, sortedBUCategories: sortedBUCats });
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: INITIAL_CURRENT_ITEM,
      editCats: false,
      fileData: null,
      newItem: true
    });
  };

  setRequiresFetch = () => {
    this.setState({
      requiresFetch: true
    });
  };

  deleteCurrentItem = () => {
    this.setRequiresFetch();
    const { currentCounty } = this.props.AdminReducer;
    const { FirstName, LastName, ID: mobileSecurityID } = this.props.AdminReducer.admin;

    const modifiedBy = FirstName + ' ' + LastName;
    const { id } = this.state.currentItem;
    //modifiedBy, id, current county.,businessunit
    this.props
      .deleteLink(id, currentCounty.id, modifiedBy, mobileSecurityID)
      .then(() => {
        this.handleMiddleTreeRefresh(this.state.currentBU);
        this.setState({ deleteConfirmation: false });
      })
      .then(() => {
        this.clearCurrentItem();
        if (!this.state.pagedResults && this.state.activePage != 1) {
          this.setState({
            activePage: this.state.activePage - 1
          });
        }
      });
    this.props.fetchAllCategories();
  };

  addNewItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { linkTitle, linkURL, category, sharedBU, notes } = this.state.currentItem;
        this.setRequiresFetch();
        const { currentCounty } = this.props.AdminReducer;

        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));

        let parsedBU = [];
        if (sharedBU.length > 0) {
          for (let i = 0; i < sharedBU.length; i++) {
            parsedBU.push(JSON.parse(sharedBU[i]));
          }
        } else {
          toast.dismiss();
          toast.error('Please choose at least one business unit', {
            position: 'bottom-center'
          });
          return;
        }
        let newItem = {
          businessUnitID: currentCounty.id || 'ZZ',
          mobileSecurityID: currentAdmin.ID,
          linkTitle: linkTitle,
          linkURL: linkURL,
          categoryID: category.id || '',
          notes: notes || '',
          modifiedBy: currentAdmin.FirstName + ' ' + currentAdmin.LastName,
          sharedBU: parsedBU || ''
        };
        this.props.postNewLink(newItem);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  updateCurrentItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { linkURL, id, category, sharedBU, linkTitle, notes } = this.state.currentItem;
        this.setRequiresFetch();
        const { originalBU } = this.state;
        const { currentCounty } = this.props.AdminReducer;
        this.setState({
          isButtonDisabled: true
        });

        // **** here's the timeout ****
        setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);
        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));

        let parsedBU = [];
        if (sharedBU.length > 0) {
          for (let i = 0; i < sharedBU.length; i++) {
            parsedBU.push(JSON.parse(sharedBU[i]));
          }
        } else {
          toast.dismiss();
          toast.error('Please check at least one business unit', {
            position: 'bottom-center'
          });
          return;
        }

        //get removed BUs, then get only ids
        let removed = originalBU.filter(x => !parsedBU.some(i => i.businessUnit == x.businessUnit && i.countyName == x.countyName));
        const buIDs = removed.map(x => x.id);
        //get added BUs
        let added = parsedBU.filter(x => !originalBU.some(i => i.businessUnit == x.businessUnit && i.countyName == x.countyName));

        let updateItem = {
          linkID: id,
          businessUnitID: currentCounty.id,
          mobileSecurityID: currentAdmin.ID,
          newLinkTitle: linkTitle,
          notes: notes || '',
          newLinkURL: linkURL,
          categoryID: category.id || '',
          deletedBU: removed || '',
          addedBU: added || '',
          modifiedBy: currentAdmin.FirstName + ' ' + currentAdmin.LastName
        };
        this.props
          .updateLink(updateItem)
          .then(() => {
            toast.dismiss();
            toast.success('Success!', {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.props.resetDefaultValues();
            this.setState({ newItem: false });
            //restructure tree to show clicked on items
            this.handleMiddleTreeRefresh(this.state.currentBU);
            this.props.fetchAllCategories();
          })
          .catch(err => console.log(err));
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
      .then(() => {
        if (!this.state.pagedResults && this.state.activePage != 1) {
          this.setState({
            activePage: this.state.activePage - 1
          });
        } else if (!this.state.pagedResults) {
          this.setState({
            currentItem: INITIAL_CURRENT_ITEM,
            newItem: true
          });
        }
      });
  };

  //Current Page Specific Functionality:
  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { linkTitle, linkURL } = this.state.currentItem;

      if (!linkTitle || !linkURL) {
        toast.dismiss();
        reject('All fields are required to post a new Link');
      }

      const URLRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
      if (!linkURL.match(URLRegex)) {
        reject('URL must be valid.');
      }

      resolve();
    });
  };

  selectCategory = value => {
    this.setState({
      currentItem: { ...this.state.currentItem, category: value || '' }
    });
  };

  handleCreateCategory = value => {
    const { FirstName, LastName } = this.props.AdminReducer.admin;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    if (value.trim().toLowerCase() === 'other') {
      toast.dismiss();
      toast.error("'Other' is a default category and can't be created", {
        position: 'bottom-center'
      });
      return;
    }
    if (value.trim().length > 50) {
      toast.dismiss();
      toast.error('Category titles are limited to 50 characters', {
        position: 'bottom-center'
      });
      return;
    }
    this.selectCategory(value);
    const newCategory = {
      catTitle: value.trim(),
      catType: 'selfservice',
      mobileSecurityID: currentAdmin.ID,
      modifiedBy: FirstName + ' ' + LastName
    };
    this.setState({ newCategory, isCategoryLoading: true }, () => this.props.postNewCategory(newCategory));
  };

  checkCategoryQuantity = value => {
    if (value.numItems == 0) {
      this.setState({
        categoryDeleteConfirmation: true,
        categoryToDelete: value
      });
    } else {
      toast.dismiss();
      toast.error('Please check if there are any service links in this category', { position: 'bottom-center' });
    }
  };

  handleDeleteCategory = () => {
    const { id } = this.state.categoryToDelete;
    const { FirstName, LastName, ID: mobileSecurityID } = this.props.AdminReducer.admin;
    const modifiedBy = FirstName + ' ' + LastName;
    const type = 'selfservice';
    this.setState({ isCategoryLoading: true });
    this.props
      .deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(
        this.setState({
          categoryToDelete: {},
          categoryDeleteConfirmation: false
        })
      )
      .then(() => {
        if (id == this.state.currentItem.category.id) {
          this.setState({
            currentItem: { ...this.state.currentItem, category: '' }
          });
        }
      })
      .catch(() => this.setState({ isCategoryLoading: false }));
  };

  /**
   * a user selects the BUs they want for a link
   */
  handleChecked = checked => {
    this.setState({
      currentItem: { ...this.state.currentItem, sharedBU: checked }
    });
  };

  /**
   * when a user clicks on a node in the leftmost tree
   */
  handleBUClick = async clicked => {
    if (clicked.isLeaf) {
      const value = JSON.parse(clicked.value);
      const { currentBU } = value;
      this.props.setCurrentCounty(currentBU);
      this.setState(
        {
          currentCatItem: INITIAL_CURRENT_CAT_ITEM,
          currentBU
        },
        () => {
          this.structureBUs();
          this.handleMiddleTreeRefresh(currentBU);
          this.updateFilterResults('', this.state.middleTree);
          this.middleFilter.current.clearFilterBy();
        }
      );
    }
  };

  /**
   * when a user clicks on a node in the leftmost tree
   */
  handleMiddleTreeRefresh = async currentBU => {
    try {
      this.setState({ isLoading: true });
      const data = await httpSelfService.allActiveLinks(currentBU.id, this.props.AdminReducer.admin.ID);
      const structuredCategories = [];

      for (let i = 0; i < data.links.length; i++) {
        const currDoc = data.links[i];
        const currCat = currDoc.categoryID;

        // if doc has category not in structerdCategories
        // make category and add do to it inside structureCategory
        if (structuredCategories.find(x => x.value === currCat) === undefined) {
          const docToPush = {
            label: currDoc.linkTitle,
            value: JSON.stringify(currDoc),
            showCheckbox: false,
            icon: <FaFile size={10} />,
            className: 'itemNode'
          };
          structuredCategories.push({
            label: currDoc.categoryTitle,
            value: currDoc.categoryID,
            children: [docToPush],
            showCheckbox: false,
            className: 'catNode'
          });
        } else if (structuredCategories.find(x => x.value === currCat) !== undefined) {
          // else if doc category == category in structuredCategories
          // add doc to said category
          const catIndex = structuredCategories.findIndex(x => x.value == currCat);
          structuredCategories[catIndex].children.push({
            label: currDoc.linkTitle,
            value: JSON.stringify(currDoc),
            showCheckbox: false,
            className: 'itemNode',
            icon: <FaFile size={10} />
          });
        }
      }

      //Sort categories, put other last
      structuredCategories.sort((a, b) => {
        if (a.label === 'Other') return 1;
        else if (b.label === 'Other') return -1;
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      });
      for (let i = 0; i < structuredCategories.length; i++) {
        structuredCategories[i].children.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
      }

      this.setState({ middleTree: structuredCategories, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  handleMiddleTreeClick = clicked => {
    //if clicking on a category
    if (clicked && clicked.isParent) {
      const updatedTree = this.state.middleTree;
      if (!clicked.expanded && clicked.value !== this.state.currentCatItem.id) {
        const item = JSON.parse(clicked.children[0].value);
        this.changeLink(item);
        for (let i = 0; i < updatedTree.length; i++) {
          const currentCategory = updatedTree[i];
          const currentCatItems = currentCategory.children;
          // highlight the parent category
          if (currentCategory.value === clicked.value) {
            currentCategory.className = 'catSelected';
          } else {
            currentCategory.className = 'catNode';
          }
          //go through items in category and highlight
          for (let j = 0; j < currentCatItems.length; j++) {
            let currentItem = currentCatItems[j];
            if (currentItem.value === clicked.children[0].value) {
              currentItem.className = 'itemSelected';
            } else {
              currentItem.className = 'itemNode';
            }
          }
        }
        this.setState({ currentCatItem: { id: clicked.value, label: clicked.label }, middleTree: updatedTree });
      }
    } else {
      //if clicking on a file, update current item
      //NOTE: after changeLink, click create new link, cant click cancel
      const updatedTree = this.state.middleTree;
      for (let i = 0; i < updatedTree.length; i++) {
        const currentCategory = updatedTree[i];
        const currentCatItems = currentCategory.children;
        // highlight the parent category
        if (currentCategory.value === clicked.parent.value) {
          currentCategory.className = 'catSelected';
        } else {
          currentCategory.className = 'catNode';
        }
        //go through items in category and highlight
        for (let j = 0; j < currentCatItems.length; j++) {
          let currentItem = currentCatItems[j];
          if (currentItem.value === clicked.value) {
            currentItem.className = 'itemSelected';
          } else {
            currentItem.className = 'itemNode';
          }
        }
      }
      this.setState({ currentCatItem: { id: clicked.parent.value, label: clicked.parent.label }, middleTree: updatedTree });
      const item = JSON.parse(clicked.value);
      this.changeLink(item);
    }
  };

  handleEditCategory = () => {
    this.setState({ editCats: true });
  };

  updateCategoryName = async () => {
    // get current category id and new category name
    this.setRequiresFetch();
    const { newCatName, currentCatItem, currentBU } = this.state;
    const { FirstName, LastName } = this.props.AdminReducer.admin;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const mobileSecurityID = currentAdmin.ID;
    const updateItem = {
      businessUnit: currentBU.id, // Self service and contacts uses BUID, Protocols and Documents use BU #
      stateCode: currentBU.state,
      newCategoryName: newCatName,
      mobileSecurityID: mobileSecurityID,
      oldCategoryID: currentCatItem.id,
      catType: 'selfservice',
      modifiedBy: FirstName + ' ' + LastName
    };
    try {
      await httpCategory.updateCategory(updateItem);
      toast.dismiss();
      toast.success('Success!', { position: toast.POSITION.BOTTOM_CENTER });
      // After updating category name, reset all fields and unselect county so clicking on county will refetch items
      this.setState(
        {
          newCatName: '',
          currentCatItem: INITIAL_CURRENT_CAT_ITEM,
          catList: [],
          filteredCatList: []
        },
        () => {
          const county = {
            countyName: 'No County',
            state: 'ZZ',
            id: 'ZZ'
          };
          this.props.setCurrentCounty(county);
          this.handleMiddleTreeRefresh(this.state.currentBU);
        }
      );
    } catch (e) {
      toast.dismiss();
      toast.error('There was an error updating category name', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  cancelAdd = () => {
    this.setState({ newItem: true, currentItem: INITIAL_CURRENT_ITEM });
    // if (this.state.allItems && this.state.pagedResults) {
    //   this.setState({ currentItem: this.state.pagedResults[0] });
    // }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filteredMiddleTree) => {
    this.setState({ searchText, filteredMiddleTree });
  };

  updateBUTreeFilterResults = (searchBUTreeText, filteredSortedBUCategories) => {
    this.setState({ searchBUTreeText, filteredSortedBUCategories });
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    // If editing categories
    if (this.state.editCats) {
      return;
    }
    let currentPageNumber = this.state.activePage - 1;
    let catFilteredAllItems = this.state.allItems || [];
    let catFilteredFilterResults = this.state.filterResult || [];
    // if category is selected, filter by category first
    if (!isEmpty(this.state.catSelected)) {
      catFilteredAllItems = catFilteredAllItems.filter(x => x.category.id === this.state.catSelected.catID);

      //if there are filtered results, fill it with cat filtered results, otherwise leave alone
      if ((catFilteredFilterResults && catFilteredFilterResults.length > 0) || this.state.searchText.length > 0) {
        catFilteredFilterResults = catFilteredFilterResults.filter(x => x.category.id === this.state.catSelected.catID);
      }
    }
    let arr =
      (this.state.filterResult && this.state.filterResult.length > 0) || this.state.searchText.length > 0
        ? catFilteredFilterResults
        : catFilteredAllItems;
    let groups = [],
      i;
    for (i = 0; i < arr.length; i += this.state.pageSize) {
      groups.push(arr.slice(i, i + this.state.pageSize));
    }

    this.setState(
      {
        pagedResults: groups[currentPageNumber],
        totalItemsCount: groups.length
      },
      () => {
        if (
          groups[currentPageNumber] &&
          groups[currentPageNumber].length > 0 &&
          groups[currentPageNumber].length > this.state.currentIndex &&
          this.state.pagedResults
        ) {
          const originalBU = this.prepSharedBUs(groups[currentPageNumber][this.state.currentIndex].sharedBU);
          this.setState({
            currentItem: { ...groups[currentPageNumber][this.state.currentIndex] },
            originalBU
          });
        } else {
          this.clearCurrentItem();
        } // if nothing on the page, clear
      }
    );
  };

  changeLink = item => {
    const originalBU = this.prepSharedBUs(item.sharedBU);
    //item.sharedBU = originalBU;

    this.setState({
      currentItem: item,
      originalBU,
      newItem: false,
      pageNumber: 1,
      editCats: false
    });
  };

  prepSharedBUs = sharedBU => {
    let originalBU = [];
    if (sharedBU.length > 0) {
      for (let i = 0; i < sharedBU.length; i++) {
        //parse the sharedBU
        let parsedObj = JSON.parse(sharedBU[i]);
        originalBU.push(parsedObj);
      }
    }
    return originalBU;
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/

  render() {
    const showDeleteModal = this.state.deleteConfirmation || this.state.categoryDeleteConfirmation;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Self Service" />
        <div className="Docu-content" style={{ opacity: showDeleteModal === true ? '0.3' : '1' }}>
          <div className="left-container">
            <SearchBUTreeFilter
              updateFilterResults={this.updateBUTreeFilterResults}
              searchText={this.state.searchBUTreeText}
              filters={this.state.searchBUTreeFilters}
              data={this.state.sortedBUCategories}
              placeholder={this.state.searchBUTreePlaceholder}
            />
            <div className="state-list-container">
              <div>
                <MultiBUSelect
                  onClick={this.handleBUClick}
                  onExpand={() => { }}
                  nodes={this.state.searchBUTreeText.trim().length > 0 ? this.state.filteredSortedBUCategories : this.state.sortedBUCategories}
                  expandOnClick
                  showExpandAll={
                    this.state.searchBUTreeText.trim().length > 0
                      ? this.state.filteredSortedBUCategories.length > 0
                      : this.state.sortedBUCategories.length > 0
                  }
                />
                {(this.state.sortedBUCategories.length === 0 ||
                  (this.state.searchBUTreeText.trim().length > 0 && this.state.filteredSortedBUCategories.length === 0)) && (
                    <EmptyState title="Nothing Here Yet" subTitle="" />
                  )}
              </div>
            </div>
          </div>

          <div className="middle-container">
            <SearchBUTreeFilter
              ref={this.middleFilter}
              updateFilterResults={this.updateFilterResults}
              searchText={this.state.searchText}
              filters={['label']}
              data={this.state.middleTree}
              placeholder={this.state.searchPlaceholder}
            />
            <div className="list-container">
              <Loadable active={this.state.isLoading} spinner text="Loading...">
                <div>
                  <MultiBUSelect
                    onClick={this.handleMiddleTreeClick}
                    nodes={this.state.searchText.trim().length > 0 ? this.state.filteredMiddleTree : this.state.middleTree}
                    expandOnClick
                    showExpandAll={
                      this.state.searchText.trim().length > 0 ? this.state.filteredMiddleTree.length > 0 : this.state.middleTree.length > 0
                    }
                    onEdit={this.handleEditCategory}
                    onExpand={() => { }}
                  />
                  {(this.state.middleTree.length === 0 ||
                    (this.state.searchText.trim().length > 0 && this.state.filteredMiddleTree.length === 0)) && (
                      <EmptyState title="Nothing Here Yet" subTitle="" />
                    )}
                </div>
              </Loadable>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              {(this.state.editCats || !this.state.newItem) && (
                <button
                  disabled={showDeleteModal === true ? true : false}
                  className="New-Message"
                  style={{ display: 'flex' }}
                  href="#"
                  onClick={() => this.clearCurrentItem()}
                >
                  <MdAdd size={23} className="icon-add" />
                  New Self Service Link
                </button>
              )}
              {!this.state.editCats && this.state.newItem && (
                <Fragment>
                  <button
                    // disabled={!this.state.allItems.length > 0}
                    className="Cancel"
                    href="#"
                    style={{ display: this.state.newItem ? 'flex' : 'none' }}
                    onClick={() => {
                      this.cancelAdd();
                    }}
                  >
                    Cancel
                  </button>
                  <button className="Create-btn" href="#" style={{ display: this.state.newItem ? 'flex' : 'none' }} onClick={this.addNewItem}>
                    Submit
                  </button>
                </Fragment>
              )}
            </div>
            <div className="form-container">
              {/* Don't show if editCats is true */}
              {!this.state.editCats && (
                <Fragment>
                  <div className="title-container">
                    {this.state.newItem ? (
                      <p className="title-content">Add New Self Service Link</p>
                    ) : (
                      <p className="title-content">
                        {' '}
                        Last modified on{' '}
                        {moment
                          .utc(this.state.currentItem.modifiedDate)
                          .local()
                          .format('MM/DD/YYYY [at] h:mm:ss a')}{' '}
                        by {this.state.currentItem.modifiedBy}
                      </p>
                    )}

                    <button
                      className="btn-long"
                      disabled={showDeleteModal === true || this.state.isButtonDisabled}
                      onClick={this.updateCurrentItem}
                      style={{
                        display: this.state.newItem === false ? 'flex' : 'none'
                      }}
                    >
                      Update
                    </button>
                    <div className="title-btn">
                      {!this.state.newItem && (
                        <button
                          disabled={showDeleteModal === true ? true : false}
                          className="btn-long-ghost delete"
                          // onClick={this.deleteCurrentItem}
                          onClick={() => {
                            this.setState({ deleteConfirmation: true });
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="form-container-input">
                    <div className="form-group">
                      <div className="form-group-stacked">
                        <span className="form-group-stacked-half-label">Title</span>
                        <input
                          maxLength={100}
                          className="form-group-text"
                          type="text"
                          onChange={event =>
                            this.setState({
                              currentItem: {
                                ...this.state.currentItem,
                                linkTitle: event.target.value
                              }
                            })
                          }
                          value={this.state.currentItem.linkTitle === '' ? '' : this.state.currentItem.linkTitle}
                          placeholder={this.state.currentItem.linkTitle === '' ? 'Title' : ''}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-stacked">
                        <span className="form-group-stacked-half-label">Link</span>
                        <input
                          maxLength={2000}
                          className="form-group-text"
                          type="text"
                          onChange={event =>
                            this.setState({
                              currentItem: {
                                ...this.state.currentItem,
                                linkURL: event.target.value
                              }
                            })
                          }
                          value={this.state.currentItem.linkURL === '' ? '' : this.state.currentItem.linkURL}
                          placeholder={this.state.currentItem.linkURL === '' ? 'https://www.example.com' : ''}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-group-stacked-textarea">
                        <span className="form-group-stacked-textarea-label">Description</span>
                        <textarea
                          maxLength={3000}
                          className="form-group-textarea"
                          onChange={event =>
                            this.setState({
                              currentItem: {
                                ...this.state.currentItem,
                                notes: event.target.value
                              }
                            })
                          }
                          value={this.state.currentItem.notes || ''}
                          placeholder={this.state.currentItem.notes === '' ? 'Notes' : ''}
                        />
                      </div>
                    </div>
                    <div
                      className="section"
                      style={{
                        marginBottom: '2rem',
                        width: 'calc(100% - 1.5rem)'
                      }}
                    >
                      <CreateSelect
                        onSelectChange={this.selectCategory}
                        options={this.state.categories}
                        onInputChange={this.selectCategory}
                        onNewOption={this.handleCreateCategory}
                        isLoading={this.state.isCategoryLoading}
                        onDeleteOption={this.checkCategoryQuantity}
                        value={this.state.currentItem.category}
                      />
                    </div>
                    <div className="stateContainer">
                      <Collapsiple trigger={'Choose Business Units'}>
                        <MultiBUSelect
                          nodes={this.state.sortedBUs}
                          onCheck={this.handleChecked}
                          onExpand={() => { }}
                          checked={this.state.currentItem.sharedBU}
                        />
                      </Collapsiple>
                    </div>
                  </div>
                </Fragment>
              )}
              {/* Show if editing category */}
              {this.state.editCats && this.state.currentCatItem.label && (
                <div className="title-container" style={{ flexDirection: 'column' }}>
                  <p className="rename-title">
                    Current Business Unit: {this.state.currentBU.businessUnit + ' - ' + this.state.currentBU.businessUnitName}
                  </p>
                  <p className="rename-title">Rename Category '{this.state.currentCatItem.label}'</p>
                  <div className="form-container-input rename-container">
                    <div className="form-group">
                      <div className="rename-category">
                        <input
                          maxLength={100}
                          className="form-group-text"
                          type="text"
                          onChange={event => this.setState({ newCatName: event.target.value })}
                          value={this.state.newCatName}
                          placeholder="New Category Name"
                        />
                        <button className="Create-btn" onClick={this.updateCategoryName}>
                          Update Category Name
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="delete-item-modal-container"
          style={{
            display: this.state.deleteConfirmation === true ? 'block' : 'none'
          }}
        >
          <span>Are you sure you want to delete this service link?</span>
          <div className="delete-item-modal-container__buttons">
            <button className="delete-item-modal-container__delete" onClick={this.deleteCurrentItem}>
              Delete
            </button>
            <button
              className="delete-item-modal-container__cancel"
              onClick={() => {
                this.setState({ deleteConfirmation: false });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          className="delete-item-modal-container"
          style={{
            display: this.state.categoryDeleteConfirmation === true ? 'block' : 'none'
          }}
        >
          <span>Are you sure you want to delete the category "{this.state.categoryToDelete.catTitle}"?</span>
          <div className="delete-item-modal-container__buttons">
            <button className="delete-item-modal-container__delete" onClick={this.handleDeleteCategory}>
              Delete
            </button>
            <button
              className="delete-item-modal-container__cancel"
              onClick={() => {
                this.setState({ categoryDeleteConfirmation: false });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    SelfServiceReducer: state.SelfServiceReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActiveLinks: (businessUnitID, mobileSecurityID) => dispatch(allActiveLinks(businessUnitID, mobileSecurityID)),
    postNewLink: params => dispatch(postNewLink(params)),
    updateLink: params => dispatch(updateLink(params)),
    deleteLink: (id, businessUnitID, modifiedBy, mobileSecurityID) => dispatch(deleteLink(id, businessUnitID, modifiedBy, mobileSecurityID)),
    postNewCategory: params => dispatch(postNewCategory(params)),
    fetchAllCategories: () => dispatch(fetchAllCategories()),
    //fetchCategoriesPerBU: (id) => dispatch(fetchCategoriesPerBU(id)),
    deleteCategory: (id, type, modifiedBy, mobileSecurityID) => dispatch(deleteCategory(id, type, modifiedBy, mobileSecurityID)),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentCounty: county => dispatch(setCurrentCounty(county)),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfService);
