import React, { Component, createRef, Fragment } from 'react'
import IdleTimer from "react-idle-timer";
import { withRouter } from 'react-router';
import SessionTimeoutDialog from "./SessionTimeoutDialog";

let countdownInterval;
let timeout;

class SessionTimeout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeoutModalOpen: false,
      timeoutCountdown: 0,
      idleTimer: createRef(null)
    }

  }
  clearSessionTimeout = () => {
    clearTimeout(timeout);
  };
  clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  handleLogout = async (isTimedOut = false) => {
    try {
      this.setState({ timeoutModalOpen: false })
      this.clearSessionInterval();
      this.clearSessionTimeout();
      sessionStorage.removeItem('admin');
      localStorage.removeItem('businessUnits');
      localStorage.removeItem('securityRoles');
      this.props.history.push('/login');
    } catch (err) {
      console.error(err);
    }
  };
  handleContinue = () => {
    this.setState({ timeoutModalOpen: false },
      this.clearSessionInterval(),
      this.clearSessionTimeout())
  };

  onActive = () => {
    if (!this.state.timeoutModalOpen) {
      this.clearSessionInterval();
      this.clearSessionTimeout();
    }
  };

  onIdle = () => {
    const delay = 1000 * 1;
    if (!this.state.timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 120;
        this.setState({ timeoutModalOpen: true, timeoutCountdown: countDown })
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown })
          } else {
            this.handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  render() {
    return (
      <Fragment>
        <IdleTimer
          ref={this.state.idleTimer}
          onActive={this.onActive}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 13}
        />
        {this.state.timeoutModalOpen && <SessionTimeoutDialog onContinue={this.handleContinue} onLogout={this.handleLogout} countDown={this.state.timeoutCountdown} />}

      </Fragment>
    )
  }

}

export default withRouter(SessionTimeout);