import SelfService from '../../httpClient/SelfService';

//Action Types
export const types = {
  FETCHING_SELF_SERVICE_DATA: 'FETCHING_SELF_SERVICE_DATA',
  FETCHING_SELF_SERVICE_DATA_SUCCESS: 'FETCHING_SELF_SERVICE_SUCCESS',
  FETCHING_SELF_SERVICE_DATA_FAILURE: 'FETCHING_SELF_SERVICE_DATA_FAILURE',
  NEW_SELF_SERVICE_DATA_SUCCESS: 'NEW_SELF_SERVICE_DATA_SUCCESS',
  UPDATE_SELF_SERVICE_DATA_SUCCESS: 'UPDATE_SELF_SERVICE_DATA_SUCCESS',
  DELETE_SELF_SERVICE_DATA_SUCCESS: 'DELETE_SELF_SERVICE_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES',
  CATEGORIES_GET_DATA_SUCCESS: 'CATEGORIES_GET_DATA_SUCCESS',
  NEW_CATEGORY_DATA_SUCCESS: 'NEW_CATEGORY_DATA_SUCCESS',
  DELETE_CATEGORY_DATA_SUCCESS: 'DELETE_CATEGORY_DATA_SUCCESS'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  isLoading: false,
  error: null,
  categories: [],
  availableLinks: []
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_SELF_SERVICE_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_SELF_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        availableLinks: action.availableLinks,
        error: null
      };

    case types.FETCHING_SELF_SERVICE_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_SELF_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        postedSuccessfully: true,
        availableLinks: action.availableLinks,
        error: null
      };

    case types.UPDATE_SELF_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableLinks: action.availableLinks,
        error: null
      };

    case types.DELETE_SELF_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableLinks: action.availableLinks,
        error: null
      };

    case types.CATEGORIES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.NEW_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.DELETE_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveLinks(businessUnitID, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_SELF_SERVICE_DATA });
    return SelfService.allActiveLinks(businessUnitID, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_SUCCESS,
          availableLinks: data.links
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function postNewLink(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_SELF_SERVICE_DATA });
    return SelfService.postNewLink(params)
      .then(data => {
        dispatch({
          type: types.NEW_SELF_SERVICE_DATA_SUCCESS,
          availableLinks: data.links
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function updateLink(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_SELF_SERVICE_DATA });
    return SelfService.updateLink(params)
      .then(data => {
        dispatch({
          type: types.UPDATE_SELF_SERVICE_DATA_SUCCESS,
          availableLinks: data.links
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function deleteLink(id, businessUnitID, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_SELF_SERVICE_DATA });
    return SelfService.deleteLink(id, businessUnitID, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_SELF_SERVICE_DATA_SUCCESS,
          availableLinks: data.links
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function fetchAllCategories() {
  return function(dispatch) {
    return SelfService.fetchAllCategories()
      .then(data => {
        dispatch({
          type: types.CATEGORIES_GET_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function postNewCategory(params) {
  return function(dispatch) {
    return SelfService.postNewCategory(params)
      .then(data => {
        dispatch({
          type: types.NEW_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function deleteCategory(id, type, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    return SelfService.deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_SELF_SERVICE_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
