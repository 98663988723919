export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR'
};

export default (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
};

export function AlertSuccess(message) {
  return { type: alertConstants.SUCCESS, message };
}

export function AlertError(message) {
  return { type: alertConstants.ERROR, message };
}

export function AlertClear() {
  return { type: alertConstants.CLEAR };
}
