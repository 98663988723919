import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaPlus, FaMinus, FaRepeat, FaRotateLeft } from 'react-icons/lib/fa';

const ActionBtns = styled.span`padding: 10px;background: grey;border-radius: 50%;margin: 5px; z-index: 11; cursor:pointer; pointer-events: ${props => (props.disabled ? 'none' : 'all')}`;

export default class ImageResize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: 0,
      scale: 1,
    };
    this.imageElementRef = createRef();
    this.imageWrapperRef = createRef();
    this.imageElement = null;
    this.imageElementPos = { x: null, y: null };
    this.scaleLimit = { min: 1, max: 2.5 };
    this.imageElementOrgPos = { x: 0, y: 0 };
  }

  componentDidMount() {
    this.bindEvents();
  }
  componentWillUnmount() {
    this.unBindEvents();
  }

  bindEvents = () => {
    const imageWrapperRef = this.imageWrapperRef.current;
    imageWrapperRef.addEventListener('mousemove', e => this.onDraggingHandler(e));
    imageWrapperRef.addEventListener('mouseup', e => this.onStopDraggingHandler(e));
    imageWrapperRef.addEventListener('touchmove', e => this.onDraggingHandler(e));
    imageWrapperRef.addEventListener('touchend', e => this.onStopDraggingHandler(e));
  }

  unBindEvents = () => {
    const imageWrapperRef = this.imageWrapperRef.current;
    imageWrapperRef.removeEventListener('mousemove', e => this.onDraggingHandler(e));
    imageWrapperRef.removeEventListener('mouseup', e => this.onStopDraggingHandler(e));
    imageWrapperRef.removeEventListener('touchmove', e => this.onDraggingHandler(e));
    imageWrapperRef.removeEventListener('touchend', e => this.onStopDraggingHandler(e));
  }

  onZoomHandler = (zoomValue) => {
    this.setState(prevState => ({
      scale: prevState.scale + zoomValue,
    }));
  };

  onRotateHandler = (rotateValue) => {
    this.setState(prevState => ({
      rotate: prevState.rotate + rotateValue,
    }));
  }

  onStartDraggingHandler = (e) => {
    e.preventDefault();
    this.imageElement = this.imageElementRef.current;
    this.imageElement.style.cursor = 'move';
    this.imageElementPos.x = window.event.clientX - this.imageElementRef.current.offsetLeft;
    this.imageElementPos.y = window.event.clientY - this.imageElementRef.current.offsetTop;
  }

  onStopDraggingHandler = (e) => {
    e.preventDefault();
    if (this.imageElement) {
      this.imageElement.style.cursor = 'pointer';
      this.imageElement = null;
    }
  }

  onDraggingHandler = (e) => {
    e.preventDefault();
    const cursorXPos = window.event.clientX;
    const cursorYPos = window.event.clientY;
    if (this.imageElement !== null) {
      this.imageElement.style.left = `${cursorXPos - this.imageElementPos.x}px`;
      this.imageElement.style.top = `${cursorYPos - this.imageElementPos.y}px`;
    }
  }

  render() {
    const { src, alt, style } = this.props;
    const { rotate, scale } = this.state;
    if (!src) return null;
    return (
      <div>
        <div
          style={{
            position: 'relative', overflow: 'hidden', width: '100%', height: '500px', ...style
          }}
        >
          <div ref={this.imageWrapperRef} style={{
            overflow: 'hidden', width: '100%', height: '500px', ...style
          }}>
            <img
              src={src}
              alt={alt}
              ref={this.imageElementRef}
              onMouseDown={e => this.onStartDraggingHandler(e)}
              onTouchEnd={e => this.onStartDraggingHandler(e)}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                position: 'relative',
                width: '100%',
                height: '500px',
                objectFit: 'contain'
              }} />
          </div>
          <div
            className="flex"
            style={{
              position: 'absolute', right: 0, bottom: 0, flexDirection: 'column', width: 'auto',
            }}
          >
            <div className="flex" style={{ flexDirection: 'row' }}>
              <ActionBtns onClick={() => this.onZoomHandler(-0.5)} disabled={scale === this.scaleLimit.min}><FaMinus size={20} /></ActionBtns>
              <ActionBtns onClick={() => this.onZoomHandler(+0.5)} disabled={scale === this.scaleLimit.max}><FaPlus size={20} /></ActionBtns>
            </div>
            <div className="flex" style={{ flexDirection: 'row' }}>
              <ActionBtns onClick={() => this.onRotateHandler(-90)}><FaRotateLeft size={20} /></ActionBtns>
              <ActionBtns onClick={() => this.onRotateHandler(+90)}><FaRepeat size={20} /></ActionBtns>
            </div>
          </div>
        </div>
      </div>);
  }
}

ImageResize.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
}

ImageResize.defaultProps = {
  alt: '',
  style: {},
}

