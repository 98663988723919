import RestApi from './RestApi';

class Protocols {
  static allActiveProtocols(businessUnitID, mobileSecurityID) {
    const api = new RestApi();
    return api
      .GET('/protocols?businessUnitID=' + encodeURIComponent(businessUnitID) + '&mobileSecurityID=' + encodeURIComponent(mobileSecurityID))
      .then(response => {
        return response;
      });
  }

  static postNewProtocol(params) {
    const api = new RestApi();
    return api.POST('/protocols', params).then(response => {
      return response;
    });
  }

  static updateProtocol(params) {
    const api = new RestApi();
    return api.PUT('/protocols', params).then(response => {
      return response;
    });
  }

  static deleteProtocol(fileKey, businessUnitID, mobileSecurityID) {
    const api = new RestApi();
    return api
      .DELETE(
        '/protocols?fileKey=' +
          encodeURIComponent(fileKey) +
          '&businessUnitID=' +
          encodeURIComponent(businessUnitID) +
          '&mobileSecurityID=' +
          encodeURIComponent(mobileSecurityID)
      )
      .then(response => {
        return response;
      });
  }

  static fetchCurrentPDF(file) {
    const api = new RestApi();
    return api.GET('/protocols?type=protocols&id=' + encodeURIComponent(file)).then(response => {
      return response;
    });
  }

  static fetchAllCategories() {
    const api = new RestApi();
    return api.GET('/categories?type=protocol').then(response => {
      return response;
    });
  }

  static fetchCategoriesPerBU(id) {
    const api = new RestApi();
    return api.GET('/categories?userID=' + encodeURIComponent(id) + '&type=protocol').then(response => {
      return response;
    });
  }

  static postNewCategory(params) {
    const api = new RestApi();
    return api.POST('/categories', params).then(response => {
      return response;
    });
  }

  static deleteCategory(id, type, modifiedBy, mobileSecurityID) {
    const api = new RestApi();
    return api.DELETE(`/categories?id=${id}&catType=${type}&modifiedBy=${modifiedBy}&mobileSecurityID=${mobileSecurityID}`).then(response => {
      return response;
    });
  }
}

export default Protocols;
