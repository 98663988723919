import RestApi from './RestApi';

class ContinuingEducation {
  static allActiveClasses(businessUnitID) {
    const api = new RestApi();
    return api.GET('/continuingEducation?businessUnitID=' + encodeURIComponent(businessUnitID)).then(response => {
      return response;
    });
  }

  static postNewClass(params) {
    const api = new RestApi();
    return api.POST('/continuingEducation', params).then(response => {
      return response;
    });
  }

  static updateClass(params) {
    const api = new RestApi();
    return api.PUT('/continuingEducation', params).then(response => {
      return response;
    });
  }

  static deleteClass(classID, mobileSecurityID, businessUnitID) {
    const api = new RestApi();
    return api
      .DELETE(
        '/continuingEducation?classID=' +
          encodeURIComponent(classID) +
          '&mobileSecurityID=' +
          encodeURIComponent(mobileSecurityID) +
          '&businessUnitID=' +
          encodeURIComponent(businessUnitID)
      )
      .then(response => {
        return response;
      });
  }
}

export default ContinuingEducation;
