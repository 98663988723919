//react
import React, { Component, Fragment } from 'react';
import { MdAdd } from 'react-icons/lib/md';
import { FaFile } from 'react-icons/lib/fa';

//css
import './Documents.css';
import httpDocument from '../../httpClient/Documents';
import httpCategory from '../../httpClient/Categories';

//components
import Heading from '../../components/Heading/Heading';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import SearchBUTreeFilter from '../../components/SearchFilter/SearchBUTreeFilter';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
// import MultiSelect from '../../components/MultiSelect/MultiSelect'; // for job titles
import CreateSelect from '../../components/CreateSelect/CreateSelect';
import EmptyState from '../../components/EmptyState/EmptyState';
import Collapsiple from '../../components/Collapsible/Collapsible';
import MultiBUSelect from '../../components/MultiBUSelect/MultiBUSelect';

import { stateCodes } from '../../assets/stateCodesToName';

// libraries
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import Loadable from 'react-loading-overlay';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from 'react-dropzone';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { isEmpty } from 'lodash';

// redux state
import { connect } from 'react-redux';
import {
  allActiveDocuments,
  postNewDocument,
  updateDocument,
  deleteDocument,
  resetDefaultValues,
  fetchCurrentPDF,
  fetchAllJobTitles,
  fetchAllCategories,
  postNewCategory,
  deleteCategory,
  fetchCategoriesPerBU
} from './../../redux/modules/Documents';
import { setCurrentCounty, setCurrentPath } from './../../redux/modules/Admin';

const INITIAL_CURRENT_ITEM = {
  id: 0,
  title: '',
  fileURL: '',
  fileString: '',
  isActive: true,
  isDownloadable: false,
  jobTitles: [],
  category: {},
  sharedBU: []
};

const INITIAL_CURRENT_CAT_ITEM = {
  label: '',
  value: ''
};

class Documents extends Component {
  constructor(props) {
    super(props);
    this.middleFilter = React.createRef();
    this.state = {
      isLoading: false,
      newItem: false,
      allItems: [],
      searchBUTreePlaceholder: 'Filter by State, County or Business Unit',
      searchBUTreeFilters: ['label'],
      searchBUTreeText: '',
      filteredBUTreeResult: [],
      searchPlaceholder: 'Filter Items by Title',
      searchFilters: ['title'],
      filterResult: [],
      pagedResults: [],
      jobTitles: [],
      categories: [],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 3,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      numPages: null,
      pageNumber: 1,
      currentItem: INITIAL_CURRENT_ITEM,
      fileData: null,
      isCategoryLoading: false,
      newCategory: {},
      deleteConfirmation: false,
      categoryDeleteConfirmation: false,
      categoryToDelete: {},
      currentIndex: 0,
      isButtonDisabled: false,
      maxSize: 20000000,
      sortedBUs: [],
      sortedBUCategories: [],
      filterdSortedBUCategories: [],
      catSelected: {},
      catFilteredResults: [],
      editCats: false, // if true, middle column will show cats instead of BUs
      catList: [], // array of cats to display in middle column
      filteredCatList: [],
      currentCatItem: INITIAL_CURRENT_CAT_ITEM, // current category we're editing
      newCatName: '',
      currentBU: '',
      categoriesPerBU: [],
      requiresFetch: true,
      middleTree: [],
      filteredMiddleTree: [],
      filteredSortedBUCategories: [],
      currentPDF: null
    };
  }

  componentWillMount = () => {
    const { ID } = this.props.AdminReducer.admin;
    this.structureBUs(ID);
  };
  //Triggered when page loads.
  componentDidMount() {
    this.props.fetchAllCategories();
    // this.props.fetchAllJobTitles();
    this.props.setCurrentPath('documents');
    // delete currentCounty so clicking on any county will fetch
    const county = {
      countyName: 'No County',
      state: 'ZZ',
      id: 'ZZ'
    };
    this.props.setCurrentCounty(county);
  }

  //When results return from server. Update the page UI.
  componentWillReceiveProps(nextProps) {
    const { availableDocuments, isLoading, postedSuccessfully, error, PDFFile, jobTitles, categories, categoriesPerBU } = nextProps.DocumentsReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }
    if (jobTitles.length > 0) {
      this.setState({ jobTitles });
    }

    //put categories into state
    //if new category, find it and set it as currentItem
    const isNewCategory = this.state.categories.length + 1 === categories.length;
    if (categories.length > 0) {
      if (isNewCategory) {
        const newCat = categories.find(c => c.catTitle == this.state.newCategory.catTitle);
        this.setState({
          currentItem: { ...this.state.currentItem, category: newCat },
          isCategoryLoading: false
        });
      }
      if (this.state.categories.length - 1 === categories.length) {
        this.setState({ isCategoryLoading: false });
      }
      this.setState({ categories });
    }

    if (PDFFile !== null) {
      // convert base64 to blob to work on IE.
      let blobFile = this.pdfBlobConvesion(PDFFile, 'pdf');
      this.setState({ fileData: blobFile });
    }

    if (postedSuccessfully) {
      this.setRequiresFetch();
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.fetchAllCategories();
      this.handleMiddleTreeRefresh(this.state.currentBU);
      this.props.resetDefaultValues();
      this.setState({ newItem: false });
    } else if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    }

    if (availableDocuments && availableDocuments.length > 0 && !isNewCategory) {
      // if(PDFFile === null) {
      this.setState({
        allItems: availableDocuments,
        newItem: false,
        deleteConfirmation: false,
        categoryDeleteConfirmation: false
      });
      // }
    } else if (!isNewCategory) {
      this.clearCurrentItem();
      this.setState({ allItems: [], newItem: true });
    }
  }

  //Triggered when the user selects a new business Unit
  //or new category
  componentDidUpdate = async (prevProps, prevState) => {
    const { currentCounty } = prevProps.AdminReducer;
    // Typical usage (don't forget to compare props):

    //reset current index, and page number if switching categories
    if (prevState.catSelected.catID !== this.state.catSelected.catID) {
      this.setState({ currentIndex: 0, activePage: 1, pageNumber: 1 });
    }

    if (!prevState.requiresFetch && this.state.requiresFetch) {
      await httpDocument.fetchCategoriesPerBU(this.props.AdminReducer.admin.mobileSecurityID).then(data => {
        this.setState({
          categoriesPerBU: data,
          requiresFetch: false
        });
      });
    }

    // if (currentCounty && this.props.AdminReducer.currentCounty.businessUnit !== currentCounty.businessUnit) {
    //   this.setState({ currentIndex: 0 }, () =>
    //     this.fetchLatestRecords(
    //       this.props.AdminReducer.currentCounty.businessUnit
    //     )
    //   );
    // }
  };

  pdfBlobConvesion = (b64Data, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    let byteCharacters = window.atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  //Fetch page specific data here from the API.
  fetchLatestRecords = businessUnit => {
    const { ID: mobileSecurityID } = this.props.AdminReducer.admin;
    this.props.allActiveDocuments(businessUnit, mobileSecurityID);
  };

  fetchCurrentPDF = item => {
    let sharedBU = this.prepSharedBUs(item.sharedBU);
    const modifiedBU = sharedBU[0]; //0 - modifiedBU
    const originalBU = sharedBU[1]; //1 - originalBU
    item.sharedBU = modifiedBU;
    this.setState({ currentItem: item, originalBU, newItem: false, pageNumber: 1 }, () => {
      //comment out this line to remove remote share errors in development
      this.props.fetchCurrentPDF(item.id);
    });
  };

  // Structure BUs by state and county
  structureBUs = () => {
    const businessUnits = JSON.parse(localStorage.getItem('businessUnits'));

    const sortedBUs = [];
    const sortedBUCats = [];
    for (let i = 0; i < businessUnits.length; i++) {
      let currentBU = businessUnits[i];
      let currentState = currentBU.state;
      let currentCounty = currentBU.countyName;
      // Populate states
      if (sortedBUs.find(x => x.value == currentState) === undefined) {
        const state = stateCodes[currentState] || currentState;
        sortedBUs.push({ value: currentState, label: state, children: [] });
        sortedBUCats.push({
          value: currentState,
          label: state,
          children: [],
          showCheckbox: false
        });
      }

      // Populate counties
      let stateIndex = sortedBUs.findIndex(x => x.value === currentState);
      if (sortedBUs[stateIndex].children.find(x => x.value == currentState + currentCounty) === undefined) {
        sortedBUs[stateIndex].children.push({
          value: currentState + currentCounty,
          label: currentCounty,
          children: []
        });
        sortedBUCats[stateIndex].children.push({
          value: currentState + currentCounty,
          label: currentCounty,
          children: [],
          showCheckbox: false
        });
      }
      // Insert BUs
      let countyIndex = sortedBUs[stateIndex].children.findIndex(x => x.value === currentState + currentCounty);
      sortedBUs[stateIndex].children[countyIndex].children.push({
        value: JSON.stringify({
          stateCode: currentState,
          countyName: currentBU.countyName,
          businessUnit: currentBU.businessUnit
        }),
        label: currentBU.businessUnit + ' - ' + currentBU.businessUnitName
      });

      //if bu is selected, give it a selected className
      //else give it regular bu className
      let buSelected = currentBU.businessUnit === this.state.currentBU.businessUnit;

      sortedBUCats[stateIndex].children[countyIndex].children.push({
        value: JSON.stringify({
          stateCode: currentState,
          countyName: currentBU.county,
          businessUnit: currentBU.businessUnit,
          currentBU
        }),
        label: currentBU.businessUnit + ' - ' + currentBU.businessUnitName,
        children: [],
        showCheckbox: false,
        className: buSelected ? 'buSelected' : 'buNode'
      });
    }
    // Sort alphabetically by state, then county
    sortedBUs.sort((a, b) => (a.value > b.value ? 1 : a.value === b.value ? (a.children.value > b.children.value ? 1 : -1) : -1));
    sortedBUCats.sort((a, b) => (a.value > b.value ? 1 : a.value === b.value ? (a.children.value > b.children.value ? 1 : -1) : -1));
    this.setState({ sortedBUs, sortedBUCategories: sortedBUCats });
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: INITIAL_CURRENT_ITEM,
      editCats: false,
      fileData: null,
      newItem: true
    });
  };

  setRequiresFetch = () => {
    this.setState({
      requiresFetch: true
    });
  };

  deleteCurrentItem = () => {
    this.setRequiresFetch();
    const { currentCounty } = this.props.AdminReducer;
    const { fileKey } = this.state.currentItem;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const mobileSecurityID = currentAdmin.ID;

    this.props
      .deleteDocument(fileKey, currentCounty.businessUnit, mobileSecurityID)
      .then(() => {
        this.props.fetchAllCategories();
        this.handleMiddleTreeRefresh(this.state.currentBU);
        this.setState({ deleteConfirmation: false });
      }) //  VVVPotentially put this in a function to increase reusability of code VVV These also exist in Self Service and Protocols in the same function
      .then(() => {
        this.clearCurrentItem();
        if (!this.state.pagedResults && this.state.activePage != 1) {
          this.setState({
            activePage: this.state.activePage - 1
          });
        }
      });
  };

  addNewItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { title, fileString, isDownloadable, jobTitles, category, sharedBU } = this.state.currentItem;
        this.setRequiresFetch();
        const { currentCounty } = this.props.AdminReducer;

        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const jobTitleIds = jobTitles.map(val => val.id).toString();

        let parsedBU = [];
        if (sharedBU.length > 0) {
          for (let i = 0; i < sharedBU.length; i++) {
            parsedBU.push(JSON.parse(sharedBU[i]));
          }
        } else {
          toast.dismiss();
          toast.error('Please choose at least one business unit', {
            position: 'bottom-center'
          });
          return;
        }

        let newItem = {
          businessUnitID: currentCounty.businessUnit || 'ZZ',
          mobileSecurityID: currentAdmin.ID,
          title: title,
          fileString: fileString,
          jobTitles: jobTitleIds.length > 0 ? jobTitleIds : '',
          category: category.id || '',
          description: '',
          sharedBU: parsedBU || '',
          isDownloadable: isDownloadable
        };
        this.props.postNewDocument(newItem);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  updateCurrentItem = () => {
    const { isDownloadable, jobTitles, isActive, category, sharedBU, fileKey, fileURL, fileLocation, title } = this.state.currentItem;
    this.setRequiresFetch();
    const { originalBU } = this.state;
    const { currentCounty } = this.props.AdminReducer;
    this.setState({
      isButtonDisabled: true
    });

    // **** here's the timeout ****
    setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const jobTitleIds = jobTitles.map(val => val.id).toString();

    let parsedBU = [];
    if (sharedBU.length > 0) {
      for (let i = 0; i < sharedBU.length; i++) {
        parsedBU.push(JSON.parse(sharedBU[i]));
      }
    } else {
      toast.dismiss();
      toast.error('Please check at least one business unit', {
        position: 'bottom-center'
      });
      return;
    }

    //get removed BUs, then get only ids
    let removed = originalBU.filter(
      x => !parsedBU.some(i => i.businessUnit == x.businessUnit && i.countyName == x.countyName && i.stateCode == x.stateCode)
    );
    const buIDs = removed.map(x => x.id);
    //get added BUs
    let added = parsedBU.filter(
      x => !originalBU.some(i => i.businessUnit == x.businessUnit && i.countyName == x.countyName && i.stateCode == x.stateCode)
    );

    let updateItem = {
      businessUnitID: currentCounty.businessUnit,
      mobileSecurityID: currentAdmin.ID,
      isActive,
      jobTitles: jobTitleIds,
      fileKey,
      fileURL,
      fileLocation,
      title,
      description: '',
      category: category.id || '',
      deletedBU: removed || '',
      addedBU: added || '',
      isDownloadable
    };
    this.props
      .updateDocument(updateItem)
      .then(() => {
        toast.dismiss();
        toast.success('Success!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.props.resetDefaultValues();
        this.setState({ newItem: false });
        //restructure tree to show clicked on items
        this.handleMiddleTreeRefresh(this.state.currentBU);
        this.props.fetchAllCategories();
      })
      .catch(err => console.log(err))
      .then(() => {
        if (!this.state.pagedResults && this.state.activePage != 1) {
          this.setState({
            activePage: this.state.activePage - 1
          });
        } else if (!this.state.pagedResults) {
          this.clearCurrentItem();
        }
      });
  };

  //Current Page Specific Functionality:
  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { title, fileString } = this.state.currentItem;

      if (!title || !fileString) {
        toast.dismiss();
        reject('All fields are required to post a new Document');
      }

      resolve();
    });
  };

  //PDF Page Change Functionality
  handlePDFPageChange = pageNumber => {
    this.setState({ pageNumber: pageNumber });
  };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  selectJobTitles = value => {
    this.setState({
      currentItem: { ...this.state.currentItem, jobTitles: value }
    });
  };

  selectCategory = value => {
    this.setState({
      currentItem: { ...this.state.currentItem, category: value || '' }
    });
  };

  handleCreateCategory = value => {
    const { FirstName, LastName } = this.props.AdminReducer.admin;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    if (value.trim().toLowerCase() === 'other') {
      toast.dismiss();
      toast.error("'Other' is a default category and can't be created", {
        position: 'bottom-center'
      });
      return;
    }
    if (value.trim().length > 50) {
      toast.dismiss();
      toast.error('Category titles are limited to 50 characters', {
        position: 'bottom-center'
      });
      return;
    }

    this.selectCategory(value);
    const newCategory = {
      catTitle: value.trim(),
      catType: 'document',
      mobileSecurityID: currentAdmin.ID,
      modifiedBy: FirstName + ' ' + LastName
    };
    this.setState({ newCategory, isCategoryLoading: true }, () => this.props.postNewCategory(newCategory));
  };

  checkCategoryQuantity = value => {
    if (value.numItems == 0) {
      this.setState({
        categoryDeleteConfirmation: true,
        categoryToDelete: value
      });
    } else {
      toast.dismiss();
      toast.error('Please check if there are any documents in this category', {
        position: 'bottom-center'
      });
    }
  };

  handleDeleteCategory = () => {
    const { id } = this.state.categoryToDelete;
    const { FirstName, LastName } = this.props.AdminReducer.admin;
    const modifiedBy = FirstName + ' ' + LastName;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const mobileSecurityID = currentAdmin.ID;
    const type = 'document';
    this.setState({ isCategoryLoading: true });
    this.props
      .deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(
        this.setState({
          categoryToDelete: {},
          categoryDeleteConfirmation: false
        })
      )
      .then(() => {
        if (id == this.state.currentItem.category.id) {
          this.setState({
            currentItem: { ...this.state.currentItem, category: '' }
          });
        }
      })
      .catch(() => this.setState({ isCategoryLoading: false }));
  };

  /**
   * a user selects the BUs they want for a document
   */
  handleChecked = checked => {
    this.setState({
      currentItem: { ...this.state.currentItem, sharedBU: checked }
    });
  };

  /**
   * when a user clicks on a node in the leftmost tree
   */
  handleBUClick = async clicked => {
    //ignore clicks on state and county
    if (clicked.isLeaf) {
      //set currount county
      const value = JSON.parse(clicked.value);
      const { currentBU } = value;
      this.props.setCurrentCounty(currentBU);
      this.setState(
        {
          currentCatItem: INITIAL_CURRENT_CAT_ITEM,
          currentBU
        },
        () => {
          this.structureBUs();
          this.handleMiddleTreeRefresh(currentBU);
          this.updateFilterResults('', this.state.middleTree);
          this.middleFilter.current.clearFilterBy();
        }
      );
    }
  };

  handleMiddleTreeRefresh = async currentBU => {
    try {
      this.setState({ isLoading: true });
      const unfilteredData = await httpDocument.allActiveDocuments(currentBU.businessUnit, this.props.AdminReducer.admin.ID);
      const data = { documents: [] };
      const structuredCategories = [];

      // Get rid of duplicate files if they have the same fileKey or a different countyName
      for (let i = 0; i < unfilteredData.documents.length; i++) {
        if (
          data.documents.filter(item => item.fileKey === unfilteredData.documents[i].fileKey).length === 0 &&
          currentBU.countyName === unfilteredData.documents[i].countyName
        ) {
          data.documents.push(unfilteredData.documents[i]);
        }
      }

      for (let i = 0; i < data.documents.length; i++) {
        const currDoc = data.documents[i];
        const currCat = currDoc.categoryID;
        // if doc has category not in structerdCategories
        // make category and add do to it inside structureCategory
        if (structuredCategories.find(x => x.value === currCat) === undefined) {
          const docToPush = {
            label: currDoc.title,
            value: JSON.stringify(currDoc),
            showCheckbox: false,
            icon: <FaFile size={10} />,
            className: 'itemNode'
          };
          structuredCategories.push({
            label: currDoc.categoryTitle,
            value: currDoc.categoryID,
            children: [docToPush],
            showCheckbox: false,
            className: 'catNode'
          });
        } else if (structuredCategories.find(x => x.value === currCat) !== undefined) {
          // else if doc category == category in structuredCategories
          // add doc to said category
          const catIndex = structuredCategories.findIndex(x => x.value == currCat);
          structuredCategories[catIndex].children.push({
            label: currDoc.title,
            value: JSON.stringify(currDoc),
            showCheckbox: false,
            className: 'itemNode',
            icon: <FaFile size={10} />
          });
        }
      }

      //Sort categories, put other last
      structuredCategories.sort((a, b) => {
        if (a.label === 'Other') return 1;
        else if (b.label === 'Other') return -1;
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      });
      for (let i = 0; i < structuredCategories.length; i++) {
        structuredCategories[i].children.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
      }

      this.setState({ middleTree: structuredCategories, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  handleMiddleTreeClick = clicked => {
    //if clicking on a category
    if (clicked && clicked.isParent) {
      const updatedTree = this.state.middleTree;
      if (!clicked.expanded && clicked.value !== this.state.currentCatItem.id) {
        const item = JSON.parse(clicked.children[0].value);
        this.changeItem(item);
        for (let i = 0; i < updatedTree.length; i++) {
          const currentCategory = updatedTree[i];
          const currentCatItems = currentCategory.children;
          // highlight the parent category
          if (currentCategory.value === clicked.value) {
            currentCategory.className = 'catSelected';
          } else {
            currentCategory.className = 'catNode';
          }
          //go through items in category and highlight
          for (let j = 0; j < currentCatItems.length; j++) {
            let currentItem = currentCatItems[j];
            if (currentItem.value === clicked.children[0].value) {
              currentItem.className = 'itemSelected';
            } else {
              currentItem.className = 'itemNode';
            }
          }
        }
        this.setState({ currentCatItem: { id: clicked.value, label: clicked.label }, middleTree: updatedTree });
      }
    } else {
      //if clicking on a file, update current item
      //NOTE: after changeItem, click create new item, cant click cancel
      const updatedTree = this.state.middleTree;
      for (let i = 0; i < updatedTree.length; i++) {
        const currentCategory = updatedTree[i];
        const currentCatItems = currentCategory.children;
        // highlight the parent category
        if (currentCategory.value === clicked.parent.value) {
          currentCategory.className = 'catSelected';
        } else {
          currentCategory.className = 'catNode';
        }
        //go through items in category and highlight
        for (let j = 0; j < currentCatItems.length; j++) {
          let currentItem = currentCatItems[j];
          if (currentItem.value === clicked.value) {
            currentItem.className = 'itemSelected';
          } else {
            currentItem.className = 'itemNode';
          }
        }
      }
      this.setState({ currentCatItem: { id: clicked.parent.value, label: clicked.parent.label }, middleTree: updatedTree });
      const item = JSON.parse(clicked.value);
      this.changeItem(item);
    }
  };

  handleEditCategory = () => {
    this.setState({ editCats: true });
  };

  updateCategoryName = async () => {
    // get current category id and new category name
    this.setRequiresFetch();
    const { newCatName, currentCatItem, currentBU } = this.state;
    const { FirstName, LastName } = this.props.AdminReducer.admin;
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const mobileSecurityID = currentAdmin.ID;
    const updateItem = {
      businessUnit: currentBU.businessUnit,
      stateCode: currentBU.state,
      newCategoryName: newCatName,
      mobileSecurityID: mobileSecurityID,
      oldCategoryID: currentCatItem.id,
      catType: 'document',
      modifiedBy: FirstName + ' ' + LastName
    };
    try {
      await httpCategory.updateCategory(updateItem);
      toast.dismiss();
      toast.success('Success!', { position: toast.POSITION.BOTTOM_CENTER });
      const { ID } = this.props.AdminReducer.admin;
      // After updating category name, reset all fields and unselect county so clicking on county will refetch items
      this.setState(
        {
          newCatName: '',
          currentCatItem: INITIAL_CURRENT_CAT_ITEM,
          catList: [],
          filteredCatList: []
        },
        () => {
          const county = {
            countyName: 'No County',
            state: 'ZZ',
            id: 'ZZ'
          };
          this.props.setCurrentCounty(county);
          this.handleMiddleTreeRefresh(this.state.currentBU);
        }
      );
    } catch (e) {
      toast.dismiss();
      toast.error('There was an error updating category name', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  // Handle file upload through dropzone
  onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        // do whatever you want with the file content
        this.setState({
          currentItem: {
            ...this.state.currentItem,
            fileString: fileAsBinaryString
          }
        });
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsDataURL(file);
    });
  };

  changeisDownloadable = event => {
    this.setState({
      ...this.state.currentItem,
      isDownloadable: event.target.checked
    });
  };

  //***NO CODE UPDATE REQUIRED - well...unless fixing a bug or adding new functionality  :)***/

  cancelAdd = () => {
    this.setState({ newItem: true, currentItem: INITIAL_CURRENT_ITEM });
    // if (this.state.allItems && this.state.pagedResults) {
    //   this.setState({ currentItem: this.state.pagedResults[0] }, () => {
    //     this.fetchCurrentPDF(this.state.currentItem);
    //   });
    // }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filteredMiddleTree) => {
    this.setState({ searchText, filteredMiddleTree });
  };

  updateBUTreeFilterResults = (searchBUTreeText, filteredSortedBUCategories) => {
    this.setState({ searchBUTreeText, filteredSortedBUCategories });
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    // If editing categories
    if (this.state.editCats) {
      return;
    }
    let currentPageNumber = this.state.activePage - 1;
    let catFilteredAllItems = this.state.allItems || [];
    let catFilteredFilterResults = this.state.filterResult || [];
    // if category is selected, filter by category first
    if (!isEmpty(this.state.catSelected)) {
      catFilteredAllItems = catFilteredAllItems.filter(x => x.category.id === this.state.catSelected.catID);

      // if there are filtered results, fill it with cat filtered results, otherwise leave alone
      if ((catFilteredFilterResults && catFilteredFilterResults.length > 0) || this.state.searchText.length > 0) {
        catFilteredFilterResults = catFilteredFilterResults.filter(x => x.category.id === this.state.catSelected.catID);
      }
    }

    let arr =
      (this.state.filterResult && this.state.filterResult.length > 0) || this.state.searchText.length > 0
        ? catFilteredFilterResults
        : catFilteredAllItems;
    let groups = [],
      i;
    for (i = 0; i < arr.length; i += this.state.pageSize) {
      groups.push(arr.slice(i, i + this.state.pageSize));
    }

    this.setState(
      {
        pagedResults: groups[currentPageNumber],
        totalItemsCount: groups.length
      },
      () => {
        // modifiedBU is used for sharedBU to populate BU select tree, originalBU is used to compare to modifiedBU to get added/removedBU
        if (
          groups[currentPageNumber] &&
          groups[currentPageNumber].length > 0 &&
          groups[currentPageNumber].length > this.state.currentIndex &&
          this.state.pagedResults
        ) {
          const sharedBU = this.prepSharedBUs(groups[currentPageNumber][this.state.currentIndex].sharedBU);
          const modifiedBU = sharedBU[0]; //0 - modifiedBU
          const originalBU = sharedBU[1]; //1 - originalBU
          this.setState({
            currentItem: {
              ...groups[currentPageNumber][this.state.currentIndex],
              sharedBU: modifiedBU
            },
            originalBU
          });
          if (this.state.currentItem.id !== groups[currentPageNumber][this.state.currentIndex].id) {
            this.props.fetchCurrentPDF(groups[currentPageNumber][this.state.currentIndex].id);
          }
        } else {
          this.clearCurrentItem();
        } // if nothing on the page, clear
      }
    );
  };

  changeItem = async item => {
    const buArrays = this.prepSharedBUs(item.sharedBU);
    item.sharedBU = buArrays[0];
    this.setState({
      currentItem: item,
      originalBU: buArrays[1],
      newItem: false,
      pageNumber: 1,
      editCats: false
    });
    try {
      const rawPDF = await httpDocument.fetchCurrentPDF(item.id);
      // convert base64 to blob to work on IE.
      const currentPDF = this.pdfBlobConvesion(rawPDF, 'pdf');
      this.setState({ currentPDF });
    } catch (e) {
      toast.dismiss();
      toast.error('Failed to load PDF, please try again');
    }
  };

  prepSharedBUs = sharedBU => {
    let originalBU = [];
    let modifiedBU = [];
    if (sharedBU.length > 0) {
      for (let i = 0; i < sharedBU.length; i++) {
        //parse the sharedBU
        let parsedObj = JSON.parse(sharedBU[i]);
        originalBU.push(parsedObj);
        //make new array without id, to be used in tree
        const { id, ...tempObj } = parsedObj;
        modifiedBU.push(JSON.stringify(tempObj));
      }
    }
    const result = [modifiedBU, originalBU];
    return result;
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/
  render() {
    const { pageNumber, numPages } = this.state;

    const showDeleteModal = this.state.deleteConfirmation || this.state.categoryDeleteConfirmation;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Documents" />
        <div className="Docu-content" style={{ opacity: showDeleteModal === true ? '0.3' : '1' }}>
          <div className="left-container">
            <SearchBUTreeFilter
              updateFilterResults={this.updateBUTreeFilterResults}
              searchText={this.state.searchBUTreeText}
              filters={this.state.searchBUTreeFilters}
              data={this.state.sortedBUCategories}
              placeholder={this.state.searchBUTreePlaceholder}
            />
            <div className="state-list-container">
              <div>
                <MultiBUSelect
                  onClick={this.handleBUClick}
                  nodes={this.state.searchBUTreeText.trim().length > 0 ? this.state.filteredSortedBUCategories : this.state.sortedBUCategories}
                  expandOnClick
                  showExpandAll={
                    this.state.searchBUTreeText.trim().length > 0
                      ? this.state.filteredSortedBUCategories.length > 0
                      : this.state.sortedBUCategories.length > 0
                  }
                />
                {(this.state.sortedBUCategories.length === 0 ||
                  (this.state.searchBUTreeText.trim().length > 0 && this.state.filteredSortedBUCategories.length === 0)) && (
                    <EmptyState title="Nothing Here Yet" subTitle="" />
                  )}
              </div>
            </div>
          </div>

          <div className="middle-container">
            <SearchBUTreeFilter
              ref={this.middleFilter}
              updateFilterResults={this.updateFilterResults}
              searchText={this.state.searchText}
              filters={['label']}
              data={this.state.middleTree}
              placeholder={this.state.searchPlaceholder}
            />
            <div className="list-container">
              <Loadable active={this.state.isLoading} spinner text="Loading...">
                <div>
                  <MultiBUSelect
                    onClick={this.handleMiddleTreeClick}
                    nodes={this.state.searchText.trim().length > 0 ? this.state.filteredMiddleTree : this.state.middleTree}
                    expandOnClick
                    showExpandAll={
                      this.state.searchText.trim().length > 0 ? this.state.filteredMiddleTree.length > 0 : this.state.middleTree.length > 0
                    }
                    onEdit={this.handleEditCategory}
                    onExpand={() => { }}
                  />
                  {(this.state.middleTree.length === 0 ||
                    (this.state.searchText.trim().length > 0 && this.state.filteredMiddleTree.length === 0)) && (
                      <EmptyState title="Nothing Here Yet" subTitle="" />
                    )}
                </div>
              </Loadable>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              {(this.state.editCats || !this.state.newItem) && (
                <button
                  disabled={showDeleteModal === true ? true : false}
                  className="New-Message"
                  style={{ display: 'flex' }}
                  href="#"
                  onClick={() => this.clearCurrentItem()}
                >
                  <MdAdd size={23} className="icon-add" />
                  New Document
                </button>
              )}
              {!this.state.editCats && this.state.newItem && (
                <Fragment>
                  <button
                    // disabled={!this.state.allItems.length > 0}
                    className="Cancel"
                    href="#"
                    style={{ display: this.state.newItem ? 'flex' : 'none' }}
                    onClick={() => {
                      this.cancelAdd();
                    }}
                  >
                    Cancel
                  </button>
                  <button className="Create-btn" href="#" style={{ display: this.state.newItem ? 'flex' : 'none' }} onClick={this.addNewItem}>
                    Submit
                  </button>
                </Fragment>
              )}
            </div>
            <div className="form-container">
              {/* Don't show if editCats is true */}
              {!this.state.editCats && (
                <Fragment>
                  <div className="title-container">
                    {this.state.newItem ? (
                      <p className="title-content">Add New Document</p>
                    ) : (
                      <p className="title-content">
                        {' '}
                        Created on{' '}
                        {moment
                          .utc(this.state.currentItem.createdDate)
                          .local()
                          .format('MM/DD/YYYY [at] h:mm:ss a')}{' '}
                        by {this.state.currentItem.firstName} {this.state.currentItem.lastName}{' '}
                      </p>
                    )}

                    <button
                      className="btn-long"
                      disabled={showDeleteModal === true || this.state.isButtonDisabled}
                      onClick={this.updateCurrentItem}
                      style={{
                        display: this.state.newItem === false ? 'flex' : 'none'
                      }}
                    >
                      Update
                    </button>
                    <div className="title-btn">
                      {!this.state.newItem && (
                        <button
                          disabled={showDeleteModal === true ? true : false}
                          className="btn-long-ghost delete"
                          onClick={() => {
                            this.setState({ deleteConfirmation: true });
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="form-container-input">
                    <div className="form-group">
                      <div className="form-group-stacked">
                        <span className="form-group-stacked-half-label">Title</span>
                        <input
                          maxLength={100}
                          //disabled={!this.state.pageEditable && !this.state.newItem}
                          className="form-group-text"
                          type="text"
                          onChange={event =>
                            this.setState({
                              currentItem: {
                                ...this.state.currentItem,
                                title: event.target.value
                              }
                            })
                          }
                          value={this.state.currentItem.title === '' ? '' : this.state.currentItem.title}
                          placeholder={this.state.currentItem.title === '' ? 'Document Title' : ''}
                        />
                      </div>
                    </div>
                    {/* <MultiSelect label="Multiselect" jobTitles={this.state.jobTitles} multiselect={this.selectJobTitles} value={this.state.currentItem.jobTitles} /> */}
                    <div
                      className="section"
                      style={{
                        marginBottom: '2rem',
                        width: 'calc(100% - 1.5rem)'
                      }}
                    >
                      <CreateSelect
                        onSelectChange={this.selectCategory}
                        options={this.state.categories}
                        onInputChange={this.selectCategory}
                        onNewOption={this.handleCreateCategory}
                        isLoading={this.state.isCategoryLoading}
                        onDeleteOption={this.checkCategoryQuantity}
                        value={this.state.currentItem.category}
                      />
                    </div>
                    <div>
                      <label className="checkbox-group downloadable">
                        <span>PDF Downloadable</span>
                        <input
                          type="checkbox"
                          className="checkbox-group-input"
                          readOnly={true}
                          checked={this.state.currentItem.isDownloadable}
                          onClick={() => {
                            this.setState(prevState => ({
                              currentItem: { ...prevState.currentItem, isDownloadable: !prevState.currentItem.isDownloadable }
                            }));
                          }}
                        />
                        <span className="checkbox-group-checkmark" />
                      </label>
                    </div>
                    {!this.state.currentItem.fileString && this.state.newItem && (
                      <div className="doc-group">
                        <span>Click to Upload or Drag File Here</span>
                        <Dropzone onDrop={this.onDrop} accept="application/pdf" maxSize={this.state.maxSize}>
                          {({ isDragActive, isDragReject, rejectedFiles }) => {
                            if (isDragActive) {
                              return 'This file is authorized';
                            }
                            if (isDragReject) {
                              return 'This file is not authorized';
                            }
                            if (rejectedFiles && rejectedFiles.length) {
                              toast.dismiss();
                              toast.error('PDF uploads are limited to 20 MB in size, please try again', { position: 'bottom-center' });
                              rejectedFiles.length = 0;
                            }
                          }}
                        </Dropzone>
                      </div>
                    )}
                    <div className="stateContainer">
                      <Collapsiple trigger={'Choose Business Units'}>
                        <MultiBUSelect nodes={this.state.sortedBUs} onCheck={this.handleChecked} checked={this.state.currentItem.sharedBU} />
                      </Collapsiple>
                    </div>
                    <div
                      style={{
                        display: this.state.newItem && this.state.currentItem.fileString !== '' ? 'flex' : 'none'
                      }}
                    >
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={1}
                        totalItemsCount={numPages}
                        pageRangeDisplayed={5}
                        onChange={this.handlePDFPageChange}
                      />
                      <button
                        className="doc-reset"
                        href="#"
                        style={{
                          display: this.state.newItem && this.state.currentItem.fileString !== '' ? 'flex' : 'none'
                        }}
                        onClick={this.clearCurrentItem}
                      >
                        Reset
                      </button>
                    </div>
                    {this.state.currentItem.fileString || this.state.currentItem.fileURL ? (
                      <div>
                        {this.state.numPages > 1 && !this.state.newItem ? (
                          <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={1}
                            totalItemsCount={numPages}
                            pageRangeDisplayed={10}
                            onChange={this.handlePDFPageChange}
                          />
                        ) : null}
                        <Document
                          file={this.state.newItem && this.state.currentItem ? this.state.currentItem.fileString : this.state.currentPDF}
                          onLoadSuccess={this.onDocumentLoad.bind(this)}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </div>
                    ) : null}
                  </div>
                </Fragment>
              )}
              {/* Show if editing category */}
              {this.state.editCats && this.state.currentCatItem.label && (
                <div className="title-container" style={{ flexDirection: 'column' }}>
                  <p className="rename-title">
                    Current Business Unit: {this.state.currentBU.businessUnit + ' - ' + this.state.currentBU.businessUnitName}
                  </p>
                  <p className="rename-title">Rename Category '{this.state.currentCatItem.label}'</p>
                  <div className="form-container-input rename-container">
                    <div className="form-group">
                      <div className="rename-category">
                        <input
                          maxLength={100}
                          className="form-group-text"
                          type="text"
                          onChange={event => this.setState({ newCatName: event.target.value })}
                          value={this.state.newCatName}
                          placeholder="New Category Name"
                        />
                        <button className="Create-btn" onClick={this.updateCategoryName}>
                          Update Category Name
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="delete-item-modal-container"
          style={{
            display: this.state.deleteConfirmation === true ? 'block' : 'none'
          }}
        >
          <span>Are you sure you want to delete this document?</span>
          <div className="delete-item-modal-container__buttons">
            <button className="delete-item-modal-container__delete" onClick={this.deleteCurrentItem}>
              Delete
            </button>
            <button
              className="delete-item-modal-container__cancel"
              onClick={() => {
                this.setState({ deleteConfirmation: false });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          className="delete-item-modal-container"
          style={{
            display: this.state.categoryDeleteConfirmation === true ? 'block' : 'none'
          }}
        >
          <span>Are you sure you want to delete the category "{this.state.categoryToDelete.catTitle}"?</span>
          <div className="delete-item-modal-container__buttons">
            <button className="delete-item-modal-container__delete" onClick={this.handleDeleteCategory}>
              Delete
            </button>
            <button
              className="delete-item-modal-container__cancel"
              onClick={() => {
                this.setState({ categoryDeleteConfirmation: false });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    DocumentsReducer: state.DocumentsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActiveDocuments: (businessUnitID, mobileSecurityID) => dispatch(allActiveDocuments(businessUnitID, mobileSecurityID)),
    postNewDocument: params => dispatch(postNewDocument(params)),
    updateDocument: params => dispatch(updateDocument(params)),
    deleteDocument: (fileKey, businessUnitID, mobileSecurityID) => dispatch(deleteDocument(fileKey, businessUnitID, mobileSecurityID)),
    fetchCurrentPDF: file => dispatch(fetchCurrentPDF(file)),
    postNewCategory: params => dispatch(postNewCategory(params)),
    fetchAllCategories: () => dispatch(fetchAllCategories()),
    fetchCategoriesPerBU: id => dispatch(fetchCategoriesPerBU(id)),
    deleteCategory: (id, type, modifiedBy, mobileSecurityID) => dispatch(deleteCategory(id, type, modifiedBy, mobileSecurityID)),
    fetchAllJobTitles: () => dispatch(fetchAllJobTitles()),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentCounty: county => dispatch(setCurrentCounty(county)),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
