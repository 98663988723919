import React, { Component, useRef, useEffect } from 'react';

//css
import './TableauEmbed.css';

const { tableau } = window;

function TableauEmbed() {
  const ref = useRef(null);
  const url = 'https://tableau.amr.net/t/AMR/views/CertificationAudit/Summary';

  function initViz() {
    new tableau.Viz(ref.current, url);
  }

  useEffect(() => {
    initViz();
  }, []);

  return (
    <div>
      <div ref={ref}></div>
      <p>
        <a className="tableau" href="https://tableau.amr.net/#/site/AMR/views/CertificationAudit/Summary?:iid=1" target="_blank">
          Certification Audit!
        </a>
      </p>
    </div>
  );
}
export default TableauEmbed;
