//react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdSearch } from 'react-icons/lib/md';
import Loadable from 'react-loading-overlay';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { DebounceInput } from 'react-debounce-input';
//css
import './Search.css';
import 'react-toastify/dist/ReactToastify.css';

//components
import Heading from '../../../components/Heading/Heading';
import ToastContainerComponent from '../../../components/ToastContainerComponent/ToastContainerComponent';

//Redux
import { connect } from 'react-redux';
import httpCDM from '../../../httpClient/CDM.js';
import { getFilterValues, searchEmployees, searchTerminatedEmployees, clearSearchResults, setSelectedEmployee, getEmployee, getTerminatedEmployee } from './../../../redux/modules/CDM';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      searchText: '', 
      currentAdmin: JSON.parse(sessionStorage.getItem('admin')), 
      historicalEmployeeData: false 
    };

    const { lookupValues } = this.props.CDMReducer;

    if (lookupValues.length === 0) {
      this.props.getFilterValues();
    }

    //clear search results by default
    this.props.clearSearchResults();
  }

  componentDidMount = async () => {
    let eCardMapping = await httpCDM.getEcardMapping('ECARD', 'AHA');
    localStorage.setItem('ecardMapping', JSON.stringify(eCardMapping));
    let agencies = await httpCDM.getAgencies();
    agencies.AgencyValues = sortBy(agencies.AgencyValues, ['Description']);
    localStorage.setItem('agencies', JSON.stringify(agencies));
    localStorage.setItem('historicalEmployeeData', false);
  };

  searchEmployees = (event, isViewing) => {
    const { currentAdmin, historicalEmployeeData } = this.state;
    const { searchEmployees, getEmployee, searchTerminatedEmployees, getTerminatedEmployee } = this.props;
    const isViewingHistoricalEmployeeData = isViewing !== undefined ? isViewing : historicalEmployeeData;
    let searchText = event && event.target.value ? event.target.value : this.state.searchText;
    this.setState({ searchText });
    let businessUnits = JSON.parse(localStorage.getItem('businessUnits'));
    let buIDs = [];
    if (businessUnits !== null && businessUnits.length > 0) {
      for (let bu in businessUnits) {
        buIDs.push(businessUnits[bu].businessUnit);
      }
      if (searchText.length > 1) {
        searchText = searchText.replace(/\s+/g, '');
        if (isViewingHistoricalEmployeeData) {
          searchTerminatedEmployees(searchText, currentAdmin.OracleEmpNum);
        } else {
          searchEmployees(searchText, currentAdmin.OracleEmpNum);
          getEmployee(searchText);
        }
      } else {
        this.props.clearSearchResults();
      }
    }
  };

  setCurrentEmployee = (employee) => {
    this.props.setSelectedEmployee(employee);
  };

  isEmployeeTerminated = (employee) => {
    return employee.EmployeeFlag == 'N' ? true : false;
  };

  handleHistoricalEmployeeToggle = () => {
    localStorage.setItem('historicalEmployeeData', !this.state.historicalEmployeeData);
    this.setState({ historicalEmployeeData: !this.state.historicalEmployeeData })
    this.searchEmployees(null, !this.state.historicalEmployeeData);
  }

  render() {
    const { employees, isLoading, error, selectedEmployeeInfo, historicalEmployeeData } = this.props.CDMReducer;
    const { searchText } = this.state;

    if (error && error.response) {
      //let message = error.response.data.error.message;
      //toast.error(message, { position: 'bottom-center' });
    }

    return (
      <div className="cdm-search">
        <ToastContainerComponent />

        <Heading title="CDM" stage="employee-search" />
        <div style={{ display: 'flex', paddingBottom: 10, flexDirection: 'column', alignItems: 'flex-end' }}>
          <div style={{ color: '#fff' }}>Search Historical Employees</div>
          <div>
            <label class="switch">
              <input 
                type="checkbox"
                name="historical-employee-data"
                onChange={this.handleHistoricalEmployeeToggle}
                value={historicalEmployeeData}
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div className="search-bar">
          <DebounceInput
            className="searchbox"
            value={searchText}
            debounceTimeout={1500}
            onChange={this.searchEmployees}
            placeholder="Search by first name, last name, employee ID or email"
          />
          <MdSearch size={25} color="grey" className="icon-search" />
        </div>

        {searchText.length > 1 && <div className="search-count">Total Results: {employees.length}</div>}

        <Loadable active={isLoading} spinner text="Loading...">
          <div>
            {searchText.length > 1 && employees && (
              <div>
                {employees.map((employee, index) => (
                  <Link
                    to={this.isEmployeeTerminated(employee) ? '' : '/CDM/EmployeeDetail'}
                    key={index}
                    onClick={
                      this.isEmployeeTerminated(employee)
                        ? (e) => e.preventDefault()
                        : () => {

                          this.setCurrentEmployee(employee);
                        }
                    }
                    className={this.isEmployeeTerminated(employee) ? 'disabled' : ''}
                  >
                    <div key={employee.OracleEmpNum} className="search-row">
                      <div className="row-employee-ID"> {employee.OracleEmpNum}</div>
                      <div className="row-employee-name">{employee.LastName + ', ' + employee.FirstName}</div>
                      <div className="row-employee-title">{employee.JobTitle}</div>
                      <div className="row-employee-location">{employee.BusUnit}</div>
                      <div className="row-employee-location">{employee.BU_Name}</div>
                      <div className="row-employee-termination-status">{this.isEmployeeTerminated(employee) ? 'Terminated' : ''}</div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </Loadable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    CDMReducer: state.CDMReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterValues: () => dispatch(getFilterValues()),
    searchEmployees: (searchText, adminID) => dispatch(searchEmployees(searchText, adminID)),
    searchTerminatedEmployees: (searchText, adminID) => dispatch(searchTerminatedEmployees(searchText, adminID)),
    clearSearchResults: () => dispatch(clearSearchResults()),
    setSelectedEmployee: (employee) => dispatch(setSelectedEmployee(employee)),
    getEmployee: (OracleEmpNum) => dispatch(getEmployee(OracleEmpNum)),
    getTerminatedEmployee: (OracleEmpNum) => dispatch(getTerminatedEmployee(OracleEmpNum))
  };
};

export default Search = connect(mapStateToProps, mapDispatchToProps)(Search);
