//react
import React, { Component } from 'react';
import Select from 'react-select';

//css
import './CountiesComponent.css';

//Redux
import { connect } from 'react-redux';
import { adminSettings, setCurrentCounty } from './../../redux/modules/Admin';

const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', borderColor: 'white', borderRadius: '5px', height: '1rem' }),
  singleValue: (styles, state) => ({ ...styles, color: 'white', display: state.selectProps.menuIsOpen ? 'none' : 'block', fontSize: '13.3333px' }),
  input: styles => ({ ...styles, color: 'white' }),
  option: styles => ({ ...styles, fontSize: '13.3333px' })
};

class CountiesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { businessUnits: [] };
  }

  componentWillMount() {
    let businessUnits = JSON.parse(localStorage.getItem('businessUnits'));

    if (businessUnits == null || businessUnits.length === 0) {
      let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
      if (currentAdmin) {
        this.props.adminSettings(currentAdmin.ID).then(() => {
          this.sortBusinessUnits();
        });
      }
    } else {
      this.sortBusinessUnits();
    }
  }

  sortBusinessUnits = () => {
    let businessUnits = JSON.parse(localStorage.getItem('businessUnits'));
    this.setState({ businessUnits });
    const currentCounty = this.props.AdminReducer.currentCounty || '';

    // if (
    //   (this.props.AdminReducer.currentCounty == null &&
    //     businessUnits != null &&
    //     businessUnits.length > 0) ||
    //   currentCounty.county === "No County"
    // ) {
    if (businessUnits && businessUnits.length > 0 && (this.props.AdminReducer.currentCounty == null || currentCounty.countyName === 'No County')) {
      this.props.setCurrentCounty(businessUnits[0]);
    }
  };

  onCountySelect = e => {
    const businessUnits = JSON.parse(localStorage.getItem('businessUnits'));
    const index = businessUnits.findIndex(bu => bu.id == e.id);
    this.props.setCurrentCounty(businessUnits[index]);
  };

  render() {
    const currentCounty = this.props.AdminReducer.currentCounty || '';
    const { currentPath } = this.props.AdminReducer;
    if (
      currentPath === 'documents' ||
      currentPath === 'selfservice' ||
      currentPath === 'contacts' ||
      currentPath === 'protocols' ||
      currentPath === 'notifications'
    ) {
      return null;
    }

    //if get to dashboard and no county is set, make first county selected
    if (this.props.AdminReducer.currentPath === 'dashboard' && currentCounty.countyName === 'No County') {
      this.sortBusinessUnits();
    }

    return (
      <div>
        {this.state.businessUnits && this.state.businessUnits.length > 0 && (
          <div className="Counties">
            <Select
              styles={selectStyles}
              onChange={this.onCountySelect}
              options={this.state.businessUnits}
              value={currentCounty}
              getOptionLabel={option => `${option.businessUnit} - ${option.county}, ${option.state}`}
              getOptionValue={option => option.id}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    adminSettings: adminID => dispatch(adminSettings(adminID)),
    setCurrentCounty: county => dispatch(setCurrentCounty(county))
  };
};

export default CountiesComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountiesComponent);
