//react
import React, { Component } from 'react';

//css
import './DashboardModule.css';

class DashboardModule extends Component {
  render() {
    return (
      <div className="moduleOption">
        <img src={this.props.icon} alt="moduleIcon" className="moduleIcon" />
        <h4 className="moduleTitle">{this.props.title}</h4>
        <div className="moduleDescription">{this.props.description}</div>
        <div className="moduleOptionLink" href="#">
          OPEN
        </div>
      </div>
    );
  }
}

export default DashboardModule;
