import React, { Component } from 'react';
// libraries
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RestApi from '../../httpClient/RestApi';

// redux state
import { connect } from 'react-redux';
import { setCurrentCounty, setCurrentPath } from './../../redux/modules/Admin';
// components
import Heading from '../../components/Heading/Heading';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
import SearchBUTreeFilter from '../../components/SearchFilter/SearchBUTreeFilter';
import EmptyState from '../../components/EmptyState/EmptyState';
import MultiBUSelect from '../../components/MultiBUSelect/MultiBUSelect';
import Checkbox from '../../components/Checkbox/Checkbox';
import { stateCodes } from '../../assets/stateCodesToName';

const INITIAL_STATE = {
  searchBUTreeText: '',
  filterResult: [],
  searchText: '',
  currentItem: {
    message: '',
    sharedBU: [],
    sendDate: moment()
      .utc()
      .format()
  },
  sortedBUs: [],
  sortedBUCategories: [],
  filteredSortedBUCategories: [],
  sendToAllUsers: false,
  viewSendToAll: false
};

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  handleCheckboxChange = (event) => {
    this.setState({ sendToAllUsers: event.target.checked });
  };
  componentDidMount() {
    this.props.setCurrentPath('notifications');
    // delete currentCounty so clicking on any county will fetch
    const county = {
      countyName: 'No County',
      state: 'ZZ',
      id: 'ZZ'
    };
    this.props.setCurrentCounty(county);
    this.structureCounties(this.props.AdminReducer.admin.ID);
    //get security Roles
    this.getSecurityRoles();
  }

  getSecurityRoles() {
    let securityRoles = JSON.parse(localStorage.getItem('securityRoles'));
    if (securityRoles[0].roleName == 'ADMIN-FULL') {
      this.setState({ viewSendToAll: true });
    }
  }
  // Structure BUs by state and county
  structureCounties() {
    const businessUnits = JSON.parse(localStorage.getItem('businessUnits'));
    const sortedBUs = [];
    for (let i = 0; i < businessUnits.length; i++) {
      let currentBU = businessUnits[i];
      let currentState = currentBU.state;
      let currentCounty = currentBU.countyName;
      let currentCountyId = currentBU.countyId;
      // Populate states
      if (sortedBUs.find((x) => x.value === currentState) === undefined) {
        const state = stateCodes[currentState] || currentState;
        sortedBUs.push({ value: currentState, label: state, children: [] });
      }

      // Populate counties
      let stateIndex = sortedBUs.findIndex((x) => x.value === currentState);
      if (sortedBUs[stateIndex].children.find((x) => x.value === currentCountyId) === undefined) {
        sortedBUs[stateIndex].children.push({ value: currentCountyId, label: currentCounty });
      }
    }
    // Sort alphabetically by state, then county
    sortedBUs.sort((a, b) => {
      if (a.value > b.value) return 1;
      if (a.value < b.value) return -1;
      if (a.children.value > b.children.value) return 1;
      if (a.children.value < b.children.value) return -1;
      return 0;
    });
    this.setState({ sortedBUs });
  }

  handleChecked = (checked) => {
    this.setState({
      currentItem: { ...this.state.currentItem, sharedBU: checked }
    });
  };

  setSendDate = (newSendDate) => {
    this.setState({
      currentItem: { ...this.state.currentItem, sendDate: newSendDate.utc().format() }
    });
  };

  addNewItem = async () => {
    try {
      await this.validateAllRequiredFields();
      const { message, sharedBU, sendDate } = this.state.currentItem;
      let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
      let newItem = {
        mobileSecurityID: currentAdmin.ID,
        message,
        counties: sharedBU.map(Number),
        sendDate
      };
      const api = new RestApi();
      await api.POST('/notificationSubscriptions?sendToAllUsers=' + encodeURIComponent(this.state.sendToAllUsers), newItem);
      this.setState((prevState) => ({
        ...prevState,
        currentItem: {
          message: '',
          sharedBU: [],
          sendDate: moment()
            .utc()
            .format()
        }
      }));
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } catch (e) {
      toast.dismiss();
      toast.error('Failed to send notification! ' + e, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      if (e && typeof e !== 'undefined') {
        console.log(e);
      } else {
        console.log('Exception is null or undefined');
      }
    }
  };

  validateAllRequiredFields = () => {
    return new Promise((resolve, reject) => {
      const { message, sharedBU, sendDate } = this.state.currentItem;
      if (!message) {
        reject('Please enter a message for your notification');
      }
      if (!sendDate) {
        reject('Please choose a time for your notification');
      }
      if (sharedBU.length === 0 && !this.state.sendToAllUsers) {
        reject('Please choose the counties that will receive this notification');
      }
      resolve();
    });
  };

  onCheck() {
    const { value, onCheck } = this.props;

    onCheck({ value, checked: this.getCheckState({ toggle: true }) });
  }

  onClick() {
    const { expandOnClick, isParent, value, onClick } = this.props;

    // Auto expand if enabled
    if (isParent && expandOnClick) {
      this.onExpand();
    }

    onClick({ value, checked: this.getCheckState({ toggle: false }) });
  }
  updateBUTreeFilterResults = (searchBUTreeText, filteredSortedBUCategories) => {
    this.setState({ searchBUTreeText, filteredSortedBUCategories });
  };

  render() {
    const { currentItem } = this.state;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Notifications" />
        <div className="GeoNoti-content">
          <div className="left-container">
            <SearchBUTreeFilter
              updateFilterResults={this.updateBUTreeFilterResults}
              searchText={this.state.searchBUTreeText}
              filters={['label']}
              data={this.state.sortedBUs}
              placeholder={'Filter by State or County'}
            />
            <div className="state-list-container">
              {/* <div style={{ marginLeft: 8 }}>
                <div className="form-group-text">
                  {this.state.viewSendToAll ? (
                    <div style={{ fontFamily: 'system-ui' }}>
                      <label>
                        <Checkbox checked={this.state.sendToAllUsers} onChange={this.handleCheckboxChange} />
                        <span style={{ marginLeft: 8 }}>Send to All Users</span>
                      </label>
                    </div>
                  ) : null}
                </div>
              </div> */}
              <div>
                {/* {!this.state.sendToAllUsers ? ( */}
                <MultiBUSelect
                  nodes={this.state.searchBUTreeText.trim().length > 0 ? this.state.filteredSortedBUCategories : this.state.sortedBUs}
                  onCheck={this.handleChecked}
                  onExpand={() => { }}
                  checked={currentItem.sharedBU}
                  showExpandAll={
                    this.state.searchBUTreeText.trim().length > 0 ? this.state.filteredSortedBUCategories.length > 0 : this.state.sortedBUs.length > 0
                  }
                />
                {/* ) : null} */}
                {(this.state.sortedBUs.length === 0 ||
                  (this.state.searchBUTreeText.trim().length > 0 && this.state.filteredSortedBUCategories.length === 0)) && (
                    <EmptyState title="Nothing Here Yet" subTitle="" />
                  )}
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              <button className="Create-btn" href="#" style={{ display: 'flex' }} onClick={() => this.addNewItem()}>
                Submit
              </button>
            </div>
            <div className="form-container">
              <div className="title-container">
                <p className="title-content">Create New Notification</p>
              </div>

              <div className="form-container-input">
                <div className="form-group" id="specialformgroup">
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">Send Date *</span>
                    <DatePicker
                      className="form-group-text"
                      selected={moment(currentItem.sendDate)}
                      onChange={this.setSendDate}
                      showTimeSelect
                      timeFormat="h:mm"
                      timeIntervals={1}
                      dateFormat="MMMM DD YYYY h:mm A"
                      timeCaption="time"
                      minDate={moment()}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked-textarea">
                    <span className="form-group-stacked-textarea-label">Message *</span>
                    <textarea
                      maxLength={500}
                      className="form-group-textarea"
                      placeholder={'Write Message Here...'}
                      value={currentItem.message || ''}
                      onChange={(event) =>
                        this.setState({
                          currentItem: {
                            ...currentItem,
                            message: event.target.value
                          }
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCounty: (county) => dispatch(setCurrentCounty(county)),
    setCurrentPath: (currentPath) => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
