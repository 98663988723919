import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !sessionStorage.getItem('admin') ? <Component {...props} /> : <Redirect to={{ pathname: '/Dashboard', state: { from: props.location } }} />
    }
  />
);
