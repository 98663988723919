//react
import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { MdExpandMore, MdLock } from 'react-icons/lib/md';
import Loadable from 'react-loading-overlay';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import uuid from 'react-uuid';

//css
import './EmployeeDetail.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

//components
import Heading from '../../../components/Heading/Heading';
import Sidepanel from '../../../components/Sidepanel/Sidepanel';
import ToastContainerComponent from '../../../components/ToastContainerComponent/ToastContainerComponent';
import Accordian from '../../../components/Accordian/Accordian';

//Redux
import { connect } from 'react-redux';

import httpCDM from '../../../httpClient/CDM.js';
import {
  employeeCerts,
  updateEmployee,
  clearError,
  postNewCert,
  updateCert,
  resetDefaultValues,
  setSelectedEmployee,
  eVerifyCert,
  getStateOrCounties,
  auditVerification,
  regulatoryApproval,
  addNewUpdateLog,
  setCertificationStatus,
  getEmployee,
  historicalEmployeeCerts,
  searchEmployees,
  searchTerminatedEmployees,
  setManualVerificationStatus,
  terminatedEmployeeCerts
} from './../../../redux/modules/CDM';
import { adminSettings, setCurrentPath } from '../../../redux/modules/Admin';

class EmployeeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titles: null,
      OracleEmpNum: null,
      isLoading: false,
      panelVisible: false,
      currentSelectedEmployee: null,
      employeeStatus: null,
      mobileStatus: null,
      employeeEmailAddress: null,
      employeeIsErt: null,
      employeeErtStatus: null,
      employeeFuntionErt: null,
      addCert: false,
      currentCert: null,
      lookupValues: [],
      documentTypes: [],
      covidManufacturers: [],
      countyValues: [],
      certStatusValues: null,
      allCountyValues: null,
      auditVerifiation: null,
      eVerifyStatusValues: null,
      eVerifyStates: null,
      covidDose: 1,
      stateValues: [
        { name: 'ALABAMA', code: 'AL' },
        { name: 'ALASKA', code: 'AK' },
        { name: 'AMERICAN SAMOA', code: 'AS' },
        { name: 'ARIZONA', code: 'AZ' },
        { name: 'ARKANSAS', code: 'AR' },
        { name: 'CALIFORNIA', code: 'CA' },
        { name: 'COLORADO', code: 'CO' },
        { name: 'CONNECTICUT', code: 'CT' },
        { name: 'DELAWARE', code: 'DE' },
        { name: 'DISTRICT OF COLUMBIA', code: 'DC' },
        { name: 'FEDERATED STATES OF MICRONESIA', code: 'FM' },
        { name: 'FLORIDA', code: 'FL' },
        { name: 'GEORGIA', code: 'GA' },
        { name: 'GUAM', code: 'GU' },
        { name: 'HAWAII', code: 'HI' },
        { name: 'IDAHO', code: 'ID' },
        { name: 'ILLINOIS', code: 'IL' },
        { name: 'INDIANA', code: 'IN' },
        { name: 'IOWA', code: 'IA' },
        { name: 'KANSAS', code: 'KS' },
        { name: 'KENTUCKY', code: 'KY' },
        { name: 'LOUISIANA', code: 'LA' },
        { name: 'MAINE', code: 'ME' },
        { name: 'MARSHALL ISLANDS', code: 'MH' },
        { name: 'MARYLAND', code: 'MD' },
        { name: 'MASSACHUSETTS', code: 'MA' },
        { name: 'MICHIGAN', code: 'MI' },
        { name: 'MINNESOTA', code: 'MN' },
        { name: 'MISSISSIPPI', code: 'MS' },
        { name: 'MISSOURI', code: 'MO' },
        { name: 'MONTANA', code: 'MT' },
        { name: 'NEBRASKA', code: 'NE' },
        { name: 'NEVADA', code: 'NV' },
        { name: 'NEW HAMPSHIRE', code: 'NH' },
        { name: 'NEW JERSEY', code: 'NJ' },
        { name: 'NEW MEXICO', code: 'NM' },
        { name: 'NEW YORK', code: 'NY' },
        { name: 'NORTH CAROLINA', code: 'NC' },
        { name: 'NORTH DAKOTA', code: 'ND' },
        { name: 'NORTHERN MARIANA ISLANDS', code: 'MP' },
        { name: 'OHIO', code: 'OH' },
        { name: 'OKLAHOMA', code: 'OK' },
        { name: 'OREGON', code: 'OR' },
        { name: 'PALAU', code: 'PW' },
        { name: 'PENNSYLVANIA', code: 'PA' },
        { name: 'PUERTO RICO', code: 'PR' },
        { name: 'RHODE ISLAND', code: 'RI' },
        { name: 'SOUTH CAROLINA', code: 'SC' },
        { name: 'SOUTH DAKOTA', code: 'SD' },
        { name: 'TENNESSEE', code: 'TN' },
        { name: 'TEXAS', code: 'TX' },
        { name: 'UTAH', code: 'UT' },
        { name: 'VERMONT', code: 'VT' },
        { name: 'VIRGIN ISLANDS', code: 'VI' },
        { name: 'VIRGINIA', code: 'VA' },
        { name: 'WASHINGTON', code: 'WA' },
        { name: 'WEST VIRGINIA', code: 'WV' },
        { name: 'WISCONSIN', code: 'WI' },
        { name: 'WYOMING', code: 'WY' }
      ],
      historicalDataValue: { All: false },
      historicalEmployeeCerts: [],
      histDataPermissionAccess: null,
      getEmpErrorMsg: '',
      individualCertName: '',
      submissionID: uuid(),
    };
    // this.doctypeMap = { 292: 1026, 293: 1025, 1021: 1027, 1022: 1028, 1024: 1030, 1023: 1029, 1028: 1034, 1029: 1035, 1030: 1036, 1031: 1037 };// 1025 and 1026 in dev for county local dispatch and county local supervisor

    this.doctypeMap = { 292: 1239, 293: 1238, 1021: 1021, 1022: 1022, 1023: 1023, 1024: 1024, 1025: 1025, 1026: 1026, 1027: 1027, 1028: 1028, 1029: 1029, 1030: 1030, 1031: 1031 };//prod ids

    // this.doctypeMap = { 292: 1239, 293: 1238, 1021: 1240, 1022: 1241, 1023: 1242, 1024: 1243, 1025: 1244, 1026: 1245, 1027: 1246, 1028: 1247, 1029: 1248, 1030: 1249, 1031: 1250 };// QA and UAT ids

  }

  componentDidMount = async () => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    let tokenEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    const historicalEmployeeData = JSON.parse(localStorage.getItem('historicalEmployeeData'));

    //const data = await httpCDM.getEmployeeTitles(tokenEmployee.OracleEmpNum);
    this.setState({ certStatusValues: await httpCDM.getCertsStatus() });
    this.setState({ allCountyValues: await httpCDM.getAllCounties() });
    const { selectedEmployeeInfo } = this.props.CDMReducer;
    const { admin, securityRoles } = this.props.AdminReducer;
    //Enable when eVerify gets added again
    //this.setState({ eVerifyStates: await httpCDM.getEverifyStates() });

    this.setState({
      // titles: data,
      currentSelectedEmployee: tokenEmployee,
      //employeeStatus: tokenEmployee.Active,
      mobileStatus: selectedEmployeeInfo ? (selectedEmployeeInfo.Active ? true : false) : '',
      employeeEmailAddress: tokenEmployee.EmailAddress,
      employeeIsErt: tokenEmployee.isERT,
      employeeErtStatus: tokenEmployee.statusERT,
      employeeFuntionErt: tokenEmployee.functionERT,
      OracleEmpNum: tokenEmployee.OracleEmpNum,
      employeeStatus: tokenEmployee.UserStatus
    });
    this.props.getStateOrCounties().then(() => {
      let countyValues = JSON.parse(localStorage.getItem('countyToken'));
      this.setState({ countyValues: countyValues.countyValues });
    });
    if (securityRoles === null || !securityRoles.length) this.props.adminSettings(admin.ID);
    if (historicalEmployeeData) {
      this.props.searchTerminatedEmployees(tokenEmployee.OracleEmpNum, currentAdmin.OracleEmpNum);
      this.props.terminatedEmployeeCerts(tokenEmployee.OracleEmpNum, '').then(() => this.setState({ getEmpErrorMsg: '' })).catch((error) => {
        if (error && error.message && error.message.includes(400)) {
          this.setState({ getEmpErrorMsg: 'No records to display' });
        }
      });
    } else {
      this.props.searchEmployees(tokenEmployee.OracleEmpNum, currentAdmin.OracleEmpNum);
      this.props.historicalEmployeeCerts(tokenEmployee.OracleEmpNum, '').then(() => this.setState({ getEmpErrorMsg: '' })).catch((error) => {
        if (error && error.message && error.message.includes(400)) {
          this.setState({ getEmpErrorMsg: 'No records to display' });
        }
      });
    }
    this.props.auditVerification(tokenEmployee.OracleEmpNum);
    this.props.regulatoryApproval(tokenEmployee.OracleEmpNum);
    this.props.setCurrentPath('employeedetails');
    this.props.getEmployee(tokenEmployee.OracleEmpNum);
  };

  componentWillReceiveProps(nextProps) {
    const { selectedEmployeeCerts } = this.props.CDMReducer;
    const { securityRoles } = this.props.AdminReducer;
    const { success, selectedEmployeeCerts: newSelectedEmployeeCerts } = nextProps.CDMReducer;
    const { securityRoles: newSecurityRoles } = nextProps.AdminReducer;
    const { histDataPermissionAccess: stateHistDataPermissionAccess } = this.state;
    let tokenCDM = JSON.parse(localStorage.getItem('tokenCDM'));
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    //const documentTypes = tokenCDM;
    //const lookupValues = this.state.lookupValues;
    const { lookupValues, documentTypes, covidManufacturers } = tokenCDM.filterValues;
    if (lookupValues.length > 0 || documentTypes.length > 0) {
      this.setState({ lookupValues, documentTypes, covidManufacturers });
    }

    if (selectedEmployeeCerts.length !== newSelectedEmployeeCerts.length)
      this.setState((prevState) => ({
        ...prevState,
        historicalEmployeeCerts: this.filterCertsWithFlag(newSelectedEmployeeCerts, 'Disposition', 'Active')
      }));

    if (securityRoles.length !== newSecurityRoles.length || (!stateHistDataPermissionAccess && securityRoles.length)) {
      let histDataPermissionAccess = {};
      for (let i in newSecurityRoles) {
        const permission = newSecurityRoles[i].roleName.split('-');
        if (['HISTORICALDATA'].includes(permission[1])) {
          if (permission.length === 3 && permission[2] === 'READONLY') {
            histDataPermissionAccess = {};
            histDataPermissionAccess.edit = false;
          }
        }
      }

      for (let i in newSecurityRoles) {
        const permission = newSecurityRoles[i].roleName.split('-');
        if (['HISTORICALDATA'].includes(permission[1]) && permission.length !== 3) {
          if (!_.has(histDataPermissionAccess, 'edit')) histDataPermissionAccess = {};
          histDataPermissionAccess.edit = true;
        }
      }
      this.setState({ histDataPermissionAccess });
    }

    if (success) {
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      // this.props.historicalEmployeeCerts(selectedEmployee.OracleEmpNum, '');
      this.props.auditVerification(selectedEmployee.OracleEmpNum);
      this.props.regulatoryApproval(selectedEmployee.OracleEmpNum);
      this.props.getEmployee(selectedEmployee.OracleEmpNum);
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getListByType = (codeType) => {
    return this.state.lookupValues.filter(function (obj) {
      return obj.codeType === codeType;
    });
  };

  closeSidePanel = () => {
    this.setState({
      panelVisible: false,
      addCert: false,
      currentCert: null
    });
  };

  openSidePanel = async (currentCert, OracleEmpNum, individualCertName) => {
    if (currentCert.Document_Type_ID == '') {
      this.setState({ panelVisible: true, addCert: true, currentCert, individualCertName });
    } else {
      this.setState({ panelVisible: true, addCert: false, individualCertName });
      if (!currentCert.CertImage) {
        this.setState({ isLoading: true });
        try {
          let state = currentCert.StateCode ? currentCert.StateCode : '';
          let county = currentCert.County ? currentCert.County : '';

          // let certInfo = currentCert.Document_Type_ID + '^' + currentCert.certNumber + '^' + state + '^' + county;
          const payload = {
            employeeID: currentCert.EmpNum,
            certificateID: currentCert.Document_Type_ID,
            fileID: currentCert.FileID,
            fileIDFront: currentCert.FileIDFront || '',
            fileIDBack: currentCert.FileIDBack || '',
            disposition: currentCert.Disposition || '',
            employeeCertificatesID: currentCert.EmployeeCertificatesID || ''
          };
          const { result } = await httpCDM.getBase64Image(payload);
          if (result.certificateImage != '') {
            currentCert.CertImage = `data:${result.certificateType};base64,` + result.certificateImage;

            // toast.error('Image not available', {
            //   position: 'bottom-center'
            // });
          }
          // let OracleImage = await httpCDM.getImageFromOracle(base64Image);
          //currentCert.CertImage = `data:${result.certificateType};base64,` + result.certificateImage;
          this.setState({ isLoading: false });
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
      if (currentCert.Disposition === 'PENDING') {
        let message = '';
        switch (currentCert.Disposition) {
          case 'PENDING':
            message = 'Pending Checkpoint Approval. This certification status prevents it from being updated at this time.';
            break;
          case 7:
            message = 'Pending. This certification status prevents it from being updated at this time.';
            break;
          default:
            message = 'This certification status prevents it from being updated at this time.';
            break;
        }
        toast.warn(message, { position: 'top-center' });
        return;
      } else {
        this.setState({ panelVisible: true, currentCert, individualCertName });
      }
    }
  };

  certStatusColor = (Disposition) => {
    switch (Disposition) {
      case 'EXPIRED':
        return <circle cx={10} cy={10} r={10} fill="#EA7E7E" />;
      case 'CURRENT':
        return <circle cx={10} cy={10} r={10} fill="#0AE298" />;
      case 'MISSING':
        return <circle cx={10} cy={10} r={10} fill="#EAE27E" />;
      case 'PENDING':
        return <circle cx={10} cy={10} r={10} fill="#BB7EEA" />;
      case '':
        return <circle cx={10} cy={10} r={10} fill="#7EA9EA" />;
      default:
        return <circle cx={10} cy={10} r={10} fill="#7F7F7F" />;
    }
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  updateEmployeeDetails = () => {
    // const { employees, isLoading, error } = this.props.CDMReducer;
    if (this.state.employeeEmailAddress === '') {
      toast.error('The Email Address field is required.', {
        position: 'bottom-center'
      });
    } else if (!this.validateEmail(this.state.employeeEmailAddress)) {
      toast.error('Please supply a valid email address.', {
        position: 'bottom-center'
      });
      // } else if (
      //   (this.state.employeeIsErt === 'Yes' || this.state.employeeIsErt === true) &&
      //   (this.state.employeeErtStatus === null || this.state.employeeFuntionErt === null)
      // ) {
      //   toast.error('ERT Status and ERT Function is required.', {
      //     position: 'bottom-center'
      //   });
    } else {
      let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
      let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
      let updateDetails = {
        OracleEmpNum: selectedEmployee.OracleEmpNum,
        adminID: currentAdmin.ID,
        adminUserName: currentAdmin.UserName,
        mobileSecurityID: this.props.CDMReducer.selectedEmployeeInfo.mobileSecurityID,
        EmailAddress: this.state.employeeEmailAddress, // george@sbcglobal.net
        Active: this.state.mobileStatus === 'true' || this.state.mobileStatus === true ? true : false, // true or false
        isERT: this.state.employeeIsErt === 'Yes' || this.state.employeeIsErt === true ? true : false, // if Active, set to true, otherwise set to false
        statusERT: this.state.employeeErtStatus === null ? 'Applicant' : this.state.employeeErtStatus, // Applicant by default
        functionERT: this.state.employeeFuntionErt === null ? 'Field Staff' : this.state.employeeFuntionErt // Field Staff by default
      };
      selectedEmployee.EmailAddress = updateDetails.EmailAddress;
      selectedEmployee.Active = updateDetails.Active;
      selectedEmployee.isERT = updateDetails.isERT;
      selectedEmployee.statusERT = this.state.employeeIsErt === false || this.state.employeeIsErt === 'No' ? 'Inactive' : updateDetails.statusERT;
      selectedEmployee.functionERT =
        this.state.employeeIsErt === false || this.state.employeeIsErt === 'No' ? 'Not Applicable' : updateDetails.functionERT;
      this.props.setSelectedEmployee(selectedEmployee);
      this.props.historicalEmployeeCerts(selectedEmployee.OracleEmpNum, '');
      this.props.auditVerification(selectedEmployee.OracleEmpNum);
      this.props.regulatoryApproval(selectedEmployee.OracleEmpNum);
      this.setState({ showModal: false }, () => {
        this.props.updateEmployee(updateDetails);
      });
    }
  };

  addNewCert = (newValues) => {
    // gets the state's valid format
    const regexp = newValues.dlRegex;
    const regexTest = new RegExp(regexp, 'g');
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const { documentTypes } = this.state;
    const tempDocTypeID = newValues.certName === '' ? 141 : parseInt(newValues.docTypeID)
    let currentDocType = documentTypes.filter(item => item.docTypeId == tempDocTypeID);

    const validate = newValues.docTypeID == 259 ? !newValues.isVaccineDeclined : true;
    const isSafetyCert = newValues.certName === 'Respiratory Fit Test' || newValues.certName === 'EVOC' || newValues.certName === 'Safe Stretcher Handling' ? true : false;

    if (currentDocType.length)
      currentDocType = currentDocType[0];
    if (!(currentDocType.docTypeId === "259" && newValues.isVaccineDeclined)) {
      if (validate && (newValues.certNumber === null || newValues.certNumber === '') && !isSafetyCert) {
        toast.dismiss();
        toast.error('Certificate Number is required.', {
          position: 'bottom-center'
        });
        return;
      }
      if (!(currentDocType.isExpiring === 'N' || newValues.expirationDate) && !newValues.issueDate) {
        toast.dismiss();
        toast.error('Issue Date and Expiration date can not be blank.', { position: 'bottom-center' });
        return;
      }

      if (!newValues.issueDate) {
        toast.dismiss();
        toast.error('Issue date can not be blank.', { position: 'bottom-center' });
        return;
      }
      if (!(currentDocType.isExpiring === 'N' || newValues.expirationDate)) {
        toast.dismiss();
        toast.error('Expiration date can not be blank.', { position: 'bottom-center' });
        return;
      }
      if (currentDocType && /Y/i.test(currentDocType.IsFrontImageRequired) && (newValues.frontImage === null || newValues.frontImage === '') && !newValues.isVaccineDeclined) {
        toast.dismiss();
        toast.error('Front Image is required.', { position: 'bottom-center' });
        return;
      }
      if (validate && (currentDocType && /Y/i.test(currentDocType.IsBackImageRequired) && (newValues.backImage === null || newValues.backImage === '') && newValues.isBackImageRequired)) {
        toast.dismiss();
        toast.error('Back Image is required.', { position: 'bottom-center' });
        return;
      }
      if (validate && newValues.isCountyCertificate && newValues.countyCode.length === 0) {
        toast.dismiss();
        toast.error('County is required for county certificates.', { position: 'bottom-center' });
        return;
      }
      if (validate && newValues.isStateCertificate && newValues.stateCode.length === 0) {
        toast.dismiss();
        toast.error(validate && 'State is required for state certificates.', { position: 'bottom-center' });
        return;
      }
      if (validate && newValues.docType.toLowerCase() === 'drivers license' && !regexTest.test(newValues.certNumber)) {
        toast.dismiss();
        toast.error('Please enter a valid format for certificate number.', { position: 'bottom-center' });
        return;
      }
      if (newValues.stateCode === 'NJ') {
        if (!newValues.zipCode) {
          toast.dismiss();
          toast.error('Please enter a valid ZIP Code', { position: 'bottom-center' });
          return;
        }
      }
    }
    let formattedCertNumber = '';
    if (newValues.stateCode === 'NJ') {
      formattedCertNumber = newValues.certNumber.concat(`:${newValues.zipCode}`)
    }
    formattedCertNumber = formattedCertNumber.length > 0 ? formattedCertNumber : newValues.certNumber;

    const payload = {
      id: this.props.CDMReducer.selectedEmployeeInfo.mobileSecurityID
        ? this.props.CDMReducer.selectedEmployeeInfo.mobileSecurityID
        : selectedEmployee.OracleEmpNum, //
      agency: newValues.agency === '' ? '' : newValues.agency, //
      certName: newValues.certName === '' ? 'ALBS' : newValues.certName,
      certNumber: isSafetyCert && newValues.certName !== 'Respiratory Fit Test' ? 'NA' : formattedCertNumber,
      // frontImage: newValues.frontImage.split(',')[1],
      // backImage: newValues.backImage.split(',')[1],
      frontImage: newValues.frontImage,
      backImage: newValues.backImage,
      businessUnit: selectedEmployee.BusUnit, //
      firstName: selectedEmployee.FirstName, //
      lastName: selectedEmployee.LastName, //
      expirationDate:
        !newValues.expirationDate && currentDocType.isExpiring === 'N'
          ? moment('12/31/2099').format('YYYY-MM-DD')
          : moment(newValues.expirationDate).format('YYYY-MM-DD'),
      issuedBy: '',
      docTypeID: newValues.certName === '' ? 141 : (this.doctypeMap[newValues.docTypeID] ? parseInt(this.doctypeMap[newValues.docTypeID]) : parseInt(newValues.docTypeID)),
      stateCode: newValues.stateCode,
      issueDate: newValues.issueDate === null || newValues.issueDate === ''
        ? newValues.expirationDate && moment(newValues.expirationDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
          ? moment(newValues.expirationDate)
            .subtract(1, 'year')
            .format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD')
        : moment(newValues.issueDate).format('YYYY-MM-DD'),
      docType: newValues.docType === '' ? 'ALBS' : newValues.docType,
      oracleID: selectedEmployee.OracleEmpNum, //
      isActive: 'A', //
      county: newValues.countyCode, //
      buDescription: selectedEmployee.BU_Name, //
      EmpNum: selectedEmployee.OracleEmpNum,
      expiring: newValues.isExpiring.toString(),
      userName: selectedEmployee.AD,
      adminID: currentAdmin.OracleEmpNum,
      lotNumber: newValues.lotNumber || '',
      manufacturer: newValues.manufacturerName ? newValues.manufacturerName : '',
      ...(newValues.docTypeID == 1007 ? { dose: newValues.dose } : {}),
      declined: newValues.docTypeID == 1007 || newValues.docTypeID == 259 ? (newValues.isVaccineDeclined ? '1' : '0') : '',
      adminWorkerType: currentAdmin.adminWorkerType,
      employeeWorkerType: this.props.CDMReducer.selectedEmployeeInfo.employeeWorkerType,
      employeeCertificatesID: '',
      submissionID: this.state.submissionID,
      createdBy: `${currentAdmin.FirstName} ${currentAdmin.LastName} (${currentAdmin.OracleEmpNum})`,
      updateSource: 'Admin',
    };
    this.props.postNewCert(payload);

    this.setState({ panelVisible: false, addCert: false });
  };

  logModification = (currentPayloadStr, preUpdatePayloadStr) => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    const payload = {
      preUpdate: preUpdatePayloadStr,
      postUpdate: currentPayloadStr,
      updatedByID: currentAdmin.OracleEmpNum,
      updatedBy: currentAdmin.FirstName + ' ' + currentAdmin.LastName,
      modifiedDate: moment(new Date()).format('MM/DD/YYYY'),
      empNum: selectedEmployee.OracleEmpNum
    };
    this.props.addNewUpdateLog(currentAdmin.ID, payload);
  };

  //Enable when eVerify gets added again
  // eVerifyStatus = async (CertNumber) => {
  //   let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
  //   this.setState({ eVerifyStatusValues: await httpCDM.eVerifyStatus(selectedEmployee.OracleEmpNum, CertNumber) });
  //   return this.state.eVerifyStatusValues;
  // };

  eCardStatus = async (eCardNumber, eCardType, OracleEmpNum) => {
    return await httpCDM.GetEcard(eCardNumber, eCardType, OracleEmpNum);
  };

  updateExistingCert = (updateValues, eVerifyBool, preUpdatepayload) => {
    // gets the state's valid format
    const regexp = updateValues.dlRegex;
    const regexTest = new RegExp(regexp, 'g');
    // first check to make sure all the required fields are filled out completely
    // fields such as exp, issue if applicaable and the images are attached if not
    // tell them to enter/select and so forth
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    let currentCert = this.state.currentCert;
    const { documentTypes } = this.state;
    let tempDocTypeID = currentCert.Document_Type_ID;
    if (Object.values(this.doctypeMap).includes(Number(tempDocTypeID))) {
      tempDocTypeID = Object.keys(this.doctypeMap).reduce((acc, curr) => {
        if (this.doctypeMap[curr] == tempDocTypeID) return curr;
        return acc;
      }, '')
    }
    let currentDocType = documentTypes.filter(item => item.docTypeId == tempDocTypeID);

    if (currentDocType.length)
      currentDocType = currentDocType[0];

    if (!(currentDocType.docTypeId == "259" && updateValues.isVaccineDeclined)) {
      if (updateValues.activeFlag == 'Inactive') {
        const certStatusPayload = {
          employeeNum: selectedEmployee.OracleEmpNum,
          docTypeID: currentCert.Document_Type_ID,
          certNumber: updateValues.certNumber,
          CertStatus: updateValues.activeFlag,
          certName: currentCert.CertName,
          fileId: currentCert.FileID,
          EmployeeCertificatesID: currentCert.EmployeeCertificatesID || '',
        };
        certStatusPayload.certificateHistoryLog = this.newCertificateHistoryLog(currentAdmin, updateValues.activeFlag, currentCert, 'activeFlag');
        return this.props.setCertificationStatus(currentAdmin.OracleEmpNum, certStatusPayload);
      } else if (this.state.historicalDataValue.All) {
        const certStatusPayload = {
          employeeNum: selectedEmployee.OracleEmpNum,
          docTypeID: currentCert.Document_Type_ID,
          certNumber: updateValues.certNumber,
          CertStatus: updateValues.activeFlag,
          certName: currentCert.CertName,
          fileId: currentCert.FileID,
          EmployeeCertificatesID: currentCert.EmployeeCertificatesID || '',
        };
        certStatusPayload.certificateHistoryLog = this.newCertificateHistoryLog(currentAdmin, updateValues.activeFlag, currentCert, 'activeFlag');
        return this.props.setCertificationStatus(currentAdmin.OracleEmpNum, certStatusPayload);
      } else {
        if (!(currentDocType.isExpiring === 'N' || updateValues.expirationDate) && !updateValues.issueDate) {
          toast.dismiss();
          toast.error('Issue Date and Expiration date can not be blank.', { position: 'bottom-center' });
          return;
        }
        else if (!updateValues.issueDate) {
          toast.dismiss();
          toast.error('Issue date can not be blank.', { position: 'bottom-center' });
          return;

        } else if (!(currentDocType.isExpiring === 'N' || updateValues.expirationDate)) {
          toast.dismiss();
          toast.error('Expiration date can not be blank.', { position: 'bottom-center' });
          return;
        }
        else if (!currentCert.CertImage && (/Y/i.test(currentDocType.IsBackImageRequired) && !updateValues.backImage) && (/Y/i.test(currentDocType.IsFrontImageRequired) && !updateValues.frontImage)) {
          toast.error('Front and back images are required.', {
            position: 'bottom-center'
          });
          return;
        } else if (currentDocType && currentCert.ActiveFlag !== 'Inactive' && /Y/i.test(currentDocType.IsFrontImageRequired) && (updateValues.frontImage === null || updateValues.frontImage === '') && !updateValues.isVaccineDeclined) {
          if (!updateValues.userIsManuallyVerifyingCert) {
            toast.dismiss();
            toast.error('Front Image is required.', { position: 'bottom-center' });
            return;
          }
        } else if (currentDocType && currentCert.ActiveFlag !== 'Inactive' && /Y/i.test(currentDocType.IsBackImageRequired) && (updateValues.backImage === null || updateValues.backImage === '') && updateValues.isBackImageRequired) {
          if (!updateValues.userIsManuallyVerifyingCert) {
            toast.dismiss();
            toast.error('Back Image is required.', { position: 'bottom-center' });
            return;
          }
        } else if (updateValues.regulatoryApproved && !updateValues.regulatoryApprovedImage) {
          toast.error('Regulatory approved extension image is required.', {
            position: 'bottom-center'
          });
          return;
        } else if (currentCert.CertName.toLowerCase() === 'drivers license' && !regexTest.test(updateValues.certNumber)) {
          toast.dismiss();
          toast.error('Please enter a valid format for certificate number.', { position: 'bottom-center' });
          return;
        }
        if (currentCert.CertName === "Respiratory Fit Test") {
          if (updateValues.manufacturerName === '') {
            toast.dismiss();
            toast.error('Please enter or select a make/manufacturer.', { position: 'bottom-center' });
            return;
          } else if (updateValues.modelNumber === '') {
            toast.dismiss();
            toast.error('Please enter or select a model number.', { position: 'bottom-center' });
            return;
          } else if (updateValues.respiratorType === '') {
            toast.dismiss();
            toast.error('Please enter or select a respirator type.', { position: 'bottom-center' });
            return;
          } else if (updateValues.respiratorSize === '') {
            toast.dismiss();
            toast.error('Please enter or select a respirator size.', { position: 'bottom-center' });
            return;
          } else if (updateValues.testResults === '') {
            toast.dismiss();
            toast.error('Please select pass or fail for test result.', { position: 'bottom-center' });
            return;
          }
        }
        if (updateValues.stateCode === 'NJ') {
          if (!updateValues.zipCode) {
            toast.dismiss();
            toast.error('Please enter a valid ZIP Code', { position: 'bottom-center' });
            return;
          }
        }
      }
    }
    else if (updateValues.activeFlag == 'Inactive') {
      const certStatusPayload = {
        employeeNum: selectedEmployee.OracleEmpNum,
        docTypeID: currentCert.Document_Type_ID,
        certNumber: updateValues.certNumber,
        CertStatus: updateValues.activeFlag,
        certName: currentCert.CertName,
        fileId: currentCert.FileID,
        EmployeeCertificatesID: currentCert.EmployeeCertificatesID || '',
      };
      certStatusPayload.certificateHistoryLog = this.newCertificateHistoryLog(currentAdmin, updateValues.activeFlag, currentCert, 'activeFlag');
      return this.props.setCertificationStatus(currentAdmin.OracleEmpNum, certStatusPayload);
    } else if (this.state.historicalDataValue.All) {
      const certStatusPayload = {
        employeeNum: selectedEmployee.OracleEmpNum,
        docTypeID: currentCert.Document_Type_ID,
        certNumber: updateValues.certNumber,
        CertStatus: updateValues.activeFlag,
        certName: currentCert.CertName,
        fileId: currentCert.FileID,
        EmployeeCertificatesID: currentCert.EmployeeCertificatesID || '',
      };
      certStatusPayload.certificateHistoryLog = this.newCertificateHistoryLog(currentAdmin, updateValues.activeFlag, currentCert, 'activeFlag');
      return this.props.setCertificationStatus(currentAdmin.OracleEmpNum, certStatusPayload);
    }

    let formattedCertNumber = '';
    if (updateValues.stateCode === 'NJ') {
      formattedCertNumber = updateValues.certNumber.concat(`:${updateValues.zipCode}`)
    }
    formattedCertNumber = formattedCertNumber.length > 0 ? formattedCertNumber : updateValues.certNumber;

    const payload = {
      id: this.props.CDMReducer.selectedEmployeeInfo.mobileSecurityID
        ? this.props.CDMReducer.selectedEmployeeInfo.mobileSecurityID
        : selectedEmployee.OracleEmpNum, //
      docId: currentCert.Document_Type_ID,
      activeFlag: updateValues.activeFlag === '' ? '' : updateValues.activeFlag,
      agency: updateValues.agency === '' ? '' : updateValues.agency,
      county: updateValues.countyCode,
      buDescription: selectedEmployee.BU_Name,
      issueDate:
        updateValues.issueDate === null
          ? currentCert.CertIssueDate === null
            ? moment().format('YYYY-MM-DD')
            : moment(currentCert.CertIssueDate).format('YYYY-MM-DD')
          : moment(updateValues.issueDate).format('YYYY-MM-DD'),
      expDate:
        !updateValues.expirationDate && !currentCert.Expiring
          ? '2099-12-31'
          : moment(updateValues.expirationDate).format('YYYY-MM-DD'),
      prevExpDate: currentCert.CertExpDate,
      certImage: !updateValues.frontImage && !updateValues.backImage && !(currentDocType.docTypeId == "259" && updateValues.isVaccineDeclined) ? currentCert.CertImage : '',
      stateCode: updateValues.stateCode,
      firstName: selectedEmployee.FirstName,
      lastName: selectedEmployee.LastName,
      certNumber: formattedCertNumber,
      CertName: currentCert.CertName === '' ? 'ALBS' : currentCert.CertName,
      frontImage: updateValues.frontImage ? updateValues.frontImage : updateValues.userIsManuallyVerifyingCert && updateValues.certImage ? updateValues.certImage : '',
      backImage: updateValues.backImage ? updateValues.backImage : '',
      businessUnit: selectedEmployee.BusUnit,
      isMissingCertificate: currentCert.CertStatusID === 'Missing' ? true : false,
      EmpNum: selectedEmployee.OracleEmpNum,
      expiring: currentCert.Expiring,
      dataSource: currentCert.DataSource,
      manuallyVerified: updateValues.manuallyVerified ? (updateValues.manuallyVerified === true ? '1' : '0') : '',
      manuallyVerifiedBy: updateValues.manuallyVerifiedBy ? updateValues.manuallyVerifiedBy : '',
      manuallyVerifiedByID: updateValues.manuallyVerifiedByID ? updateValues.manuallyVerifiedByID : '',
      manuallyVerifiedDate: updateValues.manuallyVerifiedDate
        ? updateValues.manuallyVerifiedDate == '12/31/1899 16:00'
          ? moment(new Date()).format('MM/DD/YYYY HH:mm')
          : updateValues.manuallyVerifiedDate
        : moment(new Date()).format('MM/DD/YYYY HH:mm'),
      regulatoryApproved: updateValues.regulatoryApproved ? (updateValues.regulatoryApproved === true ? '1' : '0') : '',
      regulatoryApprovedBy: updateValues.regulatoryApprovedBy ? updateValues.regulatoryApprovedBy : '',
      regulatoryApprovedByID: updateValues.regulatoryApprovedByID ? updateValues.regulatoryApprovedByID : '',
      regulatoryApprovedDate: updateValues.regulatoryApprovedDate
        ? updateValues.regulatoryApprovedDate == '12/31/1899 16:00'
          ? moment(new Date()).format('MM/DD/YYYY HH:mm')
          : updateValues.regulatoryApprovedDate
        : moment(new Date()).format('MM/DD/YYYY HH:mm'),
      regulatoryApprovedImage: updateValues.regulatoryApprovedImage ? updateValues.regulatoryApprovedImage : '',
      userName: selectedEmployee.AD,
      adminID: currentAdmin.OracleEmpNum,
      lotNumber: updateValues.lotNumber || '',
      manufacturer: updateValues.manufacturerName ? updateValues.manufacturerName : '',
      // ...(updateValues.Document_Type_ID == 1007 ? { dose: updateValues.dose } : {}),
      dose: currentCert.Document_Type_ID == 1007 ? (updateValues.dose ? updateValues.dose : 'no') : '',
      declined: currentCert.Document_Type_ID == 1007 || currentCert.Document_Type_ID == 259 ? (updateValues.isVaccineDeclined ? '1' : '0') : '',
      adminWorkerType: currentAdmin.adminWorkerType,
      employeeWorkerType: this.props.CDMReducer.selectedEmployeeInfo.employeeWorkerType,
      fileId: currentDocType.docTypeId == "259" && updateValues.isVaccineDeclined ? "" : currentCert.FileID,
      employeeCertificatesID: currentCert.EmployeeCertificatesID,
      modelNumber: updateValues.modelNumber ? updateValues.modelNumber : '',
      respiratorType: updateValues.respiratorType ? updateValues.respiratorType : '',
      respiratorSize: updateValues.respiratorSize ? updateValues.respiratorSize : '',
      testResults: updateValues.testResults ? updateValues.testResults : '',
      submissionID: this.state.submissionID,
      createdBy: `${currentAdmin.FirstName} ${currentAdmin.LastName} (${currentAdmin.OracleEmpNum})`,
      updateSource: 'Admin',
    };
    //Enable when eVerify gets added again
    //if (!eVerifyBool) {
    if (updateValues.userIsManuallyVerifyingCert) {
      const verifiedStatus = updateValues.manuallyVerified ? 'Verified' : 'Unverified';
      payload.certificateHistoryLog = this.newCertificateHistoryLog(currentAdmin, verifiedStatus, currentCert, 'manuallyVerified');
      this.props.setManualVerificationStatus(currentAdmin.ID, payload);
    } else {
      this.props.updateCert(currentAdmin.ID, payload);
    }
    // } else {
    //   this.props.eVerifyCert(payload);
    // }
    this.setState({ panelVisible: false, addCert: false });
  };

  newCertificateHistoryLog = (currentAdmin, action, currentCert, actionName) => {
    const certHistoryObj = {
      user: `${currentAdmin.FirstName} ${currentAdmin.LastName} (${currentAdmin.OracleEmpNum})`,
      date: moment(new Date()).format('MM/DD/YYYY h:mm'),
      [actionName]: action
    }
    let parsedHistoryLog = null;
    if (action.toLowerCase().includes('active')) {
      parsedHistoryLog = currentCert.CertificateHistoryLog && currentCert.CertificateHistoryLog.length > 0 ? JSON.parse(currentCert.CertificateHistoryLog) : null;
    } else {
      const auditCert = this.props.CDMReducer.selectedEmployeeAudit
        .filter((user) => user.EmployeeCertificatesID == currentCert.EmployeeCertificatesID)
        .map((user) => ({ value: user }))
        .filter((obj) => obj.value.Document_Type_ID == currentCert.Document_Type_ID)
        .map((obj) => obj.value);
        parsedHistoryLog = auditCert.length > 0 ? JSON.parse(auditCert[0].CertificateHistoryLog) : null;
    }
    const tempHistoryLog = parsedHistoryLog ? parsedHistoryLog : [];
    tempHistoryLog.push(certHistoryObj);
    return JSON.stringify(tempHistoryLog);
  }

  formatExpDate = (CertStatusID, expDate, doesCertExpire) => {
    let date = null;

    if (!expDate && CertStatusID !== 'Expired') {
      date = 'N/A';
    } else if (CertStatusID === 'Expired') {
      date = 'Expired';
    } else if (doesCertExpire === false) {
      date = 'N/A';
    } else if (
      moment(expDate)
        .format('MM/DD/YYYY')
        .includes('Invalid date') &&
      doesCertExpire === false
    ) {
      date = 'N/A';
    } else {
      date = moment(expDate).format('MM/DD/YYYY');
    }

    return date;
  };

  checkCOVIDVaccineDose = () => {
    const { selectedEmployeeCerts } = this.props.CDMReducer;
    let certs = selectedEmployeeCerts
      .filter((cert) => cert.Document_Type_ID == '141')
      .map((cert) => ({ value: cert }))
      .filter((obj) => obj.value.CertNumber == 'ABL')
      .map((obj) => obj.value);

    //I still need to check for the Declination Field
    if (certs.length > 0) {
      this.setState({ covidDose: 2 });
    }
  };

  populateAuditVerification = (auditVerification, currentCert) => {
    try {
      let auditRow = '';
      let auditCert = auditVerification ? auditVerification
        .filter((user) => user.EmployeeCertificatesID == currentCert.EmployeeCertificatesID)
        .map((user) => ({ value: user }))
        .filter((obj) => obj.value.Document_Type_ID == currentCert.Document_Type_ID)
        .map((obj) => obj.value)
        : [];
      if (!['MISSING'].includes(currentCert.Disposition) && auditCert.length > 0) {
        auditRow = auditCert[0].ManuallyVerified
          ? moment
            .utc(auditCert[0].ModifiedDate)
            .local()
            .format('MM/DD/YYYY')
          : '';
      }
      return (
        <Fragment>
          <div> {auditRow}</div>
        </Fragment>
      );
    } catch (err) {
      console.log(err);
    }
  };

  populateStateAndCounty = (cert, certName) => {
    const matchStates = /(268|390|245|246|896|247|248|240|249|1024|1023)/; //state practical nurse(1009)for dev and 1234 doctype for QA,UAT
    const matchCounty = /(702|173|174|175|292|293|1028|1031|1028|1031|1248|1250)/;// 1025 and 1026 in dev for county local dispatch and county local supervisor
    const isHyphenNeeded = matchStates.test(cert.Document_Type_ID) || matchCounty.test(cert.Document_Type_ID);
    return `${certName ? certName : cert.CertName} ${isHyphenNeeded ? '-' : ''} ${matchStates.test(cert.Document_Type_ID) ? cert.StateCode : ''} ${matchCounty.test(cert.Document_Type_ID) ? cert.County : ''
      }`;
  };

  populateStatesInRow = (cert) => {
    return (
      <ul className="cert-row">
        {cert.CertName.split(' , ').map((c, i) => (
          <li key={`${c}-${i}`}>{c}</li>
        ))}
      </ul>
    );
  };

  onChangeHistoricalData = (event) => {
    const {
      target: { value, checked, type, name }
    } = event;
    const { selectedEmployeeCerts } = this.props.CDMReducer;
    const filteredCerts = selectedEmployeeCerts.filter(v => !['REJECTED', 'MISSING'].includes(v.Disposition));
    let filteredData = this.filterCertsWithFlag(filteredCerts, 'Disposition', 'Active');
    if (checked)
      filteredData = _.groupBy(
        /All/i.test(value) ? filteredCerts : this.filterCertsWithFlag(filteredCerts, 'Disposition', value),
        'CertName'
      );
    this.setState((prevState) => ({
      historicalDataValue: {
        ...prevState.historicalDataValue,
        ...(type === 'checkbox' ? { [value]: checked, 'historical-data': '' } : { [name]: value })
      },
      historicalEmployeeCerts: filteredData
    }));
  };

  filterCertsWithFlag = (certList, flag, certStatus) => {
    const activeCert = 'PENDING, MISSING, CURRENT, EXPIRED';
    // return certList.filter(item => item[flag].toLowerCase() === mapkey.toLowerCase());
    let filteredCertList = certList.filter((item) => (/^active$/i.test(certStatus) ? activeCert.includes(item[flag]) : !activeCert.includes(item[flag])));
    return filteredCertList;
  };

  renderListItem = (cert) => {
    const { selectedEmployeeInfo } = this.props.CDMReducer;
    const { historicalDataValue, histDataPermissionAccess } = this.state;
    let separateMultiCert;
    if (cert && cert.CertName && cert.CertName.includes(',')) {
      separateMultiCert = cert.CertName.split(',');
    }
    let formattedCertNumber = '';
    if (cert.StateCode === 'NJ') {
      const index = cert.CertNumber.indexOf(':');
      formattedCertNumber = cert.CertNumber.slice(0, index);
    }
    formattedCertNumber = formattedCertNumber.length > 0 ? formattedCertNumber : cert.CertNumber;

    return (
      <>
        {separateMultiCert ?
          separateMultiCert.map((certName) => (
            <div
              className={this.state.currentCert === cert ? 'cert-selected' : 'row-cert'}
              onClick={
                historicalDataValue.All && !(histDataPermissionAccess && histDataPermissionAccess.edit)
                  ? () => { }
                  : () => {
                    this.setState({ panelVisible: false, addCert: false });
                    this.openSidePanel(cert, selectedEmployeeInfo.OracleEmpNum, certName);
                  }
              }
            >
              <div className="row-cert-doc">
                <div className="row-cert-doc-name">
                  {this.populateStateAndCounty(cert, certName)}
                </div>
                <div className="row-cert-doc-number">{!['MISSING'].includes(cert.Disposition) && cert.CertNumber !== 'NA' ? cert.CertNumber : ''}</div>
              </div>
              {this.renderCertDetails(cert)}
            </div>
          ))
          :
          <div
            className={this.state.currentCert === cert ? 'cert-selected' : 'row-cert'}
            onClick={
              historicalDataValue.All && !(histDataPermissionAccess && histDataPermissionAccess.edit)
                ? () => { }
                : () => {
                  this.setState({ panelVisible: false, addCert: false });
                  this.openSidePanel(cert, selectedEmployeeInfo.OracleEmpNum, '');
                }
            }
          >
            <div className="row-cert-doc">
              <div className="row-cert-doc-name">
                {/* if stateCode or CountyName show it, otherwise, don't show - then stateCode or county */}
                {/* {this.populateStateAndCounty(cert)} */}
                {/* {cert.CertName} {(/268|390/|/245/|/246/|/896/|/247/|/248/|/240/|/249/.test(cert.Document_Type_ID)) ? (cert.County || cert.StateCode ? '- ' + (cert.County || cert.StateCode) : null) : null} */}
                {!(cert && cert.CertName && cert.CertName.includes(' , ')) ? this.populateStateAndCounty(cert) : this.populateStatesInRow(cert)}
              </div>
              <div className="row-cert-doc-number">{!['MISSING'].includes(cert.Disposition) && cert.CertNumber !== 'NA' ? formattedCertNumber : ''}</div>
            </div>
            {this.renderCertDetails(cert)}
          </div>
        }
      </>
    );
  };

  renderCertDetails = (cert) => {    
    return (
      <>
        <div className="row-cert-status">
          <svg>{this.certStatusColor(cert.Disposition)}</svg>
        </div>
        <div className="row-cert-issue">
          {!['MISSING'].includes(cert.Disposition) ? moment(cert.CertIssueDate)
            .format('MM/DD/YYYY')
            .includes('Invalid date')
            ? moment().format('MM/DD/YYYY')
            : moment(cert.CertIssueDate).format('MM/DD/YYYY') : ''}
        </div>
        <div className="row-cert-issue">
          {!['MISSING'].includes(cert.Disposition) && !(cert.Declined == 1) ? this.formatExpDate(cert.CertStatusID, cert.CertExpDate, cert.Expiring) : ''
            // moment(cert.CertExpDate).format('MM/DD/YYYY').includes("Invalid date") && cert.Expiring === 'N' ?
            // 'N/A' :
            // (cert.CertStatus === "Expired" ? 'Expired' : (moment(cert.CertExpDate).format('MM/DD/YYYY').includes("Invalid date") ? moment().add(1, 'Year').format('MM/DD/YYYY') : moment(cert.CertExpDate).format('MM/DD/YYYY')))
          }
        </div>
        <div className="row-cert-cpupdate">
          {cert.DateTimeStamp && (
            <div>
              {moment(cert.DateTimeStamp).format('MM/DD/YYYY HH:mm')} <strong>by</strong> {cert.FirstName + ' ' + cert.LastName}
            </div>
          )}
        </div>
        {this.populateAuditVerification(this.props.CDMReducer.selectedEmployeeAudit, cert)}
      </>
    )
  };

  displayLastLogin = () => {
    const { OracleEmpNum } = this.state;
    const { employees } = this.props.CDMReducer;
    let isPresent = false;
    let value = null;
    if (Array.isArray(employees) && employees.length) {
      const currentEmp = employees.filter(v => v.OracleEmpNum == OracleEmpNum);
      isPresent = !!currentEmp.length;
      value = currentEmp.length ? currentEmp[0].LastLogin : null;
    }
    return {
      isPresent,
      value,
    }
  }

  calculateCertCount = () => {
    const { historicalEmployeeCerts } = this.state;
    let certCount = 0;
    if (Array.isArray(historicalEmployeeCerts)) {
      certCount = historicalEmployeeCerts.length;
    } else {
      certCount = Object.keys(historicalEmployeeCerts).reduce((acc, value) => acc += historicalEmployeeCerts[value].length, 0);
    }
    return certCount;
  };

  displayCertCount = () => {
    const certCount = this.calculateCertCount();
    return <div className="row-cert-name">{certCount + ' Certificate' + (certCount === 1 ? '' : 's')}</div>;
  }

  render() {
    const { selectedEmployeeCerts, employees, isLoading, isCertsLoading, error, auditVerifiation, selectedEmployeeInfo } = this.props.CDMReducer;
    const { securityRoles } = this.props.AdminReducer;
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const isViewingTerminatedEmployee = JSON.parse(localStorage.getItem('historicalEmployeeData'));
    const adminProfile = JSON.parse(sessionStorage.getItem('adminProfile'));
    const isAdminTool = adminProfile ? adminProfile.result[0].isAdminTool : false;
    const isSavingDisabled = isViewingTerminatedEmployee || isAdminTool;

    // let securityRoles = JSON.parse(localStorage.getItem('securityRoles'));
    // if (securtiyRoles[0].roleName == 'ADMIN-FULL')
    // {
    // }
    const { titles, historicalDataValue, historicalEmployeeCerts, getEmpErrorMsg } = this.state;

    if (error && error.response) {
      let message = error.response.data.error.message;
      toast.error(message, { position: 'bottom-center' });
      this.props.clearError();
    }

    // let employeeStatusOptionItems = this.getListByType('EMPLOYEE').map((option) =>
    //   <option key={option.codeValue}>{option.codeName === "Active" ? "Active" : "Not Active"}</option>
    // );

    let isErtStatusOptionItems = this.getListByType('STATUS').map((option) => (
      <option key={option.codeValue}>{option.codeName === 'Active' ? 'Yes' : 'No'}</option>
    ));

    let ertStatusOptionItems = this.getListByType('ERTSTATUS').map((option) => <option key={option.codeValue}>{option.codeName}</option>);

    let ertOptionItems = this.getListByType('ERTFUNCTIONS').map((option) => <option key={option.codeValue}>{option.codeName}</option>);

    let currentCert = this.state.currentCert;

    if (currentCert) {
      const { Document_Type_ID } = currentCert;
      if (Object.values(this.doctypeMap).includes(Number(Document_Type_ID))) {
        const docTypeId = Object.keys(this.doctypeMap).reduce((acc, curr) => {
          if (this.doctypeMap[curr] == Document_Type_ID) return curr;
          return acc;
        }, '')
        currentCert = {
          ...currentCert,
          Document_Type_ID: docTypeId
        }
      }
    }

    const _selectedEmployeeCerts = this.props.CDMReducer.selectedEmployeeCerts.map(el => {
      const { Document_Type_ID } = el;

      if (Object.values(this.doctypeMap).includes(Number(Document_Type_ID))) {
        const docTypeId = Object.keys(this.doctypeMap).reduce((acc, curr) => {
          if (this.doctypeMap[curr] == Document_Type_ID) return curr;
          return acc;
        }, '')
        return {
          ...el,
          Document_Type_ID: docTypeId
        }
      }

      return el;
    })


    return (
      <div className="cdm-employeedetail">
        <ToastContainerComponent />
        <Heading title="CDM" stage="employee-detail" />
        <Sidepanel
          panelVisible={this.state.panelVisible}
          addCert={this.state.addCert}
          closeSidePanel={this.closeSidePanel}
          currentCert={currentCert}
          documentTypes={this.state.documentTypes}
          stateValues={this.state.stateValues}
          countyValues={this.state.countyValues}
          addNewCert={this.addNewCert}
          updateExistingCert={this.updateExistingCert}
          success={this.props.CDMReducer.success}
          selectedEmployeeCerts={_selectedEmployeeCerts}
          certStatusValues={this.state.certStatusValues}
          allCountyValues={this.state.allCountyValues}
          auditVerifiation={this.props.CDMReducer.selectedEmployeeAudit}
          regulatoryApproval={this.props.CDMReducer.selectedEmployeeRegulatory}
          //Enable when eVerify gets added again
          // eVerifyStatus={this.eVerifyStatus}
          // eVerifyStates={this.state.eVerifyStates}
          eCardStatus={this.eCardStatus}
          covidManufacturers={this.state.covidManufacturers}
          // covidDose={this.state.covidDose}
          individualCertName={this.state.individualCertName}
        />

        {/* modal for editing profile */}
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestCloseExample"
          onRequestClose={this.handleCloseModal}
          className="modal"
          overlayClassName="overlay"
        >
          <div class="modal-container flex flex-center">
            <div className="modal-heading">
              <span className="modal-employee-name">{selectedEmployee.FirstName + ' ' + selectedEmployee.LastName}</span>
              <span className="modal-employee-job">{selectedEmployee.JobTitle}</span>
              {selectedEmployeeInfo && selectedEmployeeInfo.mobileSecurityID && (
                <div className="form-group-select-style2">
                  <select
                    className="form-group-select-style2-options"
                    value={this.state.mobileStatus}
                    onChange={(e) => {
                      this.setState({ mobileStatus: e.target.value });
                    }}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Not Active</option>
                  </select>
                </div>
              )}
            </div>
            <div className="modal-form">
              {selectedEmployeeInfo && selectedEmployeeInfo.mobileSecurityID && (
                <div className="form-group">
                  <div className="form-group-horizontal">
                    <span className="form-group-horizontal-label">Email</span>
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-group-text"
                      maxLength="40"
                      value={this.state.employeeEmailAddress}
                      onChange={(e) => {
                        this.setState({ employeeEmailAddress: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}
              {selectedEmployeeInfo && (
                <table>
                  <thead>
                    <tr>
                      <th colspan="2">App Info:</th>
                      <th colspan="2">Employee Info:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>App Version</b>
                      </td>
                      <td>{selectedEmployeeInfo.appVersion}</td>
                      <td>
                        <b>Manager</b>
                      </td>
                      <td>{selectedEmployeeInfo.JobTitle}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone Type</b>
                      </td>
                      <td>{selectedEmployeeInfo.appOS}</td>
                      <td>
                        <b>Work State</b>
                      </td>
                      <td>{selectedEmployeeInfo.EMP_STATE}</td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* <div className="form-group">
              <div className="form-group-select form-group-horizontal">
                <span className="form-group-horizontal-label">ERT</span>
                <select
                  className="form-group-select-options"
                  value={this.state.employeeIsErt === true || this.state.employeeIsErt === 'Yes' ? 'Yes' : 'No'}
                  onChange={e => {
                    this.setState({ employeeIsErt: e.target.value });
                  }}
                >
                  {isErtStatusOptionItems}
                </select>
              </div>
            </div> */}
              {/* {this.state.employeeIsErt === true || this.state.employeeIsErt === 'Yes' ? (
              <div className="form-group">
                <div className="form-group-select form-group-horizontal">
                  <span className="form-group-horizontal-label">ERT Status</span>
                  <select
                    className="form-group-select-options"
                    value={this.state.employeeErtStatus}
                    onChange={e => {
                      this.setState({ employeeErtStatus: e.target.value });
                    }}
                  >
                    <option key={null} />
                    {ertStatusOptionItems}
                  </select>
                </div>
              </div>
            ) : null} */}
              {/* {this.state.employeeIsErt === true || this.state.employeeIsErt === 'Yes' ? (
              <div className="form-group">
                <div className="form-group-select form-group-horizontal">
                  <span className="form-group-horizontal-label">ERT Function</span>
                  <select
                    className="form-group-select-options"
                    value={this.state.employeeFuntionErt}
                    onChange={e => {
                      this.setState({ employeeFuntionErt: e.target.value });
                    }}
                  >
                    <option key={null} />
                    {ertOptionItems}
                  </select>
                </div>
              </div>
            ) : null} */}
            </div>
            <div className="modal-btn">
              <button className="btn-long" href="#" disabled={this.state.isLoading} onClick={this.updateEmployeeDetails}>
                Save
              </button>
              <button className="btn-long-ghost" href="#" onClick={this.handleCloseModal}>
                Cancel
              </button>
            </div>
          </div>
          {/* <div className="modal-btn">
            <button className="btn-long" href="#" disabled={this.state.isLoading} onClick={this.updateEmployeeDetails}>
              Save
            </button>
            <button className="btn-long-ghost" href="#" onClick={this.handleCloseModal}>
              Cancel
            </button>
          </div> */}
        </ReactModal>

        {/* display employee profile */}
        <div className="profile">
          <div className="profile-heading">
            <div className="profile-heading-left">
              <span className="profile-employee-name">{selectedEmployee.FirstName + ' ' + selectedEmployee.LastName}&nbsp;{selectedEmployee.OracleEmpNum ? `(${selectedEmployee.OracleEmpNum})` : ''}</span>
              {/* <MdLock size={18} color="grey" className="icon-lock" /> */}
            </div>
            <div />

            <div className="profile-heading-right">
              {currentAdmin.adminTypeCode === 'SUPER' && (
                <div className="profile-edit" onClick={this.handleOpenModal}>
                  ADDITIONAL INFO
                </div>
              )}
            </div>
          </div>
          <div className="profile-detail">
            <div className="profile-detail-column">
              {/* <div className="detail-type">
                <div className="detail-type-name">Employee ID</div>
                <div className="detail-type-value">{selectedEmployee.OracleEmpNum}</div>
              </div> */}
              <div className="detail-type">
                <div className="detail-type-name">Work Status</div>
                <div className="detail-type-value">
                  {/* { this.state.employeeErtStatus } */}
                  <span className="ERT-status">{(selectedEmployee && selectedEmployee.EmployeeStatus) || ''}</span>
                </div>
              </div>
            </div>

            <div className="profile-detail-column">
              <div className="detail-type">
                <div className="detail-type-name email-short-name">Email</div>
                <div className="detail-type-value">{selectedEmployee.EmailAddress}</div>
              </div>

              {/* <div className="detail-type">
                <div className="detail-type-name mobile-short-name">Mobile Status</div>
                <div className="detail-type-value">{selectedEmployeeInfo ? (selectedEmployeeInfo.Active ? 'Active' : 'Inactive') : ''}</div>
                {<div className="detail-type-name">
                  Employee ID
                </div>
                <div className="detail-type-value">
                  {selectedEmployee.OracleEmpNum}
                </div>}
              </div> */}
            </div>

            <div className="profile-detail-column">
              {/* <div className="detail-type">
                <div className="detail-type-name">Login Name</div>
                {selectedEmployee.AD ? (
                  <div className="detail-type-value">{selectedEmployee.AD}</div>
                ) : (
                  <div className="detail-type-value">Not Mobile App User</div>
                )}
              </div> */}
              <div className="detail-type">
                <div className="detail-type-name">Last Log In</div>
                {
                  this.displayLastLogin().isPresent ? (
                    <div className="detail-type-value">
                      {this.displayLastLogin().value}
                    </div>
                  ) : (
                    <div className="detail-type-value">Not Mobile App User</div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="row-title-bu-heading">
            <div className="index-column">#</div>
            <div className="row-title-bu-status">Job Description</div>
            <div className="row-title-bu-status">Business Unit</div>
            <div className="row-title-bu-status">Business Unit Name</div>
            <div className="row-title-bu-status">Region</div>
            <div className="row-title-bu-status">Division</div>
          </div>

          {/* <div>
            {titles && (
              <div className="flex">
                {titles.map((title, index) => (
                  <div className="title-bu" key={index}>
                    <div className="index-content-column">
                      <p>{index + 1}</p>
                    </div>
                    <div className="row-bus-status">
                      <p>{title.JOB_TITLE}</p>
                    </div>
                    <div className="row-bus-status">
                      <p>{title.BUSINESS_UNIT}</p>
                    </div>
                    <div className="row-bus-status">
                      <p>{title.ENTITY}</p>
                    </div>
                    <div className="row-bus-status">
                      <p>{title.REGION}</p>
                    </div>
                    <div className="row-bus-status">
                      <p>{title.DIVISION}</p>
                    </div>
                  </div>
                ))}
              </div> */}
          <div>
            {/*             {selectedEmployeeInfo && (
              <div className="flex">
                <div className="title-bu">
                  <div className="index-content-column">
                    <p>1</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{selectedEmployeeInfo.JobTitle}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{selectedEmployeeInfo.BU_Name}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{selectedEmployeeInfo.BusUnit}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{selectedEmployeeInfo.REGION}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{selectedEmployeeInfo.DIVISION}</p>
                  </div>
                </div>
              </div>
            )} */}
            <div className="flex">
              {Array.isArray(selectedEmployeeInfo) && !!selectedEmployeeInfo.length && React.Children.toArray(selectedEmployeeInfo.map((emp, index) => (
                <div className="title-bu">
                  <div className="index-content-column">
                    <p>{index + 1}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{emp.JobTitle}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{emp.BusUnit}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{emp.BU_Name}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{emp.REGION}</p>
                  </div>
                  <div className="row-bus-status">
                    <p>{emp.DIVISION}</p>
                  </div>
                </div>
              )))}
            </div>
          </div>
        </div>

        <div className="legend">
          <div className="legend-column">
            <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#EA7E7E" />
              </svg>
              <div className="legend-name">Expired</div>
            </div>
            <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#0AE298" />
              </svg>
              <div className="legend-name">Current</div>
            </div>
            <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#EAE27E" />
              </svg>
              <div className="legend-name">Missing</div>
            </div>
            <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#BB7EEA" />
              </svg>
              <div className="legend-name">Pending</div>
            </div>
            <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#666666" />
              </svg>
              <div className="legend-name">Inactive</div>
            </div>
            {/* <div className="legend-group">
              <svg className="legend-type">
                <circle cx={10} cy={10} r={10} fill="#00000" />
              </svg>
              <div className="legend-name">Inactive</div>
            </div> */}
          </div>
          <div className="legend-group flex-column">
            {/* <div className="legend-name">
              Last System Update:{' '}
              {selectedEmployeeInfo ? (selectedEmployeeInfo.lastCertsRefresh ? selectedEmployeeInfo.lastCertsRefresh + ' (MDT)' : '') : ''}
            </div> */}
            {!!securityRoles.filter((v) => v.roleName.includes('HISTORICALDATA')).length && (
              <div className="legend-name">
                <div className="flex-row historical-data">
                  {/* {historicalDataValue['All'] && (
                    <Fragment>
                      <label className="checkbox-group">
                        <span>Active</span>
                        <input
                          type="radio"
                          className="checkbox-group-input"
                          name="historical-data"
                          onChange={this.onChangeHistoricalData}
                          value="Active"
                          checked={historicalDataValue['historical-data'] === 'Active'}
                        />
                        <span className="checkbox-group-checkmark" />
                      </label>
                      <label className="checkbox-group">
                        <span>InActive</span>
                        <input
                          type="radio"
                          className="checkbox-group-input"
                          name="historical-data"
                          onChange={this.onChangeHistoricalData}
                          value="InActive"
                          checked={historicalDataValue['historical-data'] === 'InActive'}
                        />
                        <span className="checkbox-group-checkmark" />
                      </label>
                    </Fragment>
                  )} */}
                  <span className="label">Display Historical Data:</span>
                  <label className="checkbox-group">
                    <input
                      type="checkbox"
                      className="checkbox-group-input"
                      checked={historicalDataValue['All']}
                      name="historical-data"
                      onChange={this.onChangeHistoricalData}
                      value="All"
                      />
                    <span className="checkbox-group-checkmark" />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row-cert-heading">
          {this.displayCertCount()}
          <div className="row-cert-status">Status</div>
          <div className="row-cert-issue">Issue Date</div>
          <div className="row-cert-issue">Expiration Date</div>
          <div className="row-cert-cpupdate">Certification Update</div>
          <div className="row-cert-cpupdate">Audit Verification</div>
          {!isSavingDisabled && (
            <div
              className="add-cert"
              onClick={() => {
                this.setState({ addCert: true, currentCert: '' });
              }}
              disabled={true}
            >
              Add Certificate
            </div>
          )}
        </div>

        <Loadable active={isLoading || isCertsLoading || this.state.isLoading} spinner text="Loading...">
          <div>
            {!getEmpErrorMsg ? Array.isArray(historicalEmployeeCerts) && historicalEmployeeCerts.length && !historicalDataValue['All']
              ? React.Children.toArray(historicalEmployeeCerts.map((cert) => this.renderListItem(cert)))
              : React.Children.toArray(
                _.keys(historicalEmployeeCerts).map((certName) => (
                  <Accordian title={certName}>{historicalEmployeeCerts[certName].map((cert) => this.renderListItem(cert))}</Accordian>
                ))
              ) : <div className='no-record-found'>{getEmpErrorMsg}</div>}
          </div>
        </Loadable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    CDMReducer: state.CDMReducer,
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postNewCert: (payload) => dispatch(postNewCert(payload)),
    updateCert: (adminID, payload) => dispatch(updateCert(adminID, payload)),
    setManualVerificationStatus: (adminID, payload) => dispatch(setManualVerificationStatus(adminID, payload)),
    employeeCerts: (oracleID) => dispatch(employeeCerts(oracleID)),
    historicalEmployeeCerts: (oracleID, certStatus) => dispatch(historicalEmployeeCerts(oracleID, certStatus)),
    terminatedEmployeeCerts: (oracleID, certStatus) => dispatch(terminatedEmployeeCerts(oracleID, certStatus)),
    updateEmployee: (params) => dispatch(updateEmployee(params)),
    clearError: () => dispatch(clearError()),
    setSelectedEmployee: (employee) => dispatch(setSelectedEmployee(employee)),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    getStateOrCounties: () => dispatch(getStateOrCounties()),
    setCurrentPath: (currentPath) => dispatch(setCurrentPath(currentPath)),
    //Enable when eVerify gets added again
    //eVerifyCert: (payload) => dispatch(eVerifyCert(payload)),
    auditVerification: (OracleEmpNum) => dispatch(auditVerification(OracleEmpNum)),
    regulatoryApproval: (OracleEmpNum) => dispatch(regulatoryApproval(OracleEmpNum)),
    getEmployee: (OracleEmpNum) => dispatch(getEmployee(OracleEmpNum)),
    addNewUpdateLog: (adminID, payload) => dispatch(addNewUpdateLog(adminID, payload)),
    setCertificationStatus: (adminID, payload) => dispatch(setCertificationStatus(adminID, payload)),
    adminSettings: (adminID) => dispatch(adminSettings(adminID)),
    searchEmployees: (searchText, adminID) => dispatch(searchEmployees(searchText, adminID)),
    searchTerminatedEmployees: (searchText, adminID) => dispatch(searchTerminatedEmployees(searchText, adminID)),
    // setCertificationStatus: (OracleEmpNum, certName, certNumber, certStatus) =>
    //   dispatch(setCertificationStatus(OracleEmpNum, certName, certNumber, certStatus))
  };
};

export default EmployeeDetail = connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);