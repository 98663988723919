//react
import React, { Component } from 'react';
import { MdSearch } from 'react-icons/lib/md';

const escapeRegExp = (term = '') => term.replace(/[\s\[\]\\]/g, '\\$&');

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: '',
      list: props.data || [],
      filterResult: []
    };
  }

  clearFilterBy = () => this.setState({ filterBy: '' });

  filterList = e => {
    const { value } = e.target;
    this.setState({ filterBy: value });

    // if no filter criteria, return and render the entire list
    if (!value.length || value === null || value.match(/^ *$/) !== null) {
      this.props.updateFilterResults(value, { list: this.props.data });
      return;
    }

    const list = [];
    const regExp = new RegExp(`${escapeRegExp(value)}`, 'i');

    this.props.filters.forEach(filter => {
      // go through each filter option
      this.props.data.forEach(item => {
        // go through each option to find a match
        const match = regExp.test(item[filter]);
        if (match && !list.includes(item)) {
          list.push(item);
        }
      });
    });

    if (list.length) {
      this.props.updateFilterResults(value, list);
    } else {
      // set list to empty for now, can show a message stating
      // that we could not find any results moving forward
      this.props.updateFilterResults(value, []);
    }
  };

  render() {
    return (
      <div className="side-search-bar">
        <input
          type="search"
          className="side-searchbox"
          value={this.state.filterBy}
          onChange={this.filterList}
          name="filterBy"
          placeholder={this.props.placeholder ? this.props.placeholder : 'Filter Results'}
        />
        <MdSearch size={20} color="white" className="page-icon-search" />
      </div>
    );
  }
}

export default SearchFilter;
