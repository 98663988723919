//Sets google as a global variable
/*global google*/

//react
import React, { Component } from 'react';
import { MdKeyboardArrowRight, MdAdd } from 'react-icons/lib/md';

//css
import './ContinuingEducation.css';

//components
import Heading from '../../components/Heading/Heading';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
import EmptyState from '../../components/EmptyState/EmptyState';
import MapComponent from '../../components/MapComponent/MapComponent';

// libraries
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Pagination from 'react-js-pagination';
import Loadable from 'react-loading-overlay';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// redux state
import { connect } from 'react-redux';
import { allActiveClasses, postNewClass, updateClass, deleteClass, resetDefaultValues } from './../../redux/modules/ContinuingEducation';
import { setCurrentPath } from './../../redux/modules/Admin';

class ContinuingEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEditable: true,
      isLoading: false,
      newItem: false,
      allItems: [],
      searchPlaceholder: 'Filter Items by Course Name, Start Date, End Date or Name',
      searchFilters: ['courseName', 'firstName', 'lastName', 'startDate', 'endDate'],
      filterResult: [],
      pagedResults: [],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 5,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      latitude: 37.730937,
      longitude: -121.439793,
      radius: 1609.34,
      currentItem: {
        id: 0,
        courseName: '',
        address: '',
        startDate: '',
        endDate: '',
        seatsAvailable: 1,
        isActive: true,
        description: '',
      },
      rawAddress: '',
      rawStartDate: '',
      rawEndDate: '',
      displayStartDate: '',
      displayEndDate: '',
      deleteConfirmation: false,
      testTime: moment()
        .utc()
        .format(),
      currentIndex: 0,
      isButtonDisabled: false,
      selectedAddress: false,
      timeout: undefined,
      previousStartDate: '',
      previousEndDate: '',
    };
    this.executeDatePickerBlurOnce = false;
  }


  //Triggered when page loads.
  componentDidMount() {
    const { currentCounty } = this.props.AdminReducer;
    this.fetchLatestRecords(currentCounty.businessUnit);
    this.getUserLocation();
    this.props.setCurrentPath('continuingeducation');
  }

  //When results return from server. Update the page UI.
  componentWillReceiveProps(nextProps) {
    const { availableClasses, isLoading, postedSuccessfully, error } = nextProps.ContinuingEducationReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading: isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }

    if (postedSuccessfully) {
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.resetDefaultValues();
      this.setState({ newItem: false, selectedAddress: false });
    } else if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    }
    if (availableClasses && availableClasses.length > 0) {
      this.setState({ allItems: availableClasses, newItem: false }, this.updateContentList);
      this.setState({ deleteConfirmation: false });
    } else {
      this.clearCurrentItem();
      this.setState({ allItems: [], newItem: true }, this.updateContentList);
    }
  }
  //Triggered when the user selects a new business Unit.
  componentDidUpdate(prevProps) {
    const { currentCounty, countyName } = prevProps.AdminReducer;
    // Typical usage (don't forget to compare props):
    if (currentCounty && this.props.AdminReducer.countyName !== countyName) {
      this.fetchLatestRecords(this.props.AdminReducer.currentCounty.businessUnit);
    }
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.setState({ latitude, longitude, latlng: [latitude, longitude] });
        },
        error => {
          this.setState({
            latitude: 39.600624,
            longitude: -104.892349,
            latlng: [39.600624, -104.892349]
          });
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    }
  };

  //Fetch page specific data here from the API.
  fetchLatestRecords = businessUnit => {
    this.props.allActiveClasses(businessUnit);
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: {
        ...this.state.currentItem,
        id: 0,
        courseName: '',
        address: '',
        startDate: '',
        endDate: '',
        seatsAvailable: 1,
        description: ''
      },
      newItem: true,
      rawStartDate: '',
      rawEndDate: '',
      displayStartDate: '',
      displayEndDate: ''
    });
  };

  delayedMapRefresh = async () => {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.setItemMapLocation(this.state.currentItem);
    }, 1500);
  };

  updateCurrentItem = () => {
    this.setItemMapLocation(this.state.currentItem);
    this.validateAllRequiredFields()
      .then(() => {
        this.setState({
          isButtonDisabled: true
        });

        // **** here's the timeout ****
        setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);

        const { id, courseName, address, startDate, endDate, seatsAvailable, description, isActive } = this.state.currentItem;

        //let sDate = (startDate instanceof moment) ? startDate : moment(new Date(startDate + ' GMT'));
        //let eDate = (endDate instanceof moment) ? endDate : moment(new Date(endDate + ' GMT'));
        if (moment(startDate).isAfter(endDate)) {
          toast.dismiss();
          toast.error('End Date/Time needs to be after Start Date/Time', {
            position: toast.POSITION.BOTTOM_CENTER
          });
          return;
        }

        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const { currentCounty } = this.props.AdminReducer;

        let updateItem = {
          id: id,
          businessUnitID: currentCounty.businessUnit,
          mobileSecurityID: currentAdmin.ID,
          courseName: courseName,
          address: address,
          startDate: startDate,
          endDate: endDate,
          // startDate: sDate,
          // endDate: eDate,
          isActive: isActive,
          seatsAvailable: seatsAvailable,
          description: description
        };
        this.props.updateClass(updateItem);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  deleteCurrentItem = () => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const { currentCounty } = this.props.AdminReducer;
    const { id } = this.state.currentItem;
    let deleteClass = this.props.deleteClass(id, currentAdmin.ID, currentCounty.businessUnit);
    if (deleteClass) {
      this.setState({ deleteConfirmation: false });
    }

    this.delayedMapRefresh();
  };

  addNewItem = () => {
    this.setItemMapLocation(this.state.currentItem);
    this.validateAllRequiredFields()
      .then(() => {
        const { courseName, address, startDate, endDate, seatsAvailable, description, isActive } = this.state.currentItem;

        //let sDate = (startDate instanceof moment) ? startDate : moment(new Date(startDate + ' GMT'));
        //let eDate = (endDate instanceof moment) ? endDate : moment(new Date(endDate + ' GMT'));

        if (startDate > endDate) {
          toast.dismiss();
          toast.error('End Date/Time needs to be after Start Date/Time', {
            position: toast.POSITION.BOTTOM_CENTER
          });
          return;
        }

        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const { stateCode, currentCounty } = this.props.AdminReducer;

        let newItem = {
          businessUnitID: currentCounty.businessUnit,
          mobileSecurityID: currentAdmin.ID,
          stateCode: stateCode,
          countyName: currentCounty.countyName,
          address: address,
          courseName: courseName,
          startDate: startDate,
          endDate: endDate,
          isActive: isActive,
          seatsAvailable: seatsAvailable,
          description: description
        };
        this.props.postNewClass(newItem);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });

    this.delayedMapRefresh();
  };

  //Current Page Specific Functionality:
  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { courseName, address, startDate, endDate, seatsAvailable, description } = this.state.currentItem;

      let requiredFields = [courseName, address, startDate, endDate, seatsAvailable, description];
      let missingFields = [];

      for (let field in requiredFields) {
        let currentField = requiredFields[field];
        if (!currentField) {
          let fieldName = '';
          switch (field) {
            case '0':
              fieldName = 'Course Name';
              break;
            case '1':
              fieldName = 'Address';
              break;
            case '2':
              fieldName = 'A Valid Start Date';
              break;
            case '3':
              fieldName = 'A Valid End Date';
              break;
            case '4':
              fieldName = 'Seats Available';
              break;
            case '5':
              fieldName = 'Description';
              break;
          }
          missingFields.push(fieldName);
        }
      }

      if (missingFields.length > 0) {
        toast.dismiss();

        let missingFieldsText = missingFields.length > 1 ? missingFields.join(', ') + ' are' : missingFields.join(', ') + ' is';
        reject(missingFieldsText + ' required to post a new Continuing Education class');
      }
      resolve();
    });
  };

  handleAddressSelect = address => {
    // set selectedAddress to true, so that when user clicks out of input box after selecting an address, input is not cleared
    this.setState(
      {
        selectedAddress: true,
        currentItem: { ...this.state.currentItem, address },
        rawAddress: ''
      },
      () => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
            this.setState({
              latitude: latLng.lat,
              longitude: latLng.lng,
              latLng: latLng
            });
          })
          .catch(error => {
            console.error('Error', error);
          });
      }
    );
  };

  setItemMapLocation = (item) => {

    if (!item) return;

    geocodeByAddress(item.address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          latLng: latLng
        });
      })
      .catch(error => {
        console.error('Error', error);
      });
  };

  onSetStartDate = date => {

    this.setState({
      currentItem: { ...this.state.currentItem, startDate: moment(date).local().format('YYYY-MM-DD HH:mm:ss') },
      displayStartDate: moment(date).format('MMMM DD YYYY HH:mm')
    });
    this.executeDatePickerBlurOnce = true;
    //  this.validateStartDate(date)


    /* let sDate = !startDate ? null : ((startDate instanceof moment) ? startDate : moment(new Date(startDate + ' GMT')));
     let eDate = !endDate ? null : ((endDate instanceof moment) ? endDate : moment(new Date(endDate + ' GMT')));
 
     const nowDT  = new Date();
     const startOfToday = new Date (nowDT.getUTCFullYear(), nowDT.getUTCMonth(), nowDT.getUTCDate(), 0, 0, 0, 0);
 
     if ((date._d.getHours() == 0) && (date._d.getMinutes() == 0)){
       if (!sDate) {
          date._d.setHours(date._d.getHours() + 8);
       }
     }
 
     if (date._d >= startOfToday) {
       this.setState({
         currentItem: { ...this.state.currentItem, startDate: date },
         displayStartDate: moment(date).format('MMMM DD YYYY HH:mm')
       });    
       if (!eDate) {
       }
       else {
           if (date._d >= eDate) {
             toast.dismiss();
             toast.warn('Start Date/Time MUST be before End Date/Time.', {
               position: toast.POSITION.BOTTOM_CENTER
             });           
           }
       }
     }
     else {
       toast.dismiss();
       toast.warn('Start Date/Time MUST NOT be in the past.', {
         position: toast.POSITION.BOTTOM_CENTER
       });
     }*/
  };

  validateStartDate = () => {
    if (!this.executeDatePickerBlurOnce) return;
    let isInValid = false;
    let endDate = this.state.currentItem.endDate;

    let sDate = this.state.currentItem.startDate ? moment(this.state.currentItem.startDate).local().format('YYYY-MM-DD HH:mm') : null;
    let eDate = endDate ? moment(endDate).local().format('YYYY-MM-DD HH:mm') : null;
    console.log('toast', toast);
    if (sDate < moment().local().format('YYYY-MM-DD HH:mm')) {
      toast.dismiss();
      const id = toast.warn('Start Date/Time MUST NOT be in the past.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      isInValid = true;
      console.log('id', id);
    } else if (eDate && eDate <= sDate) {
      toast.dismiss();
      const id = toast.warn('Start Date/Time MUST be before End Date/Time.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      console.log('id', id);
      isInValid = true;
    }

    if (isInValid) {
      this.setState({
        currentItem: { ...this.state.currentItem, startDate: this.state.previousStartDate },
        displayStartDate: null
      });
    }
    this.executeDatePickerBlurOnce = false;
  }

  onSetEndDate = date => {
    this.setState({
      currentItem: { ...this.state.currentItem, endDate: moment(date).local().format('YYYY-MM-DD HH:mm:ss') },
      displayEndDate: moment(date).format('MMMM DD YYYY HH:mm')
    });
    this.executeDatePickerBlurOnce = true;

    /* const { startDate, endDate } = this.state.currentItem;
     let sDate = !startDate ? null : ((startDate instanceof moment) ? startDate : moment(new Date(startDate + ' GMT')));
     let eDate = !endDate ? null : ((endDate instanceof moment) ? endDate : moment(new Date(endDate + ' GMT')));
 
     const nowDT  = new Date();
     const startOfToday = new Date (nowDT.getUTCFullYear(), nowDT.getUTCMonth(), nowDT.getUTCDate(), 0, 0, 0, 0);
 
     if ((date._d.getHours() == 0) && (date._d.getMinutes() == 0)){
       if (!eDate) {
         date._d.setHours(date._d.getHours() + 17);
       }
     }
 
     if (date._d >= startOfToday) {
       this.setState({
         currentItem: { ...this.state.currentItem, endDate: date },
         displayEndDate: moment(date).format('MMMM DD YYYY HH:mm')
       });
       if (!sDate) {
       }
       else {
          if (date._d <= sDate) {
             toast.dismiss();
             toast.warn('End Date/Time MUST be after Start Date/Time.', {
                position: toast.POSITION.BOTTOM_CENTER
             });
           }
       }
     }
     else {
       toast.dismiss();
       toast.warn('End Date/Time MUST NOT be in the past.', {
         position: toast.POSITION.BOTTOM_CENTER
       });
     }*/

  };

  validateEndDate = () => {
    if (!this.executeDatePickerBlurOnce) return;
    let isInValid = false;
    let startDate = this.state.currentItem.startDate;

    let sDate = startDate ? moment(startDate).local().format('YYYY-MM-DD HH:mm') : null;
    let eDate = this.state.currentItem.endDate ? moment(this.state.currentItem.endDate).local().format('YYYY-MM-DD HH:mm') : null;

    if (eDate < moment().local().format('YYYY-MM-DD HH:mm')) {
      toast.dismiss();
      toast.warn('End Date/Time MUST NOT be in the past.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      isInValid = true;
    } else if (sDate && eDate <= sDate) {
      toast.dismiss();
      toast.warn('End Date/Time MUST be after Start Date/Time.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      isInValid = true;
    }

    if (isInValid) {
      this.setState({
        currentItem: { ...this.state.currentItem, endDate: this.state.previousEndDate },
        displayStartDate: null
      });
    }
    this.executeDatePickerBlurOnce = false;
  }

  // handleRawStartDateChange = rawStartDate => this.setState({ rawStartDate, displayStartDate: '' });

  // handleRawEndDateChange = rawEndDate => this.setState({ rawEndDate, displayEndDate: '' });

  /*   onStartDateFocus = () => {
      // Clears field if invalid
      if (moment(this.state.currentItem.startDate).format('MMMM DD YYYY HH:mm') === 'Invalid date') {
        this.setState({
          currentItem: { ...this.state.currentItem, startDate: '' },
          displayStartDate: '',
          rawStartDate: ''
        });
      }
    }; */

  /*   onEndDateFocus = () => {
      // Clears field if invalid
      if (moment(this.state.currentItem.endDate).format('MMMM DD YYYY HH:mm') === 'Invalid date') {
        this.setState({
          currentItem: { ...this.state.currentItem, endDate: '' },
          displayEndDate: '',
          rawEndDate: ''
        });
      }
    }; */

  /*   onStartDateBlur = () => {
      // If there's valid raw input, set startDate and displayStartDate to moment(rawInput) and clear rawStartDate
      // If there's raw input but it's invalid, clear everything and warn user to enter another start date
      const { rawStartDate, currentItem } = this.state;
      if (rawStartDate !== '' && moment(rawStartDate).format('MMMM DD YYYY HH:mm') === 'Invalid date') {
        this.setState({
          currentItem: { ...currentItem, startDate: '' },
          displayStartDate: '',
          rawStartDate: ''
        });
        toast.dismiss();
        toast.warn('Please enter a valid start date.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
      if (rawStartDate !== '' && moment(rawStartDate).format('MMMM DD YYYY HH:mm') !== 'Invalid date') {
        const endDate = currentItem.endDate;
        let eDate = !endDate ? null : ((endDate instanceof moment) ? endDate : moment(new Date(endDate + ' GMT')));
        let sDate = moment(new Date(rawStartDate));
        this.setState({
          currentItem: {
            ...currentItem,
            startDate: sDate
          },
          displayStartDate: moment(rawStartDate).format('MMMM DD YYYY HH:mm'),
          rawStartDate: ''
        });
  
        if (eDate) {
          if (sDate._d > eDate._d) {
            toast.dismiss();
            toast.warn('Start Date/Time MUST be before End Date/Time.', {
               position: toast.POSITION.BOTTOM_CENTER
            });
          }
        }
      }
    }; */

  /*   onEndDateBlur = () => {
      const { rawEndDate, currentItem } = this.state;
      if (rawEndDate !== '' && moment(rawEndDate).format('MMMM DD YYYY HH:mm') === 'Invalid date') {
        this.setState({
          currentItem: { ...currentItem, endDate: '' },
          displayEndDate: '',
          rawEndDate: ''
        });
        toast.dismiss();
        toast.warn('Please enter a valid end date.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
      if (rawEndDate !== '' && moment(rawEndDate).format('MMMM DD YYYY HH:mm') !== 'Invalid date') {
        const startDate = currentItem.startDate;
        let sDate = !startDate ? null : ((startDate instanceof moment) ? startDate : moment(new Date(startDate + ' GMT')));
        let eDate = moment(new Date(rawEndDate));
        this.setState({
          currentItem: {
            ...currentItem,
            endDate: eDate
          },
          displayEndDate: moment(rawEndDate).format('MMMM DD YYYY HH:mm'),
          rawEndDate: ''
        });
  
        if (sDate) {
          if (eDate._d < sDate._d) {
            toast.dismiss();
            toast.warn('End Date/Time MUST be after Start Date/Time.', {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        }
      }
    }; */

  //***NO CODE UPDATE REQUIRED - well...unless fixing a bug or adding new functionality  :)***/
  cancelAdd = () => {
    this.setState({ newItem: false });
    if (this.state.allItems.length > 0 && this.state.pagedResults.length > 0) {
      this.setState({ currentItem: this.state.pagedResults[0] });
    }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filterResult) => {
    this.setState({ filterResult: filterResult, searchText: searchText, activePage: 1 }, this.updateContentList);
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    let currentPageNumber = this.state.activePage - 1;
    let arr =
      (this.state.filterResult && this.state.filterResult.length > 0) || this.state.searchText.length > 0
        ? this.state.filterResult
        : this.state.allItems;

    let groups = [],
      i;
    for (i = 0; i < arr.length; i += this.state.pageSize) {
      groups.push(arr.slice(i, i + this.state.pageSize));
    }
    this.setState({
      pagedResults: groups[currentPageNumber],
      totalItemsCount: groups.length
    });

    if (groups[currentPageNumber] && groups[currentPageNumber].length > 0) {
      this.setState({
        currentItem: groups[currentPageNumber][this.state.currentIndex],
        previousStartDate: groups[currentPageNumber][this.state.currentIndex].startDate,
        previousEndDate: groups[currentPageNumber][this.state.currentIndex].endDate,
      });
    }
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/

  render() {
    const {
      latitude,
      longitude,
      radius,
      searchText,
      searchFilters,
      allItems,
      searchPlaceholder,
      isLoading,
      pagedResults,
      activePage,
      totalItemsCount,
      pageRangeDisplayed,
      newItem,
      pageEditable,
      displayStartDate,
      displayEndDate,
      rawAddress
    } = this.state;
    let currentItem = null;
    if (!this.state.currentItem) {
      currentItem = {
        id: 0,
        courseName: '',
        address: '',
        startDate: '',
        endDate: '',
        seatsAvailable: 1,
        isActive: true,
        description: ''
      };
    } else {
      currentItem = this.state.currentItem;
      //this.setItemMapLocation(currentItem);
    }
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Continuing Education" />
        <div
          className="Edu-content"
          style={{
            opacity: this.state.deleteConfirmation === true ? '0.3' : '1'
          }}
        >
          <div className="left-container">
            <SearchFilter
              updateFilterResults={this.updateFilterResults}
              searchText={searchText}
              filters={searchFilters}
              data={allItems}
              placeholder={searchPlaceholder}
            />
            <div className="list-container">
              <Loadable active={isLoading} spinner text="Loading...">
                <div>
                  {pagedResults !== undefined &&
                    pagedResults.length > 0 &&
                    pagedResults.map((item, index) => (
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        key={item.id}
                        tabIndex={0}
                        className={pagedResults[index].id === currentItem.id ? 'list-item selected' : 'list-item'}
                        onClick={() => {
                          this.setState({
                            currentItem: item,
                            newItem: false,
                            currentIndex: index,
                            displayStartDate: '',
                            displayEndDate: ''
                          });
                        }}
                      >
                        <div className="list-item__left">
                          <div className="list-item__coursename">{item.courseName}</div>
                          <div className="list-item__durationseat">
                            {moment
                              .utc(item.startDate)
                              .local()
                              .format('MM/DD/YYYY')}{' '}
                            -{' '}
                            {moment
                              .utc(item.endDate)
                              .local()
                              .format('MM/DD/YYYY')}
                            , {item.seatsAvailable} {item.seatsAvailable === 1 ? 'Seat' : 'Seats'}
                          </div>
                        </div>
                        <div className="list-item__right">
                          <div className="list-item__time">
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .fromNow()}
                          </div>
                          <div className="list-item__name">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                        <div className="list-item__arrowright">
                          <MdKeyboardArrowRight size={25} color="grey" className="icon-arrowright" />
                        </div>
                      </div>
                    ))}

                  {(pagedResults === undefined || pagedResults.length === 0) && <EmptyState title="Nothing Here Yet" subTitle="" />}
                </div>
              </Loadable>
            </div>
            <div className="app-pagenation">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={1}
                totalItemsCount={totalItemsCount}
                pageRangeDisplayed={pageRangeDisplayed}
                onChange={this.handlePageChange}
              />
              <select className="form-group-select-options-page" onChange={this.changePageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              <button
                disabled={this.state.deleteConfirmation === true ? true : false}
                className="New-Message"
                style={{ display: newItem ? 'none' : 'flex' }}
                href="#"
                onClick={() => this.clearCurrentItem()}
              >
                <MdAdd size={23} className="icon-add" />
                New Course
              </button>
              <button
                disabled={!allItems.length > 0}
                className="Cancel"
                href="#"
                style={{ display: newItem ? 'flex' : 'none' }}
                onClick={() => {
                  this.cancelAdd();
                }}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoading}
                className="Create-btn"
                href="#"
                style={{ display: newItem ? 'flex' : 'none' }}
                onClick={this.props.ContinuingEducationReducer.isLoading === true ? null : this.addNewItem}
              >
                Submit
              </button>
            </div>
            <div className="form-container">
              {!newItem && (
                <div className="edu-title-container">
                  <p className="edu-title-content">
                    Created on{' '}
                    {moment
                      .utc(currentItem.createdDate)
                      .local()
                      .format('MM/DD/YYYY [at] h:mm:ss a')}{' '}
                    by {currentItem.firstName} {currentItem.lastName}
                  </p>
                  <div className="edu-title-btn">
                    <button
                      className="btn-long"
                      disabled={this.state.deleteConfirmation === true || this.state.isButtonDisabled}
                      onClick={this.updateCurrentItem}
                      style={{ display: pageEditable ? null : 'none' }}
                    >
                      Update
                    </button>
                    <button
                      disabled={this.state.deleteConfirmation === true ? true : false}
                      className="btn-long-ghost delete"
                      // onClick={this.deleteCurrentItem}
                      onClick={() => {
                        this.setState({ deleteConfirmation: true });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
              <div className="form-container-input">
                <div className="form-group">
                  <div className="form-group-stacked">
                    <span className="form-group-stacked-half-label">Course Name</span>
                    <input
                      maxLength={200}
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      type="text"
                      onChange={event =>
                        this.setState({
                          currentItem: {
                            ...currentItem,
                            courseName: event.target.value
                          }
                        })
                      }
                      value={currentItem.courseName === '' ? '' : currentItem.courseName}
                      placeholder={currentItem.courseName === '' ? 'Course Name' : ''}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked geolocation">
                    <span className="form-group-stacked-half-label">Address*</span>
                    <PlacesAutocomplete
                      value={currentItem.address !== '' ? currentItem.address : rawAddress}
                      onChange={event =>
                        this.setState({
                          currentItem: { ...currentItem, address: '' },
                          rawAddress: event
                        })
                      }
                      onSelect={this.handleAddressSelect}
                      searchOptions={{
                        componentRestrictions: { country: ['us'] },
                        location: new google.maps.LatLng(latitude, longitude),
                        radius: 50000
                      }}
                      debounce={1000}
                      shouldFetchSuggestions={rawAddress.length >= 4} // rawAddress is one behind
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div>
                          <input
                            {...getInputProps({
                              // placeholder: 'Search Places ...',
                              // className: 'location-search-input'
                              disabled: !pageEditable
                            })}
                            onKeyDown={event =>
                              // when user tries to hit the enter key without selecting an address
                              // 1. Set address to empty string to clear input to force them to select an address
                              // 2. Set the suggestions length to 0, so that it only shows suggestions when there is text in the input box
                              event.keyCode === 13 && this.state.selectedAddress === false
                                ? (this.setState({
                                  currentItem: { ...currentItem, address: '' }
                                }),
                                  (suggestions.length = 0))
                                : null
                            }
                            onBlur={event =>
                              // when user tries to click away out of text input without selecting an address
                              // 1. Set address to empty string to clear input to force them to select an address
                              // 2. Set the suggestions length to 0, so that it only shows suggestions when there is text in the input box
                              this.state.selectedAddress === false
                                ? (this.setState({
                                  currentItem: { ...currentItem, address: '' }
                                }),
                                  (suggestions.length = 0))
                                : null
                            }
                            className="form-group-text"
                          />
                          <span>
                            <div>
                              {suggestions.map(suggestion => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer'
                                  }
                                  : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer'
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </span>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">Start Date</span>
                    <DatePicker
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      selected={currentItem.startDate ? moment(currentItem.startDate) : null}
                      onChange={(date) => this.onSetStartDate(date)}
                      onBlur={() => this.validateStartDate()}
                      //onChangeRaw={event => this.handleRawStartDateChange(event.target.value)}
                      //onBlur={this.onStartDateBlur}
                      //onFocus={this.onStartDateFocus}
                      showTimeSelect
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      dateFormat="MMMM DD YYYY hh:mm a"
                      timeCaption="time"
                      placeholderText="Enter Start Date"
                    //value={displayStartDate !== '' && displayStartDate !== 'Invalid date' ? displayStartDate : null}
                    // readOnly={true}
                    />
                  </div>
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">End Date</span>
                    <DatePicker
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      selected={currentItem.endDate ? moment(currentItem.endDate) : null}
                      onChange={(date) => this.onSetEndDate(date)}
                      onBlur={() => this.validateEndDate()}
                      //onChangeRaw={event => this.handleRawEndDateChange(event.target.value)}
                      //onBlur={this.onEndDateBlur}
                      //onFocus={this.onEndDateFocus}
                      showTimeSelect
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      dateFormat="MMMM DD YYYY hh:mm a"
                      timeCaption="time"
                      placeholderText="Enter End Date"
                    //value={displayEndDate !== '' && displayEndDate !== 'Invalid date' ? displayEndDate : null}
                    // readOnly={true}
                    />
                  </div>
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">Available Seats</span>
                    <input
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      type="number"
                      onChange={event =>
                        this.setState({
                          currentItem: {
                            ...currentItem,
                            seatsAvailable: event.target.value
                          }
                        })
                      }
                      value={currentItem.seatsAvailable === '' || currentItem.seatsAvailable < 1 ? 1 : currentItem.seatsAvailable}
                      placeholder={currentItem.seatsAvailable === '' ? 'Available Seats' : ''}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked-textarea">
                    <span className="form-group-stacked-textarea-label">Class Description</span>
                    <textarea
                      maxLength={3000}
                      disabled={!pageEditable && !newItem}
                      className="form-group-textarea"
                      onChange={event =>
                        this.setState({
                          currentItem: {
                            ...currentItem,
                            description: event.target.value
                          }
                        })
                      }
                      value={currentItem.description === '' ? '' : currentItem.description}
                      placeholder={currentItem.description === '' ? 'Type Here...' : ''}
                    />
                  </div>
                </div>
              </div>
              {
                <div>
                  <MapComponent address={currentItem.address} latitude={this.state.latitude} longitude={this.state.longitude} radius={radius} id={currentItem.id} />
                </div>
              }
            </div>
          </div>
        </div>
        <div
          className="delete-item-modal-container"
          style={{
            display: this.state.deleteConfirmation === true ? 'block' : 'none'
          }}
        >
          <span>Are you sure you want to delete this course?</span>
          <div className="delete-item-modal-container__buttons">
            <button className="delete-item-modal-container__delete" onClick={this.deleteCurrentItem}>
              Delete
            </button>
            <button
              className="delete-item-modal-container__cancel"
              onClick={() => {
                this.setState({ deleteConfirmation: false });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    ContinuingEducationReducer: state.ContinuingEducationReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActiveClasses: businessUnitID => dispatch(allActiveClasses(businessUnitID)),
    postNewClass: params => dispatch(postNewClass(params)),
    updateClass: params => dispatch(updateClass(params)),
    deleteClass: (classID, mobileSecurityID, businessUnitID) => dispatch(deleteClass(classID, mobileSecurityID, businessUnitID)),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContinuingEducation);
