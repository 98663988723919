import axios from 'axios';
import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { store } from '../index.js';

//App Settings
import { history } from '../utils/AppSettings.js';
import {
  fullApiUrl, fullApiV1Url, gmrRestApiUrl, gmrRestApiUsername, gmrRestApiPassword, connectRestApiUrl,
  connectRestApiUsername,
  connectRestApiPassword
} from '../utils/NetworkSettings';
import { loginAdmin, refreshToken } from './../redux/modules/Admin';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

class RestApi {
  constructor() {
    let currentAdmin = sessionStorage.getItem('admin');
    this.requestToken = null;
    this.requestRefreshToken = null;
    this.oracleEmpNum = null;

    if (currentAdmin) {
      this.requestToken = JSON.parse(currentAdmin).Token;
      this.requestRefreshToken = JSON.parse(currentAdmin).RefreshToken;
      this.oracleEmpNum = JSON.parse(currentAdmin).OracleEmpNum;
      // console.log(this.requestRefreshToken);
    }

    const instance = axios.create({
      baseURL: fullApiUrl,
      headers: {
        'X-App-Name': 'AMRConnect',
        'X-Token-Key': this.requestToken
      }
    });

    this.instanceV1 = axios.create({
      baseURL: fullApiV1Url,
      headers: {
        'X-App-Name': 'AMRConnect',
        'X-Token-Key': this.requestToken
      }
    });

    const GMRInstance = axios.create({
      baseURL: gmrRestApiUrl,
      auth: {
        username: gmrRestApiUsername,
        password: gmrRestApiPassword
      }
    });

    const CONNECTInstance = axios.create({
      baseURL: connectRestApiUrl,
      auth: {
        username: connectRestApiUsername,
        password: connectRestApiPassword
      }
    });

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest) =>
      store.dispatch(refreshToken(this.oracleEmpNum, this.requestToken, this.requestRefreshToken, false)).then((tokenRefreshResponse) => {
        //failedRequest.forEach(v => v.response.config.headers['X-Token-Key'] = tokenRefreshResponse.admin.Token);
        failedRequest.response.config.headers['X-Token-Key'] = tokenRefreshResponse.admin.Token;
        return Promise.resolve();
      });

    createAuthRefreshInterceptor(instance, refreshAuthLogic, {
      retryInstance: instance, // default: undefined,
      // pauseInstanceWhileRefreshing: true
    });

    createAuthRefreshInterceptor(GMRInstance, refreshAuthLogic, {
      retryInstance: GMRInstance, // default: undefined,
      // pauseInstanceWhileRefreshing: true
    });

    createAuthRefreshInterceptor(CONNECTInstance, refreshAuthLogic, {
      retryInstance: CONNECTInstance, // default: undefined,
      // pauseInstanceWhileRefreshing: true
    });

    /*  axios.interceptors.response.use(response => {
       request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
       if(){}
       return request;
     }); */

    this.state = {
      restApi: instance,
      gmrApi: GMRInstance,
      amrV1Api: this.instanceV1,
      connectApi: CONNECTInstance,
      accessToken: this.requestToken,
      refreshToken: this.requestRefreshToken,
      OracleEmpNum: this.oracleEmpNum
    };
  }

  refreshToken(oracleEmpNum, token, refreshToken, param) {
    const payload = {
      oracleEmpNum: oracleEmpNum,
      token: token,
      refreshToken: refreshToken
      //amrV1Api: this.instanceV1,
    };
    // console.log(refreshToken);
    return this.POST('/admins?refreshToken=' + encodeURIComponent(param), payload).then((response) => {
      this.requestToken = response.admin.Token;
      if (response.admin.Token == '') {
        this.logOutAdmin();
      }
      return response;
    });
  }

  GMRGet(requestUrl) {
    return this.state.gmrApi
      .get(requestUrl)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          this.logOutAdmin();
        } else { */
        throw err;
        //}
      });
  }

  AMRV1GET(requestUrl) {
    return this.state.amrV1Api
      .get(requestUrl)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   this.logOutAdmin();
        // } else {
        throw err;
        //  }
      });
  }

  GMRPost(requestUrl, params) {
    return this.state.gmrApi
      .post(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          this.logOutAdmin();
        } else { */
        throw err;
        //}
      });
  }

  GET(requestUrl) {
    return this.state.restApi
      .get(requestUrl)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* debugger;
          console.log(this.state.OracleEmpNum);
          store.dispatch(if (err.response.status === 401) {
          refreshToken(this.state.OracleEmpNum, this.state.accessToken, this.state.refreshToken, true)).then((res) => {});

          //this.props.loginAdmin('');
          //this.logOutAdmin();
        } */
      });
  }

  POST(requestUrl, params) {
    return this.state.restApi
      .post(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          debugger;
          this.logOutAdmin();
        } else {
          throw err;
        } */
      });
  }

  PUT(requestUrl, params) {
    return this.state.restApi
      .put(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          debugger;
          this.logOutAdmin();
        } else {
          throw err;
        } */
      });
  }

  DELETE(requestUrl) {
    return this.state.restApi
      .delete(requestUrl)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          debugger;
          this.logOutAdmin();
        } else {
          throw err;
        } */
      });
  }

  EXTERNALGET(requestUrl) {
    return this.state.restApi
      .get(requestUrl)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        /* if (err.response.status === 401) {
          debugger;
          this.logOutAdmin();
        } else {
          throw err;
        } */
      });
  }

  EXTERNALPOST(requestUrl, params) {
    return this.state.restApi
      .post(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  CONNECT_POST(requestUrl, params) {
    return this.state.connectApi
      .post(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.logOutAdmin();
        } else {
          throw err;
        }
      });
  }

  CONNECT_GET(requestUrl, params) {
    return this.state.connectApi
      .get(requestUrl, params)
      .then((response) => {
        if (response.error) {
          throw response.error.message;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.logOutAdmin();
        } else {
          throw err;
        }
      });
  }

  logOutAdmin() {
    sessionStorage.removeItem('admin');
    localStorage.removeItem('businessUnits');
    localStorage.removeItem('securityRoles');
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE === true) {
      history.push('#' + '/login/');
    } else {
      history.push('/#/login/');
    }
    window.location.reload();
  }
  //   constructor (externalURL) {

  //     let requestUrl = externalURL ? externalURL : fullApiUrl;

  //     super(requestUrl, {
  //       headers: {
  //         // Include as many custom headers as you need
  //         Authorization: 'test',
  //         // Content-Type: application/json
  //         // and
  //         // Accept: application/json
  //         // are added by default
  //         Apptoken: 'RieT_NV-2017-dEc'
  //       },
  //       // Simulate a slow connection on development by adding
  //       // a 2 second delay before each request.
  //       devMode: true,
  //       simulatedDelay: 1000
  //     });
  //   }
}

export default RestApi;
