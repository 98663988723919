import React, { Component } from 'react';
import CheckboxTree from '../react-checkbox-tree/src/js/CheckboxTree';
import { MdCheckBoxOutlineBlank, MdCheckBox, MdIndeterminateCheckBox, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/lib/md';
import { FaPlus, FaMinus } from 'react-icons/lib/fa';

const icons = {
  check: <MdCheckBox size={20} />,
  uncheck: <MdCheckBoxOutlineBlank size={20} />,
  halfCheck: <MdIndeterminateCheckBox size={20} />,
  expandClose: <MdKeyboardArrowRight style={{ color: '#ccc' }} size={20} />,
  expandOpen: <MdKeyboardArrowDown size={20} />,
  expandAll: <FaPlus size={20} />,
  collapseAll: <FaMinus size={20} />
};
class MultiBUSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      expanded: []
    };
  }

  handleEdit = () => {
    this.props.onEdit();
  };

  handleCheck = checked => {
    this.props.onCheck(checked);
  };

  handleExpand = (expanded, targetNode) => {
    this.setState({ expanded });
    if (targetNode && this.props.onExpand) {
      this.props.onExpand(targetNode);
    }
  };

  render() {
    const { nodes, checked, disabled } = this.props;
    return (
      <div>
        <CheckboxTree
          {...this.props}
          nodes={nodes}
          checked={checked}
          expanded={this.state.expanded}
          onCheck={this.handleCheck}
          onExpand={this.handleExpand}
          onEdit={this.handleEdit}
          nativeCheckboxes
          nameAsArray
          icons={icons}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default MultiBUSelect;
