import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import AdminReducer from '../modules/Admin';
import GeofencedNotificationsReducer from '../modules/GeofencedNotifications';
import ContinuingEducationReducer from '../modules/ContinuingEducation';
import PushNotificationsReducer from '../modules/PushNotifications';
import ProtocolsReducer from '../modules/Protocols';
import DocumentsReducer from '../modules/Documents';
import FormsReducer from '../modules/Forms';
import TermsReducer from '../modules/Terms';
import PrivacyReducer from '../modules/Privacy';
import AlertReducer from '../modules/Alert';
import CDMReducer from '../modules/CDM';
import ContactsReducer from '../modules/Contacts';
import SelfServiceReducer from '../modules/SelfService';

export default function configureStore() {
  let reducer = combineReducers({
    AdminReducer: AdminReducer,
    GeofencedNotificationsReducer: GeofencedNotificationsReducer,
    ContinuingEducationReducer: ContinuingEducationReducer,
    PushNotificationsReducer: PushNotificationsReducer,
    ProtocolsReducer: ProtocolsReducer,
    DocumentsReducer: DocumentsReducer,
    FormsReducer: FormsReducer,
    TermsReducer: TermsReducer,
    PrivacyReducer: PrivacyReducer,
    AlertReducer: AlertReducer,
    CDMReducer: CDMReducer,
    ContactsReducer,
    SelfServiceReducer
  });
  let store = createStore(reducer, applyMiddleware(thunk));
  return store;
}
