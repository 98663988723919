import ContinuingEducation from '../../httpClient/ContinuingEducation';

//Action Types
export const types = {
  FETCHING_CONTINUINGEDUCATION_DATA: 'FETCHING_CONTINUINGEDUCATION_DATA',
  FETCHING_CONTINUINGEDUCATION_DATA_SUCCESS: 'FETCHING_CONTINUINGEDUCATION_DATA_SUCCESS',
  FETCHING_CONTINUINGEDUCATION_DATA_FAILURE: 'FETCHING_CONTINUINGEDUCATION_DATA_FAILURE',
  NEW_CONTINUINGEDUCATION_DATA_SUCCESS: 'NEW_CONTINUINGEDUCATION_DATA_SUCCESS',
  UPDATE_CONTINUINGEDUCATION_DATA_SUCCESS: 'UPDATE_CONTINUINGEDUCATION_DATA_SUCCESS',
  DELETE_CONTINUINGEDUCATION_DATA_SUCCESS: 'DELETE_CONTINUINGEDUCATION_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  availableClasses: [],
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_CONTINUINGEDUCATION_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_CONTINUINGEDUCATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableClasses: action.availableClasses,
        error: null
      };

    case types.FETCHING_CONTINUINGEDUCATION_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_CONTINUINGEDUCATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: true,
        availableClasses: action.availableClasses,
        error: null
      };

    case types.UPDATE_CONTINUINGEDUCATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: true,
        availableClasses: action.availableClasses,
        error: null
      };

    case types.DELETE_CONTINUINGEDUCATION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableClasses: action.availableClasses,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveClasses(businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTINUINGEDUCATION_DATA });
    return ContinuingEducation.allActiveClasses(businessUnitID)
      .then(data => {
        dispatch({
          type: types.FETCHING_CONTINUINGEDUCATION_DATA_SUCCESS,
          availableClasses: data.availableClasses
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_CONTINUINGEDUCATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function postNewClass(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTINUINGEDUCATION_DATA });
    return ContinuingEducation.postNewClass(params)
      .then(data => {
        dispatch({
          type: types.NEW_CONTINUINGEDUCATION_DATA_SUCCESS,
          availableClasses: data.availableClasses
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_CONTINUINGEDUCATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function updateClass(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTINUINGEDUCATION_DATA });
    return ContinuingEducation.updateClass(params)
      .then(data => {
        dispatch({
          type: types.UPDATE_CONTINUINGEDUCATION_DATA_SUCCESS,
          availableClasses: data.availableClasses
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_CONTINUINGEDUCATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function deleteClass(classID, mobileSecurityID, businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_CONTINUINGEDUCATION_DATA });
    return ContinuingEducation.deleteClass(classID, mobileSecurityID, businessUnitID)
      .then(data => {
        dispatch({
          type: types.DELETE_CONTINUINGEDUCATION_DATA_SUCCESS,
          availableClasses: data.availableClasses
        });
      })
      .catch(err => {
        dispatch({
          type: types.FETCHING_CONTINUINGEDUCATION_DATA_FAILURE,
          error: err
        });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
