const getUrl = () => {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case 'dev':
      return {
        apiBaseEndpointUrl: process.env.REACT_APP_DEV_API_BASE_URL,
        redirectUri: process.env.REACT_APP_DEV_OKTA_REDIRECT,
        clientId: process.env.REACT_APP_DEV_OKTA_CLIENT_ID,
        baseUrl: process.env.REACT_APP_DEV_OKTA_BASE_URL,
        gmrRestApiUrl: process.env.REACT_APP_DEV_GMR_API_URL,
        gmrRestApiUsername: process.env.REACT_APP_DEV_GMR_API_USERNAME,
        gmrRestApiPassword: process.env.REACT_APP_DEV_GMR_API_PASSWORD,
        connectRestApiUrl: process.env.REACT_APP_DEV_CONNECT_API_URL,
        connectRestApiUsername: process.env.REACT_APP_DEV_CONNECT_API_USERNAME,
        connectRestApiPassword: process.env.REACT_APP_DEV_CONNECT_API_PASSWORD
      };
    case 'qa':
      return {
        apiBaseEndpointUrl: process.env.REACT_APP_QA_API_BASE_URL,
        redirectUri: process.env.REACT_APP_QA_OKTA_REDIRECT,
        clientId: process.env.REACT_APP_PROD_OKTA_CLIENT_ID,
        baseUrl: process.env.REACT_APP_PROD_OKTA_BASE_URL,
        gmrRestApiUrl: process.env.REACT_APP_QA_GMR_API_URL,
        gmrRestApiUsername: process.env.REACT_APP_QA_GMR_API_USERNAME,
        gmrRestApiPassword: process.env.REACT_APP_QA_GMR_API_PASSWORD,
        connectRestApiUrl: process.env.REACT_APP_QA_CONNECT_API_URL,
        connectRestApiUsername: process.env.REACT_APP_QA_CONNECT_API_USERNAME,
        connectRestApiPassword: process.env.REACT_APP_QA_CONNECT_API_PASSWORD
      };
    case 'uat':
      return {
        apiBaseEndpointUrl: process.env.REACT_APP_UAT_API_BASE_URL,
        redirectUri: process.env.REACT_APP_UAT_OKTA_REDIRECT,
        clientId: process.env.REACT_APP_PROD_OKTA_CLIENT_ID,
        baseUrl: process.env.REACT_APP_PROD_OKTA_BASE_URL,
        gmrRestApiUrl: process.env.REACT_APP_UAT_GMR_API_URL,
        gmrRestApiUsername: process.env.REACT_APP_UAT_GMR_API_USERNAME,
        gmrRestApiPassword: process.env.REACT_APP_UAT_GMR_API_PASSWORD,
        connectRestApiUrl: process.env.REACT_APP_UAT_CONNECT_API_URL,
        connectRestApiUsername: process.env.REACT_APP_UAT_CONNECT_API_USERNAME,
        connectRestApiPassword: process.env.REACT_APP_UAT_CONNECT_API_PASSWORD
      };
    case 'prod':
      return {
        apiBaseEndpointUrl: process.env.REACT_APP_PROD_API_BASE_URL,
        redirectUri: process.env.REACT_APP_PROD_OKTA_REDIRECT,
        clientId: process.env.REACT_APP_PROD_OKTA_CLIENT_ID,
        baseUrl: process.env.REACT_APP_PROD_OKTA_BASE_URL,
        gmrRestApiUrl: process.env.REACT_APP_PROD_GMR_API_URL,
        gmrRestApiUsername: process.env.REACT_APP_PROD_GMR_API_USERNAME,
        gmrRestApiPassword: process.env.REACT_APP_PROD_GMR_API_PASSWORD,
        connectRestApiUrl: process.env.REACT_APP_PROD_CONNECT_API_URL,
        connectRestApiUsername: process.env.REACT_APP_PROD_CONNECT_API_USERNAME,
        connectRestApiPassword: process.env.REACT_APP_PROD_CONNECT_API_PASSWORD
      };
    default:
      return {
        apiBaseEndpointUrl: process.env.REACT_APP_DEV_API_BASE_URL,
        redirectUri: process.env.REACT_APP_DEV_OKTA_REDIRECT,
        clientId: process.env.REACT_APP_DEV_OKTA_CLIENT_ID,
        baseUrl: process.env.REACT_APP_DEV_OKTA_BASE_URL,
        gmrRestApiUrl: process.env.REACT_APP_DEV_GMR_API_URL,
        gmrRestApiUsername: process.env.REACT_APP_DEV_GMR_API_USERNAME,
        gmrRestApiPassword: process.env.REACT_APP_DEV_GMR_API_PASSWORD,
        connectRestApiUrl: process.env.REACT_APP_DEV_CONNECT_API_URL,
        connectRestApiUsername: process.env.REACT_APP_DEV_CONNECT_API_USERNAME,
        connectRestApiPassword: process.env.REACT_APP_DEV_CONNECT_API_PASSWORD
      };
  }
};

const {
  apiBaseEndpointUrl,
  redirectUri,
  clientId,
  baseUrl,
  gmrRestApiUrl,
  gmrRestApiUsername,
  gmrRestApiPassword,
  connectRestApiUrl,
  connectRestApiUsername,
  connectRestApiPassword
} = getUrl();
const apiBaseEndpointV1Version = 'v1';
const apiBaseEndpointVersion = 'admin';
const tokenAuthPath = 'oauth/token';
const googleGeoCodeAddressURL = 'https://maps.google.com/maps/api/geocode';
const fullApiUrl = `${apiBaseEndpointUrl}/api/${apiBaseEndpointVersion}/`;
const fullApiV1Url = `${apiBaseEndpointUrl}/api/${apiBaseEndpointV1Version}/`;
const cdmApiUrl = 'http://localhost:61209/api/';

export {
  apiBaseEndpointUrl,
  redirectUri,
  clientId,
  baseUrl,
  apiBaseEndpointVersion,
  tokenAuthPath,
  googleGeoCodeAddressURL,
  fullApiUrl,
  fullApiV1Url,
  cdmApiUrl,
  gmrRestApiUrl,
  gmrRestApiUsername,
  gmrRestApiPassword,
  connectRestApiUrl,
  connectRestApiUsername,
  connectRestApiPassword
};
