//react
import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

//css
import './Dashboard.css';

//components
import DashboardModule from '../../components/DashboardModule/DashboardModule';
import TableauEmbed from '../../components/TableauEmbed/TableauEmbed';

//images
import mapIcon from '../../assets/images/mapIcon.png';
import notificationsIcon from '../../assets/images/notificationsIcon.png';
import protocolsIcon from '../../assets/images/protocolsIcon.png';
import educationIcon from '../../assets/images/educationIcon.png';
import formsIcon from '../../assets/images/formsIcon.png';
import documentsIcon from '../../assets/images/documentsIcon.png';
import cdmIcon from '../../assets/images/cdmIcon.png';
import emptyStateClock from '../../assets/images/emptystate-clock.png';
import contactsIcon from '../../assets/images/contactsIcon.png';
import selfserviceIcon from '../../assets/images/selfServiceIcon.png';
//Redux
import { connect } from 'react-redux';
import { adminSettings, setCurrentPath, adminProfile } from './../../redux/modules/Admin';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessUnits: [],
      securityRoles: [],
      viewAll: false,
      viewGeofencedNotifications: false,
      viewPushNotifications: false,
      viewProtocols: false,
      viewContinuingEducation: false,
      viewForms: false,
      viewDocuments: false,
      viewEmployeeManagement: false,
      viewContacts: false,
      viewSelfService: false,
      viewNotifications: false
    };
  }

  componentWillMount() {
    let securityRoles = JSON.parse(localStorage.getItem('securityRoles'));
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    this.props.adminProfile(currentAdmin.OracleEmpNum);

    if (securityRoles === null || securityRoles.length === 0) {
      this.props.adminSettings(currentAdmin.ID).then(() => {
        this.sortTilePermissions();
      });
    } else {
      this.sortTilePermissions();
    }
  }

  componentDidMount() {
    this.props.setCurrentPath('dashboard');
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.AdminReducer.error,
      loading: nextProps.AdminReducer.isLoading,
      securityRoles: nextProps.AdminReducer.securityRoles
    });
  }

  sortTilePermissions = () => {
    let businessUnits = JSON.parse(localStorage.getItem('businessUnits'));
    this.setState({ businessUnits: businessUnits });

    let securityRoles = JSON.parse(localStorage.getItem('securityRoles'));
    this.setState({ viewAll: true })

    if (securityRoles !== null) {
      for (let i = 0, len = securityRoles.length; i < len; i++) {
        // switch (securityRoles[i].roleName) {
        //   case 'ADMIN-FULL':
        //     this.setState({ viewAll: true });
        //     break;
        //   case 'ADMIN-GEONOTIFCATIONS':
        //     this.setState({ viewGeofencedNotifications: true, viewNotifications: true });
        //     break;
        //   case 'ADMIN-PROTOCOLS':
        //     this.setState({ viewProtocols: true });
        //     break;
        //   case 'ADMIN-NOTIFICATIONS':
        //     this.setState({ viewPushNotifications: true, viewNotifications: true });
        //     break;
        //   case 'ADMIN-EDUCATION':
        //     this.setState({ viewContinuingEducation: true });
        //     break;
        //   case 'ADMIN-DOCUMENTS':
        //     this.setState({ viewDocuments: true });
        //     break;
        //   case 'ADMIN-FORMS':
        //     this.setState({ viewForms: true });
        //     break;
        //   case 'ADMIN-EMPLOYEEMANAGE':
        //     this.setState({ viewEmployeeManagement: true });
        //     break;
        //   case 'ADMIN-SELFSERVICE':
        //     this.setState({ viewSelfService: true });
        //     break;
        //   case 'ADMIN-CONTACTS':
        //     this.setState({ viewContacts: true });
        //     break;
        //   case 'ADMIN-HISTORICALDATA':
        //     this.setState({ viewContacts: true });
        //     break;
        //   default:
            // this.setState({
            //   viewAll: false,
            //   viewGeofencedNotifications: false,
            //   viewProtocols: false,
            //   viewPushNotifications: false,
            //   viewContinuingEducation: false,
            //   viewDocuments: false,
            //   viewForms: false,
            //   viewEmployeeManagement: false,
            //   viewSelfService: false,
            //   viewContacts: false,
            //   viewNotifications: false
            // });
            // break;
        // }
        // switch (securityRoles[i].roleName) {
        //   case 'ADMIN-FULL':
        //     this.setState({ viewAll: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-GEONOTIFCATIONS') {
        //     this.setState({ viewGeofencedNotifications: true, viewNotifications: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-PROTOCOLS') {
        //     this.setState({ viewProtocols: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-NOTIFICATIONS') {
        //     this.setState({ viewPushNotifications: true, viewNotifications: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-EDUCATION') {
        //     this.setState({ viewContinuingEducation: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-DOCUMENTS') {
        //     this.setState({ viewDocuments: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-FORMS') {
        //     this.setState({ viewForms: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-EMPLOYEEMANAGE') {
        //     this.setState({ viewEmployeeManagement: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-SELFSERVICE') {
        //     this.setState({ viewSelfService: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-CONTACTS') {
        //     this.setState({ viewContacts: true });
        //   } else if (securityRoles[i].roleName === 'ADMIN-HISTORICALDATA') {
        //     this.setState({ viewContacts: true });
        //   }
        // switch (securityRoles[i].roleName) {
        //   case 'ADMIN-FULL':
        //     this.setState({ viewAll: true });
        //     break;
        //   case 'ADMIN-GEONOTIFCATIONS':
        //     this.setState({ viewGeofencedNotifications: true, viewNotifications: true });
        //     break;
        //   case 'ADMIN-PROTOCOLS':
        //     this.setState({ viewProtocols: true });
        //     break;
        //   case 'ADMIN-NOTIFICATIONS':
        //     this.setState({ viewPushNotifications: true, viewNotifications: true });
        //     break;
        //   case 'ADMIN-EDUCATION':
        //     this.setState({ viewContinuingEducation: true });
        //     break;
        //   case 'ADMIN-DOCUMENTS':
        //     this.setState({ viewDocuments: true });
        //     break;
        //   case 'ADMIN-FORMS':
        //     this.setState({ viewForms: true });
        //     break;
        //   case 'ADMIN-EMPLOYEEMANAGE':
        //     this.setState({ viewEmployeeManagement: true });
        //     break;
        //   case 'ADMIN-SELFSERVICE':
        //     this.setState({ viewSelfService: true });
        //     break;
        //   case 'ADMIN-CONTACTS':
        //     this.setState({ viewContacts: true });
        //     break;
        //   case 'ADMIN-HISTORICALDATA':
        //     this.setState({ viewContacts: true });
        //     break;
        //   default:
        //     this.setState({
        //       viewAll: false,
        //       viewGeofencedNotifications: false,
        //       viewProtocols: false,
        //       viewPushNotifications: false,
        //       viewContinuingEducation: false,
        //       viewDocuments: false,
        //       viewForms: false,
        //       viewEmployeeManagement: false,
        //       viewSelfService: false,
        //       viewContacts: false,
        //       viewNotifications: false
        //     });
        //     break;
        // }
      }
    }
  };

  render() {
    return (
      <div>
        <div className="modulesHolder">
          {this.state.businessUnits !== null && this.state.businessUnits.length > 0 ? (
            <div>
              {/* {this.state.viewGeofencedNotifications || this.state.viewAll ? (
                <Link to="/GeofencedNotifications">
                  <DashboardModule
                    icon={mapIcon}
                    title="Geofenced Notifications"
                    description="Geofencing allows you to send notifications to users within certain range of a geo-location"
                  />
                </Link>
              ) : null}

              {this.state.viewPushNotifications || this.state.viewAll ? (
                <Link to="/PushNotifications">
                  <DashboardModule
                    icon={notificationsIcon}
                    title="Push Notifications"
                    description="Send push notifications to all employees for the selected county"
                  />
                </Link>
              ) : null} */}

              {this.state.viewContacts || this.state.viewAll ? (
                <Link to="/Contacts">
                  <DashboardModule icon={contactsIcon} title="Contacts" description="Add/Edit contact information for people or companies" />
                </Link>
              ) : null}

              {this.state.viewContinuingEducation || this.state.viewAll ? (
                <Link to="/ContinuingEducation">
                  <DashboardModule
                    icon={educationIcon}
                    title="Continuing Education"
                    description="Public users will be able to register for classes they are interested in."
                  />
                </Link>
              ) : null}

              {this.state.viewEmployeeManagement || this.state.viewAll ? (
                <Link to="/CDM/Search">
                  <DashboardModule icon={cdmIcon} title="Credential Management" description="Credential Management" />
                </Link>
              ) : null}

              {this.state.viewDocuments || this.state.viewAll ? (
                <Link to="/Documents">
                  <DashboardModule
                    icon={documentsIcon}
                    title="Documents"
                    description="Upload documents that will be accessible to AMR personnel. Each document must be in PDF format."
                  />
                </Link>
              ) : null}

              {/* {this.state.viewForms || this.state.viewAll ? (
                <Link to="/Forms">
                  <DashboardModule
                    icon={formsIcon}
                    title="Forms"
                    description="Add/Edit forms that will be used to gather information from your users."
                  />
                </Link>
              ) : null} */}

              {this.state.viewNotifications || this.state.viewAll ? (
                <Link to="/Notifications">
                  <DashboardModule
                    icon={notificationsIcon}
                    title="Notifications"
                    description="Send push notifications to all employees of the selected counties"
                  />
                </Link>
              ) : null}

              {this.state.viewProtocols || this.state.viewAll ? (
                <Link to="/Protocols">
                  <DashboardModule
                    icon={protocolsIcon}
                    title="Protocols"
                    description="Upload documents/system of rules that explain the correct conduct and procedures to be followed in different situations."
                  />
                </Link>
              ) : null}

              {/* {this.state.viewForms || this.state.viewAll ? (
                <Link to="/Forms">
                  <DashboardModule
                    icon={formsIcon}
                    title="Forms"
                    description="Add/Edit forms that will be used to gather information from your users."
                  />
                </Link>
              ) : null} */}

              {this.state.viewSelfService || this.state.viewAll ? (
                <Link to="/SelfService">
                  <DashboardModule icon={selfserviceIcon} title="Self Service" description="Upload links to external websites" />
                </Link>
              ) : null}
              {/* <div>
                <a href="https://tableau.amr.net/#/site/AMR/views/CertificationAudit/Summary?:iid=1" target="_blank">
                  <DashboardModule icon={documentsIcon} title="Certification Audit" description="" />
                </a>
              </div> */}
            </div>
          ) : this.state.viewEmployeeManagement ? (
            <Link to="/CDM/Search">
              <DashboardModule icon={cdmIcon} title="Credential Management" description="Credential Management" />
            </Link>
          ) : null}
        </div>
        {/* <div>
          <TableauEmbed />
        </div> */}
        {!this.state.viewEmployeeManagement && this.state.businessUnits !== null && this.state.businessUnits.length === 0 && (
          <div className="noaccess-empty">
            <img src={emptyStateClock} />
            <p className="empty-staytuned">Please stay tuned.</p>
            <p>Your setup hasn't been completed for the Admin Portal. </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminSettings: (adminID) => dispatch(adminSettings(adminID)),
    setCurrentPath: (currentPath) => dispatch(setCurrentPath(currentPath)),
    adminProfile: (adminID) => dispatch(adminProfile(adminID))
  };
};

export default Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
