//react
import React, { Component } from 'react';
import { MdKeyboardArrowRight, MdAdd } from 'react-icons/lib/md';

//css
import './PushNotifications.css';

//components
import Heading from '../../components/Heading/Heading';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
import EmptyState from '../../components/EmptyState/EmptyState';

// libraries
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import Loadable from 'react-loading-overlay';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux state
import { connect } from 'react-redux';
import { allActivePushNotifications, postNewNotification, deleteNotification, resetDefaultValues } from './../../redux/modules/PushNotifications';
import { setCurrentPath } from './../../redux/modules/Admin';

class PushNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEditable: false,
      isLoading: false,
      newItem: false,
      allItems: [],
      searchPlaceholder: 'Filter Items by Message',
      searchFilters: ['message'],
      filterResult: [],
      pagedResults: [],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 5,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      currentItem: {
        id: 0,
        message: ''
      }
    };
  }

  //Triggered when page loads.
  componentDidMount() {
    const { currentCounty } = this.props.AdminReducer;
    this.fetchLatestRecords(currentCounty.businessUnit);
    this.props.setCurrentPath('pushnotifications');
  }

  //When results return from server. Update the page UI.
  componentWillReceiveProps(nextProps) {
    const { allNotifications, isLoading, postedSuccessfully, error } = nextProps.PushNotificationsReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading: isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }

    if (postedSuccessfully) {
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.resetDefaultValues();
      this.setState({ newItem: false });
    }
    if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    }
    if (allNotifications && allNotifications.length > 0) {
      if (allNotifications !== this.state.allItems) {
        for (let i = 0; i < allNotifications.length; i++) {
          allNotifications[i].startDate = moment
            .utc(allNotifications[i].startDate)
            .local()
            .format('MM/DD/YYYY HH:mm:ss');
          allNotifications[i].expiryDate = moment
            .utc(allNotifications[i].expiryDate)
            .local()
            .format('MM/DD/YYYY HH:mm:ss');
          // allNotifications[i].createdDate = moment.utc(allNotifications[i].createdDate).local().format('MM/DD/YYYY HH:mm:ss');
        }
      }
      this.setState({ allItems: allNotifications, newItem: false }, this.updateContentList);
    } else {
      this.setState({ allItems: [], newItem: true }, this.updateContentList);
    }
  }
  //Triggered when the user selects a new business Unit.
  componentDidUpdate(prevProps) {
    const { currentCounty, countyName } = prevProps.AdminReducer;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    // Typical usage (don't forget to compare props):
    if ((currentCounty && this.props.AdminReducer.countyName !== countyName) || (currentCounty && currentCounty.businessUnit !== businessUnit)) {
      this.fetchLatestRecords(this.props.AdminReducer.currentCounty.businessUnit);
    }
  }

  //Fetch page specific data here from the API.
  fetchLatestRecords = businessUnit => {
    this.props.allActivePushNotifications(businessUnit);
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: {
        id: 0,
        message: ''
      },
      newItem: true
    });
  };

  deleteCurrentItem = () => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    const { currentCounty } = this.props.AdminReducer;
    const { id } = this.state.currentItem;
    this.props.deleteNotification(id, currentAdmin.ID, currentCounty.businessUnit);
  };

  addNewItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { message } = this.state.currentItem;
        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const { stateCode, countyName, currentCounty } = this.props.AdminReducer;
        let newItem = {
          businessUnitID: currentCounty.businessUnit,
          mobileSecurityID: currentAdmin.ID,
          stateCode: stateCode,
          countyName: countyName,
          message: message
        };
        this.props.postNewNotification(newItem);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  //Current Page Specific Functionality:
  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { message } = this.state.currentItem;
      if (!message) {
        toast.dismiss();
        reject('All fields are required to post a new Push Notification');
      }
      resolve();
    });
  };

  //***NO CODE UPDATE REQUIRED - well...unless fixing a bug or adding new functionality  :)***/
  cancelAdd = () => {
    this.setState({ newItem: false });
    if (this.state.allItems.length > 0 && this.state.pagedResults.length > 0) {
      this.setState({ currentItem: this.state.pagedResults[0] });
    }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filterResult) => {
    this.setState({ filterResult: filterResult, searchText: searchText, activePage: 1 }, this.updateContentList);
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    let currentPageNumber = this.state.activePage - 1;
    let arr =
      (this.state.filterResult && this.state.filterResult.length > 0) || this.state.searchText.length > 0
        ? this.state.filterResult
        : this.state.allItems;
    let groups = [],
      i;
    for (i = 0; i < arr.length; i += this.state.pageSize) {
      groups.push(arr.slice(i, i + this.state.pageSize));
    }
    this.setState({
      pagedResults: groups[currentPageNumber],
      totalItemsCount: groups.length
    });

    if (groups[currentPageNumber] && groups[currentPageNumber].length > 0) {
      this.setState({ currentItem: groups[currentPageNumber][0] });
    } else {
      this.clearCurrentItem();
    }
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/

  render() {
    const {
      searchText,
      searchFilters,
      allItems,
      searchPlaceholder,
      pagedResults,
      isLoading,
      currentItem,
      activePage,
      totalItemsCount,
      pageRangeDisplayed,
      newItem,
      pageEditable
    } = this.state;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Push Notifications" />
        <div className="PushNoti-content">
          <div className="left-container">
            <SearchFilter
              updateFilterResults={this.updateFilterResults}
              searchText={searchText}
              filters={searchFilters}
              data={allItems}
              placeholder={searchPlaceholder}
            />
            <div className="list-container">
              <Loadable active={isLoading} spinner text="Loading...">
                <div>
                  {pagedResults !== undefined &&
                    pagedResults.length > 0 &&
                    pagedResults.map((item, index) => (
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        key={item.id}
                        tabIndex={0}
                        className={pagedResults[index].id === currentItem.id ? 'list-item selected' : 'list-item'}
                        onClick={() => {
                          this.setState({ currentItem: item, newItem: false });
                        }}
                      >
                        <div className="list-item__left">
                          <div className="list-item__firstsentence">
                            {item.message && item.message.length > 50 ? item.message.substring(0, 50) + '...' : item.message}
                          </div>
                        </div>
                        <div className="list-item__right">
                          <div className="list-item__time">
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .fromNow()}
                          </div>
                          <div className="list-item__name">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                        <div className="list-item__arrowright">
                          <MdKeyboardArrowRight size={25} color="grey" className="icon-arrowright" />
                        </div>
                      </div>
                    ))}

                  {(pagedResults === undefined || pagedResults.length === 0) && <EmptyState title="Nothing Here Yet" subTitle="" />}
                </div>
              </Loadable>
            </div>
            <div className="app-pagenation">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={1}
                totalItemsCount={totalItemsCount}
                pageRangeDisplayed={pageRangeDisplayed}
                onChange={this.handlePageChange}
              />
              <select className="form-group-select-options-page" onChange={this.changePageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              <button className="New-Message" style={{ display: newItem ? 'none' : 'flex' }} href="#" onClick={() => this.clearCurrentItem()}>
                <MdAdd size={23} className="icon-add" />
                New Message
              </button>
              <button
                disabled={!allItems.length > 0}
                className="Cancel"
                href="#"
                style={{ display: newItem ? 'flex' : 'none' }}
                onClick={() => {
                  this.cancelAdd();
                }}
              >
                Cancel
              </button>
              <button className="Create-btn" href="#" style={{ display: newItem ? 'flex' : 'none' }} onClick={this.addNewItem}>
                Submit
              </button>
            </div>
            <div className="form-container">
              <div className="title-container">
                {newItem ? (
                  <p className="title-content">Create New Notification1</p>
                ) : (
                  <p className="title-content">
                    {' '}
                    Sent on{' '}
                    {moment
                      .utc(currentItem.createdDate)
                      .local()
                      .format('MM/DD/YYYY [at] h:mm:ss a')}{' '}
                    by {currentItem.firstName} {currentItem.lastName}{' '}
                  </p>
                )}
              </div>
              <div className="form-container-input">
                <div className="form-group">
                  <div className="form-group-stacked-textarea">
                    <textarea
                      disabled={!pageEditable && !newItem}
                      className="form-group-textarea"
                      onChange={event =>
                        this.setState({
                          currentItem: {
                            ...currentItem,
                            message: event.target.value
                          }
                        })
                      }
                      value={currentItem.message === '' ? '' : currentItem.message}
                      placeholder={currentItem.message === '' ? 'Type Here...' : ''}
                      maxLength={1000}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    PushNotificationsReducer: state.PushNotificationsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActivePushNotifications: businessUnitID => dispatch(allActivePushNotifications(businessUnitID)),
    postNewNotification: params => dispatch(postNewNotification(params)),
    deleteNotification: (notificationID, mobileSecurityID, businessUnitID) =>
      dispatch(deleteNotification(notificationID, mobileSecurityID, businessUnitID)),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PushNotifications);
