//react
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';

//App Settings
import { config } from '../../utils/AppSettings';
import {apiBaseEndpointUrl} from '../../utils/NetworkSettings'
//css
import 'react-toastify/dist/ReactToastify.css';

//Redux
import { connect } from 'react-redux';
import { loginAdmin, saveAdmin } from './../../redux/modules/Admin';

//components
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';


class SamlCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };
  }

  componentDidMount = () => {
    try {
      const user = {
        admin: this.getQueryStringParameters(window.location.href)
      }

      if (!user || !user.admin || user.admin.error || (user && user.admin && !user.admin.ID)){
        this.props.history.push('/login');
        const errorMessage = user && user.admin && user.admin.error || 'An Error Occurred'
        toast.error(errorMessage, { position: 'bottom-center' });
      }
      else {
        this.props.saveAdmin(user)
        this.props.history.push('/dashboard');
      }
    } catch (e){
      this.props.history.push('/login');
      toast.error("An Error Occurred", { position: 'bottom-center' });
    }
  };

  //takes in url and returns object of query params
  getQueryStringParameters = (url) => {
    let query;
    if (url) {
      if (url.split('?').length > 0) {
        query = url.split('?')[1];
      }
    } else {
      url = window.location.href;
      query = window.location.search.substring(1);
    }
    return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
      let [key, value] = param.split(/=(.+)/);
      params[key] = value ? decodeURIComponent(value) : '';
      return params;
    }, {});
  };

  render() {
    return (
      <div>
        <ToastContainerComponent />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginAdmin: sub => dispatch(loginAdmin(sub)),
    saveAdmin: user => dispatch(saveAdmin(user))
  };
};

export default SamlCallback = connect(
  mapStateToProps,
  mapDispatchToProps
)(SamlCallback);
