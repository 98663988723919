import RestApi from './RestApi';

class Forms {
  static allActiveForms(businessUnitID) {
    const api = new RestApi();
    return api.GET('/forms?businessUnitID=' + encodeURIComponent(businessUnitID)).then(response => {
      return response;
    });
  }

  static updateForm(params) {
    const api = new RestApi();
    return api.PUT('/forms', params).then(response => {
      return response;
    });
  }

  static fetchAllJobTitles() {
    const api = new RestApi();
    return api.GET('/forms').then(response => {
      return response;
    });
  }
}

export default Forms;
