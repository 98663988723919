//Sets google as a global variable
/*global google*/
import React, { Component } from 'react';
// libraries
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { MdKeyboardArrowRight, MdAdd } from 'react-icons/lib/md';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Loadable from 'react-loading-overlay';
import 'react-toastify/dist/ReactToastify.css';

// redux state
import { connect } from 'react-redux';
import {
  allActiveGeofencedNotifications,
  postNewGeofencedNotification,
  deleteGeofencedNotification,
  resetDefaultValues
} from './../../redux/modules/GeofencedNotifications';
import { setCurrentPath } from './../../redux/modules/Admin';
// components
import Heading from '../../components/Heading/Heading';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import EmptyState from '../../components/EmptyState/EmptyState';
import MapComponent from '../../components/MapComponent/MapComponent';

//css
import './GeofencedNotifications.css';

class GeofencedNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEditable: false,
      isLoading: false,
      allItems: [],
      pagedResult: [],
      filterResult: [],
      searchPlaceholder: 'Filter Items by Message and Address',
      searchFilters: ['message', 'address'],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 5,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      currentItem: {
        id: 0,
        address: '',
        message: '',
        startDate: moment().format('HHmm'),
        expiryDate: moment().add(1, 'days'),
        radius: 1609.34
      },
      rawAddress: '',
      radius: 1609.34,
      // latitude: 29.425967, // texas
      // longitude: -98.486142, // texas
      latitude: 37.724734,
      longitude: -121.070525,
      latLng: [],
      distanceNum: 1,
      distanceArray: ['miles', 'kilometers'],
      distanceType: 'miles',
      newItem: false,
      suggestions: false,
      userLocated: false,
      selectedAddress: false
    };
  }

  componentDidMount() {
    const { currentCounty } = this.props.AdminReducer;
    this.fetchLatestRecords(currentCounty.businessUnit);
    this.getUserLocation();
    this.setState({ pageEditable: true, newItem: true });
    this.props.setCurrentPath('geofencednotifications');
  }

  componentWillReceiveProps(nextProps) {
    const { geofencedNotifications, isLoading, postedSuccessfully, error } = nextProps.GeofencedNotificationsReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading: isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }

    if (postedSuccessfully) {
      toast.dismiss();
      toast.success('You successfully posted a new geo-notification!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.resetDefaultValues();
      this.setState({ newItem: false });
    } else if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    } else {
      // do nothing
    }
    if (geofencedNotifications && geofencedNotifications.length > 0) {
      if (geofencedNotifications !== this.state.allItems) {
        for (let i = 0; i < geofencedNotifications.length; i++) {
          geofencedNotifications[i].startDate = moment
            .utc(geofencedNotifications[i].startDate)
            .local()
            .format('MM/DD/YYYY HH:mm');
          geofencedNotifications[i].expiryDate = moment
            .utc(geofencedNotifications[i].expiryDate)
            .local()
            .format('MM/DD/YYYY HH:mm');
          // geofencedNotifications[i].createdDate = moment.utc(geofencedNotifications[i].createdDate).local().format('MM/DD/YYYY HH:mm:ss');
        }
      }
      this.setState(
        {
          allItems: geofencedNotifications,
          newItem: false,
          pageEditable: false
        },
        this.updateContentList
      );
    } else {
      this.clearCurrentItem();
      this.setState({ allItems: [], newItem: true, pageEditable: true }, this.updateContentList);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentCounty, countyName } = prevProps.AdminReducer;
    // Typical usage (don't forget to compare props):
    if (currentCounty && this.props.AdminReducer.countyName !== countyName) {
      this.fetchLatestRecords(this.props.AdminReducer.currentCounty.businessUnit);
    }
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.setState({
            latitude,
            longitude,
            latlng: [latitude, longitude],
            userLocated: true
          });
        },
        error => {
          this.setState({
            latitude: 39.600624,
            longitude: -104.892349,
            latlng: [39.600624, -104.892349],
            userLocated: false
          });
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    }
  };

  fetchLatestRecords = businessUnit => {
    this.props.allActiveGeofencedNotifications(businessUnit);
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: {
        ...this.state.currentItem,
        id: 0,
        address: '',
        message: '',
        startDate: moment().format('MM/DD/YYYY HH:mm'),
        expiryDate: moment()
          .add(1, 'days')
          .format('MM/DD/YYYY HH:mm')
      },
      newItem: true,
      pageEditable: true,
      radius: 1609.34,
      distanceType: 'miles'
    });
  };

  //Geofencing specific Functionality
  convertDistance = (distanceNum, typeChange) => {
    let { distanceType } = this.state;
    // if the user changes type, get the distanceType from selection
    if (distanceType) {
      distanceType = typeChange;
    }
    const miles = +(distanceNum * 1609.34).toFixed(2);
    const kilometers = +(distanceNum * 1000).toFixed(2);
    this.state.distanceType === 'miles' ? this.setState({ radius: miles }) : this.setState({ radius: kilometers });
  };
  // when user changes the distance type (miles/kilometers)
  onDistanceNumChange = event => {
    let distanceNum = event.target.value;
    if (distanceNum < 0) {
      toast.dismiss();
      toast.warn('Distances cannot be less than 0 miles.', {
        position: 'bottom-center'
      });
      distanceNum = 0.1;
    }
    if (distanceNum > 20) {
      toast.dismiss();
      toast.warn('Distance cannot be greater than 20 miles.', {
        position: 'bottom-center'
      });
      distanceNum = 20;
    }
    this.setState({ distanceNum: distanceNum });
    this.convertDistance(distanceNum);
  };
  // when user changes the distance number
  onDistanceTypeChange = event => {
    let distanceType = event.target.value;
    this.setState({ distanceType: distanceType }, distanceType => {
      this.convertDistance(this.state.distanceNum, distanceType);
    });
  };
  geocodeAddress = address => {
    this.setState(
      {
        selectedAddress: true,
        currentItem: { ...this.state.currentItem, address },
        rawAddress: ''
      },
      () => {
        geocodeByAddress(this.state.currentItem.address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
            this.setState({
              latitude: latLng.lat,
              longitude: latLng.lng,
              latLng: latLng
            });
          })
          .catch(error => {
            console.error('Error', error);
          });
      }
    );
  };
  //Submit Requests to Server
  addNewItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { address, expiryDate, startDate, message } = this.state.currentItem;
        const { latitude, longitude, radius } = this.state;
        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const { stateCode, countyName, currentCounty } = this.props.AdminReducer;
        let newItem = {
          mobileSecurityID: currentAdmin.ID,
          businessUnitID: currentCounty.businessUnit,
          stateCode: stateCode,
          countyName: countyName,
          address: address,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
          message: message,
          startDate: startDate,
          expiryDate: expiryDate
        };
        this.props
          .postNewGeofencedNotification(newItem)
          .then(() => {
            this.fetchLatestRecords(currentCounty.businessUnit);
            this.setState({
              newItem: false,
              pageEditable: false,
              distanceNum: 1,
              selectedAddress: false
            });
          })
          .catch(() => {
            toast.dismiss();
            toast.error('Error deleting geo-notification', {
              position: 'bottom-center'
            });
          });
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { address, startDate, expiryDate, message } = this.state.currentItem;
      const { radius } = this.state;
      if (!address || !startDate || !expiryDate || !message) {
        toast.dismiss();
        reject('All fields are required to post a new geo-notification');
      }
      if (radius <= 0) {
        toast.dismiss();
        reject('Please enter a valid distance');
      }
      resolve();
    });
  };

  deleteGeoNotification = () => {
    const { stateCode, countyName, currentCounty } = this.props.AdminReducer;
    const { id } = this.state.currentItem;
    this.props
      .deleteGeofencedNotification(id, stateCode, countyName)
      .then(() => {
        this.fetchLatestRecords(currentCounty.businessUnit);
      })
      .catch(() => {
        toast.dismiss();
        toast.error('Error deleting geo-notification', {
          position: 'bottom-center'
        });
      });
  };

  onSetStartDate = date => {
    const { expiryDate } = this.state.currentItem;
    if (moment(date) < moment(expiryDate)) {
      this.setState({
        currentItem: { ...this.state.currentItem, startDate: date }
      });
    } else {
      toast.dismiss();
      toast.error('Start Date cannot be after the Expiration Date', {
        position: 'bottom-center'
      });
    }
  };

  onSetExpiryDate = date => {
    const { startDate } = this.state.currentItem;
    if (moment(date) > moment(startDate)) {
      this.setState({
        currentItem: { ...this.state.currentItem, expiryDate: date }
      });
    } else {
      toast.dismiss();
      toast.error('End Date cannot be before the Start Date', {
        position: 'bottom-center'
      });
    }
  };

  //***NO CODE UPDATE REQUIRED - well...unless fixing a bug or adding new functionality  :)***/
  cancelAdd = event => {
    const { allItems, pagedResults } = this.state;
    this.setState({ newItem: false, pageEditable: false });
    if (allItems.length > 0 && pagedResults.length > 0) {
      this.setState({ currentItem: pagedResults[0] });
    }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filterResult) => {
    this.setState({ filterResult: filterResult, searchText: searchText, activePage: 1 }, this.updateContentList);
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    const { activePage, filterResult, searchText, allItems, pageSize } = this.state;
    let currentPageNumber = activePage - 1;
    let arr = (filterResult && filterResult.length > 0) || searchText.length > 0 ? filterResult : allItems;
    let groups = [],
      i;
    for (i = 0; i < arr.length; i += pageSize) {
      groups.push(arr.slice(i, i + pageSize));
    }

    this.setState({
      pagedResults: groups[currentPageNumber],
      totalItemsCount: groups.length
    });
    if (groups[currentPageNumber] && groups[currentPageNumber].length > 0) {
      this.setState({
        currentItem: groups[currentPageNumber][0],
        radius: groups[currentPageNumber][0].radius
      });
    }
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/
  render() {
    const {
      latitude,
      longitude,
      radius,
      searchText,
      searchFilters,
      allItems,
      searchPlaceholder,
      pagedResults,
      isLoading,
      currentItem,
      activePage,
      totalItemsCount,
      pageRangeDisplayed,
      newItem,
      pageEditable,
      distanceArray,
      distanceNum,
      distanceType,
      rawAddress
    } = this.state;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Geo-notifications" />
        <div className="GeoNoti-content">
          <div className="left-container">
            <SearchFilter
              updateFilterResults={this.updateFilterResults}
              searchText={searchText}
              filters={searchFilters}
              data={allItems}
              placeholder={searchPlaceholder}
            />
            <div className="list-container">
              <Loadable active={isLoading} spinner text="Loading...">
                <div>
                  {pagedResults !== undefined &&
                    pagedResults.length > 0 &&
                    pagedResults.map((item, index) => (
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        key={item.id}
                        tabIndex={0}
                        className={pagedResults[index].id === currentItem.id ? 'list-item selected' : 'list-item'}
                        onClick={() => {
                          this.setState({
                            currentItem: item,
                            newItem: false,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            radius: item.radius
                          });
                        }}
                      >
                        <div className="list-item__left">
                          <div className="list-item__address">{item.address}</div>
                          <div className="list-item__first-sentence">
                            {item.message.length < 50 ? item.message : item.message.substring(0, 50) + '...'}
                          </div>
                        </div>
                        <div className="list-item__right">
                          <div className="list-item__time">
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .fromNow()}
                          </div>
                          <div className="list-item__name">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                        <div className="list-item__arrowright">
                          <MdKeyboardArrowRight size={30} color="grey" className="icon-arrowright" />
                        </div>
                      </div>
                    ))}

                  {(pagedResults === undefined || pagedResults.length === 0) && <EmptyState title="Nothing Here Yet" subTitle="" />}
                </div>
              </Loadable>
            </div>
            <div>
              <div className="app-pagenation">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={1}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onChange={this.handlePageChange}
                />
                <select className="form-group-select-options-page" onChange={this.changePageSize}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="control-btn-group">
              <button
                className="New-Message"
                style={{ display: newItem === true ? 'none' : 'flex' }}
                href="#"
                onClick={() => this.clearCurrentItem()}
              >
                <MdAdd size={23} className="icon-add" />
                New Notification
              </button>
              <button
                className="Cancel"
                style={{ display: newItem ? 'flex' : 'none' }}
                href="#"
                disabled={!allItems.length > 0}
                onClick={() => {
                  this.cancelAdd();
                }}
              >
                Cancel
              </button>
              <button className="Create-btn" href="#" style={{ display: newItem ? 'flex' : 'none' }} onClick={() => this.addNewItem()}>
                Submit
              </button>
            </div>
            <div className="form-container">
              <div className="title-container">
                {newItem ? (
                  <p className="title-content">Create New Notification</p>
                ) : (
                  <p className="title-content">
                    {' '}
                    Sent on{' '}
                    {moment
                      .utc(currentItem.createdDate)
                      .local()
                      .format('MM/DD/YYYY [at] h:mm:ss a')}{' '}
                    by {currentItem.firstName} {currentItem.lastName}{' '}
                  </p>
                )}

                {/* {!newItem && <div className="edu-title-btn">
                  <button
                    className="btn-long-ghost"
                    onClick={this.deleteGeoNotification}
                  >
                    Delete
                  </button>
                </div> } */}
              </div>
              <div className="form-container-input">
                <div className="form-group">
                  <div className="form-group-stacked geolocation">
                    <span className="form-group-stacked-label">Geo-location*</span>
                    <PlacesAutocomplete
                      value={currentItem.address !== '' ? currentItem.address : rawAddress}
                      onChange={event =>
                        newItem === true
                          ? this.setState({
                            currentItem: { ...currentItem, address: '' },
                            rawAddress: event
                          })
                          : null
                      }
                      onSelect={this.geocodeAddress}
                      searchOptions={{
                        componentRestrictions: { country: ['us'] },
                        location: new google.maps.LatLng(latitude, longitude),
                        radius: 50000
                      }}
                      debounce={1000}
                      shouldFetchSuggestions={rawAddress.length >= 4} // rawAddress is one behind
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div>
                          <input
                            disabled={!pageEditable && !newItem}
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'location-search-input'
                            })}
                            onKeyDown={event =>
                              // when user tries to hit the enter key without selecting an address
                              // 1. Set address to empty string to clear input to force them to select an address
                              // 2. Set the suggestions length to 0, so that it only shows suggestions when there is text in the input box
                              event.keyCode === 13 && this.state.selectedAddress === false
                                ? (this.setState({
                                  currentItem: { ...currentItem, address: '' }
                                }),
                                  (suggestions.length = 0))
                                : null
                            }
                            onBlur={event =>
                              // when user tries to click away out of text input without selecting an address
                              // 1. Set address to empty string to clear input to force them to select an address
                              // 2. Set the suggestions length to 0, so that it only shows suggestions when there is text in the input box
                              this.state.selectedAddress === false
                                ? (this.setState({
                                  currentItem: { ...currentItem, address: '' }
                                }),
                                  (suggestions.length = 0))
                                : null
                            }
                            className="form-group-text"
                          />
                          <span>
                            <div>
                              {!pageEditable && !newItem
                                ? ''
                                : suggestions.map(suggestion => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer'
                                    }
                                    : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer'
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </span>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-stacked-textarea">
                    <span className="form-group-stacked-textarea-label">Message *</span>
                    <textarea
                      maxLength={500}
                      disabled={!pageEditable && !newItem}
                      className="form-group-textarea"
                      placeholder={currentItem.message === '' ? 'Write Message Here...' : ''}
                      value={currentItem.message === '' ? '' : currentItem.message}
                      onChange={event =>
                        newItem === true
                          ? this.setState({
                            currentItem: {
                              ...currentItem,
                              message: event.target.value
                            }
                          })
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="form-group" id="specialformgroup">
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">Start Date *</span>
                    <DatePicker
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      selected={moment(currentItem.startDate)}
                      onChange={this.onSetStartDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM DD YYYY HH:mm"
                      timeCaption="time"
                      readOnly={true}
                    />
                  </div>
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">End Date *</span>
                    <DatePicker
                      disabled={!pageEditable && !newItem}
                      className="form-group-text"
                      selected={moment(currentItem.expiryDate)}
                      onChange={this.onSetExpiryDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM DD YYYY HH:mm"
                      timeCaption="time"
                      readOnly={true}
                    />
                  </div>
                  <div className="form-group-stacked-third">
                    <span className="form-group-stacked-third-label">Distance *</span>
                    <div className="form-group-compond">
                      <NumberFormat
                        className="form-group-text"
                        disabled={!pageEditable && !newItem}
                        onChange={this.onDistanceNumChange}
                        value={currentItem.id > 0 ? currentItem.radius / 1609.34 : distanceNum}
                        decimalScale={1}
                      />
                      <div className="form-group-select">
                        <select className="form-group-select-options" onChange={this.onDistanceTypeChange} disabled={!pageEditable && !newItem}>
                          {distanceArray.map(distance => (
                            <option key={distance} value={distance}>
                              {pageEditable === false ? 'miles' : distance}
                            </option>
                          ))}
                        </select>
                        {/* <MdExpandMore size={25} color='grey' onClick={this.onDistanceTypeChange} className="icon-expand"></MdExpandMore>   */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-container form-container-map">
                <MapComponent
                  address={currentItem.address}
                  latitude={latitude}
                  longitude={longitude}
                  radius={radius}
                  id={currentItem.id}
                  distanceType={distanceType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    GeofencedNotificationsReducer: state.GeofencedNotificationsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActiveGeofencedNotifications: (stateCode, countyName) => dispatch(allActiveGeofencedNotifications(stateCode, countyName)),
    postNewGeofencedNotification: params => dispatch(postNewGeofencedNotification(params)),
    deleteGeofencedNotification: (notificationID, stateCode, countyName) =>
      dispatch(deleteGeofencedNotification(notificationID, stateCode, countyName)),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofencedNotifications);
