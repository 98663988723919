import Protocols from '../../httpClient/Protocols';

//Action Types
export const types = {
  FETCHING_PROTOCOLS_DATA: 'FETCHING_PROTOCOLS_DATA',
  FETCHING_PROTOCOLS_DATA_SUCCESS: 'FETCHING_PROTOCOLS_DATA_SUCCESS',
  FETCHING_PROTOCOLS_DATA_FAILURE: 'FETCHING_PROTOCOLS_DATA_FAILURE',
  NEW_PROTOCOLS_DATA_SUCCESS: 'NEW_PROTOCOLS_DATA_SUCCESS',
  UPDATE_PROTOCOLS_DATA_SUCCESS: 'UPDATE_PROTOCOLS_DATA_SUCCESS',
  DELETE_PROTOCOLS_DATA_SUCCESS: 'DELETE_PROTOCOLS_DATA_SUCCESS',
  PDF_GET_DATA_SUCCESS: 'PDF_GET_DATA_SUCCESS',
  RESET_DEFAULT_VALUES: 'RESET_DEFAULT_VALUES',
  CATEGORIES_GET_DATA_SUCCESS: 'CATEGORIES_GET_DATA_SUCCESS',
  CATEGORIES_PER_BU_GET_DATA_SUCCESS: 'CATEGORIES_PER_BU_GET_DATA_SUCCESS',
  NEW_CATEGORY_DATA_SUCCESS: 'NEW_CATEGORY_DATA_SUCCESS',
  DELETE_CATEGORY_DATA_SUCCESS: 'DELETE_CATEGORY_DATA_SUCCESS'
};

//Initial States
export const initialState = {
  postedSuccessfully: false,
  availableProtocols: [],
  isLoading: false,
  error: null,
  PDFFile: null,
  categories: [],
  categoriesPerBU: []
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PROTOCOLS_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_PROTOCOLS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        availableProtocols: action.availableProtocols,
        error: null
      };

    case types.FETCHING_PROTOCOLS_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.NEW_PROTOCOLS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: null,
        postedSuccessfully: true,
        availableProtocols: action.availableProtocols,
        error: null
      };

    case types.UPDATE_PROTOCOLS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableProtocols: action.availableProtocols,
        error: null
      };

    case types.DELETE_PROTOCOLS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableProtocols: action.availableProtocols,
        error: null
      };

    case types.PDF_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PDFFile: action.PDFFile,
        error: null
      };

    case types.CATEGORIES_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.CATEGORIES_PER_BU_GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriesPerBU: action.categoriesPerBU,
        error: null
      };

    case types.NEW_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.DELETE_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories,
        error: null
      };

    case types.RESET_DEFAULT_VALUES:
      return {
        ...state,
        isLoading: false,
        postedSuccessfully: false,
        error: null
      };

    default:
      return state;
  }
};

//Actions
export function allActiveProtocols(businessUnitID, mobileSecurityID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PROTOCOLS_DATA });
    return Protocols.allActiveProtocols(businessUnitID, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.FETCHING_PROTOCOLS_DATA_SUCCESS,
          availableProtocols: data.protocols
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewProtocol(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PROTOCOLS_DATA });
    return Protocols.postNewProtocol(params)
      .then(data => {
        dispatch({
          type: types.NEW_PROTOCOLS_DATA_SUCCESS,
          availableProtocols: data.protocols
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function updateProtocol(params) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PROTOCOLS_DATA });
    return Protocols.updateProtocol(params)
      .then(data => {
        dispatch({
          type: types.UPDATE_PROTOCOLS_DATA_SUCCESS,
          availableProtocols: data.protocols
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteProtocol(protocolID, mobileSecurityID, businessUnitID) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PROTOCOLS_DATA });
    return Protocols.deleteProtocol(protocolID, mobileSecurityID, businessUnitID)
      .then(data => {
        dispatch({
          type: types.DELETE_PROTOCOLS_DATA_SUCCESS,
          availableProtocols: data.protocols
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchCurrentPDF(file) {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PROTOCOLS_DATA });
    return Protocols.fetchCurrentPDF(file)
      .then(data => {
        dispatch({ type: types.PDF_GET_DATA_SUCCESS, PDFFile: data });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchAllCategories() {
  return function(dispatch) {
    return Protocols.fetchAllCategories()
      .then(data => {
        dispatch({
          type: types.CATEGORIES_GET_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function fetchCategoriesPerBU(id) {
  return function(dispatch) {
    return Protocols.fetchCategoriesPerBU(id)
      .then(data => {
        dispatch({
          type: types.CATEGORIES_PER_BU_GET_DATA_SUCCESS,
          categoriesPerBU: data
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function postNewCategory(params) {
  return function(dispatch) {
    return Protocols.postNewCategory(params)
      .then(data => {
        dispatch({
          type: types.NEW_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function deleteCategory(id, type, modifiedBy, mobileSecurityID) {
  return function(dispatch) {
    return Protocols.deleteCategory(id, type, modifiedBy, mobileSecurityID)
      .then(data => {
        dispatch({
          type: types.DELETE_CATEGORY_DATA_SUCCESS,
          categories: data.categories
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PROTOCOLS_DATA_FAILURE, error: err });
      });
  };
}

export function resetDefaultValues() {
  return function(dispatch) {
    dispatch({ type: types.RESET_DEFAULT_VALUES });
  };
}
