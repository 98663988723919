import RestApi from './RestApi';

class PushNotifications {
  static allActivePushNotifications(businessUnitID) {
    const api = new RestApi();
    return api.GET('/pushNotifications?businessUnitID=' + encodeURIComponent(businessUnitID)).then(response => {
      return response;
    });
  }

  static postNewNotification(params) {
    const api = new RestApi();
    return api.POST('/pushNotifications', params).then(response => {
      return response;
    });
  }

  static deleteNotification(notificationID, mobileSecurityID, businessUnitID) {
    const api = new RestApi();
    return api
      .DELETE(
        '/pushNotifications?notificationID=' +
          encodeURIComponent(notificationID) +
          '&mobileSecurityID=' +
          encodeURIComponent(mobileSecurityID) +
          '&businessUnitID=' +
          encodeURIComponent(businessUnitID)
      )
      .then(response => {
        return response;
      });
  }
}

export default PushNotifications;
