export const stateCodes = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};
export const states = [
  {
    stateName: 'Alabama',
    stateCode: 'AL'
  },
  {
    stateName: 'Alaska',
    stateCode: 'AK'
  },
  {
    stateName: 'American Samoa',
    stateCode: 'AS'
  },
  {
    stateName: 'Arizona',
    stateCode: 'AZ'
  },
  {
    stateName: 'Arkansas',
    stateCode: 'AR'
  },
  {
    stateName: 'California',
    stateCode: 'CA'
  },
  {
    stateName: 'Colorado',
    stateCode: 'CO'
  },
  {
    stateName: 'Connecticut',
    stateCode: 'CT'
  },
  {
    stateName: 'Delaware',
    stateCode: 'DE'
  },
  {
    stateName: 'District Of Columbia',
    stateCode: 'DC'
  },
  {
    stateName: 'Federated States Of Micronesia',
    stateCode: 'FM'
  },
  {
    stateName: 'Florida',
    stateCode: 'FL'
  },
  {
    stateName: 'Georgia',
    stateCode: 'GA'
  },
  {
    stateName: 'Guam',
    stateCode: 'GU'
  },
  {
    stateName: 'Hawaii',
    stateCode: 'HI'
  },
  {
    stateName: 'Idaho',
    stateCode: 'ID'
  },
  {
    stateName: 'Illinois',
    stateCode: 'IL'
  },
  {
    stateName: 'Indiana',
    stateCode: 'IN'
  },
  {
    stateName: 'Iowa',
    stateCode: 'IA'
  },
  {
    stateName: 'Kansas',
    stateCode: 'KS'
  },
  {
    stateName: 'Kentucky',
    stateCode: 'KY'
  },
  {
    stateName: 'Louisiana',
    stateCode: 'LA'
  },
  {
    stateName: 'Maine',
    stateCode: 'ME'
  },
  {
    stateName: 'Marshall Islands',
    stateCode: 'MH'
  },
  {
    stateName: 'Maryland',
    stateCode: 'MD'
  },
  {
    stateName: 'Massachusetts',
    stateCode: 'MA'
  },
  {
    stateName: 'Michigan',
    stateCode: 'MI'
  },
  {
    stateName: 'Minnesota',
    stateCode: 'MN'
  },
  {
    stateName: 'Mississippi',
    stateCode: 'MS'
  },
  {
    stateName: 'Missouri',
    stateCode: 'MO'
  },
  {
    stateName: 'Montana',
    stateCode: 'MT'
  },
  {
    stateName: 'Nebraska',
    stateCode: 'NE'
  },
  {
    stateName: 'Nevada',
    stateCode: 'NV'
  },
  {
    stateName: 'New Hampshire',
    stateCode: 'NH'
  },
  {
    stateName: 'New Jersey',
    stateCode: 'NJ'
  },
  {
    stateName: 'New Mexico',
    stateCode: 'NM'
  },
  {
    stateName: 'New York',
    stateCode: 'NY'
  },
  {
    stateName: 'North Carolina',
    stateCode: 'NC'
  },
  {
    stateName: 'North Dakota',
    stateCode: 'ND'
  },
  {
    stateName: 'Northern Mariana Islands',
    stateCode: 'MP'
  },
  {
    stateName: 'Ohio',
    stateCode: 'OH'
  },
  {
    stateName: 'Oklahoma',
    stateCode: 'OK'
  },
  {
    stateName: 'Oregon',
    stateCode: 'OR'
  },
  {
    stateName: 'Palau',
    stateCode: 'PW'
  },
  {
    stateName: 'Pennsylvania',
    stateCode: 'PA'
  },
  {
    stateName: 'Puerto Rico',
    stateCode: 'PR'
  },
  {
    stateName: 'Rhode Island',
    stateCode: 'RI'
  },
  {
    stateName: 'South Carolina',
    stateCode: 'SC'
  },
  {
    stateName: 'South Dakota',
    stateCode: 'SD'
  },
  {
    stateName: 'Tennessee',
    stateCode: 'TN'
  },
  {
    stateName: 'Texas',
    stateCode: 'TX'
  },
  {
    stateName: 'Utah',
    stateCode: 'UT'
  },
  {
    stateName: 'Vermont',
    stateCode: 'VT'
  },
  {
    stateName: 'Virgin Islands',
    stateCode: 'VI'
  },
  {
    stateName: 'Virginia',
    stateCode: 'VA'
  },
  {
    stateName: 'Washington',
    stateCode: 'WA'
  },
  {
    stateName: 'West Virginia',
    stateCode: 'WV'
  },
  {
    stateName: 'Wisconsin',
    stateCode: 'WI'
  },
  {
    stateName: 'Wyoming',
    stateCode: 'WY'
  }
];
