//react
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { MdClose } from 'react-icons/lib/md';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';
import Checkbox from '../../components/Checkbox/Checkbox';
import Loadable from 'react-loading-overlay';
import styled from 'styled-components';
import ImageResize from '../../components/ImageResize/ImageResize';
import { downloadBase64File } from '../../utils/helperFunctions';

//Libraries
import Pagination from 'react-js-pagination';
import { confirmAlert } from 'react-confirm-alert';

//css
import './Sidepanel.css';

//image
import Certificate from '../../assets/images/cert.png';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

//components
import ToastContainerComponent from '../ToastContainerComponent/ToastContainerComponent';

import httpCDM from '../../httpClient/CDM.js';
import CovidVaccine from '../CovidVaccine/CovidVaccine';

const makeManufacturerList = [
  { label: '3M', value: '3M' },
  { label: 'Moldex', value: 'Moldex' },
  { label: 'Honeywell', value: 'Honeywell' },
  { label: 'O&M Halyard', value: 'O&M Halyard' },
  { label: 'Prestige', value: 'Prestige' },
];
const modelNumberList = [
  { label: '1510', value: '1510' },
  { label: '1511', value: '1511' },
  { label: '1512', value: '1512' },
  { label: '1513', value: '1513' },
  { label: '1517', value: '1517' },
  { label: '1804', value: '1804' },
  { label: '1804S', value: '1804S' },
  { label: '1860', value: '1860' },
  { label: '1860S', value: '1860S' },
  { label: '1870', value: '1870' },
  { label: '1870+', value: '1870+' },
  { label: '8110S', value: '8110S' },
  { label: '8200', value: '8200' },
  { label: '8210', value: '8210' },
  { label: '9205', value: '9205' },
  { label: '9210+', value: '9210+' },
  { label: '9502+', value: '9502+' },
  { label: '26827', value: '26827' },
  { label: '46727', value: '46727' },
  { label: '46767', value: '46767' },
  { label: '14110441', value: '14110441' },
  { label: '14110439', value: '14110439' },
  { label: '2200N95', value: '2200N95' },
  { label: '2201N95', value: '2201N95' },
  { label: '4700N100', value: '4700N100' },
  { label: 'DC365N95HC', value: 'DC365N95HC' },
  { label: 'M2600N95', value: 'M2600N95' },
  { label: 'RP88010', value: 'RP88010' },
  { label: 'RP88020', value: 'RP88020' },
];
const respiratorTypeList = [
  { label: 'N95', value: 'N95' },
  { label: 'N100', value: 'N100' },
  { label: 'P100', value: 'P100' },
];
const respiratorSizeList = [
  { label: 'X-Small', value: 'X-Small' },
  { label: 'Small', value: 'Small' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Large', value: 'Large' },
  { label: 'X-Large', value: 'X-Large' },
  { label: 'One Size Fits Most', value: 'One Size Fits Most' },
];
const testResultList = [
  { label: 'Pass', value: 'Pass' },
  { label: 'Fail', value: 'Fail' },
];

const INITIAL_STATE = {
  issueDate: '',
  expirationDate: '',
  rawIssueDate: '',
  rawExpirationDate: '',
  displayIssueDate: '',
  displayExpirationDate: '',
  displayCertNumber: '',
  certNumber: '',
  frontImage: '',
  backImage: '',
  docTypeID: '',
  stateCode: '',
  countyCode: '',
  docType: '',
  docId: '',
  requestSuccess: false,
  certImage: '', //
  certName: '', // --------------docType
  isStateCertificate: false,
  isCountyCertificate: false,
  isExpiring: 'Y',
  agency: '',
  activeFlag: '',
  auditCert: '',
  zipCode: '',
  manuallyVerified: false,
  manuallyVerifiedBy: '',
  manuallyVerifiedByID: '',
  manuallyVerifiedDate: '',
  regulatoryApproved: false,
  regulatoryApprovedBy: '',
  regulatoryApprovedByID: '',
  regulatoryApprovedDate: '',
  regulatoryApprovedImage: '',
  isRegulatoryEnabled: '',
  preUpdatePayload: '',
  isVaccineDeclined: false,
  manufacturerName: '',
  lotNumber: '',
  covidDose: '',
  eVerifyStatus: '',
  checkingEVerifyStatus: false,
  eVerifiedStatusMessage: '',
  isEverifyCertificate: false,
  isEcard: false,
  eCardVerified: false,
  isLoadingSidePanel: false,
  validFormatPlaceHolder: 'Certificate Number',
  dlRegex: '',
  // numPages: null,
  // pageNumber: 1,
  frontNumPages: null,
  frontPageNumber: 1,
  backNumPages: null,
  backPageNumber: 1,
  regulatoryNumPages: null,
  regulatoryPageNumber: 1,
  certificateNumPages: null,
  certificatePageNumber: 1,
  isBackImageRequired: true,
  wordDocFormats: [
    { keyFormat: 'msword', formatValue: '.doc' },
    { keyFormat: 'vnd.openxmlformats-officedocument.wordprocessingml.document', formatValue: '.docx' },
    { keyFormat: 'vnd.openxmlformats-officedocument.wordprocessingml.template', formatValue: '.dotx' },
    { keyFormat: 'vnd.ms-word.document.macroEnabled.12', formatValue: '.docm' },
    { keyFormat: 'vnd.ms-word.template.macroEnabled.12', formatValue: '.dotm' }
  ],
  enableEcardBtn: false,
  agencyValues: [
    // { name: 'American Heart Association' },
    // { name: 'FEMA' },
    // { name: 'GMR' },
    // { name: 'IAED' },
    // { name: 'Local EMS Agency' },
    // { name: 'Local Training Center' },
    // { name: 'National Association of EMTs' },
    // { name: 'National Registry of EMTs' },
    // { name: 'NCTI' },
    // { name: 'State DMV' },
    // { name: 'State EMS Agency' },
    // { name: 'State Fire Agency' },
    // { name: 'State Nursing Board' }
  ],
  modelNumber: '',
  respiratorSize: '',
  respiratorType: '',
  testResults: '',
  filteredModels: [],
  filteredTypes: [],
  filteredSizes: [],
  isSafetyCert: false,
  isRespFitTest: false,
  createdBy: '',
  updateSource: '',
  createDate: '',
  certImage: '',
  userIsManuallyVerifyingCert: false,
  hideManuallyVerified: false,
  certificateHistoryLog: null,
};

class Sidepanel extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    //this.doctypeMap = { 292: 1025, 293: 1026, 1021: 1027, 1022: 1028, 1024: 1030, 1023: 1029, 1028: 1034, 1029: 1035, 1030: 1036, 1031: 1037 };// 1025 and 1026 in dev for county local dispatch and county local supervisor
    this.doctypeMap = { 292: 1239, 293: 1238, 1021: 1021, 1022: 1022, 1023: 1023, 1024: 1024, 1025: 1025, 1026: 1026, 1027: 1027, 1028: 1028, 1029: 1029, 1030: 1030, 1031: 1031 };//prod ids

    // this.doctypeMap = { 292: 1239, 293: 1238, 1021: 1240, 1022: 1241, 1023: 1242, 1024: 1243, 1025: 1244, 1026: 1245, 1027: 1246, 1028: 1247, 1029: 1248, 1030: 1249, 1031: 1250 };// QA and UAT ids
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.individualCertName) {
      this.setState({ stateCode: nextProps.individualCertName.includes('-') ? nextProps.individualCertName.trim().slice(0, 2) : '' });
      this.onCertNameSelect('', nextProps.individualCertName.includes('-') ? nextProps.individualCertName.trim().slice(3) : nextProps.individualCertName.trim());
    }
    if (nextProps.currentCert) {
      if (nextProps.currentCert.Disposition !== 'MISSING') {
        this.onCertNameSelect('', nextProps.currentCert.CertName.includes('-') ? nextProps.currentCert.CertName.trim().slice(3) : nextProps.currentCert.CertName.trim());
      }
      if (nextProps.currentCert.Agency === "American Heart Association") {
        this.setState({
          enableEcardBtn: true
        });
      }
      if (nextProps.currentCert.Disposition === 'MISSING') {
        this.setState({
          activeFlag: nextProps.currentCert.ActiveFlag,
          docTypeID: nextProps.currentCert.Document_Type_ID,
          certName: nextProps.currentCert.CertName ? nextProps.currentCert.CertName : '',
          auditVerifiation: false,
          manuallyVerified: false,
          issueDate: '',
          expirationDate: '',
          agency: '',
          certNumber: '',
        });
      } else {
        // let formattedManufacturer = '';
        // if (nextProps.currentCert.Manufacturer && nextProps.currentCert.Manufacturer.toLowerCase() === '3m') {
        //   formattedManufacturer = nextProps.currentCert.Manufacturer.toUpperCase();
        // } else {
        //   formattedManufacturer = nextProps.currentCert.Manufacturer;
        // }
        let formattedCertNumber = '';
        let formattedZipCode = '';
        if (nextProps.currentCert.StateCode === 'NJ') {
          const splitCertNumberAndZipCode = nextProps.currentCert.CertNumber.split(':');
          formattedCertNumber = splitCertNumberAndZipCode[0];
          formattedZipCode = splitCertNumberAndZipCode[1];
        }
        formattedCertNumber = formattedCertNumber.length > 0 ? formattedCertNumber : nextProps.currentCert.CertNumber

        this.setState({
          issueDate: nextProps.currentCert.CertIssueDate,
          expirationDate: nextProps.currentCert.Declined !== '1' ? nextProps.currentCert.CertExpDate : '',
          certNumber: nextProps.currentCert.CertNumber ? formattedCertNumber : '',
          stateCode: nextProps.currentCert.StateCode ? nextProps.currentCert.StateCode : '',
          agency: nextProps.currentCert.Agency ? nextProps.currentCert.Agency : '',
          //activeFlag: _.capitalize(nextProps.currentCert.CertStatus),
          activeFlag: nextProps.currentCert.ActiveFlag,
          countyCode: nextProps.currentCert.County ? nextProps.currentCert.County : '',
          docTypeID: nextProps.currentCert.Document_Type_ID,
          certName: nextProps.currentCert.CertName ? nextProps.currentCert.CertName : '',
          isVaccineDeclined: nextProps.currentCert.Declined ? nextProps.currentCert.Declined === '1' : undefined,
          manufacturerName: nextProps.currentCert.Manufacturer ? nextProps.currentCert.Manufacturer.toUpperCase() : null,
          modelNumber: nextProps.currentCert.ModelNumber,
          respiratorType: nextProps.currentCert.RespiratorType,
          respiratorSize: nextProps.currentCert.RespiratorSize,
          testResults: nextProps.currentCert.TestResults,
          createdBy: nextProps.currentCert.CreatedBy,
          updateSource: nextProps.currentCert.UpdateSource,
          createDate: nextProps.currentCert.CreateDate,
          certImage: nextProps.currentCert.CertImage,
          zipCode: formattedZipCode
        });
        this.populateAuditVerification(nextProps.auditVerifiation, nextProps.currentCert);
        this.populateRegulatoryApproval(nextProps.regulatoryApproval, nextProps.currentCert);
      }

      this.prePayload(nextProps.currentCert, nextProps.individualCertName);

      //Display state drop down if cert is a driver license
      let firstWord = nextProps.currentCert.CertName.split(' ')[0];
      if (nextProps.currentCert.CertName.toLowerCase().includes('state') || nextProps.currentCert.CertName.toLowerCase().includes('county') || firstWord.toLowerCase() === 'drivers') {
        //if (firstWord.toLowerCase() === 'state' || firstWord.toLowerCase() === 'drivers') {
        this.setState({ isStateCertificate: true });
      } else {
        this.setState({ isStateCertificate: false });
      }
      if (firstWord.toLowerCase() === 'county') {
        this.setState({ isCountyCertificate: true });
      } else {
        this.setState({ isCountyCertificate: false });
      }
      this.checkIfIsEcard(nextProps.currentCert.Document_Type_ID);

      //Check cert image format, if word doc then disable Regulatory image button
      if (!!nextProps.currentCert.CertImage) {
        this.setIsRegualtoryEnabled(nextProps.currentCert.CertImage);
      }

      if (!this.props.addCert) {
        if (nextProps.currentCert.CertName.toLowerCase() == 'drivers license') {
          this.getValidDLFormat(nextProps.currentCert.StateCode, nextProps.currentCert.CertName.toLowerCase());
        }
      }
    }

    let tempAgencyValues = JSON.parse(localStorage.getItem('agencies'));
    this.setState({ agencyValues: tempAgencyValues.AgencyValues });
    if (tempAgencyValues.AgencyValues.filter((vendor) => vendor.name === this.state.agency).length < 0) {
      this.setState({ agency: '' });
    }
    // If a cert is successfully added or no longer viewing a cert, reset state
    if (this.props.success || (this.props.currentCert && !nextProps.currentCert) || this.props.addCert) {
      this.resetState();
    }
  }

  checkIfIsEcard(docTypeID) {
    let temp = JSON.parse(localStorage.getItem('ecardMapping'));
    let _object = temp.eCardMapping.filter((item) => item.codeValue == docTypeID).map((item) => ({ value: item }));
    if (_object.length > 0) {
      this.setState({ isEcard: true });
    } else {
      this.setState({ isEcard: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.certNumber !== this.state.certNumber) {
      //CHECK WHEN TO USE THIS OR WHY I ADDED IT
      this.setState({
        isLoadingSidePanel: false,
        eCardVerified: false
        //Enable when eVerify gets added again (3 lines)
        //eVerifiedStatusMessage: '',
        //isEverifyCertificate: false,
        //checkingEVerifyStatus: false,
      });

      // if (this.state.agencyValues.filter((vendor) => vendor.name === this.state.Agency).length < 0) {
      //   this.setState({ agency: '' });
      // }
      //console.log(this.state.agencyValues);
      //Enable when eVerify gets added again (4 lines)
      // if (this.props.eVerifyStates && this.state.isStateCertificate) {
      //   let eVerifyState = this.findValue(this.props.eVerifyStates.eVerifyStates, this.state.stateCode);
      //   this.setState({ isEverifyCertificate: eVerifyState });
      // }

      if (!this.props.addCert && this.state.stateCode !== prevState.stateCode) {
        if (this.state.certName.toLowerCase() == 'drivers license') {
          this.getValidDLFormat(this.state.stateCode, this.state.certName.toLowerCase());
        }
      }
    }
  }

  setIsRegualtoryEnabled(currentCertImage) {
    //const words = nextProps.currentCert.CertImage.split(',');
    const words = currentCertImage.split(',');
    let format = words[0]
      .split('/')
      .pop()
      .split(';')[0];
    let documentFormat = this.state.wordDocFormats
      .filter((item) => item.keyFormat == format)
      .map(({ formatValue }) => ({ formatValue }))
      .map((a) => a.formatValue);
    if ('Document' + documentFormat === 'Document.docx' || 'Document' + documentFormat === 'Document.doc') {
      this.setState({ isRegulatoryEnabled: true });
    } else {
      this.setState({ isRegulatoryEnabled: false });
    }
  }
  getValidDLFormat = async (stateCode, docType) => {
    //var docType = this.props.addCert ? this.state.docType.toLowerCase() : this.props.currentCert.CertName.toLowerCase();
    if (docType == 'drivers license') {
      const response = await httpCDM.getValidDLFormat(stateCode);
      this.setState({ validFormatPlaceHolder: response.stateDLFormat.formatMsg, dlRegex: response.stateDLFormat.regexFormat });
    }
  };

  findValue(object, value) {
    let _object = object.filter((item) => item.ST == value).map((item) => ({ value: item }));

    if (_object.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  populateAuditVerification = (auditVerification, currentCert) => {
    //Check if cert is null
    let tempCertID = !!currentCert.EmployeeCertificatesID ? currentCert.EmployeeCertificatesID : '';
    let auditCert = auditVerification ? auditVerification
      .filter((user) => user.EmployeeCertificatesID == tempCertID)
      .map((user) => ({ value: user }))
      .filter((obj) => obj.value.Document_Type_ID == currentCert.Document_Type_ID)
      .map((obj) => obj.value)
      : [];
    if (auditCert.length > 0) {
      this.setState({
        manuallyVerified: auditCert[0].ManuallyVerified,
        manuallyVerifiedBy: auditCert[0].ModifiedBy,
        manuallyVerifiedByID: auditCert[0].ModifiedByID,
        // manuallyVerifiedDate: moment(auditCert[0].ModifiedDate).format('MM/DD/YYYY HH:mm')
        manuallyVerifiedDate: moment
          .utc(auditCert[0].ModifiedDate)
          .local()
          .format('MM/DD/YYYY HH:mm'),
      });
    } else {
      this.setState({ manuallyVerified: false, manuallyVerifiedBy: '', manuallyVerifiedDate: '' });
    }
    this.getCertificateHistoryLog(auditCert, currentCert);
  };

  getCertificateHistoryLog = (auditCert, currentCert) => {
    const allCertificateHistory = [];
    if (auditCert.length > 0 && auditCert[0].CertificateHistoryLog) {
      const auditCertHistoryLog = typeof auditCert[0].CertificateHistoryLog === 'string' ? JSON.parse(auditCert[0].CertificateHistoryLog) : auditCert[0].CertificateHistoryLog;
      for (let i = 0; i < auditCertHistoryLog.length; i++) {
        allCertificateHistory.push(auditCertHistoryLog[i]);
      }
    }
    if (currentCert.CertificateHistoryLog) {
      const currentCertHistoryLog = typeof currentCert.CertificateHistoryLog === 'string' ? JSON.parse(currentCert.CertificateHistoryLog) : currentCert.CertificateHistoryLog;
      for (let i = 0; i < currentCertHistoryLog.length; i++) {
        allCertificateHistory.push(currentCertHistoryLog[i]);
      }
    }
    this.setState({ certificateHistoryLog: allCertificateHistory });
  }

  populateRegulatoryApproval = (regulatoryApproval, currentCert) => {
    let regApproval = regulatoryApproval
      .filter((user) => user.CertNumber == currentCert.CertNumber)
      .map((user) => ({ value: user }))
      .filter((obj) => obj.value.Document_Type_ID == currentCert.Document_Type_ID)
      .map((obj) => obj.value);
    if (regApproval.length > 0) {
      this.setState({
        regulatoryApproved: regApproval[0].RegulatoryApproved,
        regulatoryApprovedBy: regApproval[0].UploadedBy,
        regulatoryApprovedByID: regApproval[0].UploadedByID,
        regulatoryApprovedImage: regApproval[0].RegulatoryImage.split(',')[1] === ' ' ? '' : regApproval[0].RegulatoryImage,
        //regulatoryApprovedDate: moment(regApproval[0].UploadDate).format('MM/DD/YYYY HH:mm')
        regulatoryApprovedDate: moment
          .utc(regApproval[0].UploadDate)
          .local()
          .format('MM/DD/YYYY HH:mm')
      });
    } else {
      this.setState({ regulatoryApproved: false, regulatoryApprovedBy: '', regulatoryApprovedImage: '', regulatoryApprovedDate: '' });
    }
  };

  prePayload = (currentCert, certName) => {
    let selectedEmployee = JSON.parse(localStorage.getItem('tokenEmployee'));
    const singleCertName = certName.length > 0 ? (certName.includes('-') ? certName.trim().slice(3) : certName.trim()) : currentCert.CertName === '' ? 'ABLS' : currentCert;
    const singleCertState = certName.length > 0 ? certName.trim().slice(0, 2) : currentCert.StateCode === '' ? '' : currentCert.StateCode;
    const singleDocType = certName.length > 0 && this.props.documentTypes.find((cert) => singleCertName === cert.docType);
    let njCertNumber = '';
    if (this.state.zipCode) {
      njCertNumber = currentCert.CertNumber.concat(`:${this.state.zipCode}`);
    }
    const payload = {
      id: selectedEmployee.mobileSecurityID ? selectedEmployee.mobileSecurityID : selectedEmployee.OracleEmpNum, //
      docId: singleDocType ? singleDocType.docTypeId : currentCert.Document_Type_ID,
      activeFlag: currentCert.ActiveFlag === '' ? '' : currentCert.ActiveFlag,
      agency: currentCert.Agency === '' ? '' : currentCert.Agency,
      county: currentCert.County,
      buDescription: selectedEmployee.BU_Name,
      issueDate:
        currentCert.IssueDate === null
          ? moment()
          : currentCert.IssueDate
            ? moment(currentCert.IssueDate).format('YYYY-MM-DD')
            : currentCert.CertIssueDate,
      expDate:
        currentCert.ExpirationDate || currentCert.ExpirationDate !== null
          ? moment(currentCert.ExpirationDate).format('YYYY-MM-DD')
          : moment()
            .add(1, 'Year')
            .format('YYYY-MM-DD'),
      prevExpDate: currentCert.CertExpDate,
      stateCode: singleCertState,
      firstName: selectedEmployee.FirstName,
      lastName: selectedEmployee.LastName,
      certNumber: njCertNumber.length > 0 ? njCertNumber : currentCert.CertNumber,
      CertName: currentCert.CertName === '' ? 'ABLS' : currentCert,
      frontImage: currentCert.FrontImage ? currentCert.FrontImage.split(',')[1] : '',
      backImage: currentCert.BackImage ? currentCert.BackImage.split(',')[1] : '',
      businessUnit: selectedEmployee.BusUnit,
      isMissingCertificate: currentCert.CertStatus === 'Missing' ? true : false,
      EmpNum: selectedEmployee.OracleEmpNum,
      expiring: currentCert.Expiring,
      dataSource: currentCert.DataSource,
      manuallyVerified: this.state.manuallyVerified,
      manuallyVerifiedBy: this.state.manuallyVerifiedBy,
      manuallyVerifiedByID: this.state.manuallyVerifiedByID,
      manuallyVerifiedDate: this.state.manuallyVerifiedDate,
      regulatoryApproved: this.state.regulatoryApproved === true ? '1' : '0',
      regulatoryApprovedBy: this.state.regulatoryApprovedBy,
      regulatoryApprovedByID: this.state.regulatoryApprovedByID,
      regulatoryApprovedDate: this.state.regulatoryApprovedDate,
      regulatoryApprovedImage: this.state.regulatoryApprovedImage ? this.state.regulatoryApprovedImage.split(',')[1] : '',
      manufacturerName: currentCert.Manufacturer ? currentCert.Manufacturer : '',
      modelNumber: currentCert.ModelNumber ? currentCert.ModelNumber : '',
      respiratorType: currentCert.RespiratorType ? currentCert.RespiratorType : '',
      respiratorSize: currentCert.RespiratorSize ? currentCert.RespiratorSize : '',
    };
    if (certName.length > 0 && certName.includes('-')) {
      this.setState({ stateCode: certName.trim().slice(0, 2) })
    }
    this.setState({ preUpdatePayload: JSON.stringify(payload), docTypeID: singleDocType ? singleDocType.docTypeId : currentCert.Document_Type_ID });
  };

  onDropImage = (pictureFile, pictureDataURL) => {
    if (pictureDataURL.length === 1) {
      this.setState({
        regulatoryApprovedImage: pictureDataURL[0]
      });
    } else {
      this.setState({ regulatoryApprovedImage: '' });
      toast.dismiss();
      toast.error('Please upload one image at a time.', {
        position: 'bottom-center'
      });
    }
  };

  onDropFront = (pictureFile, pictureDataURL) => {
    if (pictureDataURL.length === 1) {
      this.setState({
        frontImage: pictureDataURL[0]
      });
    } else {
      this.setState({ frontImage: '' });
      toast.dismiss();
      toast.error('Please upload one image at a time.', {
        position: 'bottom-center'
      });
    }
  };

  onDropBack = (pictureFile, pictureDataURL) => {
    if (pictureDataURL.length === 1) {
      this.setState({
        backImage: pictureDataURL[0]
      });
    } else {
      this.setState({ backImage: '' });
      toast.dismiss();
      toast.error('Please upload one image at a time.', {
        position: 'bottom-center'
      });
    }
  };

  onSetIssueDate = (issueDate) => {
    var aDate = moment(issueDate, 'MM-DD-YYYY', true);
    const certName = this.props.current ? this.props.current.CertName : this.state.certName;

    if (this.state.expirationDate !== '' && moment(issueDate) >= moment(this.state.expirationDate) && aDate.isValid()) {
      toast.dismiss();
      toast.error('Issue Date should be a valid Date and cannot be after the Expiration Date.', {
        position: 'bottom-center'
      });
    } else if (certName === "Respiratory Fit Test" || certName === "Safe Stretcher Handling" || certName === "EVOC") {
      if (moment(issueDate).isAfter(new Date)) {
        toast.dismiss();
        toast.error('Issue date cannot be a future date.', {
          position: 'bottom-center'
        });
    }
      const expDate = certName === 'Safe Stretcher Handling' ? '12-31-2099' : certName !== 'EVOC' ? moment(issueDate, 'MM/DD/YYYY').add(1, 'years') : '';
      this.setState({
        issueDate,
        displayIssueDate: moment(issueDate).format('MM/DD/YYYY'),
        expirationDate: expDate,
        displayExpirationDate: expDate ? moment(expDate).format('MM/DD/YYYY') : '',
      });
    } else {
      this.setState({
        manuallyVerified: false,
        hideManuallyVerified: true,
        issueDate,
        displayIssueDate: moment(issueDate).format('MM/DD/YYYY'),
        ...(this.state.docTypeID == '259' ? {
          expirationDate: moment(issueDate).add(1, 'year'),
          displayExpirationDate: moment(issueDate).add(1, 'year').format('MM/DD/YYYY'),
        } : {})
      });
    }
  };

  onTodoChange(value) {
    this.setState({ CertNumber: value });
  }

  onSetExpirationDate = (expirationDate) => {
    var aDate = moment(expirationDate, 'MM-DD-YYYY', true);

    if (this.state.issueDate !== '' && moment(expirationDate) <= moment(this.state.issueDate) && aDate.isValid()) {
      toast.dismiss();
      toast.error('Expiration Date should be valid Date and cannot be before the Issue Date.', {
        position: 'bottom-center'
      });
    } else {
      this.setState({
        expirationDate,
        displayExpirationDate: moment(expirationDate).format('MM/DD/YYYY'),
        manuallyVerified: false,
        hideManuallyVerified: true,
      });
    }
  };

  //PDF Page Change Functionality
  handlePDFPageChange = (myPageNumber) => {
    this.setState({ pageNumber: myPageNumber });
  };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };

  handleFrontPDFPageChange = (myPageNumber) => {
    this.setState({ frontPageNumber: myPageNumber });
  };

  onFrontDocumentLoad = ({ numPages }) => {
    this.setState({ frontNumPages: numPages });
  };

  handleBackPDFPageChange = (myPageNumber) => {
    this.setState({ backPageNumber: myPageNumber });
  };

  onBackDocumentLoad = ({ numPages }) => {
    this.setState({ backNumPages: numPages });
  };

  handleRegulatoryPDFPageChange = (myPageNumber) => {
    this.setState({ regulatoryPageNumber: myPageNumber });
  };

  onRegulatoryDocumentLoad = ({ numPages }) => {
    this.setState({ regulatoryNumPages: numPages });
  };

  handleCertificatePDFPageChange = (myPageNumber) => {
    this.setState({ certificatePageNumber: myPageNumber });
  };

  onCertificateDocumentLoad = ({ numPages }) => {
    this.setState({ certificateNumPages: numPages });
  };

  handleRawIssueDateChange = (rawIssueDate) => this.setState({ rawIssueDate, displayIssueDate: '', ...(this.state.docTypeID == '259' ? { rawExpirationDate: moment(rawIssueDate.add(1, 'year')), displayExpirationDate: '' } : {}) });

  handleRawExpirationDateChange = (rawExpirationDate) => this.setState({ rawExpirationDate, displayExpirationDate: '' });

  onIssueDateFocus = () => {
    // Clears field if invalid
    if (moment(this.state.issueDate).format('MM/DD/YYYY') === 'Invalid date') {
      this.setState({
        issueDate: '',
        displayIssueDate: '',
        rawIssueDate: ''
      });
    }
  };

  onExpirationDateFocus = () => {
    // Clears field if invalid
    if (moment(this.state.expirationDate).format('MM/DD/YYYY') === 'Invalid date') {
      this.setState({
        expirationDate: '',
        displayExpirationDate: '',
        rawExpirationDate: ''
      });
    }
  };

  onIssueDateBlur = () => {
    // If there's valid raw input, set issueDate and displayIssueDate to moment(rawInput) and clear rawIssueDate
    // If there's raw input but it's invalid, clear everything and warn user to enter another issue date

    const { rawIssueDate, expirationDate } = this.state;
    if (rawIssueDate !== '' && moment(rawIssueDate).format('MM/DD/YYYY') === 'Invalid date') {
      if (!this.props.addCert) {
        this.setState({
          issueDate: moment(this.props.currentCert.CertIssueDate).format('MM/DD/YYYY'),
          displayIssueDate: moment(this.props.currentCert.CertIssueDate).format('MM/DD/YYYY'),
          rawIssueDate: moment(this.props.currentCert.CertIssueDate).format('MM/DD/YYYY'),
          ...(this.state.docTypeID == "259" ? {
            expirationDate: moment(this.props.currentCert.CertIssueDate).add(1, 'year').format('MM/DD/YYYY'),
            displayExpirationDate: moment(this.props.currentCert.CertIssueDate).add(1, 'year').format('MM/DD/YYYY'),
            rawExpirationDate: moment(this.props.currentCert.CertIssueDate).add(1, 'year').format('MM/DD/YYYY')
          } : {})
        });
      } else {
        this.setState({
          issueDate: '',
          displayIssueDate: '',
          rawIssueDate: '',
          ...(this.state.docTypeID == "259" ? {
            expirationDate: '',
            displayExpirationDate: '',
            rawExpirationDate: ''
          } : {})
        });
      }
      toast.dismiss();
      toast.warn('Please enter a valid issue date.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

    if (rawIssueDate !== '' && moment(rawIssueDate).format('MM/DD/YYYY') !== 'Invalid date') {
      if (expirationDate !== '' && moment(rawIssueDate) >= moment(expirationDate)) {
        this.setState({
          issueDate: '',
          displayIssueDate: '',
          rawIssueDate: '',
          ...(this.state.docTypeID == "259" ? {
            expirationDate: '',
            displayExpirationDate: '',
            rawExpirationDate: ''
          } : {})
        });
        toast.dismiss();
        toast.error('Issue Date cannot be after the Expiration Date.', {
          position: 'bottom-center'
        });
      } else {
        this.setState({
          issueDate: moment(rawIssueDate).format('MM/DD/YYYY'),
          displayIssueDate: moment(rawIssueDate).format('MM/DD/YYYY'),
          rawIssueDate: '',
          ...(this.state.docTypeID == "259" || this.state.docTypeID == '242' ? {
            expirationDate: moment(rawIssueDate).add(1, 'year').format('MM/DD/YYYY'),
            displayExpirationDate: moment(rawIssueDate).add(1, 'year').format('MM/DD/YYYY'),
            rawExpirationDate: ''
          } : {})
        });
      }
    }
  };

  onExpirationDateBlur = () => {
    // If there's valid raw input, set expirationDate and displayExpirationDate to moment(rawInput) and clear rawExpirationDate
    // If there's raw input but it's invalid, clear everything and warn user to enter another expiration date
    const { rawExpirationDate, issueDate } = this.state;
    if (rawExpirationDate !== '' && moment(rawExpirationDate).format('MM/DD/YYYY') === 'Invalid date') {
      if (!this.props.addCert) {
        this.setState({
          expirationDate: this.props.currentCert.CertExpDate ? moment(this.props.currentCert.CertExpDate).format('MM/DD/YYYY') : '',
          displayExpirationDate: this.props.currentCert.CertExpDate ? moment(this.props.currentCert.CertExpDate).format('MM/DD/YYYY') : '',
          rawExpirationDate: this.props.currentCert.CertExpDate ? moment(this.props.currentCert.CertExpDate).format('MM/DD/YYYY') : ''
        });
      } else {
        this.setState({
          expirationDate: '',
          displayExpirationDate: '',
          rawExpirationDate: ''
        });
      }

      toast.dismiss();
      toast.warn('Please enter a valid expiration date.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

    if (rawExpirationDate !== '' && moment(rawExpirationDate).format('MM/DD/YYYY') !== 'Invalid date') {
      if (issueDate !== '' && moment(rawExpirationDate) <= moment(issueDate)) {
        this.setState({
          expirationDate: '',
          displayExpirationDate: '',
          rawExpirationDate: ''
        });
        toast.dismiss();
        toast.error('Expiration Date cannot be before the Issue Date.', {
          position: 'bottom-center'
        });
      } else {
        this.setState({
          expirationDate: moment(rawExpirationDate).format('MM/DD/YYYY'),
          displayExpirationDate: moment(rawExpirationDate).format('MM/DD/YYYY'),
          rawExpirationDate: ''
        });
      }
    }
  };

  onCertNameSelect = (e, certName) => {
    let text = !certName ? e.target[e.target.selectedIndex].text : '';
    let value;
// console.log('e target value', e.target[e.target.selectedIndex].text)
    if (certName) {
      text = certName;
    }
    const checkIsSafetyCert = text === 'EVOC' || text === 'Safe Stretcher Handling' ? true : false;
    this.setState({ isSafetyCert: checkIsSafetyCert });

    if (text === 'Respiratory Fit Test') {
      this.setState({
        manufacturerName: null,
        modelNumber: null,
        respiratorSize: null,
        respiratorType: null,
        testResults: null,
        isRespFitTest: true,
      });
    } else {
      this.setState({
        isRespFitTest: false,
      });
    }

    const docTypeSelected = this.props.documentTypes.find((cert) => cert.docType === text);
    value = docTypeSelected ? docTypeSelected.docTypeId : null;
    const isExpiring = (docTypeSelected && docTypeSelected.isExpiring) || '';
    const { stateValues, countyValues, selectedEmployeeCerts } = this.props;

    this.setState({
      certName: text,
      validFormatPlaceHolder: '',
      docType: text,
      docTypeID: value,
      docId: text,
      isExpiring,
      agency: '',
      enableEcardBtn: false
    });

    let firstWord = text.split(' ')[0];
    const currentCertData = selectedEmployeeCerts.find(v => v.Document_Type_ID == value);
    if (currentCertData && currentCertData.Disposition == 'REJECTED') {
      const isStateCert = stateValues.filter(v => v.code == currentCertData.StateCode);
      const isCountyCert = countyValues.filter(v => v.Name == currentCertData.County);
      if (isStateCert.length && !isCountyCert.length) this.setState({ isStateCertificate: true, stateCode: '' });
      if (isCountyCert.length) this.setState({ isCountyCertificate: true, countyCode: '' });
    } else {
      if (text.toLowerCase().includes('state') || text.toLowerCase().includes('county') || firstWord.toLowerCase() === 'drivers') {
        this.setState({ isStateCertificate: true, stateCode: '' });
      } else {
        this.setState({ isStateCertificate: false });
      }

      if (firstWord.toLowerCase() === 'county') {
        this.setState({ isCountyCertificate: true, countyCode: '' });

      } else {
        this.setState({ isCountyCertificate: false });
      }
    }

    if (text === 'Drivers License-Canada') {
      this.setState({ stateCode: 'CD', countyCode: '' });
    }

    this.checkIfIsEcard(value);

    //return this.renderCovidVaccineTemplate();
  };

  filterCountyValues = () => {
    const { countyValues, selectedEmployeeCerts } = this.props;
    const { isCountyCertificate, docTypeID, stateCode } = this.state;
    let filteredCountyValues = countyValues;

    if (isCountyCertificate && stateCode) {
      filteredCountyValues = filteredCountyValues.filter((county) => county.ST === stateCode);
      for (let i = 0; i < selectedEmployeeCerts.length; i++) {
        if (docTypeID === selectedEmployeeCerts[i].Document_Type_ID) {
          if (!['REJECTED', 'INACTIVE'].includes(selectedEmployeeCerts[i].Disposition))
            filteredCountyValues = filteredCountyValues.filter((county) => selectedEmployeeCerts[i].County !== county.Name);
        }
      }
    } else {
      filteredCountyValues = [{ Name: 'No counties for this cert', ST: '' }];
    }
    return filteredCountyValues;
  };

  filterAllCountyValues = () => {
    const { allCountyValues } = this.props;
    let filterAllCountyValues = [{ CountyName: 'No counties for this cert', State: '' }];
    if (allCountyValues != null && this.state.isCountyCertificate && this.state.stateCode) {
      filterAllCountyValues = allCountyValues.countyValues.filter((user) => user.State == this.state.stateCode);
    }
    return filterAllCountyValues;
  };

  filterStateValues = () => {
    const { stateValues, selectedEmployeeCerts } = this.props;
    const { isStateCertificate, docTypeID } = this.state;

    let filteredStateValues = stateValues;
    if (!isStateCertificate) {
      let stateDisabled = [{ name: 'No states for this cert', code: '' }];
      filteredStateValues = stateDisabled;
    }
    return filteredStateValues;
  };

  filterStateValuesEdit = () => {
    const { stateValues, selectedEmployeeCerts } = this.props;
    const { isStateCertificate, docTypeID } = this.state;
    let filteredStateValues = stateValues;
    if (!isStateCertificate) {
      let stateDisabled = [{ name: 'No states for this cert', code: '' }];
      filteredStateValues = stateDisabled;
    }
    return filteredStateValues;
  };

  handleCertificationChange = (event) => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    this.setState({ userIsManuallyVerifyingCert: true });
    if (this.state.manuallyVerified) {
      this.setState({
        manuallyVerified: event.target.checked,
        manuallyVerifiedBy: '',
        manuallyVerifiedDate: '',
        manuallyVerifiedByID: ''
      });
    } else {
      this.setState({
        manuallyVerified: event.target.checked,
        manuallyVerifiedBy: currentAdmin.FirstName + ' ' + currentAdmin.LastName,
        manuallyVerifiedDate: moment(new Date()).format('MM/DD/YYYY HH:mm'),
        manuallyVerifiedByID: currentAdmin.OracleEmpNum
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  handleRegulatoryChange = (event) => {
    let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
    if (this.state.regulatoryApproved) {
      this.setState({
        regulatoryApproved: event.target.checked,
        regulatoryApprovedBy: '',
        regulatoryApprovedDate: '',
        regulatoryApprovedByID: '',
        manuallyVerified: false
      });
    } else {
      this.setState({
        regulatoryApproved: event.target.checked,
        regulatoryApprovedBy: currentAdmin.FirstName + ' ' + currentAdmin.LastName,
        regulatoryApprovedDate: moment(new Date()).format('MM/DD/YYYY HH:mm'),
        regulatoryApprovedByID: currentAdmin.OracleEmpNum,
        manuallyVerified: false
      });
    }
  };

  //Enable when eVerify gets added again (10 lines)
  // async handleEVerifyStatus() {
  //   const response = await this.props.eVerifyStatus(this.state.certNumber);
  //   this.setState({ eVerifyStatus: response.eVerifyStatus[0] });
  //   if (this.state.eVerifyStatus) {
  //     this.setState({ eVerifiedStatusMessage: 'Verified' });
  //     // this.setState({ agency: this.state.eVerifyStatus.Agency });
  //   } else {
  //     this.setState({ eVerifiedStatusMessage: 'Certificate could not be verified' });
  //   }
  // }

  //Enable when eVerify gets added again(7 lines)
  // handleEVerify(currentImage) {
  //   this.props.updateExistingCert(this.state, true, this.state.preUpdatePayload);
  //   if ((this.state.frontImage && this.state.backImage) || currentImage) {
  //     this.setState({ isLoadingSidePanel: true });
  //     setTimeout(() => this.setState({ checkingEVerifyStatus: true, isLoadingSidePanel: false }), 20000);
  //   }
  // }

  async handleECard() {
    if (this.state.certNumber) {
      this.setState({ isLoadingSidePanel: true });
      let eCardType = this.state.agency == 'American Heart Association' || 'AHA' ? 'AHA' : '';
      let employee = JSON.parse(localStorage.getItem('tokenEmployee'));
      const response = await this.props.eCardStatus(this.state.certNumber, eCardType, employee.OracleEmpNum);
      const eCard = response.ecard;
      let eFullName = Object.keys(eCard).length > 0 ? eCard.fullName.split(' ') : '';
      let eFirstName = Object.keys(eCard).length > 0 ? eFullName[0].substring(0, 3) : '';
      let eLastName = Object.keys(eCard).length > 0 ? eFullName[1].substring(0, 3) : '';
      let currentFirstName = employee.FirstName.substring(0, 3);
      let currentLastName = employee.LastName.substring(0, 3);
      if (
        Object.keys(eCard).length == 0 ||
        (eFirstName.toLowerCase() != currentFirstName.toLowerCase() && eLastName.toLowerCase() != currentLastName.toLowerCase())
      ) {
        this.setState({ isLoadingSidePanel: false });
        toast.error('eCard could not be verified. Please add credential manually.', {
          position: 'bottom-center'
        });
      } else {
        this.setState({ isLoadingSidePanel: false });
        let currentIssuedDate = moment(this.issueDate).format('YYYY-MM-DD');
        let currentExpDate = moment(this.expirationDate).format('YYYY-MM-DD');
        let eExpDate = moment(eCard.expDate).format('YYYY-MM-DD');
        let eIssuedDate = moment(eCard.issueDate).format('YYYY-MM-DD');

        if (currentIssuedDate != eIssuedDate || currentExpDate != eExpDate || this.state.certNumber != eCard.certNumber) {
          //const eCardFrontImage = 'data:application/pdf;base64, ' + eCard.frontImage;
          const eCardFrontImage = 'data:image/jpeg;base64, ' + eCard.frontImage;
          const eCardBackImage = eCard.backImage;
          // console.log(eCardFrontImage);
          this.setState({
            frontImage: eCardFrontImage,
            backImage: eCardBackImage,
            issueDate: eIssuedDate,
            expirationDate: eExpDate,
            certNumber: eCard.certNumber,
            eCardVerified: true,
            isBackImageRequired: false
          });
        }
      }
    } else {
      toast.error('Certification Number is required.', {
        position: 'bottom-center'
      });
    };
  }

  downloadPDF(doc, format) {
    const linkSource = `data:application/pdf;base64,${doc}`;
    const downloadLink = document.createElement('a');
    const fileName = 'Document' + format;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  disableEnableRegulatory() {
    if (!!this.state.frontImage && !!this.state.backImage && this.state.isRegulatoryEnabled) {
      this.setState({ isRegulatoryEnabled: false });
    } else {
      if (this.props.currentCert.CertImage) {
        this.setIsRegualtoryEnabled(this.props.currentCert.CertImage);
      }
    }
  }
  ///////////New input file ///////////////
  onChangeHandlerFront = (event) => {
    if (!event.target.files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
      toast.error('Please select valid image (jpg, jpeg, png, pdf).', {
        position: 'bottom-center'
      });
      event.target.value = null;
    } else if (event.target.files[0].size > 1000000) {
      toast.error(
        'The image size must be less than 2 MB.  Please reduce the size of the image and reload.  The image has not been saved to this certification.',
        {
          position: 'bottom-center'
        }
      );
      event.target.value = null;
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.setState({ frontImage: e.target.result, manuallyVerified: false, hideManuallyVerified: true });
        this.disableEnableRegulatory();
      };
    }
  };

  onChangeHandlerBack = (event) => {
    if (!event.target.files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
      toast.error('Please select valid image (jpg, jpeg, png, pdf).', {
        position: 'bottom-center'
      });
      event.target.value = null;
    } else if (event.target.files[0].size > 1000000) {
      toast.error(
        'The image size must be less than 2 MB.  Please reduce the size of the image and reload.  The image has not been saved to this certification.',
        {
          position: 'bottom-center'
        }
      );
      event.target.value = null;
    } else {
      let reader2 = new FileReader();
      reader2.readAsDataURL(event.target.files[0]);
      reader2.onload = (e) => {
        this.setState({ backImage: e.target.result, manuallyVerified: false, hideManuallyVerified: true });
        this.disableEnableRegulatory();
      };
    }
  };

  onChangeHandlerRegulatory = (event) => {
    if (!event.target.files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
      toast.error('Please select valid image (jpg, jpeg, png, pdf).', {
        position: 'bottom-center'
      });
      event.target.value = null;
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.setState({ regulatoryApprovedImage: e.target.result });
      };
    }
  };

  onSelectManufacturer = (e) => {
    this.setState({ manufacturerName: e.target.value, modelNumber: '', respiratorSize: '', respiratorType: '', manuallyVerified: false, hideManuallyVerified: true });

    switch (e.target.value.toLowerCase()) {
      case '3m':
        this.setState({
          filteredModels: [
            { label: '1804', value: '1804' },
            { label: '1860', value: '1860' },
            { label: '1870', value: '1870' },
            { label: '8200', value: '8200' },
            { label: '8210', value: '8210' },
            { label: '9205', value: '9205' },
            { label: '1804S', value: '1804S' },
            { label: '1860S', value: '1860S' },
            { label: '1870+', value: '1870+' },
            { label: '8110S', value: '8110S' },
            { label: '9210+', value: '9210+' },
            { label: '9502+', value: '9502+' },
          ]
        });
        break;
      case 'honeywell':
        this.setState({
          filteredModels: [
            { label: '14110441', value: '14110441' },
            { label: '14110439', value: '14110439' },
            { label: 'DC365N95HC', value: 'DC365N95HC' },
          ]
        });
        break;
      case 'moldex':
        this.setState({
          filteredModels: [
            { label: '1510', value: '1510' },
            { label: '1511', value: '1511' },
            { label: '1512', value: '1512' },
            { label: '1513', value: '1513' },
            { label: '1517', value: '1517' },
            { label: '2200N95', value: '2200N95' },
            { label: '2201N95', value: '2201N95' },
            { label: '4700N100', value: '4700N100' },
            { label: 'M2600N95', value: 'M2600N95' },
          ]
        });
        break;
      case 'o&m halyard':
        this.setState({
          filteredModels: [
            { label: '26827', value: '26827' },
            { label: '46727', value: '46727' },
            { label: '46767', value: '46767' },
          ]
        });
      case 'prestige':
        this.setState({
          filteredModels: [
            { label: 'RP88010', value: 'RP88010' },
            { label: 'RP88020', value: 'RP88020' },
          ]
        });
        break;
      default:
        this.setState({ filteredModels: [] });
        break;
    }
  }

  onSelectModel = (e) => {
    this.setState({ modelNumber: e.target.value, manuallyVerified: false, hideManuallyVerified: true });
    switch (e.target.value.toUpperCase()) {
      case '1804':
      case '1804':
      case '1860':
      case '1870':
      case '8200':
      case '8210':
      case '9205':
      case '1870+':
      case '9210+':
      case '9502+':
      case '46727':
      case '46767':
      case 'DC365N95HC':
      case 'RP88020':
        this.setState({ respiratorSize: 'One Size Fits Most', filteredSizes: [{ label: 'One Size Fits Most', value: 'One Size Fits Most' }] });
        break;
      case '1510':
        this.setState({ respiratorSize: 'X-Small', filteredSizes: [{ label: 'X-Small', value: 'X-Small' }] });
        break;
      case '1804S':
      case '1860S':
      case '8110S':
      case '1511':
      case '2201N95':
      case 'RP88010':
      case '14110439':
        this.setState({ respiratorSize: 'Small', filteredSizes: [{ label: 'Small', value: 'Small' }] });
        break;
      case '1512':
      case '1517':
        this.setState({ respiratorSize: 'Medium', filteredSizes: [{ label: 'Medium', value: 'Medium' }] });
        break;
      case '2200N95':
      case '4700N100':
        this.setState({
          filteredSizes: [
            { label: 'Medium', value: 'Medium' },
            { label: 'Large', value: 'Large' },
          ]
        });
        break;
      case '1513':
      case 'M2600N95':
        this.setState({ respiratorSize: 'Large', filteredSizes: [{ label: 'Large', value: 'Large' }] });
        break;
      case '14110441':
        this.setState({ respiratorSize: 'X-Large', filteredSizes: [{ label: 'X-Large', value: 'X-Large' }] });
      default:
        this.setState({ filteredSizes: [] });
        break;
    }

    switch (e.target.value.toUpperCase()) {
      case '14110441':
      case '14110439':
        this.setState({ respiratorType: 'P100', filteredTypes: [{ label: 'P100', value: 'P100' }] });
        break;
      case '4700N100':
        this.setState({ respiratorType: 'N100', filteredTypes: [{ label: 'N100', value: 'N100' }] });
        break;
      default:
        this.setState({ respiratorType: 'N95', filteredTypes: [{ label: 'N95', value: 'N95' }] });
        break;
    }
  }
  //////////////////////////////////////////
  frontPDF = (image) => {
    return (
      <Fragment>
        <div>
          {this.state.frontNumPages > 1 && !this.state.newItem ? (
            <Pagination
              activePage={this.state.frontPageNumber}
              itemsCountPerPage={1}
              totalItemsCount={this.state.frontNumPages}
              pageRangeDisplayed={10}
              onChange={this.handleFrontPDFPageChange}
            />
          ) : null}
          <div>
            <Document className="thumbnailPDF" file={image} onLoadSuccess={this.onFrontDocumentLoad}>
              <Page pageNumber={this.state.frontPageNumber} />
            </Document>
          </div>
        </div>
      </Fragment>
    );
  };

  backPDF = (image) => {
    return (
      <Fragment>
        <div>
          {this.state.backNumPages > 1 && !this.state.newItem ? (
            <Pagination
              activePage={this.state.backPageNumber}
              itemsCountPerPage={1}
              totalItemsCount={this.state.backNumPages}
              pageRangeDisplayed={10}
              onChange={this.handleBackPDFPageChange}
            />
          ) : null}
          <div>
            <Document className="thumbnailPDF" file={image} onLoadSuccess={this.onBackDocumentLoad}>
              <Page pageNumber={this.state.backPageNumber} />
            </Document>
          </div>
        </div>
      </Fragment>
    );
  };

  regulatoryPDF = (image) => {
    return (
      <Fragment>
        <div>
          {this.state.regulatoryNumPages > 1 && !this.state.newItem ? (
            <Pagination
              activePage={this.state.regulatoryPageNumber}
              itemsCountPerPage={1}
              totalItemsCount={this.state.regulatoryNumPages}
              pageRangeDisplayed={10}
              onChange={this.handleRegulatoryPDFPageChange}
            />
          ) : null}
          <div>
            <Document className="thumbnailPDF" file={image} onLoadSuccess={this.onRegulatoryDocumentLoad}>
              <Page pageNumber={this.state.regulatoryPageNumber} />
            </Document>
          </div>
        </div>
      </Fragment>
    );
  };

  certificatePDF = (image) => {
    return (
      <Fragment>
        <div>
          {this.state.certificateNumPages > 1 && !this.state.newItem ? (
            <Pagination
              activePage={this.state.certificatePageNumber}
              itemsCountPerPage={1}
              totalItemsCount={this.state.certificateNumPages}
              pageRangeDisplayed={10}
              onChange={this.handleCertificatePDFPageChange}
            />
          ) : null}
          <div>
            <Document className="thumbnailPDF" file={image} onLoadSuccess={this.onCertificateDocumentLoad}>
              <Page pageNumber={this.state.certificatePageNumber} />
            </Document>
          </div>
        </div>
      </Fragment>
    );
  };
  myPDF = (image) => {
    const { pageNumber, numPages } = this.state;
    return (
      <Fragment>
        <div>
          {this.state.numPages > 1 && !this.state.newItem ? (
            <Pagination
              activePage={this.state.pageNumber}
              itemsCountPerPage={1}
              totalItemsCount={this.state.numPages}
              pageRangeDisplayed={10}
              onChange={this.handlePDFPageChange}
            />
          ) : null}
          <div>
            <Document className="thumbnailPDF" file={image} onLoadSuccess={this.onDocumentLoad.bind(this)}>
              <Page pageNumber={this.state.pageNumber} />
            </Document>
          </div>
        </div>
      </Fragment>
    );
  };

  renderSwitch = (image, imageToLoad) => {
    switch (imageToLoad) {
      case 'regulatory':
        return this.regulatoryPDF(image);
      case 'back':
        return this.backPDF(image);
      case 'front':
        return this.frontPDF(image);
      case 'certificate':
        return this.certificatePDF(image);
    }
  }
  displayImageBasedOnImageType = (image, imageToLoad) => {

    var format = '';
    var string64 = '';
    // const { pageNumber, numPages } = this.state;
    if (image) {
      const words = image.split(',');
      string64 = words[1];
      format = words[0]
        .split('/')
        .pop()
        .split(';')[0];
    }
    return (
      <Fragment>
        <Fragment>
          {image && !this.state.isVaccineDeclined ? (
            <div>
              <div className="cert-title">Current Certificate Photo</div>
              {format.toUpperCase() === 'PDF' ? (
                <div>{this.renderSwitch(image, imageToLoad)}</div>
              ) : (
                <div>
                  {format === '' || this.state.wordDocFormats.filter((docFormat) => docFormat.keyFormat === format).length > 0 ? (
                    <div className="cert-title-main">
                      <div className="detail-type">
                        This document type is not supported. Please download and convert to a PDF or supported image type (jpg, png).
                      </div>
                      <button
                        className={'panel-btn-center'}
                        href="#"
                        onClick={() => {
                          {
                            let documentFormat = this.state.wordDocFormats
                              .filter((item) => item.keyFormat == format)
                              .map(({ formatValue }) => ({ formatValue }))
                              .map((a) => a.formatValue);
                            // this.downloadPDF(string64, documentFormat);
                            downloadBase64File(image, 'download', documentFormat.length ? documentFormat[0] : 'docx');
                          }
                        }}
                      >
                        Download Document
                      </button>
                    </div>
                  ) : (
                    <div>
                      {/*<img src={image ? image : Certificate} className="thumbnail" alt="certificate" />*/}
                      <ImageResize src={image ? image : Certificate} />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="cert-title-main">
              <div className="profile-heading">There is no image available for this Certificate</div>
            </div>
          )}
        </Fragment>
      </Fragment>
    );
  };
  resetState = () => this.setState(INITIAL_STATE);

  renderSingleImageUploader = () => {
    return (
      <Fragment>
        {this.state.regulatoryApprovedImage ? (
          <Fragment>
            <button className="clear-cert" onClick={() => this.setState({ regulatoryApprovedImage: '' })}>
              Clear Regulatory Approved Image
            </button>
            <div>{this.displayImageBasedOnImageType(this.state.regulatoryApprovedImage, 'regulatory')}</div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="cert-title">Upload Regulatory Approved Image</div>
            <div>
              <input type="file" name="file" accept="image/png,image/jpg,image/jpeg,application/pdf" onChange={this.onChangeHandlerRegulatory} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  renderEcard = () => {
    const { enableEcardBtn } = this.state;
    //let disableBtn = this.state.isEcard && (this.state.agency == 'American Heart Association' || this.state.agency == 'AHA') ? false : true;
    return (
      <Fragment>
        <div className="form-group-stacked form-group-half">
          <button
            className={!enableEcardBtn ? 'panel-btn-center-disabled' : 'panel-btn-center'}
            href="#"
            disabled={!enableEcardBtn}
            onClick={() => {
              this.handleECard();
            }}
          >
            E-Card
          </button>
        </div>
      </Fragment>
    );
  };

  renderExpirationDatePicker = (displayExpirationDate, currentCert) => {
    return (
      <Fragment>
        <div className="form-group-stacked form-group-half">
          <span className="form-group-stacked-label">Expiration Date</span>
          {currentCert.Expiring === true ? (
            <DatePicker
              selected={this.state.expirationDate ? moment(this.state.expirationDate) : null}
              onSelect={this.onSetExpirationDate}
              onChangeRaw={(event) => this.handleRawExpirationDateChange(event.target.value)}
              onFocus={this.onExpirationDateFocus}
              onBlur={this.onExpirationDateBlur}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              className="form-group-text"
              placeholderText="Enter Expiration Date"
              value={displayExpirationDate !== '' && displayExpirationDate !== 'Invalid date' ? displayExpirationDate : null}
              readOnly={false}
              disable={this.state.docTypeID == '259'}
            />
          ) : (
            'N/A'
          )}
        </div>
      </Fragment>
    );
  };

  renderFileUploader = () => {
    const { docTypeID, agency } = this.state;
    const { currentCert } = this.props;
    const { documentTypes } = this.props;
    let _docTypeId = docTypeID;
    const isViewingTerminatedEmployee = JSON.parse(localStorage.getItem('historicalEmployeeData'));
    if (Object.values(this.doctypeMap).includes(Number(docTypeID))) {
      _docTypeId = Object.keys(this.doctypeMap).reduce((acc, curr) => {
        if (this.doctypeMap[curr] == docTypeID) return curr;
        return acc;
      }, '')
    }
    let currentDocType = documentTypes.filter(item => item.docTypeId == _docTypeId);
    if (currentDocType.length)
      currentDocType = currentDocType[0];
    return (
      <Fragment>
        {currentDocType && /Y/i.test(currentDocType.IsFrontImageRequired) ? (this.state.frontImage ? (
          <Fragment>
            <button
              className="clear-cert"
              onClick={() => {
                this.setState({ frontImage: '', eCardVerified: false, backImage: this.state.eCardVerified ? '' : this.state.backImage });
                this.disableEnableRegulatory();
              }}
              disabled={isViewingTerminatedEmployee}
            >
              Clear Front Image
            </button>
            <div>
              <div className="cert-title">Front Image</div>
              {this.displayImageBasedOnImageType(this.state.frontImage, 'front')}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="cert-title">Upload Front Image</div>
            <div>
              <input
                type="file"
                name="file"
                accept="image/png,image/jpg,image/jpeg, application/pdf"
                onChange={this.onChangeHandlerFront}
                disabled={!docTypeID || /(American Heart Association|AHA)/i.test(agency) || (currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || isViewingTerminatedEmployee}
              />
            </div>
          </Fragment>
        )) : null}
        {!this.state.eCardVerified && (
          <Fragment>
            {currentDocType && /Y/i.test(currentDocType.IsBackImageRequired) ? (this.state.backImage ? (
              <Fragment>
                <button
                  onClick={() => {
                    this.setState({ backImage: '' });
                    this.disableEnableRegulatory();
                  }}
                  className="clear-cert"
                  disabled={isViewingTerminatedEmployee}
                >
                  Clear Back Image
                </button>
                <div>
                  <div className="cert-title">Back Image</div>
                  {this.displayImageBasedOnImageType(this.state.backImage, 'back')}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="cert-title">Upload Back Image</div>
                <div>
                  <input
                    type="file"
                    name="file"
                    accept="image/png,image/jpg,image/jpeg, application/pdf"
                    onChange={this.onChangeHandlerBack}
                    disabled={
                      !docTypeID || /(American Heart Association|AHA)/i.test(agency) || (currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (isViewingTerminatedEmployee)
                    }
                  />
                </div>
              </Fragment>
            )) : null}
          </Fragment>
        )}
      </Fragment>
    );
  };

  renderCovidVaccineTemplate = (filteredDocumentTypes) => {
    const { certName, documentTypes, covidManufacturers, covidDose, addCert, currentCert } = this.props;
    const { docType, docTypeID, wordDocFormats } = this.state;
    return (
      <CovidVaccine
        docType={docType}
        documentTypes={documentTypes}
        covidManufacturersList={covidManufacturers}
        docTypeID={docTypeID}
        covidDose={covidDose}
        filteredDocumentTypes={filteredDocumentTypes}
        onCertNameSelect={this.onCertNameSelect}
        renderSwitch={this.renderSwitch}
        wordDocFormats={wordDocFormats}
        {...this.props}
      />
    );
  };

  renderCertificateHistoryLog = () => {
    const { certificateHistoryLog, createdBy, updateSource, createDate } = this.state;
    const sortedCertHistoryLog = certificateHistoryLog.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    return (
      <div>
        <div style={{ marginBottom: 5, borderBottom: sortedCertHistoryLog.length > 0 ? '2px solid rgb(212, 212, 212)' : 'none' }}>
          Cert added from {!updateSource ? 'NA' : updateSource.includes('Admin') ? `${updateSource} Tool` : updateSource}: {createdBy} on {moment.utc(createDate).local().format('MM/DD/YYYY')} at {moment.utc(createDate).local().format('h:mm A')}
        </div>
        <div style={{ overflow: 'auto', maxHeight: '200px' }}>
          {sortedCertHistoryLog.map((historyLog) => (
            <div>
              {historyLog.manuallyVerified && (
                <div style={{ marginBottom: 5 }}>
                  Cert changed to {historyLog.manuallyVerified}: {historyLog.user} on {moment.utc(historyLog.date).local().format('MM/DD/YYYY')} at {moment.utc(historyLog.date).local().format('h:mm A')}
                </div>
              )}
              {historyLog.activeFlag && (
                <div style={{ marginBottom: 5 }}>
                  Cert changed to {historyLog.activeFlag}: {historyLog.user} on {moment.utc(historyLog.date).local().format('MM/DD/YYYY')} at {moment.utc(historyLog.date).local().format('h:mm A')}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  handleVaccinedDenyChange = (event) => {
    if (event.target.checked) {
      if (window.confirm('All fields will be cleared. Do you wish to Continue?'))
        this.setState({
          isVaccineDeclined: event.target.checked,
          issueDate: moment().format('MM/DD/YYYY'),
          displayIssueDate: moment().format('MM/DD/YYYY'),
          rawIssueDate: moment().format('MM/DD/YYYY'),
          certNumber: '',
          agency: '',
          expirationDate: moment().add(1, 'year').format('MM/DD/YYYY'),
          displayExpirationDate: moment().add(1, 'year').format('MM/DD/YYYY'),
          rawExpirationDate: moment().add(1, 'year').format('MM/DD/YYYY'),
          manuallyVerified: false
        });
      if (!this.props.addCert) {
        //Setting current cert image to Failed because we should not be sending an image when Vaccine is declined
        //and API will be expecting Failed
        this.props.currentCert.CertImage = 'Failed';
      }
    }
    else this.setState({ isVaccineDeclined: event.target.checked, manuallyVerified: false })
  };

  render() {
    const {
      addCert,
      panelVisible,
      currentCert,
      selectedEmployeeCerts,
      documentTypes,
      stateValues,
      certStatusValues,
      allCountyValues,
      eVerify,
      individualCertName
    } = this.props;
    const {
      fullName,
      displayIssueDate,
      displayExpirationDate,
      agencyValues,
      docTypeID,
      agency,
      isVaccineDeclined,
      filteredModels,
      filteredTypes,
      filteredSizes,
      isRespFitTest,
      createdBy,
      updateSource,
      certificateHistoryLog,
    } = this.state;
    // Filter out current employee certs that aren't state, county or driver license
    /*const filteredCertIDs =
      selectedEmployeeCerts.length > 0
        ? selectedEmployeeCerts
          .filter((cert) => {
            let firstWord = cert.CertName.split(' ')[0];
            return (
              firstWord.toLowerCase() !== 'state' &&
              firstWord.toLowerCase() !== 'drivers' &&
              firstWord.toLowerCase() !== 'county' &&
              firstWord.toLowerCase() !== 'covid'
            );
          })
   
          .map((cert) => cert.Document_Type_ID)
        : [];
    const filteredDocumentTypes = documentTypes.filter((doc) => !filteredCertIDs.includes(doc.docTypeId));*/
    const filteredDocumentTypes = documentTypes.filter((doc) => {
      const cert = _.find(selectedEmployeeCerts, { Document_Type_ID: doc.docTypeId });
      return !cert || (cert && doc.AllowDuplicates === 'Y') || (["INACTIVE", "MISSING", "REJECTED"].includes(cert.Disposition) && !selectedEmployeeCerts.find(cer => cer.Document_Type_ID === doc.docTypeId && cer.Disposition === "CURRENT"));
    });
    let filteredStateValues = this.filterStateValues();
    let filteredCountyValues = this.filterCountyValues();
    let filterStateValuesEdit = this.filterStateValuesEdit();
    let filterAllCountyValues = this.filterAllCountyValues();

    const isViewingTerminatedEmployee = JSON.parse(localStorage.getItem('historicalEmployeeData'));
    const adminProfile = JSON.parse(sessionStorage.getItem('adminProfile'));
    const isSavingDisabled = adminProfile ? adminProfile.result[0].isAdminTool : false;

    if (panelVisible && currentCert && !individualCertName) {
      // if (/1007|166/.test(docTypeID)) {
      if (this.state.docTypeID == '1007') {
        return this.renderCovidVaccineTemplate(filteredDocumentTypes);
      } else {
        return (
          <div>
            {/* <Form onSubmit={this.handleSubmit.bind(this)}> */}
            <div className="side-panel">
              <Loadable active={this.state.isLoadingSidePanel} spinner text="Loading...">
                <div>
                  <div className="panel-btn-group">
                      <button
                        className="panel-btn"
                        href="#"
                        disabled={this.props.isLoading}
                        onClick={() => {
                          this.props.updateExistingCert(this.state, false, this.state.preUpdatePayload);
                        }}
                      >
                        Save Updates
                      </button>
                  </div>
                  <MdClose size={25} color="#3a9ef0" className="icon-close" onClick={this.props.closeSidePanel} />
                  <div className="cert-info">
                    <div className="border-box">
                      <div className="cert-title-main">
                        <div className="profile-heading">{currentCert.CertName}</div>
                      </div>
                      <div className="form-group">
                        {!this.state.isSafetyCert && !this.state.certName.toLowerCase().includes('drivers license') && (
                          <div className="form-group-stacked form-group-half">
                            <span className="form-group-stacked-label">Certification Number</span>
                            {
                              <input
                                maxLength={100}
                                className="form-group-text"
                                type="text"
                                onChange={(event) => this.setState({ certNumber: event.target.value, manuallyVerified: false })}
                                value={this.state.certNumber === '' || this.state.certNumber === 'NA' ? '' : this.state.certNumber}
                                placeholder={this.state.certNumber === 'NA' ? "" : this.state.certNumber}
                                disabled={(currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined) || (isViewingTerminatedEmployee)}
                              />
                            }
                          </div>
                        )}
                        {this.state.certName.toLowerCase().includes('drivers license') && (
                          <div className="form-group-stacked form-group-half">
                            <span className="form-group-stacked-label">Certificate Number</span>
                            <input
                              maxLength={100}
                              className="form-group-text"
                              type="text"
                              onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^[a-zA-Z0-9]*$/; 
                                if (value.match(regex) || value === "") {
                                  this.setState({ certNumber: value, manuallyVerified: false });
                                }
                              }}
                              value={this.state.certNumber === '' || this.state.certNumber === 'NA' ? '' : this.state.certNumber}
                              placeholder={this.state.certNumber === 'NA' ? "" : this.state.certNumber}
                              disabled={(currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined) || (isViewingTerminatedEmployee)}
                            />
                          </div>
                        )}

                        <div className="form-group-stacked form-group-half">
                          <span className="form-group-stacked-label">Issue Date</span>
                          {
                            <DatePicker
                              selected={this.state.issueDate ? moment(this.state.issueDate) : null}
                              onSelect={this.onSetIssueDate}
                              onChangeRaw={(event) => this.handleRawIssueDateChange(event.target.value)}
                              onFocus={this.onIssueDateFocus}
                              onBlur={this.onIssueDateBlur}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              className="form-group-text"
                              placeholderText="Enter Issue Date"
                              value={displayIssueDate !== '' && displayIssueDate !== 'Invalid date' ? displayIssueDate : moment()}
                              readOnly={false}
                              disabled={/(American Heart Association|AHA)/i.test(agency) || (currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined) || (isViewingTerminatedEmployee)}
                            />
                          }
                        </div>
                        <div className="form-group-stacked form-group-half">
                          <span className="form-group-stacked-label">Expiration Date</span>
                          {currentCert.Expiring === true ? (
                            <DatePicker
                              selected={this.state.expirationDate ? moment(this.state.expirationDate) : null}
                              onSelect={this.onSetExpirationDate}
                              onChangeRaw={(event) => this.handleRawExpirationDateChange(event.target.value)}
                              onFocus={this.onExpirationDateFocus}
                              onBlur={this.onExpirationDateBlur}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              className="form-group-text"
                              placeholderText="Enter Expiration Date"
                              value={displayExpirationDate !== '' && displayExpirationDate !== 'Invalid date' ? displayExpirationDate : null}
                              readOnly={false}
                              disabled={
                                /(American Heart Association|AHA)/i.test(agency) ||
                                (currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) ||
                                (docTypeID == '259') ||
                                currentCert.CertName === "Respiratory Fit Test" ||
                                currentCert.CertName === "Safe Stretcher Handling" ||
                                isViewingTerminatedEmployee
                              }
                            />
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </div>

                      {currentCert.CertName === "Respiratory Fit Test" && (
                        <div>
                          {this.state.manufacturerName !== null && (
                            <>
                              <span className="form-group-stacked-label">Respirator Make/Manufacturer</span>
                              <div className="form-group row">
                                {/* <div className="col-8"> */}
                                <input
                                  maxLength={100}
                                  className="form-group-text"
                                  style={{ flex: 6, marginRight: 10 }}
                                  type="text"
                                  onChange={(e) => this.onSelectManufacturer(e)}
                                  value={this.state.manufacturerName}
                                  placeholder={this.state.manufacturerName}
                                  disabled={isViewingTerminatedEmployee}
                                // disabled={(currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined)}
                                />
                                {/* </div> */}
                                {/* <div className="col-sm-4"> */}
                                <select
                                  className="form-group-select-options"
                                  value=""
                                  // disabled={(currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined)}
                                  onChange={(e) => this.onSelectManufacturer(e)}
                                >
                                  <option value="" hidden></option>
                                  {makeManufacturerList.map((option) => (
                                    <option key={option.label} value={option.value}>
                                      {option.value}
                                    </option>
                                  ))}
                                </select>
                                {/* </div> */}
                              </div>
                            </>
                          )}

                          {this.state.modelNumber !== null && (
                            <>
                              <span className="form-group-stacked-label">Model Number</span>
                              <div className="form-group row">
                                <input
                                  maxLength={100}
                                  className="form-group-text"
                                  style={{ flex: 6, marginRight: 10 }}
                                  type="text"
                                  onChange={(e) => this.onSelectModel(e)}
                                  value={this.state.modelNumber}
                                  placeholder={this.state.modelNumber}
                                  disabled={isViewingTerminatedEmployee}
                                />
                                <select
                                  className="form-group-select-options"
                                  value=""
                                  onChange={(e) => this.onSelectModel(e)}
                                >
                                  <option value="" hidden></option>
                                  {filteredModels.length === 0 ?
                                    modelNumberList.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                    : filteredModels.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                  }
                                </select>
                              </div>
                            </>
                          )}

                          {this.state.respiratorType !== null && (
                            <>
                              <span className="form-group-stacked-label">Respirator Type</span>
                              <div className="form-group row">
                                <input
                                  maxLength={100}
                                  className="form-group-text"
                                  style={{ flex: 6, marginRight: 10 }}
                                  type="text"
                                  onChange={(event) => this.setState({ respiratorType: event.target.value, manuallyVerified: false, hideManuallyVerified: true })}
                                  value={this.state.respiratorType}
                                  placeholder={this.state.respiratorType}
                                  disabled={isViewingTerminatedEmployee}
                                />
                                <select
                                  className="form-group-select-options"
                                  value=""
                                  onChange={(e) => this.setState({ respiratorType: e.target.value, manuallyVerified: false, hideManuallyVerified: true })}
                                >
                                  <option value="" hidden></option>
                                  {filteredTypes.length === 0 ?
                                    respiratorTypeList.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                    : filteredTypes.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                  }
                                </select>
                              </div>
                            </>
                          )}

                          {this.state.respiratorSize !== null && (
                            <>
                              <span className="form-group-stacked-label">Respirator Size</span>
                              <div className="form-group row">
                                <input
                                  maxLength={100}
                                  className="form-group-text"
                                  style={{ flex: 6, marginRight: 10 }}
                                  type="text"
                                  onChange={(event) => this.setState({ respiratorSize: event.target.value, manuallyVerified: false, hideManuallyVerified: true })}
                                  value={this.state.respiratorSize}
                                  placeholder={this.state.respiratorSize}
                                  disabled={isViewingTerminatedEmployee}
                                />
                                <select
                                  className="form-group-select-options"
                                  value=""
                                  onChange={(e) => this.setState({ respiratorSize: e.target.value, manuallyVerified: false, hideManuallyVerified: true })}
                                >
                                  <option value="" hidden></option>
                                  {filteredSizes.length === 0 ?
                                    respiratorSizeList.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                    : filteredSizes.map((option) => (
                                      <option key={option.label} value={option.value}>
                                        {option.value}
                                      </option>
                                    ))
                                  }
                                </select>
                              </div>
                            </>
                          )}

                          {this.state.testResults !== null && (
                            <>
                              <span className="form-group-stacked-label">Test Results</span>
                              <div className="form-group row">
                                <select
                                  className="form-group-select-options"
                                  value={this.state.testResults}
                                  onChange={(e) => this.setState({ testResults: e.target.value, manuallyVerified: false, hideManuallyVerified: true })}
                                  disabled={isViewingTerminatedEmployee}
                                >
                                  <option value="">Select Test Result</option>
                                  {testResultList.map((option) => (
                                    <option key={option.label} value={option.value}>
                                      {option.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {!this.state.isSafetyCert && (
                        <>
                          <div className="form-group">
                            <div className="form-group-stacked form-group-half">
                              <span className="form-group-stacked-label">Agency</span>
                              {
                                <select
                                  className="form-group-select-options"
                                  value={this.state.agency}
                                  disabled={(currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (docTypeID == '259' && isVaccineDeclined) || (isViewingTerminatedEmployee)}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState(
                                      (prevState) => ({ agency: value, enableEcardBtn: false, manuallyVerified: false, hideManuallyVerified: true }),
                                      () => {
                                        if (this.state.isEcard && /(American Heart Association|AHA)/i.test(value)) {
                                          if (!alert('AHA has been selected, please use eCard. Otherwise remove AHA to manually enter')) {
                                            this.setState({ enableEcardBtn: true });
                                          }
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <option value="">Select Agency</option>
                                  {agencyValues.map((option) => (
                                    <option key={option.Code} value={option.Description}>
                                      {option.Description}
                                    </option>
                                  ))}
                                </select>
                              }
                            </div>

                            {this.renderEcard()}
                          </div>

                          <div className="form-group">
                          {this.state.stateCode !== 'CD' && (
                            <div className="form-group-stacked form-group-half">
                              <span className="form-group-stacked-label">State</span>
                              <select
                                disabled={!this.state.isStateCertificate || (docTypeID == '259' && isVaccineDeclined) || (isViewingTerminatedEmployee)}
                                className="form-group-select-options"
                                value={this.state.stateCode}
                                onChange={(e) => {
                                  this.setState({ stateCode: e.target.value, countyCode: '', manuallyVerified: false, hideManuallyVerified: true });
                                  this.getValidDLFormat(e.target.value, currentCert.CertName.toLowerCase());
                                }}
                              >
                                {this.state.stateCode === '' && this.state.isStateCertificate && (
                                  <option value="" disabled hidden>
                                    Select State
                                  </option>
                                )}
                                {filterStateValuesEdit.map((option) => (
                                  <option key={option.code + option.name} value={option.code}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                            <div className="form-group">
                              {this.state.stateCode === 'NJ' && (
                                <div className="form-group-stacked">
                                  <span className="form-group-stacked-label">ZIP Code</span>
                                  <input
                                    className="form-group-text"
                                    type="number"
                                    value={this.state.zipCode}
                                    onChange={(e) => {
                                      this.setState({ zipCode: e.target.value });
                                    }}
                                    disabled={(this.state.stateCode !== 'NJ')}
                                    />
                                </div>
                              )}
                            </div>
                            <div className="form-group-stacked form-group-half">
                              <span className="form-group-stacked-label">County</span>
                              <select
                                disabled={!(this.state.isCountyCertificate && this.state.stateCode) || (isViewingTerminatedEmployee)}
                                className="form-group-select-options"
                                value={this.state.countyCode}
                                onChange={(e) => {
                                  this.setState({ countyCode: e.target.value, manuallyVerified: false, hideManuallyVerified: true });
                                }}
                              >
                                {this.state.countyCode === '' && this.state.isCountyCertificate && (
                                  <option value="" disabled hidden>
                                    Select County
                                  </option>
                                )}
                                {filterAllCountyValues.map((option) => (
                                  <option key={option.CountyName + option.State} value={option.CountyName}>
                                    {option.CountyName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group">
                        <div className="form-group-stacked">
                          <span className="form-group-stacked-label">Cert Status</span>
                          <select
                            className="form-group-select-options"
                            value={this.state.activeFlag}
                            onChange={(e) => {
                              this.setState({ activeFlag: e.target.value });
                            }}
                            disabled={isViewingTerminatedEmployee}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* //Enable this when eVerify gets added again(47 lines) */}
                    {/* <div className="border-box">
                    <div className="form-group">
                      <div className="form-group-stacked form-group-half">
                        <span className="form-group-stacked-label">E-verify Status</span>
                        <div>
                          {this.state.eVerifyStatus ? (
                            <div>
                              <div> Verified on {moment(this.state.eVerifyStatus.VerifiedDate).format('MM/DD/YYYY')}</div>
                            </div>
                          ) : (
                            <div>{this.state.eVerifiedStatusMessage}</div>
                          )}
                        </div>
                      </div>
                      {!this.state.checkingEVerifyStatus ? (
                        <div className="form-group-stacked form-group-half">
                          {
                            <button
                              className={this.state.isEverifyCertificate ? 'panel-btn' : 'panel-btn-disabled'}
                              href="#"
                              disabled={!this.state.isEverifyCertificate}
                              onClick={() => {
                                this.handleEVerify(currentCert.CertImage);
                              }}
                            >
                              E-Verify
                            </button>
                          }
                        </div> */}
                    {/* ) : (
                        <div className="form-group-stacked form-group-half">
                          {
                            <div
                              className="panel-btn"
                              href="#"
                              disabled={this.props.isLoading}
                              onClick={() => {
                                this.handleEVerifyStatus();
                              }}
                            >
                              Check Status
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div> */}

                    {!this.state.hideManuallyVerified && (
                      <div className="border-box">
                        <div className="form-group">
                          <div className="form-group-stacked">
                            <div className="form-group-stacked-label">
                              <label>
                                <Checkbox
                                  checked={this.state.manuallyVerified}
                                  onChange={this.handleCertificationChange}
                                  disabled={currentCert && /INACTIVE/i.test(currentCert.ActiveFlag) || (isViewingTerminatedEmployee)}
                                />
                                <span style={{ marginLeft: 8 }}>Certification Manually Verified</span>
                              </label>
                              {this.state.manuallyVerified && (
                                <div>
                                  <label className="panel-lbl">
                                    <span style={{ marginLeft: 11 }}>
                                      Certification was verified by {this.state.manuallyVerifiedBy} on {this.state.manuallyVerifiedDate}
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.regulatoryApproved && (moment(this.state.regulatoryApprovedDate) < moment('10/13/2022', 'MM/DD/YYYY')) && <div className="border-box">
                      <div className="form-group">
                        <div className="form-group-stacked">
                          <div className="form-group-stacked-label">
                            <label style={this.state.isRegulatoryEnabled ? { opacity: 0.3 } : { opacity: 1.0 }}>
                              <Checkbox
                                checked={this.state.regulatoryApproved}
                                disabled={this.state.isRegulatoryEnabled || (currentCert && /INACTIVE/i.test(currentCert.ActiveFlag)) || (isViewingTerminatedEmployee)}
                                onChange={this.handleRegulatoryChange}
                              />
                              <span style={{ marginLeft: 8 }}>Regulatory Approved Extension</span>
                            </label>
                            {this.state.regulatoryApproved && (
                              <div>
                                <label className="panel-lbl">
                                  <span style={{ marginLeft: 11 }}>
                                    {this.state.regulatoryApprovedBy} on {this.state.regulatoryApprovedDate}
                                  </span>
                                </label>
                                {this.renderSingleImageUploader()}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>}
                    {this.state.docTypeID == '259' && (<div className="form-group">
                      <div className="form-group-stacked">
                        <div className="form-group-stacked-label">
                          <label>
                            <span style={{ marginRight: 8 }}>Vaccine Declined:</span>
                            <Checkbox
                              checked={isVaccineDeclined}
                              onChange={this.handleVaccinedDenyChange}
                              disabled={isViewingTerminatedEmployee}
                            />
                          </label>
                        </div>
                      </div>
                    </div>)}
                    <div className="border-box">
                      {this.props.currentCert.Disposition !== 'MISSING' && !this.state.eCardVerified && this.displayImageBasedOnImageType(currentCert.CertImage, 'certificate')}
                      {this.renderFileUploader()}
                    </div>
                    {certificateHistoryLog && (
                      <div className="border-box">
                        {/* <div>Created By: <span style={{ fontWeight: 'bold' }}>{createdBy} from {!updateSource ? 'NA' : updateSource.includes('Admin') ? `${updateSource} Tool` : updateSource}</span></div> */}
                        {/* <div>Source: <span style={{ fontWeight: 'bold' }}>{!updateSource ? 'NA' : updateSource.includes('Admin') ? `${updateSource} Tool` : updateSource}</span></div> */}
                          {this.renderCertificateHistoryLog()}
                      </div>
                    )}
                  </div>
                </div>
              </Loadable>
            </div >
            {/* </Form> */}
          </div >
        );
      }
    } else if (addCert) {
      if (/1007/.test(docTypeID)) {
        return this.renderCovidVaccineTemplate(filteredDocumentTypes);
      } else {
        return (
          <div>
            {/* <Form onSubmit={this.handleSubmit.bind(this)}> */}
            <div className="side-panel">
              {!isSavingDisabled && (
                <div className="panel-btn-group">
                  <div
                    className="panel-btn"
                    href="#"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.props.addNewCert(this.state);
                    }}
                  >
                    Add/Update
                  </div>
                </div>
              )}
              <MdClose size={25} color="#3a9ef0" className="icon-close" onClick={this.props.closeSidePanel} />

              <div className="cert-info">
                <div className="border-box">
                  <div className="form-group">
                    {individualCertName ?
                      <div className="cert-title-main">
                        <div className="profile-heading">{individualCertName.includes('-') ? individualCertName.trim().slice(3) : individualCertName.trim()}</div>
                      </div>
                      :
                      <div className="form-group-stacked">
                        <span className="form-group-stacked-label">Certificate Name</span>
                        <select className="form-group-select-options" value={docTypeID} onChange={this.onCertNameSelect} disabled={docTypeID == '259' && isVaccineDeclined}>
                          <option value="">Select Certificate</option>
                          {filteredDocumentTypes.map((option) => (
                            <option key={option.docTypeId} value={option.docTypeId}>
                              {option.docType}
                            </option>
                          ))}
                        </select>
                      </div>
                    }
                  </div>

                  {!this.state.isSafetyCert && !this.state.certName.toLowerCase().includes('drivers license') &&(
                    <div className="form-group">
                        <div className="form-group-stacked">
                          <span className="form-group-stacked-label">Certificate Number</span>
                          <input
                            className="form-group-text"
                            type="text"
                            //placeholder="Certificate Number"
                            placeholder={this.state.validFormatPlaceHolder}
                            value={this.state.certNumber}
                            onChange={(e) => {
                              this.setState({ certNumber: e.target.value });
                            }}
                            disabled={(docTypeID == '259' && isVaccineDeclined) || !docTypeID}
                            />
                        </div>
                      </div>
                  )}
                  {this.state.certName.toLowerCase().includes('drivers license') && (
                    <div className="form-group">
                      <div className="form-group-stacked">
                        <span className="form-group-stacked-label">Certificate Number</span>
                        <input
                          className="form-group-text"
                          type="text"
                          //placeholder="Certificate Number"
                          placeholder={this.state.validFormatPlaceHolder}
                          value={this.state.certNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[a-zA-Z0-9]*$/; 
                            if (value.match(regex) || value === "") {
                              this.setState({ certNumber: value });
                            }
                          }}
                          disabled={(docTypeID == '259' && isVaccineDeclined) || !docTypeID}
                          />
                      </div>
                    </div>
                  )}
                  {!this.state.isSafetyCert && (
                    <>
                      <div className="form-group">
                        <div className="form-group-stacked form-group-half">
                          <span className="form-group-stacked-label">Agency</span>
                          {
                            <select
                              className="form-group-select-options"
                              value={this.state.agency}
                              onChange={(e) => {
                                const value = e.target.value;
                                this.setState(
                                  (prevState) => ({ agency: value, enableEcardBtn: false }),
                                  () => {
                                    if (this.state.isEcard && /(American Heart Association|AHA)/i.test(value)) {
                                      if (!alert('AHA has been selected, please use eCard. Otherwise remove AHA to manually enter')) {
                                        this.setState({ enableEcardBtn: true });
                                      }
                                    }
                                  }
                                );
                              }}
                              disabled={(docTypeID == '259' && isVaccineDeclined) || !docTypeID}
                            >
                              <option value="">Select Agency</option>
                              {agencyValues.map((option) => (
                                <option key={option.Code} value={option.Description}>
                                  {option.Description}
                                </option>
                              ))}
                            </select>
                          }
                        </div>
                        {this.renderEcard()}
                      </div>
                      <div className="form-group">
                        {this.state.stateCode !== 'CD' && (
                          <div className="form-group-stacked">
                            <span className="form-group-stacked-label">State</span>
                            <select
                              className="form-group-select-options"
                              disabled={!(this.state.isStateCertificate && docTypeID)}
                              value={this.state.stateCode}
                              onChange={(e) => {
                                this.setState({ stateCode: e.target.value, countyCode: '' });
                                this.getValidDLFormat(e.target.value, this.state.docType.toLowerCase());
                              }}
                            >
                              {this.state.isStateCertificate && (
                                <option value="" disabled hidden>
                                  Select State
                                </option>
                              )}
                              {filteredStateValues.map((option) => (
                                <option key={option.code + option.name} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        {this.state.stateCode === 'NJ' && (
                          <div className="form-group-stacked">
                            <span className="form-group-stacked-label">ZIP Code</span>
                            <input
                              className="form-group-text"
                              type="number"
                              value={this.state.zipCode}
                              onChange={(e) => {
                                this.setState({ zipCode: e.target.value });
                              }}
                              disabled={(this.state.stateCode !== 'NJ')}
                              />
                          </div>
                        )}
                        </div>
                      <div className="form-group">
                        <div className="form-group-stacked">
                          <span className="form-group-stacked-label">County</span>
                          <select
                            className="form-group-select-options"
                            value={this.state.countyCode}
                            disabled={!(this.state.isCountyCertificate && docTypeID && this.state.stateCode)}
                            onChange={(e) => {
                              this.setState({ countyCode: e.target.value });
                            }}
                            >
                            {this.state.isCountyCertificate && (
                              <option value="" disabled hidden>
                                Select County
                              </option>
                            )}
                            {filteredCountyValues.map((option) => (
                              <option key={option.Name + option.ST} value={option.Name}>
                                {option.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <div className="form-group-stacked form-group-half">
                      <span className="form-group-stacked-label">Issue Date</span>
                      <DatePicker
                        selected={this.state.issueDate ? moment(this.state.issueDate) : null}
                        onSelect={this.onSetIssueDate}
                        onChangeRaw={(event) => this.handleRawIssueDateChange(event.target.value)}
                        onFocus={this.onIssueDateFocus}
                        onBlur={this.onIssueDateBlur}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="form-group-text"
                        placeholderText="Enter Issue Date"
                        value={displayIssueDate !== '' && displayIssueDate !== 'Invalid date' ? displayIssueDate : null}
                        readOnly={false}
                        disabled={(docTypeID == '259' && isVaccineDeclined) || !docTypeID || /(American Heart Association|AHA)/i.test(agency)}
                      />
                    </div>
                    <div className="form-group-stacked form-group-half">
                      <span className="form-group-stacked-label">Expiration Date:</span>
                      {this.state.isExpiring === 'Y' ? (
                        <DatePicker
                          selected={this.state.expirationDate ? moment(this.state.expirationDate) : null}
                          onSelect={this.onSetExpirationDate}
                          onChangeRaw={(event) => this.handleRawExpirationDateChange(event.target.value)}
                          onFocus={this.onExpirationDateFocus}
                          onBlur={this.onExpirationDateBlur}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          className="form-group-text"
                          placeholderText="Enter Expiration Date"
                          value={displayExpirationDate !== '' && displayExpirationDate !== 'Invalid date' ? displayExpirationDate : null}
                          readOnly={false}
                          disabled={(
                            docTypeID == '259') || 
                            !docTypeID || 
                            /(American Heart Association|AHA)/i.test(agency) ||
                            isRespFitTest ||
                            currentCert.CertName === "Safe Stretcher Handling"
                          }
                          />
                          ) : (
                            'N/A'
                      )}
                    </div>
                  </div>
                  {this.state.docTypeID == '259' && (<div className="form-group">
                    <div className="form-group-stacked">
                      <div className="form-group-stacked-label">
                        <label>
                          <span style={{ marginRight: 8 }}>Vaccine Declined:</span>
                          <Checkbox
                            checked={isVaccineDeclined}
                            onChange={this.handleVaccinedDenyChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>)}
                </div>
                <div className="border-box">{this.renderFileUploader()}</div>
              </div>
            </div>
            {/* </Form> */}
          </div>
        );
      }
    } else {
      return <div className="hide" />;
    }
  }
}

export default Sidepanel;
