import AppInfo from '../../httpClient/AppInfo';

//Action Types
export const types = {
  FETCHING_PRIVACY_DATA: 'FETCHING_PRIVACY_DATA',
  FETCHING_PRIVACY_DATA_SUCCESS: 'FETCHING_PRIVACY_DATA_SUCCESS',
  FETCHING_PRIVACY_DATA_FAILURE: 'FETCHING_PRIVACY_DATA_FAILURE'
};

//Initial States
export const initialState = {
  privacy: null,
  isLoading: false,
  error: null
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PRIVACY_DATA:
      return { ...state, isLoading: true, error: null };

    case types.FETCHING_PRIVACY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        privacy: action.privacy,
        error: null
      };

    case types.FETCHING_PRIVACY_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

//Actions
export function fetchPrivacyStatements() {
  return function(dispatch) {
    dispatch({ type: types.FETCHING_PRIVACY_DATA });
    return AppInfo.getPrivacyStatements()
      .then(data => {
        dispatch({
          type: types.FETCHING_PRIVACY_DATA_SUCCESS,
          privacy: data.privacy
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCHING_PRIVACY_DATA_FAILURE, error: err });
      });
  };
}
