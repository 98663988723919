//react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/lib/md';
//css
import './Forms.css';

//components
import Heading from '../../components/Heading/Heading';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import ToastContainerComponent from '../../components/ToastContainerComponent/ToastContainerComponent';

// libraries
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import Loadable from 'react-loading-overlay';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Empty from '../../assets/images/empty.png';

// redux state
import { connect } from 'react-redux';
import { allActiveForms, updateForm, fetchAllJobTitles, resetDefaultValues } from './../../redux/modules/Forms';
import { setCurrentPath } from './../../redux/modules/Admin';

class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      restrictRole: false,
      allJobTitles: [],
      pageEditable: false,
      isLoading: false,
      newItem: false,
      allItems: [],
      searchPlaceholder: 'Filter Items by Form Name',
      searchFilters: ['formName'],
      filterResult: [],
      pagedResults: [],
      totalItemsCount: 0,
      searchText: '',
      activePage: 1,
      pageSize: 10,
      pageRangeDisplayed: 5,
      defaultErrorMessage: 'Something went wrong. Please try again in a few minutes',
      currentItem: {
        id: 0,
        jobTitles: []
      }
    };
  }

  //Triggered when page loads.
  componentDidMount() {
    const { currentCounty } = this.props.AdminReducer;
    this.fetchLatestRecords(currentCounty.businessUnit);
    this.fetchAllJobTitles();
    this.props.setCurrentPath('forms');
  }

  //When results return from server. Update the page UI.
  componentWillReceiveProps(nextProps) {
    const { allForms, jobTitles, isLoading, postedSuccessfully, error } = nextProps.FormsReducer;
    const { businessUnit: nextBusinessUnit } = nextProps.AdminReducer.currentCounty;
    const { businessUnit } = this.props.AdminReducer.currentCounty;
    this.setState({ isLoading: isLoading });

    // On switching business units
    if (businessUnit && businessUnit !== nextBusinessUnit) {
      this.setState({ activePage: 1, pageNumber: 1 });
    }

    if (postedSuccessfully) {
      toast.dismiss();
      toast.success('Success!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.props.resetDefaultValues();
      this.setState({ newItem: false });
    }
    if (error && error.response) {
      let message = this.state.defaultErrorMessage;
      toast.dismiss();
      toast.error(message, { position: 'bottom-center' });
    }

    this.setState({ allJobTitles: jobTitles });

    if (allForms && allForms.length > 0) {
      this.setState({ allItems: allForms, newItem: false }, this.updateContentList);
    } else {
      this.setState({ allItems: [], newItem: true }, this.updateContentList);
    }
  }

  //Triggered when the user selects a new business Unit.
  componentDidUpdate(prevProps) {
    const { currentCounty, countyName } = prevProps.AdminReducer;
    // Typical usage (don't forget to compare props):
    if (currentCounty && this.props.AdminReducer.countyName !== countyName) {
      this.fetchLatestRecords(this.props.AdminReducer.currentCounty.businessUnit);
    }
  }

  //Fetch page specific data here from the API.
  fetchLatestRecords = businessUnit => {
    this.props.allActiveForms(businessUnit);
  };

  fetchAllJobTitles = () => {
    this.props.fetchAllJobTitles();
  };

  clearCurrentItem = () => {
    this.setState({
      currentItem: {
        id: 0,
        jobTitles: []
      },
      newItem: true
    });
  };

  updateCurrentItem = () => {
    this.validateAllRequiredFields()
      .then(() => {
        const { id, formName, jobTitles } = this.state.currentItem;

        const { restrictRole } = this.state;

        let currentAdmin = JSON.parse(sessionStorage.getItem('admin'));
        const { currentCounty } = this.props.AdminReducer;

        let updateItem = {
          id: id,
          businessUnitID: currentCounty.businessUnit,
          mobileSecurityID: currentAdmin.ID,
          formName: formName,
          jobTitles: !restrictRole ? [] : jobTitles
        };

        let success = this.props.updateForm(updateItem);
        if (success) {
          const { currentCounty } = this.props.AdminReducer;
          this.fetchLatestRecords(currentCounty.businessUnit);
        }
      })
      .catch(err => {
        toast.dismiss();
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };

  //Current Page Specific Functionality:
  validateAllRequiredFields = (onSuccess, onFail) => {
    return new Promise((resolve, reject) => {
      const { jobTitles } = this.state.currentItem;
      if (!jobTitles) {
        toast.dismiss();
        reject('Job Titles are required to Post a New Form');
      }
      resolve();
    });
  };

  handleCheckbox = jobTitleID => {
    var currentjobTitles = this.state.currentItem.jobTitles;
    var index = currentjobTitles.indexOf(jobTitleID);
    index === -1 ? currentjobTitles.push(jobTitleID) : currentjobTitles.splice(index, 1);
    this.setState({
      currentItem: { ...this.state.currentItem, jobTitles: currentjobTitles }
    });
  };

  updateRestrictStatus = status => {
    this.setState({
      restrictRole: status
    });
  };

  //***NO CODE UPDATE REQUIRED - well...unless fixing a bug or adding new functionality  :)***/
  cancelAdd = () => {
    this.setState({ newItem: false });
    if (this.state.allItems.length > 0 && this.state.pagedResults.length > 0) {
      this.setState({
        restrictRole: this.state.pagedResults[this.state.currentIndex].jobTitles.length > 0,
        currentItem: this.state.pagedResults[this.state.currentIndex]
      });
    }
  };

  //Filter Content Function
  updateFilterResults = (searchText, filterResult) => {
    this.setState({ filterResult: filterResult, searchText: searchText, activePage: 1 }, this.updateContentList);
  };
  //End Filter Content Function

  //Paging Functionality
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, this.updateContentList);
  };

  updateContentList = () => {
    let currentPageNumber = this.state.activePage - 1;
    let arr =
      (this.state.filterResult && this.state.filterResult.length > 0) || this.state.searchText.length > 0
        ? this.state.filterResult
        : this.state.allItems;
    let groups = [],
      i;
    for (i = 0; i < arr.length; i += this.state.pageSize) {
      groups.push(arr.slice(i, i + this.state.pageSize));
    }
    this.setState({
      pagedResults: groups[currentPageNumber],
      totalItemsCount: groups.length
    });

    if (groups[currentPageNumber] && groups[currentPageNumber].length > 0) {
      this.setState({
        restrictRole: groups[currentPageNumber][this.state.currentIndex].jobTitles.length > 0,
        currentItem: groups[currentPageNumber][this.state.currentIndex]
      });
    }
  };

  changePageSize = e => {
    this.setState(
      {
        activePage: 1,
        pageSize: parseInt(e.target.value, 10)
      },
      this.updateContentList
    );
  };
  //End Paging Functions//
  //***NO CODE UPDATE REQUIRED***/

  render() {
    const {
      searchText,
      searchFilters,
      allItems,
      searchPlaceholder,
      pagedResults,
      isLoading,
      currentItem,
      activePage,
      totalItemsCount,
      pageRangeDisplayed,
      allJobTitles
    } = this.state;
    return (
      <div className="tile-page">
        <ToastContainerComponent />
        <Heading title="Forms" />

        {allItems.length > 0 ? (
          <div className="Form-content">
            <div className="left-container">
              <SearchFilter
                updateFilterResults={this.updateFilterResults}
                searchText={searchText}
                filters={searchFilters}
                data={allItems}
                placeholder={searchPlaceholder}
              />
              <div className="list-container">
                <Loadable active={isLoading} spinner text="Loading...">
                  <div>
                    {pagedResults !== undefined &&
                      pagedResults.length > 0 &&
                      pagedResults.map((item, index) => (
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          key={item.id}
                          tabIndex={0}
                          className={pagedResults[index].id === currentItem.id ? 'list-item selected' : 'list-item'}
                          onClick={() => {
                            this.setState({
                              restrictRole: item.jobTitles.length > 0,
                              currentItem: item,
                              newItem: false,
                              currentIndex: index
                            });
                          }}
                        >
                          <div className="list-item__left">
                            <div className="list-item__formtype">{item.formName}</div>
                          </div>
                          <div className="list-item__arrowright">
                            <MdKeyboardArrowRight size={25} color="grey" className="icon-arrowright" />
                          </div>
                        </div>
                      ))}
                  </div>
                </Loadable>
              </div>
              <div className="app-pagenation">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={1}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onChange={this.handlePageChange}
                />
                <select className="form-group-select-options-page" onChange={this.changePageSize}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>

            <div className="right-container">
              <div className="formtype-container">
                {this.state.currentItem.id > 0 && (
                  <div
                    style={{
                      display: this.state.allItems.length <= 0 ? 'none' : null
                    }}
                  >
                    <p className="form-title">
                      {currentItem.formName}
                      <button disabled={this.state.isLoading} onClick={this.updateCurrentItem} className="btn-long update">
                        Save Update
                      </button>
                    </p>

                    <div
                      className="role-container"
                      style={{
                        display: this.state.allItems.length <= 0 ? 'none' : null
                      }}
                    >
                      <label className={this.state.restrictRole ? 'role-select' : 'role-select defaultchecked'}>
                        Give Access to All
                        <input
                          type="checkbox"
                          className="role-select-input"
                          checked={!this.state.restrictRole}
                          onClick={() => {
                            this.setState({ restrictRole: false });
                          }}
                        />
                        <span className="role-select-checkmark" />
                      </label>
                      <label className={this.state.restrictRole ? 'role-select checked' : 'role-select'}>
                        Restrict Access to Certain Job Titles
                        <input
                          type="checkbox"
                          className="role-select-input"
                          checked={this.state.restrictRole}
                          onClick={() => {
                            this.setState({ restrictRole: true });
                          }}
                        />
                        <span className="role-select-checkmark" />
                      </label>
                      <div
                        style={{
                          display: this.state.restrictRole ? 'block' : 'none'
                        }}
                        className="restrict-role-group"
                      >
                        <div>
                          {allJobTitles !== undefined &&
                            allJobTitles.length > 0 &&
                            allJobTitles.map((item, index) => (
                              <label key={item.id} className="checkbox-group">
                                {item.jobTitle}
                                <input
                                  type="checkbox"
                                  className="checkbox-group-input"
                                  onChange={() => this.handleCheckbox(item.id)}
                                  checked={currentItem.jobTitles.indexOf(item.id) > -1}
                                />
                                <span className="checkbox-group-checkmark" />
                              </label>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="noaccess-empty">
            <img src={Empty} className="empty-image" />
            <p className="empty-message">Forms have not been setup for the selected Business Unit</p>
            <Link to="/">
              <div className="form-group">
                <button className="btn-long btn-long-white" href="#">
                  Back to Home
                </button>
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminReducer: state.AdminReducer,
    FormsReducer: state.FormsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    allActiveForms: businessUnitID => dispatch(allActiveForms(businessUnitID)),
    updateForm: params => dispatch(updateForm(params)),
    fetchAllJobTitles: () => dispatch(fetchAllJobTitles()),
    resetDefaultValues: () => dispatch(resetDefaultValues()),
    setCurrentPath: currentPath => dispatch(setCurrentPath(currentPath))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forms);
