//react
import React, { Component } from 'react';

//css
import './Terms.css';

//Redux
import { connect } from 'react-redux';
import { fetchTermsAndConditions } from './../../redux/modules/Terms';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: null,
      error: null
    };
    //fetch termsAndCondition from server
    this.props.fetchTermsAndConditions();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.TermsReducer.error,
      loading: nextProps.TermsReducer.isLoading,
      terms: nextProps.TermsReducer.terms
    });
  }

  render() {
    return (
      <div>
        {this.state.error}
        {this.state.terms}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    TermsReducer: state.TermsReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTermsAndConditions: () => dispatch(fetchTermsAndConditions())
  };
};

export default (Terms = connect(
  mapStateToProps,
  mapDispatchToProps
)(Terms));
