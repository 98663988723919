import RestApi from './RestApi';

class Documents {
  static allActiveDocuments(businessUnitID, mobileSecurityID) {
    const api = new RestApi();
    return api
      .GET('/documents?businessUnitID=' + encodeURIComponent(businessUnitID) + '&mobileSecurityID=' + encodeURIComponent(mobileSecurityID))
      .then(response => {
        return response;
      });
  }

  static postNewDocument(params) {
    const api = new RestApi();
    return api.POST('/documents', params).then(response => {
      return response;
    });
  }

  static updateDocument(params) {
    const api = new RestApi();
    return api.PUT('/documents', params).then(response => {
      return response;
    });
  }

  static deleteDocument(fileKey, businessUnitID, mobileSecurityID) {
    const api = new RestApi();
    return api
      .DELETE(
        '/documents?fileKey=' +
          encodeURIComponent(fileKey) +
          '&businessUnitID=' +
          encodeURIComponent(businessUnitID) +
          '&mobileSecurityID=' +
          encodeURIComponent(mobileSecurityID)
      )
      .then(response => {
        return response;
      });
  }

  static fetchCurrentPDF(file) {
    const api = new RestApi();
    return api.GET('/documents?type=documents&id=' + encodeURIComponent(file)).then(response => {
      return response;
    });
  }

  static fetchHelpPDF(file) {
    const api = new RestApi();
    return api.GET('/documents?url=' + encodeURIComponent(file)).then(response => {
      return response;
    });
  }


  static fetchAllJobTitles() {
    const api = new RestApi();
    return api.GET('/forms').then(response => {
      return response;
    });
  }

  static fetchAllCategories() {
    const api = new RestApi();
    return api.GET('/categories?type=document').then(response => {
      return response;
    });
  }

  static fetchCategoriesPerBU(id) {
    const api = new RestApi();
    return api.GET('/categories?userID=' + encodeURIComponent(id) + '&type=document').then(response => {
      return response;
    });
  }

  static postNewCategory(params) {
    const api = new RestApi();
    return api.POST('/categories', params).then(response => {
      return response;
    });
  }

  static deleteCategory(id, type, modifiedBy, mobileSecurityID) {
    const api = new RestApi();
    return api.DELETE(`/categories?id=${id}&catType=${type}&modifiedBy=${modifiedBy}&mobileSecurityID=${mobileSecurityID}`).then(response => {
      return response;
    });
  }
}

export default Documents;
