import RestApi from './RestApi';

class Admin {
  static loginAdmin(sub) {
    const payload = {
      sub: encodeURIComponent(sub)
    };
    const api = new RestApi();
    return api.POST('/admins', payload).then(response => {
      return response;
    });
  }

  static adminSettings(adminID) {
    const api = new RestApi();
    return api.GET('/admins?adminID=' + encodeURIComponent(adminID)).then(response => {
      return response;
    });
  }

  static adminProfile(adminID) {
    const api = new RestApi();
    return api.CONNECT_GET('/v1/Employees/employeeProfile?employeeID=' + encodeURIComponent(adminID)).then(response => {
      return response;
    });
  }
}

export default Admin;
