import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';

class MapComponent extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.distanceType !== nextProps.distanceType ||
      this.props.radius !== nextProps.radius ||
      this.props.latitude !== nextProps.latitude ||
      this.props.longitude !== nextProps.longitude
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { latitude, longitude, radius, id } = this.props;
    const GoogleMapCreate = withGoogleMap(() => (
      <GoogleMap defaultCenter={{ lat: latitude, lng: longitude }} defaultZoom={13} radius={1609.344}>
        <Marker title={'POSITION'} name={'POSITION'} position={{ lat: latitude, lng: longitude }} />
        <Circle
          strokeColor="#eaeaea"
          strokeOpacity={0.1}
          strokeWeight={0.1}
          fillColor="#eaeaea"
          fillOpacity={0.15}
          center={{ lat: latitude, lng: longitude }}
          radius={id > 0 ? radius : radius}
        />
      </GoogleMap>
    ));
    return <GoogleMapCreate containerElement={<div className="google-map" />} mapElement={<div style={{ height: '100%' }} />} />;
  }
}

export default MapComponent;
